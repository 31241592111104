const ufiFormatter = (ufi) => {
  let flightNum = ufi.slice(0, 7);
  let flightDate = ufi.slice(7, -8);
  let depArr = ufi.slice(-8);

  return `${flightNum}  ${flightDate}  ${depArr}`;
};

const ufiToEdgPath = (ufi, edg) => {
  let flightNum = ufi.slice(0, 7);
  let flightDate = ufi.slice(7, -8);
  let depArr = ufi.slice(-8);

  return `${flightNum}${depArr}${flightDate}/${flightNum}_${flightDate}_${depArr}`;
}

export {
  ufiFormatter,
  ufiToEdgPath
};