import { HTTP_REQUEST_TYPE, DISPATCHER_API } from "../constants/api";
import { sendHttpRequest } from "./api";

const getDispatchNotes = async (noteType) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DISPATCHER_API.GET_NOTE_LISTS,
        {noteType}
    );
    return resp;
};


const updateDispatchNote = async (updatedId, updatedObj) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        DISPATCHER_API.POST_UPDATE_NOTE,
        {
            updatedId: updatedId,
            updatedObj: updatedObj
        }
    );
    return resp;
};

const createDispatchNote = async (createObj) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        DISPATCHER_API.POST_CREATE_NOTE,
        {
            createObj: createObj
        }
    );
    return resp;
};

const deleteDispatchNote = async (deleteId) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        DISPATCHER_API.POST_DELETE_NOTE,
        {
            deleteId: deleteId
        }
    );
    return resp;
};

export {
    getDispatchNotes, updateDispatchNote, createDispatchNote, deleteDispatchNote
};