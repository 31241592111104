
const KeyValueContent = ({title, content}) => {
    return (
        <div style={{whiteSpace: "pre-wrap", fontSize: "14px", marginTop: "15px"}}>
            <span className={"CathaySansRg"}>{title} :  </span>
            <span className={"RobotoMonoRg"} style={{fontSize: "17px"}}>
                {(!!content ? content : "--")}
            </span>
        </div>
    )
};

export default KeyValueContent;
