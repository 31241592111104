import {CCol, CRow} from '@coreui/react-pro';
import React, {useEffect, useRef, useState} from 'react';
import {IPortSec, IPortTab} from '../../slice/dispatcherSlice';
import PortPageAttachment from './PortPageAttachment';
import CXButton from '../CXForm/CXButton';
import {postDeletePortFile, postUploadPortFile} from '../../services/portAPI';
import AddOrEditOFPLightBox from '../Sim/AddOrEditOFPLightBox';

interface IPortPageAttachmentContainerProps {
    portTabContents: IPortSec[],
    isEditMode: boolean;
    tabIndex: number;
    dispatchReducer: React.Dispatch<any>;
    downloadFile: Function;
    portInfo: any;
}

const PortPageAttachmentContainer = ({
    portTabContents,
    isEditMode,
    tabIndex,
    dispatchReducer,
    downloadFile,
    portInfo
}: IPortPageAttachmentContainerProps) => {


    const uploadFileRef = useRef<HTMLInputElement>(null);

    const triggerFileExplorer = () => {
        if (uploadFileRef?.current) {
            uploadFileRef.current.click();
        }
    };

    const selectFile = (e) => {
        if (e.target.files.length > 0) {
            let bodyFormData = new FormData();
            let fileList: any = Array.prototype.slice.call(e.target.files);

            fileList.forEach(element => {
                bodyFormData.append("files", element);
                bodyFormData.append("fileNames", element.name);
            });
            console.log(fileList);

            bodyFormData.append("galacxyId", portInfo.galacxyId);
            bodyFormData.append("airline", portInfo.airline);
            bodyFormData.append("icao", portInfo.icao);
            bodyFormData.append("created-by", "");
            for (const key in bodyFormData.entries()) {
                console.log(key)
                console.log(key[0], key[1]);
            }

            postUploadPortFile(bodyFormData).then(res => {
                if (res.status === 500) {
                    throw res;
                }

                dispatchReducer({type: "LOAD", port: res});
            }).catch(err => {
                if (err.status === 500) {
                    // dispatch(pushToast({ type: "error", message: "Import SIM template failed" }));
                } else {
                    console.log(err);
                }
            })
        }

        // fix: avoid select File not firing
        e.target.value = "";
    };

    const removeAttachment = (itemId, fileId, fileIndex) => {

        dispatchReducer({type: "REMOVE_ATTACHMENT", tabIndex: tabIndex, fileIndex: fileIndex});

        // const request = {
        //   ...portInfo,
        //   itemId,
        //   fileId
        // }

        // postDeletePortFile(request).then(res => {
        //   if (res.status === 500) {
        //     throw res;
        //   }
        //   if (res.status === "ok") {
        //     dispatchReducer({type:"LOAD", port: res});
        //   }

        //   // getTemplates();
        // }).catch(err => {
        //   if (err.status === 500) {
        //     // dispatch(pushToast({ type: "error", message: "Import SIM template failed" }));
        //   } else {
        //     console.log(err);
        //   }
        // })
    }

    const removeTab = (index) => {
        dispatchReducer({type: "REMOVE_CONTENT_SECTION", tabIndex: tabIndex, index: index});
    }

    const UploadAppendixFileButton = () => {
        return <CXButton
            onClick={() => triggerFileExplorer()}
            text={"Upload file"}
            outline={true}
            fontSize={"14px"}/>
    };


    return (
        <div className="port-page-content">
            <CRow>
                <CCol xs={4} className={'content-title'}>
                    {portTabContents[0].name}
                </CCol>
                <CCol xs={8}>
                    {isEditMode &&
                        <>
                            <AddOrEditOFPLightBox TriggerButton={UploadAppendixFileButton()} heading={"Upload file"}
                                                  submitButtonText={"Upload file"}/>
                            <input ref={uploadFileRef} onChange={selectFile} type="file" accept={".pdf,.jpg,.png"} hidden
                                   multiple></input>
                        </>
                    }
                    <div style={{marginTop: "44px"}}>
                        Files uploaded:
                    </div>
                    {portTabContents.map((content, index) => {
                        return <PortPageAttachment
                            key={`AttachmentTab_${index}`}
                            secItems={content.items}
                            removeAttachment={removeAttachment}
                            isEditMode={isEditMode}
                            downloadFile={downloadFile}
                            portInfo={portInfo}/>
                    })
                    }
                </CCol>
            </CRow>
        </div>
    );
};

export default PortPageAttachmentContainer;
