import { Modal } from "react-bootstrap";
import { CXButton } from "../../../components/CXForm";
import {
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTableBody,
  CTableDataCell,
} from "@coreui/react-pro";
import { ACTIONS } from "../../../constants/CarReportConstants";

export interface ICarDeleteModalProps {
  isShow: boolean;
  itemId?: string;
  modelTitle?: string;
  modelDetailTitle?: string;
  colNames: string[];
  colValues: string[];
  dispatchReducer: React.Dispatch<any>;
  onClickAction: Function;
}

export const CarDeleteModal = ({
  isShow,
  itemId,
  modelTitle = "Delete CAR report email",
  modelDetailTitle = "Are you sure to delete this CAR report email?",
  colNames,
  colValues,
  dispatchReducer,
  onClickAction,
}: ICarDeleteModalProps) => {
  const closeModal = () => {
    dispatchReducer({ type: ACTIONS.CLOSE });
  };

  const handleClose = () => {
    dispatchReducer({ type: ACTIONS.CLOSE });
  };

  const handleDelete = () => {
    dispatchReducer({ type: ACTIONS.DROP });
    onClickAction(ACTIONS.DROP, itemId);
  };

  return (
    <Modal
      className="appendixModal"
      show={isShow}
      dialogClassName="modal-90w"
      onHide={closeModal}
      size="xl"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title>{modelTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`carReportDeleteModal`}>
        <div className={`deleteEmailTitle`}>{modelDetailTitle}</div>
        <CTable className="appendixTable" borderless hover>
          <CTableHead>
            <CTableRow>
              {!!colNames &&
                colNames.length !== 0 &&
                Array.isArray(colNames) &&
                colNames.map((col_name, index) => (
                  <CTableHeaderCell
                    key={`CarDeleteModal_${index}`}
                    scope="col"
                    className={"CathaySansLt px-0 appendixHeader"}
                  >
                    {col_name}
                  </CTableHeaderCell>
                ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {!!colValues &&
              colValues.length !== 0 &&
              Array.isArray(colValues) && (
                <CTableRow style={{ borderBottom: "0px solid #D8D8D8" }}>
                  {colValues.map((col_value, index) => (
                    <CTableDataCell
                      key={`CarDeleteModal_DataCell_${index}`}
                      className={`RobotoMonoRg tableItem px-1 ${""}`}
                      align="middle"
                      style={{ position: "relative" }}
                    >
                      {col_value}
                    </CTableDataCell>
                  ))}
                </CTableRow>
              )}
          </CTableBody>
        </CTable>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <>
          <div className="cancel-button">
            <CXButton
              outline={true}
              text={"Cancel"}
              onClick={() => handleClose()}
              fontSize={"16px"}
              width={"100%"}
            />
          </div>
          <div className="save-button">
            <CXButton
              text={"Delete"}
              onClick={() => handleDelete()}
              fontSize={"16px"}
              width={"100%"}
            />
          </div>
        </>
      </Modal.Footer>
    </Modal>
  );
};
