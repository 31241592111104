const DB_COLLECTIONS: string[] = [];

enum DATA_TYPES {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  OBJECT = "object",
  ARRAY = "array"
}

const SUPPORT_DATATYPES: string[] = [
  DATA_TYPES.STRING,
  DATA_TYPES.NUMBER,
  DATA_TYPES.BOOLEAN,
  DATA_TYPES.OBJECT,
  DATA_TYPES.ARRAY
];

export { DB_COLLECTIONS, SUPPORT_DATATYPES, DATA_TYPES }
