import React, { useCallback, useEffect, useState } from 'react';
import { CTable, CTableBody, CButton, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';

import FlightPlanTableItem from './FlightPlanTableItem';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearFilter, selectApp } from '../../slice/appSlice';
import NoDataTableItem from './NoDataTableItem';
import AppSectionOutline from '../AppSectionOutline';

interface IFlightPlanTableProps {
  tableHeading?: Array<string>;
  flightPlans: any;
  user: string;
}

const FlightPlanTable = ({ tableHeading=[], flightPlans, user }: IFlightPlanTableProps) => {
  const dispatch = useAppDispatch();
  const { filter, selectedTag } = useAppSelector(selectApp);
  let filteredFlightPlan;

  const filterRemark = [{
    key: "isDraftMZFW",
    label: "Draft MZFW",
    explain: "OFP will be excluded in Flight Folder if the flight is marked as MZFW in Lido"
  },{
    key: "isStop",
    label: "[STOP] Remark",
    explain: "OFP will be excluded in Flight Folder if the Briefing Remark is marked as STOP in OFP XML"
  },{
    key: "missingAdj",
    label: "Missing Adj factor",
    explain: "OFP from LIDO is missing the fuel adjustment factor by weight change"
  },{
    key: "hasError",
    label: " Flight plan error",
    explain: "Flight Folder failed to parse the OFP from LIDO"
  },{
    key: "aircraftChanged",
    label: "Aircraft changed",
    explain: "Aircraft change within ETD - 24 hours"
  },{
    key: "isAOS",
    label: "A.O.S. Alert FPS",
    explain: "AOS OFP that is triggered by aircraft change within ETD - 4 hours"
  }];
  const currentTag = filterRemark.find((remark) => remark.key === selectedTag)?.label || "";
  const currentExplanation = filterRemark.find((remark) => remark.key === selectedTag)?.explain || "";

  const filterFunction = (item) => {
    let filterCriteria = Object.entries(filter).filter(([key, val]) => val === true);
    if (filterCriteria.length === 1) {
      let key = filterCriteria[0][0];
      return Object.entries(filter).filter(([key, val]) => val === true).map(([key, val]) => {
        return item[`${key}`] === val;
      }).every(condition => condition);
    } else {
      return Object.entries(filter).map(([key, val]) => {
        return item[`${key}`] === val;
      }).every(condition => condition);
    }
  };

  if (Object.values(filter || {}).every(bool => bool === false)) {
    filteredFlightPlan = flightPlans;
  } else {
    filteredFlightPlan = flightPlans.filter(item => filterFunction(item));
  }


  return <AppSectionOutline>
    <div className={"d-flex justify-content-between"}>
      <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", letterSpacing: "0.27px", color: "#4C4C4C", padding: '5px 0px' }}>
        {(!!currentTag)? currentTag:  "Flight plan received"}
        {(!!currentExplanation) && <span style={{ fontWeight: 400, paddingLeft: "20px" }}>{currentExplanation}</span>}
      </div>
      <CButton color="link" style={{ visibility: !Object.values(filter || {}).every(bool => bool === false) ? "visible" : "hidden" }} className={"linkButton clickable"} onClick={() => dispatch(clearFilter())}>Clear filter</CButton>
    </div>
    <CTable hover borderless>
      {
        tableHeading.length !== 0 && <CTableHead>
        <CTableRow>
          {
            tableHeading.map((heading, index) => {
              return <CTableHeaderCell key={`${heading}${index}`} className={"CathaySansLt px-0"} style={{ fontSize: "16px", lineHeight: "22px", letterSpacing: "0.3px" }} scope="col">{heading}</CTableHeaderCell>
            })
          }
        </CTableRow>
      </CTableHead>
      }
      <CTableBody>
        {
          (filteredFlightPlan && filteredFlightPlan.length !== 0) ?
            filteredFlightPlan.map((flightPlan, index) => {
              return <FlightPlanTableItem key={`FlightPlanTableItem${index}`} {...flightPlan} user={user}/>
            })
            :
            <NoDataTableItem colSpan={8} message={"There is no data in the past 24 hours."} />
        }
      </CTableBody>
    </CTable>
  </AppSectionOutline>
};

export default FlightPlanTable;