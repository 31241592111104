import { CFormCheck } from '@coreui/react';
import "./style/cxRadioButton.scss";

interface ICXRadioButtonProps {
  name: string;
  id: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: Function;
  additionalValidation?: Function;
  errMsg?: string;
  disabled?: boolean;
  isInline?: boolean;
}

const CXRadioButton = ({
  name,
  id,
  value,
  label,
  checked,
  onChange,
  additionalValidation = () => false,
  errMsg = '',
  disabled = false,
  isInline = false,
}: ICXRadioButtonProps) => {
  const className = `cx-radio-button ${checked ? 'checked' : 'unchecked'}`;
  const CFormCheckProps: any = {
    type: "radio",
    id,
    name,
    value,
    label,
    checked,
    disabled,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => { onChange(e) },
    inline: isInline,
    className,
  };

  return (
    <>
      <CFormCheck {...CFormCheckProps} />
      {additionalValidation && additionalValidation(value) && !!errMsg && (
        <div className={"cx-error-message"}>{errMsg}</div>
      )}
    </>
  );
};

export default CXRadioButton;
