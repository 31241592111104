import React, { useCallback, useEffect, useRef, useState } from 'react';
import './style/style.scss';
import { DownArrowIcon } from './assets/images';

interface ICXDropDownOption {
  text?: string;
  value: string | number;
  disabled?: boolean;
}

interface ICXDropDownProps {
  disabled?: boolean;
  zIndex?: number;
  options: ICXDropDownOption[];
  onChange: Function;
  fetchData?: Function;
  preAction?: Function;
  postAction?: Function;
  placeholder: string;
  value: string;
  readOnly?: boolean;
  noOptionMsg?: string;
  onChangeField?: Function;
  field?: string;
  state?: any;
  itemWidth?: string;
  className?: string;
}

const CXDropDown = ({ disabled = false, zIndex, options, noOptionMsg = "No record", onChange, placeholder, value, readOnly = false, onChangeField, field, state, fetchData, preAction, postAction, itemWidth, className }: ICXDropDownProps) => {
  const [show, setShow] = useState(false);
  const dropDownRef = useRef<HTMLInputElement>(null);
  /*
    ----------------------------- Sample component ------------------------------
    <CXDropDown disabled={true} value={dropDown1} placeholder={"Aircraft type"} onChange={setDropDown1} options={[
      {value:"Option 1"},
      {value:"Option 2"},
      {value:"Option 3", disabled: true}
    ]}/>
    ----------------------------- Sample component ------------------------------
  */

  useEffect(() => {
    if (show && !!fetchData && !!preAction) {
      preAction();
      fetchData();
    } else {
      if (!!postAction) {
        postAction();
      }
    }
  }, [show]);

  const onBlur = (e) => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 200);
    }

  };

  const toggleDropDown = () => {
    if (!disabled && !readOnly) {
      if (!!dropDownRef?.current) {
        dropDownRef.current.focus();
      }
      setShow(!show);
    }
  };

  const selectOption = (option: ICXDropDownOption) => {
    if (!option?.disabled && !readOnly && (!!option.value || option.value === 0)) {
      if (onChangeField) {
        onChangeField(option.value, field, state, onChange);
      } else {
        onChange(option.value);
      }
      setShow(false);
    }
  }

  return (
    <div className={`cx-dropdown ${className?className:''}`} style={{ zIndex: !!zIndex ? zIndex : 1039 }}>
      <div className={"cx-form-control"}>
        <input
          ref={dropDownRef}
          className={`cx-form-input ${show || !!value ? "focused" : ""} ${disabled ? "cx-disabled" : ""} ${readOnly ? "cx-dropdown-readonly" : ""}`}
          value={options.find(option => option.value === value)?.text || value}
          onClick={toggleDropDown}
          onBlur={onBlur}
          readOnly
        />
        {(show || !!value) ? <div>
          <div className={"focused-label"} onClick={toggleDropDown}>{placeholder}</div>
        </div>
          :
          <div className={`not-focused-label ${disabled ? "cx-disabled" : ""}`} onClick={toggleDropDown}>{placeholder}</div>}
        <DownArrowIcon className={`cx-down-arrow-icon ${disabled ? "cx-dropdown-item-disabled" : ""}`} height={24} width={24} onClick={toggleDropDown} />
        <div className={`cx-dropdown-body ${show ? "open" : ""}`} style={{ width: `${itemWidth ? itemWidth : '100%'}` }}>
          <ul className={"cx-dropdown-list"}>
            {(options.length !== 0) ?
              options.map((option, index) => <li key={`${option.value}${index}`}>
                <button className={`cx-dropdown-item ${(option?.disabled) ? "cx-disabled" : ""}`} onClick={() => selectOption(option)}>{option?.text ? option?.text : (option.value || "-")} </button>
              </li>) :
              <li key={`noOptionMsg`}>
                <button className={`cx-dropdown-item cx-dropdown-item-disabled`} onClick={() => { }}>{noOptionMsg}</button>
              </li>
            }
          </ul>
        </div>
      </div>

    </div>
  );
};

export default CXDropDown;