import { HTTP_REQUEST_TYPE, CARREPORT_API, BEKOL_API } from "../constants/api";
import { sendHttpRequest } from "./api";

// get or search car report
const getCarReport = async (isCX: boolean=true, email?: string, type?: string) => {

  const getCarReportItem = async (apiPath: string, email?: string, type?: string) => {
    //Old data in the database needs to be converted before displaying
    const coverData = (resp: any) => {
      if (resp?.emailList && Array.isArray(resp?.emailList)) {
        resp.emailList = resp.emailList.map((item) => {
          if (item?.Type === "DEFAULT_RECIPIENT") {
            return { ...item, Type: "Default recipient" };
          }
          else if (item?.Type === "SENDER") {
            return { ...item, Type: "Sender" };
          }
          return item;
        });
      }
    }
    
    //type needs to be converted
    const searchType = transformValue(type); 
  
    const playload = {
      ...(email && { Email: email }),
      ...(type && { Type: searchType }),
    };
  
    const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      apiPath,
      playload
    );
  
    coverData(resp);
    
    const [isSucceed, detail, respData] = handelRequestData(resp);
    return [isSucceed, detail, respData?.emailList || []];
  };

  const apiPath = (isCX)? CARREPORT_API.GET_EMAILLIST_DATA: BEKOL_API.GET_EMAILLIST_DATA;
  return getCarReportItem(apiPath, email, type);
};

// create car report
const createCarReport = async (item: any, isCX: boolean = true) => {

  const createCarReportItem = async (item: any, apiPath: string) => {
    const { _id, updatedAt, createdAt, ...createObj } = item;
    const [, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, apiPath, {
      ...createObj,
    });
  
    const [isSucceed, detail, respData] = handelRequestData(resp);
    return [isSucceed, detail, respData?.email || {}];
  };

  const apiPath = (isCX)? CARREPORT_API.POST_EMAIL_CREATE: BEKOL_API.POST_EMAIL_CREATE;
  const tempObj = transformObjValue(item);
  return createCarReportItem(tempObj, apiPath);
};

// update car report
const updateCarReport = async (item: any, isCX: boolean = true) => {

  const updateCarReportItem = async (item: any, apiPath: string) => {
    const { updatedAt, createdAt, __v, ...updateObj } = item;
    const [, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, apiPath, {
      ...updateObj,
    });
    return handelRequestData(resp);
  };

  const apiPath = (isCX)? CARREPORT_API.POST_EMAIL_UPDATE: BEKOL_API.POST_EMAIL_UPDATE;
  const tempObj = transformObjValue(item);
  return updateCarReportItem(tempObj, apiPath);
};

// delete car report
const deleteCarReport = async (id: string, isCX: boolean = true) => {

  const deleteCarReportItem = async (id: string, apiPath: string) => {
    const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.delete,
      `${apiPath}?_id=${id}`
    );
    return handelRequestData(resp);
  };

  const apiPath = (isCX)? CARREPORT_API.DELETE_EMAIL_DELETE: BEKOL_API.DELETE_EMAIL_DELETE;
  return deleteCarReportItem(id, apiPath);
};

//Transforms the value of the 'Type' property in an object from 'Default recipient' to 'DEFAULT_RECIPIENT'.
function transformObjValue(obj) {
  if (obj && obj?.Type === "Default recipient") {
    obj.Type = "DEFAULT_RECIPIENT";
  } else if (obj && obj?.Type === "Sender") {
    obj.Type = "SENDER";
  }
  return obj;
}

function transformValue(type) {
  switch (type) {
    case "ALL":
      return "";
    case "Default recipient":
      return "DEFAULT_RECIPIENT";
    case "Sender":
      return "SENDER";
    default:
      return type;
  }
}

// Handles response data.
// A boolean indicating if the request was successful. It's considered successful if the status code is between 200-299 or if there's no status code.
const handelRequestData = (resp: any): [boolean, string, any] => {
  const [detail, status, title] = [resp?.detail, resp?.status, resp?.title];
  const isSucceed = !status || (status && status >= 200 && status < 300);
  console.log("===handelRequestData===: ", isSucceed,detail,resp,title,status);
  return [isSucceed, detail, resp];
};

export {
  getCarReport,
  createCarReport,
  updateCarReport,
  deleteCarReport,
  handelRequestData,
};
