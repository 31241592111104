import React from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CTable,
  CTableHead,
  CTableBody,
  CTableRow,
  CTableHeaderCell,
  CCol
} from '@coreui/react';
import {IFeedbackTagVisible} from "../../interfaces/crewfeedback/feedback";
import './crewFeedbackStyle.scss';
import {FeedbackTagVisibilityDialogItem, FeedbackVisibilityDialogItem} from "./FeedbackListItem";
import {CXButton} from "../CXForm";
import {Modal} from "react-bootstrap";
import {CancelIcon} from "../../assets/images";

interface TagVisibilityDialogProps {
  title: string;
  description: string;
  tableHeaders: string[];
  data: IFeedbackTagVisible | null;
  btnConfirmText: string;
  onConfirm: () => void;
  btnCancelText: string;
  onCancel: () => void;
}

const TagVisibilityDialog: React.FC<TagVisibilityDialogProps> = ({
                                                                   title,
                                                                   description,
                                                                   tableHeaders,
                                                                   data,
                                                                   btnConfirmText,
                                                                   onConfirm,
                                                                   btnCancelText,
                                                                   onCancel
                                                                 }) => {
  const headers = tableHeaders.map((header, index) => ({
    key: index,
    label: header,
    _style: {width: 'auto'},
  }));
  const descriptionStyle = {
    marginLeft: "40px",
    color: "#4C4C4C",
    fontSize: "18px",
    fontStyle: "normal",
    fontFamily: "CathaySansRg",
    fontWeight: "500",
    lineHeight: "22px"
  }
  const titleStyle = {
    color: "#4C4C4C",
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "CathaySansRg",
    fontWeight: "700",
    lineHeight: "36px"
  }
  return (
    <CModal
      className="reminder-dialog"
      alignment="center"
      visible={true}
      onClose={() => onCancel()}
      style={{minHeight: "32vh"}}
    >
      <CModalHeader
        closeButton={false}
        style={{
          marginTop: "40px",
          marginLeft: "40px"
        }}>
        <CModalTitle style={titleStyle}>{title}</CModalTitle>
        <CancelIcon
          style={{
            height: "40px",
            width: '40px',
            cursor: "pointer",
            position: "absolute",
            right: 40,
            top: 40,
          }}
          onClick={() => onCancel()}/>
      </CModalHeader>
      <CModalBody>
        <p style={descriptionStyle}>{description}</p>
          <CTable align="middle" borderless hover>
            <CTableHead>
              <CTableRow
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "36px",
                  marginRight: "36px"
                }}>
                {headers.map((header, index) => (
                  <CTableHeaderCell key={`CTableHeaderCell_${index}`}
                                    className={"CathaySansRg"}
                                    style={{
                                      flex: 1,
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      lineHeight: "18px",
                                      color: "#4C4C4C"
                                    }}>
                    <div>
                      {header.label}
                    </div>
                  </CTableHeaderCell>
                ))}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                data !== null ? (
                  <FeedbackTagVisibilityDialogItem {...data}/>
                ) : (
                  <p>No data available.</p>
                )
              }
            </CTableBody>
          </CTable>
      </CModalBody>
      <Modal.Footer className="modal-footer" style={{marginRight: "20px", marginBottom: "20px"}}>
        <div className='cancel-button'
             style={{width: "210px"}}>
          <CXButton outline={true}
                    text={btnCancelText}
                    onClick={() => onCancel()}
                    fontSize={"16px"}
                    width={"100%"}/>
        </div>
        <div className='save-button'
             style={{width: "210px"}}>
          <CXButton text={btnConfirmText}
                    onClick={() => onConfirm()}
                    fontSize={"16px"}
                    width={"100%"}/>
        </div>
      </Modal.Footer>
    </CModal>
  );
};

export default TagVisibilityDialog;
