import { useEffect, useState } from 'react';
import { CTableRow, CTableDataCell, CFormCheck } from '@coreui/react';


interface IFileCheckBoxRowProps {
  index: number;
  appendixFile: {
    pdfName: string,
    uploadDate: string,
    isChecked: boolean
  };
  onUpdateFileIndex: Function;
  isSelected: boolean;
}


const FileCheckBoxRow = ({ index, appendixFile, isSelected, onUpdateFileIndex }: IFileCheckBoxRowProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(isSelected);

  const RobotoMonoRgDataCell = ({ children }) => {
    return <CTableDataCell className={"RobotoMonoRg"} style={{ position: "relative" }} align='middle'>{ children }</CTableDataCell>
  };

  const onClickCheckbox = (fileName: string) => {
    onUpdateFileIndex(fileName, !isChecked);
    setIsChecked(!isChecked);
  }

  useEffect(() => {
    setIsChecked(isSelected);
  } , [isSelected]);


  return  (<CTableRow style={{ borderBottom: "1px solid #D8D8D8" }}>
        <RobotoMonoRgDataCell><CFormCheck id="flexCheckDefault" checked={isChecked} onChange={()=> onClickCheckbox(appendixFile.pdfName)}/></RobotoMonoRgDataCell>
        <RobotoMonoRgDataCell>{appendixFile.pdfName || "--"}</RobotoMonoRgDataCell>
        <RobotoMonoRgDataCell>{appendixFile.uploadDate || "--"}</RobotoMonoRgDataCell>
    </CTableRow>) 
}

export default FileCheckBoxRow;