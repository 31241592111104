import React from 'react';
import {CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody} from '@coreui/react';
import SnnRulesTableItem from './SnnRulesTableItem';
import NoDataTableItem from './NoDataTableItem';
import DefectTableItem from './DefectTableItem';
import {GENERIC_TABLE_TYPES} from '../../constants/genericTableConstant';
import NsuNotamTableItem from './NsuNotamTableItem';
import OeNotamTableItem from './OeNotamTableItem';
import AppendixRuleTableItem from './AppendixRuleTableItem';
import PortListTableItem from './PortListTableItem';

interface IGenericTableProps {
    type: string;
    fieldNames: Array<string>;
    data: any;
}

const GenericTable = ({type, data, fieldNames}: IGenericTableProps) => {
    const DynamicTableItem = () => {
        let TableItem;
        switch (type) {
            case GENERIC_TABLE_TYPES.snnRule:
                const snnRulesData = Object.values(data).sort((a: any, b: any) => {
                    return new Date(b.createAt).valueOf() - new Date(a.createAt).valueOf()
                });
                TableItem = snnRulesData.map((snn: any, index) => <SnnRulesTableItem
                    key={`SnnRulesTableItem${index}`} {...snn} />);
                break;
            case GENERIC_TABLE_TYPES.defect:
                const defectsData = Object.values(data).sort((a: any, b: any) => {
                    return a.aircraft === b.aircraft
                    ? new Date(b.created).valueOf() - new Date(a.created).valueOf()
                    : a.aircraft.localeCompare(b.aircraft, undefined, { numeric: true });
                });
                TableItem = defectsData.map((defect: any, index) => <DefectTableItem
                    key={`DefectTableItem${index}`} {...defect} />);
                break;
            case GENERIC_TABLE_TYPES.nsuNotam:
                const nsuNotamData = Object.values(data).sort((a: any, b: any) => {
                    return new Date(b.createAt).valueOf() - new Date(a.createAt).valueOf()
                });
                TableItem = nsuNotamData.map((nsuNotam: any, index) => <NsuNotamTableItem
                    key={`NsuNotamTableItem${index}`} {...nsuNotam} />);
                break;
            case GENERIC_TABLE_TYPES.oeNotam:
                const oeNotamData = Object.values(data).sort((a: any, b: any) => {
                    return new Date(b.createAt).valueOf() - new Date(a.createAt).valueOf()
                });
                TableItem = oeNotamData.map((oeNotam: any, index) => <OeNotamTableItem
                    key={`OeNotamTableItem${index}`} {...oeNotam} />);
                break;
            case GENERIC_TABLE_TYPES.appendixRule:
                const appendixRuleData = Object.values(data).sort((a: any, b: any) => {
                    return new Date(b.createAt).valueOf() - new Date(a.createAt).valueOf()
                });
                TableItem = appendixRuleData.map((appendixRule: any, index) => <AppendixRuleTableItem
                    key={`AppendixRuleTableItem${index}`} {...appendixRule} />);
                break;
            case GENERIC_TABLE_TYPES.portList:
                // const portListData = Object.values(data).sort((a: any, b: any) => {
                //     return new Date(b.createAt).valueOf() - new Date(a.createAt).valueOf()
                // });
                TableItem = data.map((port: any, index) => <PortListTableItem
                    key={`portListTableItem${index}`} {...port} />);
                break;
        }
        return TableItem
    };

    return <div style={{border: "1px solid #b9bcc0", padding: 20, borderRadius: 10, margin: 0}}>
        <CTable hover>
            <CTableHead>
                <CTableRow>
                    {
                        Object.values(fieldNames).map((name, index) => {
                            return <CTableHeaderCell key={`${GenericTable}${name}${index}`}
                                                     scope="col">{name}</CTableHeaderCell>
                        })
                    }
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {
                    (!!data && Object.values(data).length !== 0) ?
                        <DynamicTableItem/>
                        :
                        <NoDataTableItem colSpan={14} message={`There is no ${type} retrieved.`}/>
                }
            </CTableBody>
        </CTable>
    </div>
};

export default GenericTable;
