import React from 'react';
import { CRow, CCol } from '@coreui/react';
import { ICrew } from '../../slice/simSlice';

interface IDetailSimTraineeInfoListItemComponentProps {
  crew: ICrew;
  index: number;
}

const DetailSimTraineeInfoListItemComponent = ({crew, index}: IDetailSimTraineeInfoListItemComponentProps) => {
  return <CRow key={`DetailSimTraineeInfoListItemComponent${index}`}>
    <CCol xs={1}>
      <div className={"CathaySansMd"} style={{ padding: "11px 0px 9px 0px", fontSize: "16px", lineHeight: "20px", color: "#000000" }}>
        {crew?.dutyCode || "--"}
      </div>
    </CCol>
    <CCol xs={2}>
      <div className={"CathaySansMd"} style={{ padding: "11px 0px 9px 0px", fontSize: "16px", lineHeight: "20px", color: "#000000" }}>
        {crew?.galacxyId || "--"}
      </div>
    </CCol>
    <CCol xs={9}>
      <div className={"CathaySansRg"} style={{ padding: "11px 0px 9px 0px", fontSize: "16px", lineHeight: "20px", color: "#000000" }}>
        {crew?.crewName || "--"}
      </div>
    </CCol>
  </CRow>
};

export default DetailSimTraineeInfoListItemComponent;