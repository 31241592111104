import { CXButton } from "../../components/CXForm";
import { ISNNSearch } from "../../slice/simSlice";
import { CRow, CCol } from "@coreui/react-pro";
import {
  SNN_SEARCH_FIELDS,
} from "../../constants/simConstant";
import "./snnRule.scss";
import InputBoxGenerator from "../CXForm/InputBoxGenerator";
import React from "react";

/** a constant for this page's z-indx */
const BASE_ZINDEX = 1029;

const ActivenessDropDown = [
  {
    text: "All",
    value: "All"
  },
  {
    text: "Active",
    value: "Active"
  },
  {
    text: "Inactive",
    value: "Inactive"
  }
]

const IntervalDropDown = [
  {
    text: "ALL",
    value: "ALL"
  },
  {
    text: "Enabled",
    value: "Enabled"
  },
  {
    text: "Disabled",
    value: "Disabled"
  }
]

// category dropdown options: All / General / Fuel Policies
const CategoryDropDownOptions = [
  {
    text: "All",
    value: "All"
  },
  {
    text: "General",
    value: "SNN"
  },
  {
    text: "Fuel Policies",
    value: "FUEL_POLICIES"
  }
]

interface ISNNRuleSearchBarProps {
  searchCriteria: ISNNSearch;
  setSearchCriteria: any;
  onChangeSearchForm: any;
  onChangeField: any;
  onClickSearch: any;
  interval: string;
  setInterval: any;
  airline?: string;
  setAirline?: any;
  airlineOptions: any;
  activeStatus: string;
  setActiveStatus: any;
  activeCategory: string;
  setActiveCategory: React.Dispatch<React.SetStateAction<string>>;
  isCXSNN?: boolean;
}

const SNNSearchBar = ({
  searchCriteria,
  setSearchCriteria,
  onChangeSearchForm,
  onChangeField,
  onClickSearch,
  interval,
  setInterval,
  airline,
  setAirline,
  airlineOptions,
  activeStatus,
  setActiveStatus,
  activeCategory,
  setActiveCategory,
  isCXSNN = true,
}: ISNNRuleSearchBarProps) => {
  const firTextInputFields = [
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.flightNo,
        placeholder: "Flight Number",
        value: searchCriteria.flightNo,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.aircraftType,
        placeholder: "Aircraft type",
        value: searchCriteria.aircraftType,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.registration,
        placeholder: "Registration (Example:ABC)",
        value: searchCriteria.registration,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.dep,
        placeholder: "Origin (multiple)",
        value: searchCriteria.dep,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.arr,
        placeholder: "Dest (multiple)",
        value: searchCriteria.arr,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.altn,
        placeholder: "ALTN",
        value: searchCriteria.altn,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.era,
        placeholder: "ERA",
        value: searchCriteria.era,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
  ];

  const secDateFields = [
    {
      gridSize: 3,
      inputType: "date",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.startAt,
        placeholder: "Eff Start(UTC)",
        value: searchCriteria.startAt,
        state: searchCriteria,
        onChange: setSearchCriteria,
        onChangeField: onChangeField,
        hasDefault: false,
        zIndex: BASE_ZINDEX + 1,
      },
    },
    {
      gridSize: 3,
      inputType: "date",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.endAt,
        placeholder: "Eff End(UTC)",
        value: searchCriteria.endAt,
        state: searchCriteria,
        onChange: setSearchCriteria,
        onChangeField: onChangeField,
        hasDefault: false,
        zIndex: BASE_ZINDEX + 1,
      },
    },
  ];

  const secTextInputFields = [
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.snn,
        placeholder: "SNN Content",
        value: searchCriteria.snn,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.waypoint,
        placeholder: "Waypoint",
        value: searchCriteria.waypoint,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 0,
      inputType: "text",
      inputBoxProps: {
        field: SNN_SEARCH_FIELDS.countryICAOCode,
        placeholder: "Country code",
        value: searchCriteria.countryICAOCode,
        onChangeField: onChangeSearchForm,
        zIndex: BASE_ZINDEX,
      },
    },
  ];

  const dropdownFields = [
    {
      gridSize: 2,
      inputType: "dropdown",
      inputBoxProps: {
        field: "airline",
        placeholder: "Airline",
        value: airline,
        onChange: setAirline,
        options: airlineOptions,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 2,
      inputType: "dropdown",
      inputBoxProps: {
        field: "interval",
        placeholder: "Interval",
        value: interval,
        onChange: setInterval,
        options: IntervalDropDown,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 2,
      inputType: "dropdown",
      inputBoxProps: {
        field: "activeStatus",
        placeholder: "Status",
        value: activeStatus,
        onChange: setActiveStatus,
        options: ActivenessDropDown,
        zIndex: BASE_ZINDEX,
      },
    },
    {
      gridSize: 2,
      inputType: "dropdown",
      inputBoxProps: {
        field: "category",
        placeholder: "Category",
        value: activeCategory,
        onChange: setActiveCategory,
        options: CategoryDropDownOptions,
        zIndex: BASE_ZINDEX,
      },
    },
  ];

  const combinedArray = [
    [firTextInputFields],
    [secDateFields, secTextInputFields],
    isCXSNN ? [dropdownFields] : [dropdownFields.filter((field) => ['interval', "activeStatus"].includes(field.inputBoxProps.field))],
  ];

  return (
    <>
      <div className="justify-content-between snnRule">
        {combinedArray.map((group, index) => (
          <CRow className="flex-nowrap" key={index}>

            {group.flat().map((item, index) => (
              <InputBoxGenerator key={index} className={item.inputType !== "date" ? `${item.inputBoxProps.field}Col ${item.inputBoxProps.field}` : ""} {...item}></InputBoxGenerator>
            ))}
            {index === 1 && (
              <CCol sm="1" className="filterCol">
                <div className="filter">
                  <CXButton
                    text="Search"
                    onClick={onClickSearch}
                    fontSize="16px"
                    width="100%"
                  />
                </div>
              </CCol>
            )}
          </CRow>
        ))}
      </div>
    </>
  );
};

export default SNNSearchBar;
