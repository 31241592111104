import * as React from 'react';
import moment from 'moment';
import {CTableRow, CTableDataCell, CCol} from '@coreui/react';
import {EditIcon} from "../../assets/images";
import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';

const EditButton = ({data, dispatcher}) => {
    return <div className={`"snnTableRow clickable"`} style={{marginLeft: "4px"}}>
        <EditIcon height={40} width={40} onClick={() => {
            dispatcher({ type: "EDIT", updatedConfigItem: data });
        }} />
    </div>
};

const FuelAdvConfigTableItem = ({fuelAdv, dispatchReducer, routePath}) => {
    return <CTableRow>
        <CTableDataCell className={"RobotoMonoRg"}>{fuelAdv.icao || "-"}</CTableDataCell>
        <CTableDataCell
            className={"RobotoMonoRg"}>{moment.utc(fuelAdv.lastUpdated).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
        <CTableDataCell className={"RobotoMonoRg"}>{fuelAdv.galacxyId || "-"}</CTableDataCell>
        <CTableDataCell className={"RobotoMonoRg"}
                        style={{whiteSpace: "pre-line"}}>{fuelAdv.emails.join("\n ") || "-"}</CTableDataCell>
        <CTableDataCell className={"RobotoMonoRg"}>{fuelAdv.active ? "Yes" : "No"}</CTableDataCell>
        <Access route={routePath} access={[AccessType.UPDATE]}>
            <CTableDataCell><EditButton
                dispatcher={dispatchReducer}
                data={fuelAdv}
            /></CTableDataCell>
        </Access>
    </CTableRow>
};

export default FuelAdvConfigTableItem;
