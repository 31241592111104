import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface DataSlice {
    collections: Collection[];
    documents: any[];
}

interface Collection {
    name: string;
    size: number;
}

interface DocumentReqBody {
    colName: string;
    documents: any[];
}

const initialState: DataSlice = {
    collections: [],
    documents: []
}

const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        setCollections: (state, { payload }) => {
            state.collections = payload || [];
        },
        setDocuments: (state, { payload }) => {
            state.documents = payload || [];
        },
    },
});

const selectData = (state: RootState): DataSlice => state.data as DataSlice;
const {
    setCollections,
    setDocuments
} = dataSlice.actions;

export {
    dataSlice,
    selectData,
    setCollections,
    setDocuments
};
export type { DataSlice, DocumentReqBody };

export default dataSlice.reducer;
