import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { PublicClientApplication } from "@azure/msal-browser";
import { Provider } from "react-redux"
import {
    MsalProvider,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";

import '@coreui/coreui/dist/css/coreui.min.css';
import '@coreui/coreui-pro/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/style.scss';

import { store } from './app/store';
import ApiContextProvider from './components/ApiContextProvider';
import { msalConfig, msalInstance } from "./config/authConfig";
import PageLayout from './components/PageLayout';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from "redux-persist";
import LoginRedirectPage from './pages/LoginRedirectPage';

// require("dotenv").config();

// const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistStore(store)}>
                <MsalProvider instance={msalInstance}>
                    <AuthenticatedTemplate>
                        <ApiContextProvider>
                            <PageLayout />
                        </ApiContextProvider>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <LoginRedirectPage />
                    </UnauthenticatedTemplate>
                </MsalProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
