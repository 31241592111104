import { CCol, CRow } from '@coreui/react-pro';
import moment from 'moment';
import PortPageRunwayContent from './PortPageRunwayContent';

interface IPortPageContentContainerRunwayProps {
  runwayTabData: any,
  isEditMode: boolean;
  tabIndex: number;
}

const PortPageRunwayContentContainer = ({ runwayTabData, isEditMode, tabIndex }: IPortPageContentContainerRunwayProps) => {
  const portPageRunwayContentEditProps = {
    runwayTabData,
    tabIndex,
    isEditMode,
  };

  return (
    <>
      {runwayTabData && !isEditMode &&
        <CRow className={"ms-auto"}>
          <>
            <CCol sm={1} className='runway-version-text'>
              <span>{`V${runwayTabData.version || ''}`}</span>
            </CCol>
            <CCol sm={11} className='runway-last-updated-text'>
              <span>{`Last updated at ${moment.utc(runwayTabData.created)?.format("DD MMM YYYY HH:mm")} by `}<strong>{runwayTabData.userName || ''}</strong></span>
            </CCol>
          </>
        </CRow>
      }
      {runwayTabData ?
        <PortPageRunwayContent {...portPageRunwayContentEditProps} /> : null
      }
    </>
  );
};

export default PortPageRunwayContentContainer;