import * as React from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell } from '@coreui/react';

const NsuNotamTableItem = ({ category, icao, airline, flightNo, dep, arr, startAt, endAt, review, initiator, text, remark, status, created, createdBy }) => {
  return <CTableRow>
    <CTableDataCell className={"RobotoMonoRg"}>{category || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{icao || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{airline || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{flightNo || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{dep || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{arr || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(startAt).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(endAt).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(review).format("YYYY-MM-DD") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{initiator || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg formattedText"}>{text || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{remark || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{createdBy || "-"}</CTableDataCell>
  </CTableRow>
};

export default NsuNotamTableItem;
