import { CRow, CCol } from '@coreui/react-pro';
import { Modal } from 'react-bootstrap';
import { CXButton } from './CXForm';

interface IReminderModalProps {
  isShow: boolean;
  onHide: Function;
  onCancelClick: Function;
  onConfirmCancelClick: Function;
}

const ReminderModal = (props: IReminderModalProps) => {
  const { isShow, onHide, onCancelClick, onConfirmCancelClick } = props;

  return (
    <Modal show={isShow} onHide={() => onHide()}>
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title>Are you sure to cancel ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CRow>
          <CCol>
            The changes made will be lost as they are not saved.
          </CCol>
        </CRow>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => onCancelClick()} fontSize={"16px"} width={"100%"} /></div>
        <div className='save-button'><CXButton text={"Confirm"} onClick={() => onConfirmCancelClick()} fontSize={"16px"} width={"100%"} /></div>
      </Modal.Footer>
    </Modal>
  )
};

export default ReminderModal;