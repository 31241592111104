import React, { useState } from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell } from '@coreui/react';
import { CXTextInput, CXButton } from '../CXForm';
import { useLocation } from "react-router-dom";
import Access from '../Access';
import { AccessType } from '../../constants/roleAccessConstant';

interface ICXDefectTableItemProps {
  aircraft: string;
  addNumber: string;
  addTitle: string;
  addDetail: string;
  melReference: string;
  deferType: string;
  displayDeferType: string;
  created: Date;
  createdAt: Date;
  deletedAt?: Date; //this field is different from UO defect -> deletedAt
  expiryDate: Date;
  __v: number;
  remark: string;
  _id: string;
  saveRemark: Function;
}

const CXDefectTableItem = ({ aircraft, addNumber, addTitle, addDetail, melReference, deletedAt, created, expiryDate, __v, remark, _id, saveRemark }: ICXDefectTableItemProps) => {
  const { pathname: routePath } = useLocation();
  const defaultChart = '-';
  const [remarkEdit, setRemarkEdit] = useState(remark || '');
  const customStyleForRemarkCol = { width: "35%" };

  return <CTableRow>
    <CTableDataCell className={"RobotoMonoRg"}>{aircraft || defaultChart}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{addNumber || defaultChart}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{addTitle || defaultChart}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg formattedText"} style={{ maxWidth: "350px" }} >{addDetail || defaultChart}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{melReference || defaultChart}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(expiryDate).format("YYYY-MM-DD HH:mm") || defaultChart}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(created).format("YYYY-MM-DD HH:mm") || defaultChart}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{deletedAt ? "true" : "false"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"} style={customStyleForRemarkCol} >
      <CXTextInput
        value={remarkEdit}
        placeholder={"remark"}
        onChange={(remark) => setRemarkEdit(remark)} />
      <Access route={routePath} access={[AccessType.UPDATE]}>
        <CXButton
          outline={false}
          text={"Save Remark"}
          onClick={() => {
            if (!!_id) {
              saveRemark({
                defectId: _id,
                remark: remarkEdit
              })
            }
          }} />
        </Access>
    </CTableDataCell>
  </CTableRow>
};

export default CXDefectTableItem;