import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from "@coreui/react";
import { DATA_TYPES } from "../../constants/dataConstant"

interface ReactProps {
    colList: string[];
    documents: any[];
    onClickDocument: Function;
}

const DataTable = ({ colList, documents, onClickDocument }: ReactProps) => {

    const displayData = (targetValueObj: any) => {
        const valDataType = targetValueObj?.type.toString().toLowerCase();
        let resultVal = targetValueObj?.value;
        switch (valDataType) {
            case DATA_TYPES.OBJECT:
                resultVal = "Object";
                break;
            case DATA_TYPES.BOOLEAN:
                resultVal = resultVal.toString();
                break;
            case DATA_TYPES.NUMBER:
                resultVal = resultVal || 0;
                break;
            case DATA_TYPES.STRING:
            default:
                break;
        }
        return resultVal;
    }

    return (
        <>
            <CTable hover>
                <CTableHead>
                    <CTableRow>
                        {colList.map((col, index) =>
                            col === '_id' ? null
                                : <CTableHeaderCell key={`k_${col}_${index}`} scope="col">
                                    <span className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "4C4C4C" }}>
                                        {col}
                                    </span>
                                </CTableHeaderCell>
                        )}
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {documents && Array.isArray(documents) && documents?.map(document => {
                        const docId = document['_id']?.value || '';
                        return (<CTableRow key={docId} onClick={() => onClickDocument(document)}>
                            {colList.map((key: keyof any, index) => {
                                const currentVal = document[key]?.value;
                                return key === '_id'
                                    ? null
                                    : <CTableDataCell key={`k_${docId}_${index}`}>
                                        {Array.isArray(currentVal) && currentVal.length > 0
                                            ? currentVal.map((value) => {
                                                return <div key={`k_${docId}_${index}_${value || ''}`}>{value}</div>
                                            })
                                            : <span> {displayData(document[key])}</span>}
                                    </CTableDataCell>
                            })}
                        </CTableRow>
                        )
                    })}
                </CTableBody>
            </CTable>
        </>
    );
};

export default DataTable;