const formatMDXEditorContent = (content: string) => {
  // Remove all end space charts - '&#x20;' cause this additional charts can be read by MDXEditor itself but not iOS side
  const endSpaceCharts = '&#x20;';
  const contentUpdated = content.replaceAll(endSpaceCharts, '');
  return contentUpdated;
}

export {
  formatMDXEditorContent
}
