import { CCard, CCardBody, CCardText, CCardTitle, CCol, CRow } from '@coreui/react';
import React, { useState } from 'react';

import { ExternalCrewlist, RecencyDueDate } from '../slice/simSlice';
import ViewLabel from './ViewLabel';
import { CenteredRightArrowIcon, DownArrowIcon, RightArrowIcon } from '../assets/images';


interface IRecencyDueDateProps {
    recencyDueDate: RecencyDueDate;

}

const RecencyDueDateCard = ({ recencyDueDate }: IRecencyDueDateProps) => {
    return (
        <>
            <div>
                <span className='view-label-value'>Aircraft Type: {recencyDueDate.aircraftype}</span>
            </div>
            <div>
                <span className='view-label-value'>Takeoff History: </span>
                {recencyDueDate.takeoffhistory.map(recency => <div className='view-label-value'>{recency}</div>)}
            </div>
            <div>
                <span className='view-label-value'>Landing History: </span>
                {recencyDueDate.landinghistory.map(recency => <div className='view-label-value'>{recency}</div>)}
            </div>
            <div>
                <span className='view-label-value'>Takeoff Due Date: {recencyDueDate.takeoffDueDate}</span>
            </div>
            <div>
                <span className='view-label-value'>Landing Due Date: {recencyDueDate.landingDueDate}</span>
            </div>
        </>
    )
}

interface IUOCrewlistCardProps {
    externalCrewlist: ExternalCrewlist;
}

const UOCrewlistCard = ({ externalCrewlist }: IUOCrewlistCardProps) => {

    const [isExpand, setIsExpand] = useState<boolean>(false);

  return (
        <div style={{ background: "#F5F5F5", borderRadius: "16px", padding: "22px 25px", marginTop: "24px" }}>
            <div className={"clickable"} style={{ background: "#F5F5F5", position: "relative" }} onClick={() => { setIsExpand(!isExpand) }}>
            <div className={"loadsheetArrow"}>
                {isExpand ? <DownArrowIcon height={"24px"} width={"24px"} /> : <CenteredRightArrowIcon height={"24px"} width={"24px"} />}
            </div>
            <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", paddingLeft: "25px" }}>
            <CRow className="">
                <CCol sm="auto">            
                    <ViewLabel label="ufi" value={externalCrewlist.ufi || "--"} valueFont={"labelValue"}></ViewLabel>
                </CCol>
                <CCol sm="auto">            
                    <ViewLabel label="Reg" value={externalCrewlist.aircraftRegistration || "--"} valueFont={"labelValue"}></ViewLabel>
                </CCol>
                <CCol sm="auto">            
                    <ViewLabel label="Flight No." value={externalCrewlist.flightNumber || "--"} valueFont={"labelValue"}></ViewLabel>
                </CCol>
                <CCol sm="auto">            
                    <ViewLabel label="Dep" value={externalCrewlist.departurePort || "--"} valueFont={"labelValue"}></ViewLabel>
                </CCol>
                <CCol sm="auto">            
                    <ViewLabel label="Arr" value={externalCrewlist.arrivalPort || "--"} valueFont={"labelValue"}></ViewLabel>
                </CCol>
                <CCol sm="auto">            
                    <ViewLabel label="Std" value={externalCrewlist.stdUTC || "--"} valueFont={"labelValue"}></ViewLabel>
                </CCol>
                <CCol sm="auto">            
                    <ViewLabel label="Last Updated" value={externalCrewlist.updatedAt || "--"} valueFont={"labelValue"}></ViewLabel>
                </CCol>
                <CCol sm="auto">            
                    <ViewLabel label="Created At" value={externalCrewlist.createdAt || "--"} valueFont={"labelValue"}></ViewLabel>
                </CCol>
            </CRow>
            </div>
            </div>
            {isExpand && 
                <CRow>
                    <div className={"d-flex"} style={{ overflow: "scroll", marginTop: "20px" }}>
                        {externalCrewlist.crews.map(crew => { 
                            return <CCol sm="auto">
                                    <CCard style={{ width: '15rem', marginTop: "5px" }}>
                                        <CCardBody>
                                            <CCardText className='view-label-value'>
                                                ERN: {crew.currentErn || "--"}
                                            </CCardText>
                                            <CCardText className='view-label-value'>
                                                Badge Name: {crew.badgeName || "--"}
                                            </CCardText>
                                            <CCardText className='view-label-value'>
                                                Rank Code: {crew.rankCode || "--"}
                                            </CCardText>
                                            <hr/>
                                            <CCardTitle className='CathaySansRg'>
                                                Recency Due Date
                                            </CCardTitle>
                                                {crew.recencyDueDate.length > 0 ? crew.recencyDueDate.map(date => {
                                                    return <RecencyDueDateCard recencyDueDate={date}/>
                                                })
                                                : "--"}
                                            <hr/>
                                            <CCardTitle className='CathaySansRg'>
                                                Qualification
                                            </CCardTitle>
                                                {crew.qualification.length > 0 ? crew.qualification.map(quali => {
                                                    return (
                                                        <>
                                                            <div>
                                                                <span className='view-label-value'>Code: {quali.code}</span>
                                                            </div>
                                                            <div>
                                                                <span className='view-label-value'>DueDate: {quali.dueDate}</span>
                                                            </div>
                                                        </>)
                                                    })
                                                : "--"}
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            }
                        )}
                    </div>
                </CRow>
            }
        </div>
  )
};

export default UOCrewlistCard;