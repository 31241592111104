const stringArrayToUniqueDropDownOptions = (array) => {
  //convert array into unique Array
  let uniqueArray = Array.from(new Set([...array]));
  // Fit into Drop Down Option Structure
  return uniqueArray.map(item => {
    return { value: item };
  });
};

export {
  stringArrayToUniqueDropDownOptions
}; 