interface ApiErrorObj {
    status: string;
    title: string;
    detail: any;
  }
  
interface ApiRespObj {
    errors: ApiErrorObj;
}
  
class ServerError extends Error {
    status: string;
  
    title: string;
  
    detail: string;
  
    constructor(responseObj: ApiRespObj) {
      super();
  
      const { errors } = responseObj || {};
      const { status, title, detail } = errors;
  
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, ServerError);
      }
  
      this.status = status;
      this.title = title;
      this.detail = detail;
    }
  
    toJson() {
      return {
        status: this.status,
        title: this.title,
        detail: this.detail,
      };
    }
}

export default ServerError;
  