import * as React from 'react';
import { CTableRow, CTableDataCell, CFormCheck } from '@coreui/react';
import { DownloadIcon } from '../../../assets/images';
import moment from 'moment';

interface SidStarProps {
  index: number;
  sidStar: {
    _id: string,
    filename: string,
    key: string,
    updateBy: string,
    updatedAt: string,
    isActive: boolean,
  };
  onDownloadSidStar: Function;

}

const SidStarTableItem = ({ index, sidStar, onDownloadSidStar }: SidStarProps) => {

  return <CTableRow>
    <CTableDataCell className={"sidStarRow"}>
      <div>
        {sidStar.filename || "-"}
        {(sidStar.isActive) &&
          <span style={{
            color: '#FFFFFF',
            background: '#116F9A',
            borderRadius: '5px',
            margin: '15px',
            padding: '4px',
          }}>{'Active'}
          </span>
        }
      </div>
    </CTableDataCell>
    <CTableDataCell className={"sidStarRow"}>{sidStar.updateBy || "-"}</CTableDataCell>
    <CTableDataCell className={"sidStarRow"}>{moment.utc(sidStar.updatedAt).format("DD MMM YYYY HH:mm") || "-"}</CTableDataCell>
    <CTableDataCell>
      <DownloadIcon cursor={"pointer"} height={40} width={40} onClick={() => onDownloadSidStar(sidStar.key, sidStar.filename)} />
    </CTableDataCell>
  </CTableRow>
};

export default SidStarTableItem;
