import React, { useRef} from 'react';
import { CToaster } from '@coreui/react-pro';
import './style/style.scss';


interface ICXToasterProps {
  toast: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
}

const CXToaster = ({ toast }: ICXToasterProps) => {
  /*
    ----------------------------- Sample component ------------------------------
    const [text1, setText1] = useState("");
    <CXToaster value={text1} placeholder={"Flight Number"} onChange={setText1} />
    ----------------------------- Sample component ------------------------------
  */
  const toaster = useRef(null)

  return (
    <>
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  )
};

export default CXToaster;