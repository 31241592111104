import React from 'react';
import { checkIfHasAccess } from '../utils/roleAccessHelper';
import { AccessType } from '../constants/roleAccessConstant';
import { selectAuth } from '../slice/authSlice';
import { useAppSelector } from '../app/hooks';

interface AccessProps {
    route: string;
    access: AccessType[];
    children: React.ReactNode;
    fallback?: React.ReactNode;
}

const Access: React.FC<AccessProps> = ({ route, access, children, fallback = null }) => {
    const { userGroupIDs } = useAppSelector(selectAuth);
    const hasAccess = checkIfHasAccess(route, userGroupIDs, access);

    return (
        <>
            {
                hasAccess ? children : fallback
            }
        </>
    );
}

export default Access;