import React, { useState, useEffect } from 'react';
import moment from "moment";
import { LineBreakIcon } from '../assets/images';

const AppClock = () => {
  const [UTCTime, setUTCTime] = useState(moment.utc());
  const [HKGTime, setHKGTime] = useState(moment().utcOffset(8));
  useEffect(() => {
    const intervalId = setInterval(() => {
      setUTCTime(moment.utc());
      setHKGTime(moment().utcOffset(8));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return <div className={"clockBody"}>
    <div className={'CathaySansMd'}>
      <div style={{ position: "absolute", top: "16px", left: "16px", height: "52px", width: "170px" }}>
        <div className={"appClockHourMin"} 
          style={{ 
            position: "absolute", 
            bottom: "0px", 
            left: "0px", 
            fontSize: "48px", 
            lineHeight: "52px", 
            height: "52px", 
            width: "128px" }}
        >
          {`${UTCTime.format("HH")}:${UTCTime.format("mm")}`}
        </div>
        <div style={{ position: "absolute", top: "11px", right: "0px", fontSize: "28px", lineHeight: "46px", height: "41px", width: "40px" }}>{`:${UTCTime.format("ss")}`}</div>
      </div>
      <div style={{ position: "absolute", top: "80px", left: "16px", height: "23px", width: "170px" }}>
        <div style={{ position: "absolute", bottom: "0px", left: "0px", fontSize: "20px", lineHeight: "20px", height: "20px", width: "40px" }}>UTC</div>
        <div style={{ position: "absolute", bottom: "0px", right: "1px", fontSize: "20px", lineHeight: "20px", height: "20px", width: "118px" }}>{UTCTime.format("DD MMM YYYY")} </div>
      </div>
    </div>
    <LineBreakIcon style={{ position: "absolute", top: "16px", left: "206px" }} width={"1px"} height={"92px"} />
    <div className={'CathaySansMd'}>
      <div style={{ position: "absolute", top: "16px", left: "226px", height: "52px", width: "170px" }}>
        <div className={"appClockHourMin"} 
          style={{ 
            position: "absolute", 
            bottom: "0px", 
            left: "0px", 
            fontSize: "48px", 
            lineHeight: "52px", 
            height: "52px", 
            width: "128px" }}
        >
          {`${HKGTime.format("HH")}:${HKGTime.format("mm")}`}
        </div>
        <div style={{ position: "absolute", top: "11px", right: "0px", fontSize: "28px", lineHeight: "46px", height: "41px", width: "40px" }}>{`:${HKGTime.format("ss")}`}</div>
      </div>
      <div style={{ position: "absolute", top: "80px", left: "226px", height: "23px", width: "170px" }}>
        <div style={{ position: "absolute", bottom: "0px", left: "0px", fontSize: "20px", fontWeight: "500", lineHeight: "20px", height: "20px", width: "40px" }}>HKG</div>
        <div style={{ position: "absolute", bottom: "0px", right: "1px", fontSize: "20px", fontWeight: "400", lineHeight: "20px", height: "20px", width: "118px" }}>{HKGTime.format("DD MMM YYYY")} </div>
      </div>
    </div>
  </div>
};

export default AppClock;