import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from "@coreui/react-pro";
import { useEffect, useState } from 'react';
import { DownArrowIcon } from "../../../../assets/images";
// import { SortDirection, useSort } from "./useSort";
import { sortAutoItems } from '../../../../utils/sortHelper';

import styles from './style.module.scss';

export interface IColumn {
    /** Unique key for the column */
    key: string;
    /** Title for the column header */
    title: string;
    /**
    * Path to the data for the column.
    * TODO: support nested data. e.g., 'a.b.c' would be ['a', 'b', 'c']
    */
    dataIndex: string;
    /** Width of the column, optional */
    width?: number;
    /** Whether the column is sortable, optional */
    isSortable?: boolean;
    /**
    * Function to render the data in the column, optional.
    */
    render?: (data: any) => React.ReactNode;
    /**
    * Function to sort the data in the column, optional. e.g., (a, b) => a.length - b.length
    */
    sorter?: (a: any, b: any) => number;
}

interface ITableProps {
    dataSource: any[];
    columns: IColumn[];
    /** Whether to show hover effect on the table */
    hover?: boolean;
}

// const getSortIconClasses = (columnKey: string, sortBy: string, direction: SortDirection) => {
//     let classes = styles.sortIcon;
//     classes += sortBy === columnKey && direction !== SortDirection.NONE ? ` ${styles.visible}` : ` ${styles.hidden}`;
//     classes += sortBy === columnKey && direction === SortDirection.DESCENDING ? ` ${styles.rotate180}` : ` ${styles.rotate0}`;
//     return classes;
// };

export function Table({ dataSource, columns, hover = true }: ITableProps) {

    // const { sortedData, sortByColumn, currentSortingRule } = useSort(dataSource, columns)

    const [list, setList] = useState(dataSource);

    const sortByLocale = (key: string) => {
        const { sortedList } = sortAutoItems([...list], key);
        const data = sortedList.map(item => ({ ...item }));
        setList(data);
    }

    useEffect(() => {
        setList(dataSource);
    }, [dataSource]);

    return (
        <CTable borderless hover={hover} className={styles.tableContainer}>
            {/* --- Table Head --- */}
            <CTableHead className={styles.tableHead}>
                <CTableRow>
                    {
                        columns.map((column, index) => {
                            // const sortIconClasses = getSortIconClasses(column.key, currentSortingRule.sortBy, currentSortingRule.direction);
                            return (
                                <CTableHeaderCell
                                    style={{ width: column.width }}
                                    // onClick={column.isSortable ? () => {
                                    //     sortByColumn(column.key)
                                    // } : undefined}
                                    onClick={() => {
                                        sortByLocale && sortByLocale(column.key)
                                    }}
                                    className={`CathaySansLt px-0 appendixHeader ${column.isSortable ? 'clickable' : ''}`}
                                    key={`tableContainer${index}`} scope="col">
                                    {column.title}
                                    {column.isSortable && (
                                        <DownArrowIcon
                                            className={styles.rotate0}
                                            height={12}
                                            width={12}
                                        />
                                    )}
                                </CTableHeaderCell>
                            )
                        })
                    }
                </CTableRow>
            </CTableHead>
            {/* --- Table Body --- */}
            <CTableBody className={styles.tableBody}>
                {
                    list.map((data, index) => (
                        <CTableRow key={`tableRow${index}`} >
                            {
                                columns.map((column, index) => {
                                    if (column.render) {
                                        return (
                                            <CTableDataCell key={`tableDataCell${index}`}>
                                                {/* TODO: remove Action from data object */}
                                                {column.render(data)}
                                            </CTableDataCell>
                                        )
                                    }
                                    if (Array.isArray(data[column.dataIndex])) {
                                        return (
                                            <CTableDataCell key={`tableDataCell${index}`}>
                                                {data[column.dataIndex].map((v, i) => (
                                                    <div key={`tableDataCellItem${i}`}>
                                                        {v}
                                                    </div>
                                                ))}
                                            </CTableDataCell>
                                        )
                                    }
                                    return (
                                        <CTableDataCell key={`tableContainer${index}`}>
                                            {data[column.dataIndex]}
                                        </CTableDataCell>
                                    )
                                })
                            }
                        </CTableRow>
                    ))
                }
            </CTableBody>
        </CTable>
    )
}