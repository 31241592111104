
import React from 'react';
import { Link } from "react-router-dom";
import { SettingIcon } from '../assets/images';

const AppSidebarItem = ({ name, link }) => {
  const currentPage = window.location.pathname;
  return <Link to={`${link}`} >
    <div className={`sideBarTab ${currentPage === link ? "selectedSideBarTab" : ""}`}>
      <div style={{ position: "absolute", top: "8px", left: "12px" }}>
        <SettingIcon width={24} height={24} />
      </div>
      <div className={`sideBarTabText ${currentPage === link ? "CathaySansBd" : "CathaySansLt"}`} style={currentPage === link ? { color: "#ffffff" } : {}}>{name}</div>
    </div>
  </Link>;
};

export default AppSidebarItem;