import React from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CTable,
  CTableHead,
  CTableBody,
  CTableRow,
  CTableHeaderCell,
  CCol
} from '@coreui/react';
import {IFeedbackVisible} from "../../interfaces/crewfeedback/feedback";
import './crewFeedbackStyle.scss';
import {FeedbackVisibilityDialogItem} from "./FeedbackListItem";
import {CXButton} from "../CXForm";
import {Modal} from "react-bootstrap";
import {CancelIcon} from "../../assets/images";

interface VisibilityDialogProps {
  title: string;
  description: string;
  tableHeaders: string[];
  data: IFeedbackVisible | null;
  btnConfirmText: string;
  onConfirm: () => void;
  btnCancelText: string;
  onCancel: () => void;
}

const VisibilityDialog: React.FC<VisibilityDialogProps> = ({
                                                             title,
                                                             description,
                                                             tableHeaders,
                                                             data,
                                                             btnConfirmText,
                                                             onConfirm,
                                                             btnCancelText,
                                                             onCancel
                                                           }) => {
  const headers = tableHeaders.map((header, index) => ({
    key: index,
    label: header,
    _style: {width: 'auto'},
  }));
  const descriptionStyle = {
    marginLeft: "40px",
    color: "#4C4C4C",
    fontSize: "18px",
    fontStyle: "normal",
    fontFamily: "CathaySansRg",
    fontWeight: "500",
    lineHeight: "22px"
  }
  const titleStyle = {
    color: "#4C4C4C",
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "CathaySansRg",
    fontWeight: "700",
    lineHeight: "36px"
  }
  return (
    <CModal
      className="reminder-dialog"
      alignment="center"
      visible={true}
      onClose={() => onCancel()}
      style={{minHeight: "88vh"}}
    >
      <CModalHeader
        closeButton={false}
        style={{
          marginTop: "40px",
          marginLeft: "40px"
        }}>
        <CModalTitle style={titleStyle}>{title}</CModalTitle>
        <CancelIcon
          style={{
            height: "40px",
            width: '40px',
            cursor: "pointer",
            position: "absolute",
            right: 40,
            top: 40,
          }}
          onClick={() => onCancel()}/>
      </CModalHeader>
      <CModalBody>
        <p style={descriptionStyle}>{description}</p>
        <CTable align="middle" borderless style={{
          width: "100%",
          height: "100%",
          borderCollapse: "collapse",
        }}>
          <CTableHead>
            <CTableRow
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "36px",
                marginRight: "36px"
              }}>
              {headers.map((header, index) => {
                  let cellWidthWeight = 1;
                  if (header.label === 'Feedback content' ||
                    header.label === 'Remarks' ||
                    header.label === 'Tag') {
                    cellWidthWeight = 2;
                  }
                  return (<CTableHeaderCell key={`CTableHeaderCell_${index}`}
                                            className={"CathaySansRg"}
                                            style={{
                                              flex: cellWidthWeight,
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              lineHeight: "18px",
                                              color: "#4C4C4C"
                                            }}>
                    <div>
                      {header.label}
                    </div>
                  </CTableHeaderCell>)
                }
              )
              }
            </CTableRow>
          </CTableHead>
          <CTableBody style={{
            height: "100%",
          }}>
            {
              data !== null ? (
                <FeedbackVisibilityDialogItem {...data}/>
              ) : (
                <p>No data available.</p>
              )
            }
          </CTableBody>
        </CTable>
      </CModalBody>
      <Modal.Footer
        className="modal-footer"
        style={{
          marginRight: "20px",
          marginBottom: "20px"
        }}>
        <div className='cancel-button' style={{width: "210px"}}>
          <CXButton
            outline={true}
            text={btnCancelText}
            onClick={() => onCancel()}
            fontSize={"16px"} width={"100%"}/>
        </div>
        <div className='save-button' style={{width: "210px"}}>
          <CXButton
            text={btnConfirmText}
            onClick={() => onConfirm()}
            fontSize={"16px"}
            width={"100%"}/>
        </div>
      </Modal.Footer>
    </CModal>
  );
};

export default VisibilityDialog;
