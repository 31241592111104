import { HTTP_REQUEST_TYPE, APPENDIX_API, BEKOL_API } from "../constants/api";
import { IAppendixRuleRequest } from "../slice/simSlice";
import { sendHttpRequest } from "./api";


const getAppendixRules = async (appendixSearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        APPENDIX_API.GET_APPENDIX_RULES,
        appendixSearch ? appendixSearch : {}
    );
    return resp;
};

const getAppendixFiles = async (appendixFileSearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        APPENDIX_API.GET_APPENDIX_FILES,
        appendixFileSearch ? appendixFileSearch : {}
    );
    return resp;
};

const postCreateAppendixRule = async (appendixRule: any) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        APPENDIX_API.POST_APPENDIX_RULE_CREATE,
        {
            ...appendixRule
        }
    );
    return resp;
};

const postUpdateAppendixRule = async (id: string, appendixRule: IAppendixRuleRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        `${APPENDIX_API.POST_APPENDIX_RULE_UPDATE}${id}/update`,
        {
            ...appendixRule
        }
    );
    return resp;
};

const deleteAppendixRule = async (id: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.delete,
        `${APPENDIX_API.DELETE_APPENDIX_RULE}${id}/delete`,
        {}
    );
    return resp;
};

const postAppendixFile = async (bodyFormData, isHKE = false) => {
    const uploadFileAPI = isHKE? BEKOL_API.POST_UPLOAD_APPENDIX_FILE: APPENDIX_API.POST_UPLOAD_APPENDIX_FILE;
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        uploadFileAPI,
        bodyFormData,
        true,
        true
    );
    return resp;
};

// const postHKEAppendixFile = async (bodyFormData: FormData) => {
//     const [, resp] = await sendHttpRequest(
//         HTTP_REQUEST_TYPE.post,
//         BEKOL_API.POST_UPLOAD_APPENDIX_FILE,
//         bodyFormData
//     );
//     return resp;
// };

const deleteAppendixFile = async (id: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.delete,
        `${APPENDIX_API.DELETE_APPENDIX_FILE}/delete`,
        {key: id}
    );
    return resp;
};

const getHKEAppendixRules = async (appendixSearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        BEKOL_API.GET_APPENDIX_RULES,
        appendixSearch ? appendixSearch : {}
    );
    return resp;
};

const getHKEAppendixFiles = async (appendixFileSearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        BEKOL_API.GET_APPENDIX_FILES,
        appendixFileSearch ? appendixFileSearch : {}
    );
    return resp;
};

const postHKECreateAppendixRule = async (appendixRule: any) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        BEKOL_API.POST_APPENDIX_RULE_CREATE,
        {
            ...appendixRule
        }
    );
    return resp;
};

const postHKEUpdateAppendixRule = async (id: string, appendixRule: IAppendixRuleRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        `${BEKOL_API.POST_APPENDIX_RULE_UPDATE}${id}/update`,
        {
            ...appendixRule
        }
    );
    return resp;
};

const deleteHKEAppendixRule = async (id: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.delete,
        `${BEKOL_API.DELETE_APPENDIX_RULE}${id}/delete`,
        {}
    );
    return resp;
};

const deleteHKEAppendixFile = async (id: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        `${BEKOL_API.DELETE_APPENDIX_FILE}/delete`,
        {key: id}
    );
    return resp;
};

export {
    getAppendixRules,
    getAppendixFiles,
    postCreateAppendixRule,
    postUpdateAppendixRule,
    deleteAppendixRule,
    postAppendixFile,
    deleteAppendixFile,
    deleteHKEAppendixFile,
    getHKEAppendixRules,
    getHKEAppendixFiles,
    postHKECreateAppendixRule,
    postHKEUpdateAppendixRule,
    deleteHKEAppendixRule,
    // postHKEAppendixFile,
};
