import React from 'react';
/**
 * Application home page component
 * @returns {JSX}
 */
const NotFoundPage = (props) => {
    return (
        <>
            <div className={"CathaySansBd"} style={{fontSize: "32px", lineHeight: "36px"}}>404 Not Found</div>
        </>
    );
};

export default NotFoundPage;