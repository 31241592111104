import React from 'react';
import { CloseIcon } from './assets/images';
import CXButton from './CXButton';

interface ICXLightBoxProps {
  visible: boolean;
  TriggerButton: JSX.Element;
  heading: string;
  submitButtonText: string;
  submitButtonDisabled?: boolean;
  children?: React.ReactNode;
  clear?: Function;
  submit: React.MouseEventHandler<HTMLButtonElement>;
  setVisible: Function;
  zIndex?: number;
}

const CXLightBox = ({ submitButtonDisabled = false, visible, setVisible, TriggerButton, heading, submitButtonText, children, zIndex = 1032, submit, clear }: ICXLightBoxProps) => {

  const closeAction = () => {
    if (!!clear) {
      clear();
    }
    setVisible(false);
  };

  return (
    <div>
      {/* <CXButton disabled={disabled} outline={true} text={triggerButtonText} onClick={() => setVisible(true)} fontSize={"14px"} /> */}
      {TriggerButton}
      {visible ?
        <div style={{
          position: 'fixed',
          top: '0',
          left: '0',
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(76, 76, 76, 0.5)',
          overflow: 'scroll',
          zIndex
        }}>
          <div
            className='mx-auto'
            style={{
              position: "relative",
              marginTop: "56px",
              padding: "40px",
              height: "943px",
              width: "1024px",
              backgroundColor: "white",
              borderRadius: '16px'
            }}>
            <div className={"d-flex justify-content-between"}>
              <div className={"CathaySansBd"} style={{ fontSize: "24px", lineHeight: "36px" }}>{heading}</div>
              <CloseIcon style={{ cursor: "pointer" }} height={32} width={32} onClick={closeAction} />
            </div>
            <div style={{ marginTop: "24px", color: "#4C4C4C" }}>
              {children}
            </div>
            <div style={{ position: 'absolute', bottom: "40px", right: "40px", width: "200px" }}><CXButton text={submitButtonText} onClick={submit} fontSize={"16px"} disabled={submitButtonDisabled} width={"100%"} /></div>
            <div style={{ position: 'absolute', bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Cancel"} onClick={closeAction} fontSize={"16px"} width={"100%"} /></div>
          </div>
        </div>
        : null}
    </div>
  );
};

export default CXLightBox;