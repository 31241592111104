import { useEffect } from "react";
import { CRow, CCol } from "@coreui/react-pro";
import moment from "moment";

import { CXButton, CXDropDown, CXTextInput } from "../../../components/CXForm";
import TagsInput from "../../../components/TagInputs";
import { DeleteIcon, EditIcon } from "../../../assets/images";
import { Table, Table as TopicListTable } from '../sharedComponents/Table'
import { Modal } from '../sharedComponents/Modal';
import Access from '../../../components/Access';
import { AccessType } from '../../../constants/roleAccessConstant';
import { useLocation } from "react-router-dom";


import './style.scss';

/** a constant for this page's z-indx */
const BASE_ZINDEX = 1100;

const FormItem = ({ label, children }) => {
  return (
    <CRow>
      <CCol xs={6}>
        <span className="modal-section-title">{label}</span>
      </CCol>
      <CCol xs={6}>
        {children}
      </CCol>
    </CRow>
  )
}

/** Error placeholder */
const ErrorPlaceholder = ({ errorMessage }) => {
  return (
    <div className="errorPlaceholder">
      {errorMessage}
    </div>
  )
}

/** Modal title map */
const modalTitleMap = {
  add: 'Add new CAR report topic',
  edit: 'Edit CAR report topic',
  delete: 'Delete CAR report topic',
}

/** Action button text map */
const actionButtonTextMap = {
  add: 'Add',
  edit: 'Save',
  delete: 'Delete',
}

/** get category options */
const getCategoryOptions = (categoryList: any) => {
  return categoryList.map((item: any) => {
    return {
      value: item,
      label: item
    };
  });
}

const CarReportTopicPageContainer = (props) => {
  const { pathname: routePath } = useLocation();
  const {
    keyword,
    setKeyword,
    selectedCategory,
    setSelectedCategory,
    categoryList,
    topicList,
    modalType,
    modalInfo,
    isUO,
    getCategories,
    getTopics,
    handleAddTopic,
    handleEditTopic,
    handleDeleteTopic,
    handleModalOpen,
    handleModalClose,
    handleModalInfoChange,
    isAirlineValid,
    isCategoryValid,
    isSubjectValid,
    isEmailsValid,
  } = props

  const modalActionMap = {
    add: handleAddTopic,
    edit: handleEditTopic,
    delete: handleDeleteTopic,
  }

  const generateModalBody = () => {
    if (modalType === "add" || modalType === "edit") {
      return (
        <>
          <FormItem label={'CAR report information'}>
            <CXDropDown
              zIndex={BASE_ZINDEX - 1}
              value={modalInfo.Airline}
              placeholder={"Airline"}
              onChange={async (v) => {
                handleModalInfoChange('Airline', v)
              }}
              options={isUO ? [
                {
                  text: "HKE",
                  value: "HKE",
                }
              ] : [
                {
                  text: "CPA",
                  value: "CPA",
                },
                {
                  text: "AHK",
                  value: "AHK",
                },
              ]}
              className={!isAirlineValid ? "errorBorder" : ''}
            />
            {
              !isAirlineValid && <ErrorPlaceholder errorMessage={'Airline may not be blank.'} />
            }
            <CXDropDown
              zIndex={BASE_ZINDEX - 2}
              value={modalInfo.Category}
              placeholder={"Category"}
              onChange={(v) => {
                handleModalInfoChange('Category', v)
              }}
              options={getCategoryOptions(categoryList)}
              className={!isCategoryValid ? "errorBorder" : ''}
            />
            {
              !isCategoryValid && <ErrorPlaceholder errorMessage={'Category may not be blank.'} />
            }
            <CXTextInput
              key={'input'}
              placeholder={"Subject"}
              value={modalInfo.Subject}
              onChange={(v) => {
                handleModalInfoChange('Subject', v)
              }}
              errMsg={'Subject may not be blank.'}
              additionalValidation={(v) => isSubjectValid}
              className={!isSubjectValid ? "errorBorder" : ''}
            />
          </FormItem>
          <hr className="solid divider" />
          <FormItem label={'Emails'}>
            <TagsInput
              className={'tagsInputContainer'}
              placeholder={"Emails (Use “enter” key for multiple input)"}
              tagList={modalInfo.Emails}
              onChangeField={(v) => {
                handleModalInfoChange('Emails', v)
              }}
              errMsg={'Please enter valid email addresses.'}
              additionalValidation={(tags) => {
                return isEmailsValid
              }}
            />
          </FormItem>
        </>

      );
    }
    // delete type
    if (modalType === 'delete') {
      return (
        <div>
          <div style={{ marginBottom: '20px' }}>
            <span>Are you sure you want to delete this topic?</span>
          </div>
          <div>
            <Table
              hover={false}
              columns={
                [
                  {
                    title: 'Airline',
                    dataIndex: 'Airline',
                    key: 'Airline',
                  },
                  {
                    title: 'Category',
                    dataIndex: 'Category',
                    key: 'Category',
                  },
                  {
                    title: 'Subject',
                    dataIndex: 'Subject',
                    key: 'Subject',
                  },
                  {
                    title: 'Emails',
                    dataIndex: 'Emails',
                    key: 'Emails',
                  },
                ]
              }
              dataSource={
                [
                  {
                    Airline: modalInfo.Airline,
                    Category: modalInfo.Category,
                    Subject: modalInfo.Subject,
                    Emails: modalInfo.Emails,
                  }
                ]
              }
            ></Table>
          </div>
        </div>
      );
    }
  }

  useEffect(() => {
    getCategories();
    getTopics()
  }, []);

  return (
    <div className="carReportTopicPage">
      {/* --------- Search Bar --------- */}
      <div className={"section d-flex justify-content-between"}>
        <div
          className={"CathaySansBd"}
          style={{ fontSize: "32px", lineHeight: "36px" }}
        >
          CAR Report Topic
        </div>
      </div>
      <CRow className="searchBar justify-content-between">
        <CCol className="d-flex">
          <CCol sm="auto">
            <div className={'searchBox'}>
              <CXTextInput
                placeholder={"Search by subject/ email address"}
                value={keyword}
                onChange={(v) => {
                  setKeyword(v)
                }}
                zIndex={1029}
              />
            </div>
          </CCol>
          <CCol sm="auto">
            <div className={"searchButton"}>
              <CXButton
                text={"Search"}
                onClick={() => {
                  getTopics(keyword, selectedCategory === 'All' ? '' : selectedCategory)
                }}
                fontSize={"16px"}
                width={"100%"}
              />
            </div>
          </CCol>
        </CCol>
        <CCol className="d-flex flex-row-reverse">
          <Access route={routePath} access={[AccessType.CREATE]}>
            <CXButton
              onClick={() => {
                handleModalOpen('add')
              }}
              text={"Add New Subject"}
              outline={true}
              fontSize={"14px"}
              width="162px"
            />
          </Access>
        </CCol>
      </CRow>
      <CRow>
        <div>
          <CCol sm={1} className="ps-0" style={{ minWidth: "240px" }}>
            <CXDropDown
              itemWidth="240px"
              value={selectedCategory}
              placeholder={"Category"}
              onChange={(v) => {
                setSelectedCategory(v)
              }}
              options={[{ value: 'All', label: 'All' }, ...getCategoryOptions(categoryList)]}
              zIndex={1000}
            />
          </CCol>
        </div>
      </CRow>
      {/* --------- Table --------- */}
      <div className={"section tableContainer"}>
        <TopicListTable
          columns={[
            {
              title: 'Airline',
              dataIndex: 'Airline',
              key: 'Airline',
              width: 70,
              isSortable: true,
            },
            {
              title: 'Category',
              dataIndex: 'Category',
              key: 'Category',
              isSortable: true,
            },
            {
              title: 'Subject',
              dataIndex: 'Subject',
              key: 'Subject',
              isSortable: true,

            },
            {
              title: 'Emails',
              dataIndex: 'Emails',
              key: 'Emails',
              isSortable: true,

            },
            {
              title: 'Last updated',
              dataIndex: 'updatedAt',
              key: 'updatedAt',
              isSortable: true,
              width: 170,
              render: (data) => {
                return (<>
                  {data.galacxyId && <CRow><span>{data.galacxyId}</span></CRow>}
                  {data.updatedAt && <CRow><span>{moment(data.updatedAt).format('DD MMM YYYY HH:mm')}</span></CRow>}
                </>)
              }
            },
            {
              title: '',
              dataIndex: 'Action',
              key: 'Action',
              width: 120,
              render: (data) => {
                return (
                  <CRow>
                    <Access route={routePath} access={[AccessType.UPDATE]}>
                      <CCol >
                        <EditIcon
                          className="clickable-icon"
                          height={40}
                          width={40}
                          onClick={() => {
                            handleModalOpen('edit', data)
                          }}
                        />
                      </CCol>
                    </Access>
                    <Access route={routePath} access={[AccessType.DELETE]}>
                      <CCol >
                        <DeleteIcon
                          className="clickable-icon"
                          height={40}
                          width={40}
                          onClick={() => {
                            handleModalOpen('delete', data)
                          }}
                        />
                      </CCol>
                    </Access>
                  </CRow>
                )
              }
            },
          ]}
          dataSource={topicList}
        ></TopicListTable>
      </div>
      {/* --------- Modal --------- */}
      <Modal
        show={!!modalType}
        onHide={handleModalClose}
        title={modalTitleMap[modalType]}
        className="carReportTopicPageModal"
        onAction={async () => {
          await modalActionMap[modalType]()
        }}
        actionButtonText={actionButtonTextMap[modalType]}
      >
        {generateModalBody()}
      </Modal>
    </div >
  );
};

export default CarReportTopicPageContainer;
