import { CTableDataCell, CTableRow } from '@coreui/react-pro';
import * as React from 'react';

interface INoDataTableItemProps {
  message: string;
  colSpan: number;
}

const NoDataTableItem = ({ message, colSpan }: INoDataTableItemProps) => {
  return <CTableRow>
    <CTableDataCell align={"middle"} colSpan={colSpan} className={"CathaySansRg text-center"} style={{ fontSize: "25px", height: "200px", fontWeight: 'bold' }}>{message}</CTableDataCell>
  </CTableRow>
};

export default NoDataTableItem;