import React from 'react';

interface IAppSectionOutlineProps {
  children?: React.ReactNode;
}

const AppSectionOutline = ({ children }: IAppSectionOutlineProps) => {
  return <div style={{ border: "1px solid #b9bcc0", padding: 20, borderRadius: 10, margin: 0 }}>
    {children}
  </div>
};

export default AppSectionOutline;