import {HTTP_REQUEST_TYPE, BEKOL_API} from "../constants/api";
import {sendHttpRequest} from "./api";
import {IFuelAdvisoryConfigObject} from "../slice/dispatcherSlice";

const getUOPastHourFlightPlan = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        BEKOL_API.GET_PAST_HOUR_FLIGHT_PLAN,
        {}
    );
    return resp;
};

const getUOSnnRules = async () => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      BEKOL_API.GET_SNN_RULES,
      {}
  );
  return resp;
};

const getUODefects = async (type: string) => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      BEKOL_API.GET_DEFECT,
      type != "ALL" ? {
        type: type
      } : {}
  );
  return resp;
};

const getUOCrewlists = async (date: string, flightNo: string) => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      BEKOL_API.GET_CREWLIST,
      flightNo != "" ||  flightNo != undefined?
      {
        flightDate: date,
        flightNo: flightNo
      }
      :
      {
        flightDate: date
      }
  );
  return resp;
};

const getUONsuNotam = async () => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      BEKOL_API.GET_NSU_NOTAM,
      {}
  );
  return resp;
};

const getUOOeNotam = async () => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      BEKOL_API.GET_OE_NOTAM,
      {}
  );
  return resp;
};

const getUOAppendixRule = async () => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      BEKOL_API.GET_APPENDIX_RULE,
      {}
  );
  return resp;
};

const getUOClosedFlightList = async (stdStart: string, stdEnd: string) => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      BEKOL_API.CLOSE_FLIGHT_LIST,
      {
          stdStart,
          stdEnd
      }
  );
  return resp;
};

const getUOClosedFlightDetail = async (ufi: string) => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      BEKOL_API.CLOSE_FLIGHT_DETAIL,
      {
          ufi
      }
  );
  return resp;
};

const postUoNotamFile = async (bodyFormData: FormData) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        BEKOL_API.POST_NOTAM_FILE,
        bodyFormData,
        true,
        true
    );
    return resp;
};

const getUOFuelAdvisoryConfig = async () => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.get,
      BEKOL_API.GET_FUEL_ADVISORY,
      {}
  );
  return resp;
}

const updateUOFuelAdvisoryConfig = async (updatedConfig: IFuelAdvisoryConfigObject) => {
  const [, resp] = await sendHttpRequest(
      HTTP_REQUEST_TYPE.post,
      BEKOL_API.UPDATE_FUEL_ADVISORY,
      {
          ...updatedConfig
      }
  );
  return resp;
}

export {
  getUOPastHourFlightPlan,
  getUOSnnRules,
  updateUOFuelAdvisoryConfig,
  getUOFuelAdvisoryConfig,
  getUODefects,
  getUOCrewlists,
  getUONsuNotam,
  getUOOeNotam,
  getUOAppendixRule,
  getUOClosedFlightList,
  getUOClosedFlightDetail,
  postUoNotamFile
};
