import { AIRCRAFT_API, HTTP_REQUEST_TYPE } from "../constants/api";
import { IAircraftRequest } from "../slice/simSlice";
import { sendHttpRequest } from "./api";


const getAircrafts = async (registration: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        AIRCRAFT_API.GET_AIRCRAFTS,
        registration ? {registration}: {}
    );
    return resp;
};

const postCreateAircraft = async (aircraft: IAircraftRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        AIRCRAFT_API.POST_AIRCRAFT_CREATE,
        {
            ...aircraft
        }
    );
    return resp;
};

export {
    getAircrafts,
    postCreateAircraft
};