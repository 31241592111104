import { CCol, CRow } from '@coreui/react-pro';
import { SEC_TITLES } from '../../constants/portPageRunwayConstant';
import CXTextInput from '../../components/CXForm/CXTextInput';
import CXTextArea from '../../components/CXForm/CXTextArea';
import CXTextEditorJodit from '../../components/CXForm/CXTextEditorJodit';
import SectionContentItems from '../../components/FlightPlanConfig/SectionContentItems';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {
  updateSecItems,
  updateSecText,
  removeSecItem,
  addSecItem,
  updateRunwayName, selectPortPageRunway
} from '../../slice/portPageRunwaySlice';
import portRunwayService from '../../services/portPageRunway/portRunwayAPI';


interface IPortPageRunwaySecContentItemsProps {
  runwayTabData: any;
  tabIndex: number;
  isEditMode: boolean;
}

const PortPageRunwayContent = ({ runwayTabData, tabIndex, isEditMode }: IPortPageRunwaySecContentItemsProps) => {
  const dispatch = useAppDispatch();
  const { tempRunwayNames} = useAppSelector(selectPortPageRunway);
  const changeSecText = (value, secTitle) => {
    const updateSecTextProps = {
      tabIndex,
      value,
      secTitle
    };

    dispatch(updateSecText({ ...updateSecTextProps }));
  }

  const changeRunwayName = (value, secTitle) => {
    const updateNameProps = {
      tabIndex,
      value,
      secTitle
    };

    dispatch(updateRunwayName({ ...updateNameProps }));
  }

  const changeSecItems = (value, rowIndex, columnName, secTitle) => {
    const updateSecItemsProps = {
      tabIndex,
      value,
      secTitle,
      columnName,
      rowIndex
    };

    dispatch(updateSecItems({ ...updateSecItemsProps }));
  }

  const changeValueLvoApproach = (value, rowIndex, columnName) => {
    changeSecItems(value, rowIndex, columnName, SEC_TITLES.lvoApproach);
  };

  const changeValueApproach = (value, rowIndex, columnName) => {
    changeSecItems(value, rowIndex, columnName, SEC_TITLES.approach);
  };

  const changeValueTakeOffLights = (value, rowIndex, columnName) => {
    changeSecItems(value, rowIndex, columnName, SEC_TITLES.takeOff_Lights);
  };
  const changeValueTakeOffPerformance = (value, rowIndex, columnName) => {
    changeSecItems(value, rowIndex, columnName, SEC_TITLES.takeOff_Performance);
  };

  const dispatchRemoveItem = (rowIndex, secTitle) => {
    const removeSecItemProps = {
      tabIndex,
      secTitle,
      rowIndex
    };
    dispatch(removeSecItem({ ...removeSecItemProps }));
  }

  const removeItemApproach = (rowIndex) => {
    dispatchRemoveItem(rowIndex, SEC_TITLES.approach);
  }

  const removeItemTakeOffLights = (rowIndex) => {
    dispatchRemoveItem(rowIndex, SEC_TITLES.takeOff_Lights);
  }

  const addNewItemApproach = (newItemObj) => {
    //need to convert newItemObj to raw data structure first.
    const rawNewObj = portRunwayService.convertItemToRawDataFormat(newItemObj, SEC_TITLES.approach);
    dispatch(addSecItem({ tabIndex, secTitle: SEC_TITLES.approach, newItem: rawNewObj }));
  }
  const addNewItemTakeOffLights = (newItemObj) => {
    const rawNewObj = portRunwayService.convertItemToRawDataFormat(newItemObj, SEC_TITLES.takeOff_Lights);
    dispatch(addSecItem({ tabIndex, secTitle: SEC_TITLES.takeOff_Lights, newItem: rawNewObj }));
  }

  const processHTMLInput = (inputHTML) => {
    const parser = new DOMParser(),
          parsedHTML = parser.parseFromString(inputHTML, 'text/html'),
          HTMLelements = parsedHTML.getElementsByTagName('*');

    for (let i = 0; i < HTMLelements.length; i++) {
      HTMLelements[i].removeAttribute('style');
    }

    const updatedHtmlString = parsedHTML.documentElement.innerHTML;
    return updatedHtmlString;
  }

  const renderSection = (secTitle, content) => {
    return (
      <CRow className="port-page-content">
        <CCol xs={4} className={'sec-title'}>
          {secTitle}
        </CCol>
        {content}
      </CRow>
    )
  }

  const renderSecText = (secTitle) => {
    const runwayInputProps = {
      value: tempRunwayNames[tabIndex],
      onChange: e => changeRunwayName(e,SEC_TITLES.runwayName),
      customStyle: !!runwayTabData.runway ? 'cx-form-input-with-value' : '',
      hasLabel: false
    };

    const notesTextAreaProps = {
      value: runwayTabData.notes,
      onChange: e => changeSecText(e, SEC_TITLES.notes),
      customStyleObj: {
        lineHeight: 18,
        paddingTop: 8,
        paddingBottom: 8
      },
      minRows: 6,
    };
    return (
      <>
        {secTitle === SEC_TITLES.runwayName &&
          <CCol>
            {isEditMode ?
              <CXTextInput {...runwayInputProps} />
              : <span className='sec-content-base-md'>{runwayTabData.runway}</span>
            }
          </CCol>
        }
        {secTitle === SEC_TITLES.notes &&
          <CCol>
            {isEditMode ?
              <CXTextArea {...notesTextAreaProps} />
              : <span className='sec-content-base-rg show-text-area-content'>{runwayTabData.notes}</span>
            }
          </CCol>
        }
        {secTitle === SEC_TITLES.engineFailure &&
          <CCol>
            {isEditMode ?
              <CXTextEditorJodit htmlContent={runwayTabData.engineFailure} tabIndex={0} secIndex={0} secItemIndex={0} onBlurEvent={(e) => changeSecText(processHTMLInput(e), SEC_TITLES.engineFailure)} />
              : <div className="textEditor-display sec-content-base-rg" dangerouslySetInnerHTML={{ __html: runwayTabData.engineFailure }} />
            }
          </CCol>
        }
      </>
    )
  }

  const renderSecItems = (secTitle: string, headersNotDisplay, rawTableData, remark, onChangeCallBack, changeValue, removeItem, addNewItem) => {
    const fixedValueColumns = portRunwayService.getFixedValueColumns(secTitle);
    const items = portRunwayService.getConvertedSecData(secTitle, rawTableData, isEditMode);
    const secContentBaseStyle = isEditMode ? 'sec-content-input' : 'sec-content-base-rg';
    let specialStyles = secTitle === SEC_TITLES.lvoApproach ?
      [{ col: 'type', styleName: 'sec-content-base-md' }, { col: 'subType', styleName: 'sec-content-base-rg' }]
      : undefined;

    const sectionContentItemsProps = {
      key: secTitle,
      isEditMode,
      headersNotDisplay,
      fixedValueColumns,
      items,
      changeValue,
      removeItem,
      addNewItem,
      specialStyles
    }

    const remarkTextAreaProps = {
      value: remark || '',
      onChange: e => onChangeCallBack(e, secTitle),
      customStyleObj: {
        lineHeight: 18,
        paddingTop: 8,
        paddingBottom: 8
      },
      minRows: 3,
    };

    return (
      <CCol className={secContentBaseStyle}>
        <SectionContentItems {...sectionContentItemsProps} />
        <>
          <CRow className='mt-4 align-center'>
            <CCol xs="auto" className='sec-content-base-md'>Remarks</CCol>
          </CRow>
          <CRow>
            <CCol xs={12} className='mt-2'>
              {isEditMode ?
                <CXTextArea {...remarkTextAreaProps} /> :
                <span className='sec-content-base-rg show-text-area-content'>{remark}</span>
              }
            </CCol>
          </CRow>
        </>
      </CCol>
    )
  }

  const renderTakeOffSecItems = (secTitle, remark, onChangeCallBack) => {
    const itemsLights = portRunwayService.getConvertedSecData(SEC_TITLES.takeOff_Lights, runwayTabData.takeoff.lights, isEditMode);
    const itemsPerformance = portRunwayService.getConvertedSecData(SEC_TITLES.takeOff_Performance, runwayTabData.takeoff.performance, isEditMode);
    const secContentBaseStyle = isEditMode ? 'sec-content-input' : 'sec-content-base-rg';

    const lightsSectionContentItemsProps = {
      key: SEC_TITLES.takeOff_Lights,
      isEditMode,
      headersNotDisplay: ['type'],
      fixedValueColumns: portRunwayService.getFixedValueColumns(SEC_TITLES.takeOff_Lights),
      items: itemsLights,
      changeValue: changeValueTakeOffLights,
      removeItem: removeItemTakeOffLights,
      addNewItem: addNewItemTakeOffLights
    };
    const performanceSectionContentItemsProps = {
      key: SEC_TITLES.takeOff_Lights,
      isEditMode,
      headersNotDisplay: ['type'],
      fixedValueColumns: portRunwayService.getFixedValueColumns(SEC_TITLES.takeOff_Performance),
      items: itemsPerformance,
      changeValue: changeValueTakeOffPerformance,
      removeItem: undefined,
      addNewItem: undefined,
      specialStyles: [{ col: 'type', styleName: 'sec-content-base-rg' }]
    };

    const remarkTextAreaProps = {
      value: remark || '',
      onChange: e => onChangeCallBack(e, secTitle),
      customStyleObj: {
        lineHeight: 18,
        paddingTop: 8,
        paddingBottom: 8
      },
      minRows: 3,
    };
    return (
      <CCol className={secContentBaseStyle}>
        <CRow>
          <CCol xs={8}>
            <SectionContentItems {...lightsSectionContentItemsProps} />
          </CCol>
          <CCol xs={4}>
            <SectionContentItems {...performanceSectionContentItemsProps} />
          </CCol>
        </CRow>
        <CRow className='mt-4 align-center'>
          <CCol xs="auto" className='sec-content-base-md'>Remarks</CCol>
        </CRow>
        <CRow>
          <CCol xs={12} className='mt-2'>
            {isEditMode ?
              <CXTextArea {...remarkTextAreaProps} /> :
              <span className='sec-content-base-rg show-text-area-content'>{remark}</span>
            }
          </CCol>
        </CRow>
      </CCol>
    )
  }

  return (
    <>
      {renderSection(SEC_TITLES.runwayName,
        renderSecText(SEC_TITLES.runwayName))}
      {renderSection(SEC_TITLES.notes,
        renderSecText(SEC_TITLES.notes))}
      {renderSection(SEC_TITLES.lvoApproach,
        renderSecItems(SEC_TITLES.lvoApproach, ['type', 'subType'], runwayTabData.lvoApproach,
          runwayTabData.lvoApproachRemark, changeSecText, changeValueLvoApproach, null, null))}
      {renderSection(SEC_TITLES.approach,
        renderSecItems(SEC_TITLES.approach, ['type'], runwayTabData.approach,
          runwayTabData.approachRemark, changeSecText, changeValueApproach, removeItemApproach, addNewItemApproach))}
      {renderSection(SEC_TITLES.takeOff,
        renderTakeOffSecItems(SEC_TITLES.takeOff, runwayTabData.takeoffRemark, changeSecText))}
      {renderSection(SEC_TITLES.engineFailure,
        renderSecText(SEC_TITLES.engineFailure))}
    </>
  );
};

export default PortPageRunwayContent;
