import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import _ from 'lodash';
import { CXButton } from "../../../components/CXForm";
import { selectUser } from '../../../slice/userSlice';
import { getSidStarFiles, postUploadSidStarFile, postDownloadSidStarFile, getSidStarFileCount } from '../../../services/sidStarAPI';
import SidStarPageContainer from './SidStarPageContainer';
import { FLIGHT_PLAN_CONFIG_PATHS } from '../../../constants/path';
import AddOrEditOFPLightBox from '../../../components/Sim/AddOrEditOFPLightBox';
import { ISidStarListItem, ISidStarPageContainerProps } from './interface';
import { sortAutoItems } from '../../../utils/sortHelper';
import { pushToast } from '../../../slice/appSlice';

/**
 * Application home page component
 * @returns {JSX}
 */

// for both cx and uo
const SidStarListColumns = [
  {
    key: "filename",
    label: "File name"
  },
  {
    key: "updateBy",
    label: "Updated by"
  },
  {
    key: "updatedAt",
    label: "Update time"
  },
];

const SidStarPage = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { userId } = useAppSelector(selectUser);
  const [sidStarList, setSidStarList] = useState<ISidStarListItem[]>([]);
  // file count
  const [fileCount, setFileCount] = useState<number>(0);
  const uploadFileRef = useRef<HTMLInputElement>(null);
  const isCXSidStar = pathname === FLIGHT_PLAN_CONFIG_PATHS.sidStar;

  useEffect(() => {
    fetchSidStarFileList();
    fetchSidStarFileCount();
  }, [])

  const fetchSidStarFileList = () => {
    getSidStarFiles(isCXSidStar)
      .then((res) => {
        if (res?.status === 500) {
          console.log('get SidStar list failed, res=', res);
          setSidStarList([]);
        } else if (res?.sidstarList && res.sidstarList.length) {
          res.sidstarList[0].isActive = true;
          setSidStarList(res.sidstarList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetch sidstar file count
  const fetchSidStarFileCount = () => {
    getSidStarFileCount(isCXSidStar).then(res => {
      if (res?.status === 500) {
        setFileCount(0);
      } else {
        setFileCount(res.totalCount);
      }
    })
  }

  const renderImportDataButton = () => {
    return (
      <>
        <AddOrEditOFPLightBox TriggerButton={UploadFileHandling()} heading={"import data"} submitButtonText={"Import data"} />
        <input ref={uploadFileRef} onChange={selectFile} type="file" accept={".csv,.xlsx"} hidden />
      </>
    );
  };

  const UploadFileHandling = () => {
    return <CXButton
      onClick={() => triggerFileExplorer()}
      text={"Import data"}
      fontSize={"14px"} />
  };

  const triggerFileExplorer = () => {
    if (uploadFileRef?.current) {
      uploadFileRef.current.click();
    }
  };

  const selectFile = (e) => {
    const fileForSubmit = _.get(e, 'target.files[0]');
    if (!fileForSubmit) return;
    let bodyFormData = new FormData();
    bodyFormData.append("file", fileForSubmit);
    bodyFormData.append("createdBy", userId);
    postUploadSidStarFile(bodyFormData, isCXSidStar)
      .then(([isSucceed,]) => {
        if (!isSucceed) {
          return;
        }
        dispatch(pushToast({ type: "success", message: "File is successfully uploaded" }));
        fetchSidStarFileList();
        fetchSidStarFileCount();
      })
      .catch((err) => {
        console.log(err);
      });
    // fix: avoid select File not firing
    e.target.value = "";
  };

  const onClickDownload = (key: string, filename: string) => {
    postDownloadSidStarFile(key, filename, isCXSidStar).then((res) => {
      if (res.status === 500) {
        throw res;
      }
      const blobFile = new Blob([res], { type: res.type })
      const fileURL = URL.createObjectURL(blobFile);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = filename;
      alink.target = "_blank";
      alink.click();
    }).catch((err) => {
      console.log(err);
    });
  }

  const sortByLocale = (key: string) => {
    const { sortedList } = sortAutoItems([...sidStarList], key);
    const sortedSidStarList = sortedList.map(item => ({ ...item }));
    setSidStarList(sortedSidStarList);
  }

  const SidStarPageContainerProps: ISidStarPageContainerProps = {
    sidStarListColumns: SidStarListColumns,
    sidStarList: sidStarList,
    fileCount: fileCount,
    renderImportDataButton: renderImportDataButton,
    onClickDownload: onClickDownload,
    sortByLocale: sortByLocale,
    routePath: pathname
  };

  return (
    <>
      <SidStarPageContainer {...SidStarPageContainerProps} />
    </>
  );
};

export default SidStarPage;
