import React, { useEffect, useState } from 'react';
import AppClock from '../../components/AppClock';
import AppFilterButton from '../../components/AppFilterButton';
import FlightPlanTable from '../../components/AdminDashboard/FlightPlanTable';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { resetFlightPlans, selectDispatcher, setFlightPlans } from '../../slice/dispatcherSlice';
import { selectApp, setAppFlags } from '../../slice/appSlice';
import { IFilterButtonObject } from '../../interfaces/app';
import { selectUser } from '../../slice/userSlice';
import { getUOPastHourFlightPlan } from '../../services/bekolDispatchAPI';
import { CCol, CRow } from '@coreui/react-pro';

/**
 * Application home page component
 * @returns {JSX}
 */


const UODispatchPage = () => {
    const dispatch = useAppDispatch();
    const { flightPlan, flightPlanLength } = useAppSelector(selectDispatcher);
    const { filter } = useAppSelector(selectApp);
    const { access } = useAppSelector(selectUser);
    const { isAdmin, isUODispatch } = access as any;
    const [ uoflightPlanStatus, setUOFlightPlanStatus] = useState(0)


    useEffect(() => {
        dispatch(resetFlightPlans());
        const getFlightPlan = async () => {
            const response = await getUOPastHourFlightPlan();           
            if (!!response) {
                let lastUpdatedTime = new Date(response.flightPlan[0].created);
                if (lastUpdatedTime) {
                    let currentUtc = new Date();
                    let difference = Math.abs(currentUtc.valueOf() - lastUpdatedTime.valueOf());
                    let differenceInMins = Math.floor((difference / 1000) / 60);
                    setUOFlightPlanStatus(differenceInMins);
                }

                dispatch(setFlightPlans(response));
            }
        }

        dispatch(setAppFlags({ field: "isBackgroundLoading", value: true }));
        // send get flightplan request when first load
        getFlightPlan();

        // send get flightplan request every 10s
        const intervalId = setInterval(async () => {
            try {
                await getFlightPlan();
            } catch (err) {
                console.log(err);
            }
        }, 10000);

        return () => {
            dispatch(setAppFlags({ field: "isBackgroundLoading", value: false }));
            clearInterval(intervalId)
        };
    }, [dispatch, isAdmin, isUODispatch]);

    const tableHeading = [
        "Flight no",
        "Serial",
        "Reg",
        "UFI",
        "Created time",
        "STD",
    ];

    const statusAlert = (min: number) => {
        let colour;
        if (min <= 15){
            colour = "greenStatus";
        } else if (min > 15 && min < 60){
            colour = "yellowStatus";
        } else {
            colour = "redStatus";
        }
        return colour;
    }

    let filterList: Array<IFilterButtonObject> = [
        {
            count: flightPlanLength?.draftMZFWFlightPlansLength || 0,
            description: 'Draft MZFW',
            tag: "isDraftMZFW",
        }, {
            count: flightPlanLength?.stopShowingFlightPlansLength || 0,
            description: '[STOP] Remark',
            tag: "isStop",
        }, {
            count: flightPlanLength?.missingAdjFlightPlansLength || 0,
            description: 'Missing Adj factor',
            tag: "missingAdj",
        }, {
            count: flightPlanLength?.latestErrorFlightPlansLength || 0,
            description: 'Flight plan error',
            tag: "hasError",
        }, {
            count: flightPlanLength?.changedFlightPlansLength || 0,
            description: 'Aircraft changed',
            tag: "aircraftChanged",
        }, {
            count: flightPlanLength?.AOSAlertFlightPlansLength || 0,
            description: 'A.O.S. Alert FPS',
            tag: "isAOS",
        }
    ]
    return (
        <>
            <div className='p-0 m-0 d-flex align-items-center'>
                <div className={"pageHeading CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>HKE Dashboard</div>
                <div className={"ms-auto"}>
                    <CRow>
                        <CCol sm="auto" className="lastUpdatedText">
                            Last Flight Plan
                        </CCol>
                        <CCol>
                            <button className={`tag flightPlanStatus ${statusAlert(uoflightPlanStatus)}`}
                            >{uoflightPlanStatus} mins ago</button>
                        </CCol>
                    </CRow>
                </div>
            </div>

            <div className={"section d-flex flex-wrap"} >
                <AppClock />
                {
                    filterList.map((filterButton: IFilterButtonObject, index) => {
                        return <div key={`FilterButton${index}`}><AppFilterButton {...filterButton} selected={(filter && filter[`${filterButton.tag}`]) || false} /></div>
                    })
                }
            </div>
            <div className={"section"}>
                <FlightPlanTable tableHeading={tableHeading} flightPlans={flightPlan || []} user={'UO'}/>
            </div>
        </>
    );
};

export default UODispatchPage;
