interface IPageHeaderTitleProps {
  headerTitle: string
}

const PageHeaderTitle = ({ headerTitle }: IPageHeaderTitleProps) => {
  return (
    <div>
      <div className={"section d-flex justify-content-between"} >
        <div className={"CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>{headerTitle}</div>
      </div>
    </div>
  )
};

export default PageHeaderTitle;