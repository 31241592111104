import { DATA_CONFIGURATION_PATHS } from "../../constants/path";
import { routeConfig } from "../../interfaces/route";
import CarReportEmailPage from "./CarReportEmailPage";
import CarReportTopicPage from './CarReportTopicPage/CarReportTopicPage';
import SidStarPage from "./SidStarPage/SidStarPage";

const dataConfigRouterConfig: routeConfig[] = [
  {
    path: DATA_CONFIGURATION_PATHS.carReportEmail,
    component: CarReportEmailPage,
  },
  {
    path: DATA_CONFIGURATION_PATHS.uoCarReportEmail,
    component: CarReportEmailPage,
  },
  {
    path: DATA_CONFIGURATION_PATHS.carReportTopic,
    component: CarReportTopicPage,
  },
  {
    path: DATA_CONFIGURATION_PATHS.uoCarReportTopic,
    component: CarReportTopicPage,
  },
  {
    path: DATA_CONFIGURATION_PATHS.cxSidStar,
    component: SidStarPage,
  },
  {
    path: DATA_CONFIGURATION_PATHS.uoSidStar,
    component: SidStarPage,
  },
];

export default dataConfigRouterConfig;
