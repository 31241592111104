const SEC_TITLES = {
    runwayName: 'Runway Name',
    notes: 'Notes',
    lvoApproach: 'LVO Approach',
    approach: 'Approach',
    takeOff: 'Takeoff',
    engineFailure: 'Engine Failure',
    takeOff_Lights:'Takeoff Lights',
    takeOff_Performance:'Takeoff Performance'
};

const SECS_DISPLAYITEMS= [SEC_TITLES.lvoApproach, SEC_TITLES.approach, SEC_TITLES.takeOff];
const SECS_DISPLAYTEXT=[SEC_TITLES.runwayName, SEC_TITLES.notes, SEC_TITLES.engineFailure];

const PDF_LAYOUT_MODE = {
    WHITE: 'WHITE',
    DARK: 'DARK'
}

export {
    SEC_TITLES,
    SECS_DISPLAYITEMS,
    SECS_DISPLAYTEXT,
    PDF_LAYOUT_MODE
}