import React from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody } from '@coreui/react';
import FlightStatusTableItem from './FlightStatusTableItem';
import NoDataTableItem from '../../../components/AdminDashboard/NoDataTableItem';

const FlightStatusTable = ({ flightStatus, onClickView }) => {
  return <div style={{ border: "1px solid #b9bcc0", padding: 20, borderRadius: 10, margin: 0 }}>
    <CTable hover>
      <CTableHead className='flight-status-header'>
        <CTableRow>
          <CTableHeaderCell scope="col">UFI</CTableHeaderCell>
          <CTableHeaderCell scope="col">Serial</CTableHeaderCell>
          <CTableHeaderCell scope="col">STD</CTableHeaderCell>
          <CTableHeaderCell scope="col">Timeline</CTableHeaderCell>
          <CTableHeaderCell scope="col">Padis</CTableHeaderCell>
          <CTableHeaderCell scope="col">Crewlist</CTableHeaderCell>
          <CTableHeaderCell scope="col">Flight Plan</CTableHeaderCell>
          <CTableHeaderCell scope="col">EDG</CTableHeaderCell>
          <CTableHeaderCell scope="col">ZFW</CTableHeaderCell>
          {/* <CTableHeaderCell scope="col">refueling</CTableHeaderCell> Not Implemented */}
          <CTableHeaderCell scope="col">Activate</CTableHeaderCell>
          <CTableHeaderCell scope="col">Closed flight</CTableHeaderCell>
          <CTableHeaderCell scope="col">Closed flight Email alert</CTableHeaderCell>
          <CTableHeaderCell scope="col">Events</CTableHeaderCell>
          <CTableHeaderCell scope="col">Created</CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {
          flightStatus.length !== 0 ?
            flightStatus.map((flight, index) => <FlightStatusTableItem key={`FlightStatusTableItem${index}`} {...flight} onClickView={onClickView} />)
            :
            (<NoDataTableItem colSpan={11} message={`There is no flight status data.`} />)
        }
      </CTableBody>
    </CTable>
  </div>
};

export default FlightStatusTable;