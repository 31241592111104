interface IToggleSwitchProps {
    name: string;
    checked: boolean;
    onChangeField?: Function;
    field?: string;
    state?: any;
    setState?: any;
    isDisabled?: boolean; 
}
const ToggleSwitch = ({name, checked, onChangeField, field, state, setState, isDisabled = false}:IToggleSwitchProps) => {

    const onChangeToggle = (e) => {
        if(onChangeField){
            onChangeField(!checked, field, state, setState)
        }
    }

    

    return (
        <div className="toggle-switch">
            <input
            type="checkbox"
            className="toggle-switch-checkbox"
            name={name}
            id={name}
            checked={checked}
            disabled={isDisabled}
            />
            <label className="toggle-switch-label" htmlFor="toggleSwitch" onClick={(e)=>onChangeToggle(e.target)}>
            <span className="toggle-switch-inner" />
            <span className="toggle-switch-switch" />
            </label>
        </div>
    )
}

export default ToggleSwitch