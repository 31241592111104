import { HTTP_REQUEST_TYPE, SNN_API, BEKOL_API } from "../constants/api";
import { ISNNRuleRequest } from "../slice/simSlice";
import { handleRequestData, sendHttpRequest } from "./api";
import {IfuelPolicyEmailReq} from "../interfaces/snn"

const sendAlert = async (req: IfuelPolicyEmailReq) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        SNN_API.SEND_ALERT,
        req
    );
    const [isSucceed, errorMsg, respData, status] = handleRequestData(resp);
    return [isSucceed, errorMsg, respData, status];
}

const sendCancelPolicy = async (req: IfuelPolicyEmailReq) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        SNN_API.CANCEL_POLICY,
        req
    );
    const [isSucceed, errorMsg, respData, status] = handleRequestData(resp);
    return [isSucceed, errorMsg, respData, status];
}

const getSnnRules = async (snnSearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        SNN_API.GET_SNN_RULES,
        snnSearch ? snnSearch : {}
    );
    return resp;
};

const postCreateSnnRule = async (snnRule: ISNNRuleRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        SNN_API.POST_SNN_RULE_CREATE,
        {
            ...snnRule
        }
    );
	const [isSucceed, errorMsg, respData, status] = handleRequestData(resp);
	return [isSucceed, errorMsg, respData, status];
};

const postUpdateSnnRule = async (id: string, snnRule: ISNNRuleRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        `${SNN_API.POST_SNN_RULE_UPDATE}${id}/update`,
        {
            ...snnRule
        }
    );
	const [isSucceed, errorMsg, respData, status] = handleRequestData(resp);
	return [isSucceed, errorMsg, respData, status];
};

const deleteSnnRule = async (id: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.delete,
        `${SNN_API.DELETE_SNN_RULE}${id}/delete`,
        {}
    );
    return resp;
};

const getHKESnnRules = async (snnSearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        BEKOL_API.GET_SNN_LIST,
        snnSearch ? snnSearch : {}
    );
    return resp;
};

const postHKECreateSnnRule = async (snnRule: ISNNRuleRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        BEKOL_API.POST_SNN_RULE_CREATE,
        {
            ...snnRule
        }
    );
    return resp;
};

const postHKEUpdateSnnRule = async (id: string, snnRule: ISNNRuleRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        `${BEKOL_API.POST_SNN_RULE_UPDATE}${id}/update`,
        {
            ...snnRule
        }
    );
    return resp;
};

const deleteHKESnnRule = async (id: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.delete,
        `${BEKOL_API.DELETE_SNN_RULE}${id}/delete`,
        {}
    );
    return resp;
};

export {
    sendAlert,
    getSnnRules,
    postCreateSnnRule,
    postUpdateSnnRule,
    deleteSnnRule,
    getHKESnnRules,
    postHKECreateSnnRule,
    postHKEUpdateSnnRule,
    deleteHKESnnRule,
    sendCancelPolicy
};