import { useState, Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useLocation } from "react-router-dom";

import {
  getCarReportTopicCategories,
  getCarReportTopics,
  createCarReportTopic,
  updateCarReportTopic,
  deleteCarReportTopic,
} from '../../../services/carReportTopicAPI';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { selectAuth } from "../../../slice/authSlice";
import { selectUser } from "../../../slice/userSlice";
import { pushToast } from '../../../slice/appSlice';
import { DATA_CONFIGURATION_PATHS } from "../../../constants/path";
import CarReportTopicPageContainer from './CarReportTopicPageContainer';
import { emailFormatterValidation } from "../../../utils/formValidateHelper";

import { ICarReportTopicState } from './types';

const initialState: ICarReportTopicState = {
  keyword: '',
  topicList: [],
  categoryList: [],
  selectedCategory: 'All',
  modalType: '',
  modalInfo: {
    Airline: '',
    Category: '',
    Subject: '',
    Emails: [],
  },
  validationError: {},
  enteredFields: [],
};

const inputFields = ['Airline', 'Category', 'Subject', 'Emails'];

const CarReportTopicPage = () => {

  const [keyword, setKeyword] = useState(initialState.keyword);
  const [selectedCategory, setSelectedCategory] = useState(initialState.selectedCategory);
  const [topicList, setTopicList] = useState<any[]>(initialState.topicList);
  const [categoryList, setCategoryList] = useState<string[]>(initialState.categoryList);
  const [enteredFields, setEnteredFields] = useState<string[]>(initialState.enteredFields);
  const [modalType, setModalType] = useState(initialState.modalType);
  const [modalInfo, setModalInfo] = useState(initialState.modalInfo);
  const [validationError, setValidationError] = useState(initialState.validationError);

  const [isAirlineValid, setIsAirlineValid] = useState(true);
  const [isCategoryValid, setIsCategoryValid] = useState(true);
  const [isSubjectValid, setIsSubjectValid] = useState(true);
  const [isEmailsValid, setIsEmailsValid] = useState(true);

  const { access } = useAppSelector(selectUser);
  const { userName } = useAppSelector(selectAuth);
  const { pathname } = useLocation();

  const appDispatch = useAppDispatch();

  const isUO = useMemo(() => {
    return pathname === DATA_CONFIGURATION_PATHS.uoCarReportTopic;
  }, [pathname]);

  // check data validation
  const checkDataValidation = () => {
    const isAirline = modalInfo.Airline?.length > 0;
    const isCategory = modalInfo.Category?.length > 0;
    const isSubject = modalInfo.Subject?.length > 0;
    const isEmails = modalInfo.Emails.length > 0 && modalInfo.Emails.every((item: string) => emailFormatterValidation(item));
    setIsAirlineValid(isAirline);
    setIsCategoryValid(isCategory);
    setIsSubjectValid(isSubject);
    setIsEmailsValid(isEmails);
    const isValid = isAirline && isCategory && isSubject && isEmails;
    return isValid;
  };

  // get categories
  const getCategories = async () => {
    await getCarReportTopicCategories(isUO).then((res) => {
      setCategoryList(res.categoryList);
    })
  };

  // get topics
  const getTopics = async (keyword = '', category = '') => {
    await getCarReportTopics(keyword, category, isUO).then((res) => {
      setTopicList(res.topicList);
    })
  };

  // handle add topic
  const handleAddTopic = async () => {
    try {
      setEnteredFields(inputFields);
      if (!checkDataValidation()) return
      await createCarReportTopic({
        Airline: modalInfo.Airline,
        Category: modalInfo.Category,
        Subject: modalInfo.Subject,
        Emails: modalInfo.Emails,
        galacxyId: userName
      }, isUO)
      setModalType('')
      getTopics(keyword, selectedCategory === 'All' ? '' : selectedCategory)
    } catch (error) {
      // if (error instanceof Error) {
      //   appDispatch(pushToast({ type: "error", message: error.message, width: '500px' }));
      // }
    }
  }

  // handle edit topic
  const handleEditTopic = async () => {
    try {
      setEnteredFields(inputFields);
      if (!checkDataValidation()) return
      if (!modalInfo._id) return
      await updateCarReportTopic({
        _id: modalInfo._id,
        Airline: modalInfo.Airline,
        Category: modalInfo.Category,
        Subject: modalInfo.Subject,
        Emails: modalInfo.Emails,
        galacxyId: userName
      }, isUO)
      setModalType('')
      getTopics(keyword, selectedCategory === 'All' ? '' : selectedCategory)
    } catch (error) {
      // if (error instanceof Error) {
      //   appDispatch(pushToast({ type: "error", message: error.message }));
      // }
    }
  }

  // handle delete topic
  const handleDeleteTopic = async () => {
    try {
      if (!modalInfo._id) return
      await deleteCarReportTopic(modalInfo._id, isUO)
      setModalType('')
      getTopics(keyword, selectedCategory === 'All' ? '' : selectedCategory)
    } catch (error) {
      // if (error instanceof Error) {
      //   appDispatch(pushToast({ type: "error", message: error.message }));
      // }
    }
  }

  // handle modal open
  const handleModalOpen = (type: typeof initialState.modalType, info: any = initialState.modalInfo) => {
    setModalType(type)
    setModalInfo({
      Airline: info.Airline || '',
      Category: info.Category || '',
      Subject: info.Subject || '',
      Emails: info.Emails || [],
      _id: info._id || '',
    })
    setEnteredFields(type === 'add' ? [] : inputFields)
  }

  // handle modal close
  const handleModalClose = () => {
    setModalType('')
    setModalInfo(initialState.modalInfo)
    setValidationError({})
    setEnteredFields([])
    setIsAirlineValid(true);
    setIsCategoryValid(true);
    setIsSubjectValid(true);
    setIsEmailsValid(true);
  }

  // handle modal info change
  const handleModalInfoChange = (field: string, value: any) => {
    if (!enteredFields.includes(field)) {
      setEnteredFields([...enteredFields, field])
    }
    setModalInfo({
      ...modalInfo,
      [field]: value
    })
  }

  useEffect(() => {
    // only validate when the field is entered
    if (enteredFields.includes('Airline')) {
      setIsAirlineValid(modalInfo.Airline?.length > 0);
    }
    if (enteredFields.includes('Category')) {
      setIsCategoryValid(modalInfo.Category?.length > 0);
    }
    if (enteredFields.includes('Subject')) {
      setIsSubjectValid(modalInfo.Subject?.length > 0);
    }
    if (enteredFields.includes('Emails')) {
      setIsEmailsValid(modalInfo.Emails.length > 0 && modalInfo.Emails.every((item: string) => emailFormatterValidation(item)));
    }
  }, [modalInfo])

  const carReportTopicPageProps = {
    keyword,
    setKeyword,
    selectedCategory,
    setSelectedCategory,
    categoryList,
    topicList,
    setTopicList,
    enteredFields,
    validationError,
    modalType,
    modalInfo,
    isUO,
    setModalType,
    getCategories,
    getTopics,
    handleAddTopic,
    handleEditTopic,
    handleDeleteTopic,
    handleModalOpen,
    handleModalClose,
    handleModalInfoChange,
    isAirlineValid,
    isCategoryValid,
    isSubjectValid,
    isEmailsValid,
  }

  return (
    <CarReportTopicPageContainer {...carReportTopicPageProps}></CarReportTopicPageContainer>
  );
};

export default CarReportTopicPage;

