import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import { Col } from 'react-bootstrap';

import { useAppDispatch } from '../../app/hooks';
import { CRow, CCol, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import NoDataTableItem from '../../components/AdminDashboard/NoDataTableItem';
import { CLOSE_FLIGHT_PATHS, UO_PATHS } from '../../constants/path';
import AppSectionOutline from '../../components/AppSectionOutline';
import { CenteredRightArrowIcon, DownArrowIcon, RightArrowIcon } from '../../assets/images';
import { GOOGLE_MAP_API } from '../../constants/api';
import { getResource } from '../../services/api';
import FixedImage from '../../components/AdminDashboard/FixedImage';
import { ufiFormatter } from '../../utils/ufiHelper';
import { dateFormatter } from '../../utils/dateHelper';

/**
 * Close Flight Detail Content Component for CX and UO
 * @returns {JSX}
 */

interface ICloseFlightDetailContentProps {
  user: string;
  ufi: string;
  closeFlightPackageIdentifier: string;
  closeFlightDetail: any;
}

const CloseFlightDetailContent = ({ user, ufi, closeFlightPackageIdentifier, closeFlightDetail }: ICloseFlightDetailContentProps) => {
  const params = useParams()
  const dispatch = useAppDispatch();
  const closeFlightList = Object.values(closeFlightDetail?.closeFlightList || {});
  const closeFlightCount = closeFlightList.length;

  const [focusedCloseFlightPlan, setFocusedCloseFlightPlan]: any = useState({});
  const [depClearance, setDepClearance]: any = useState({});
  const [arrClearance, setArrClearance]: any = useState({});
  const [opsTakeOffSector, setOpsTakeOffSector]: any = useState({});
  const [opsLandingSector, setOpsLandingSector]: any = useState({});
  const [expandLoadsheet, setExpandLoadsheet] = useState(false);
  const [isShowDetailIndex, setIsShowDetailIndex] = useState<number>(-1);

  useEffect(() => {
    setFocusedCloseFlightPlan(closeFlightList.find((closeFlight: any) => !!closeFlightPackageIdentifier && closeFlight.closeFlightPackageIdentifier === closeFlightPackageIdentifier));
  }, [dispatch, closeFlightList, closeFlightPackageIdentifier]);

  useEffect(() => {
    setDepClearance(focusedCloseFlightPlan?.depClearance || {});
    setArrClearance(focusedCloseFlightPlan?.arrClearance || {});
    setOpsTakeOffSector(focusedCloseFlightPlan?.opsTakeoffSector || {});
    setOpsLandingSector(focusedCloseFlightPlan?.opsLandingSector || {});
  }, [focusedCloseFlightPlan?.arrClearance, focusedCloseFlightPlan?.depClearance, focusedCloseFlightPlan?.opsLandingSector, focusedCloseFlightPlan?.opsTakeoffSector]);

  const downloadPDFHandler = useCallback(async (fileName) => {
    const resp = await getResource(user, "pdf", encodeURIComponent(fileName));
    const fileURL = URL.createObjectURL(resp);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = fileName;
    alink.click();
  }, []);

  const downloadPackageHandler = useCallback(async (fileName) => {
    const resp = await getResource(user, "zip", encodeURIComponent(fileName));
    const fileURL = URL.createObjectURL(resp);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = fileName;
    alink.click();
  }, []);

  const onClickShow = (index: number) => {
    if (isShowDetailIndex === index) {
      setIsShowDetailIndex(-1);
    } else {
      setIsShowDetailIndex(index);
    }
  }

  const TableHeading = ({text, index, isCloseStatusPanel = false}) => {
    return <CTableHeaderCell className={"CathaySansRg px-0"} style={{
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "0.3px",
      color: "#4C4C4C",
      background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
    }} key={`${text}${index}`} scope="col">{text}</CTableHeaderCell>;
  };

  const TableRow = ({
                      displayData,
                      count,
                      index,
                      hasArrow,
                      noLineBreak = false,
                      noPadding = false,
                      isCloseStatusPanel = false
                    }) => {
    return <CTableRow key={`flightMessageAndProcessHistory${index}`}
                      className={`${((count === (index + 1)) || (noLineBreak)) ? "" : "border-bottom"}`}>
      {Object.values(displayData).map((data: any, index) => {
        if (data[1] === "date") {
          return <CTableDataCell align="top" key={`flightMessageAndProcessHistory${index}`}
                                 className={"RobotoMonoRg px-0"}
                                 style={{
                                   fontWeight: 400,
                                   fontSize: "14px",
                                   lineHeight: "18px",
                                   color: "#000000",
                                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                 }}>
            <div className={"RobotoMonoAlign"}>{data[0].toUpperCase()}</div>
          </CTableDataCell>
        } else if (data[1] === "link") {
          let selected = closeFlightPackageIdentifier === data[0];
          if (selected) {
            return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                   className={"px-0"}
                                   style={{
                                     background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                   }}>
              <div className={`CathaySansRg px-0 eff-link-disabled`} style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "18px",
                color: "#000000"
              }}>{data[0].slice(-17)}</div>
            </CTableDataCell>
          } else {
            let link = `${((user === "cx") ? CLOSE_FLIGHT_PATHS : UO_PATHS).closeFlightDetail
              .replace(":ufi", encodeURIComponent(ufi))
              .replace(":closeFlightPackageIdentifier", encodeURIComponent(data[0]))}`;
            return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                   className={`CathaySansRg px-0 eff-link`}
                                   style={{
                                     fontWeight: 400,
                                     fontSize: "14px",
                                     lineHeight: "18px",
                                     background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                   }}>{<Link to={link} >{data[0].slice(-17)}</Link>}</CTableDataCell>
          }
        } else if (data[1] === "download") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={`CathaySansRg px-0 eff-link`}
                                 style={{
                                   fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                 }}>
            <button className={"download"} onClick={() => downloadPDFHandler(data[0])}>Download PDF</button>
          </CTableDataCell>
        } else if (data[1] === "downloadOfp") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={`CathaySansRg px-0 eff-link`}
                                 style={{fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`}}>
            <button className={"download"} onClick={() => downloadPackageHandler(data[0])}>Download Package</button>
          </CTableDataCell>

        }  else if (data[1] === "location") {
          let redirectLink = `${GOOGLE_MAP_API.REDIRECT.replace(":latlng", encodeURIComponent(`${data[0][0]},${data[0][1]}`))}`;
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg eff-link px-0"}
                                 style={{
                                   fontWeight: 400, fontSize: "14px", lineHeight: "22px",
                                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                 }}><a href={redirectLink}
                                       target="_blank"
                                       rel="noreferrer">View
            in Google Maps</a></CTableDataCell>

        } else if (data[1] === "bold string") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"} style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
          }}>{data[0]}</CTableDataCell>

        } else if (data[1] === "vertical string") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"}
                                 style={{
                                   fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                 }}>
            <div className={"CathaySansRg px-0 d-flex"}
                 style={{
                   fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                 }}>{data[0][0]}</div>
            <div className={"CathaySansRg px-0 d-flex"}
                 style={{
                   fontWeight: 400, fontSize: "14px", lineHeight: "18px",
                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                 }}>{data[0][1]}</div>
          </CTableDataCell>
        } else if (data[1] === "unit string") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"}
                                 style={{
                                   fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                 }}>
            <div className={"CathaySansBd px-0 d-flex"}
                 style={{
                   fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                 }}>{data[0][0]}</div>
            <div className={"CathaySansRg px-0 d-flex"}
                 style={{
                   fontWeight: 400, fontSize: "14px", lineHeight: "18px",
                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                 }}>{data[0][1]}</div>
          </CTableDataCell>
        } else if (data[1] === "object") {
          let remarks = data[0];
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"}
                                 style={{
                                   fontWeight: 400, fontSize: "14px", lineHeight: "18px",
                                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                 }}>
            <div className={"d-flex"}>
              {!!remarks?.close?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.close.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.close.value}</span>z
              </div>}
              {!!remarks?.time1?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.time1.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.time1.value}</span>z
              </div>}
              {!!remarks?.time2?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.time2.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.time2.value}</span>z
              </div>}
              {!!remarks?.reRouteToPos?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.reRouteToPos.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.reRouteToPos.value}</span>
                <span>, through</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks?.reRouteToPos?.note || "--"}</span>
              </div>}
              {!!remarks?.directToPos?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.directToPos.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.directToPos.value}</span>
              </div>}
              {!!remarks?.isCallATC?.value && <div style={{marginRight: "16px"}}>
                <span className={"RobotoMonoRg"} style={{fontWeight: 500, fontSize: "14px", lineHeight: "20px"}}></span>
                <span className={"CathaySansRg"}
                      style={{fontWeight: 500, fontSize: "14px", lineHeight: "20px"}}>{remarks.isCallATC.label}</span>
              </div>}
              {!!remarks?.isFuelCheck?.value && <div style={{marginRight: "16px"}}>
                <span className={"RobotoMonoRg"} style={{fontWeight: 500, fontSize: "14px", lineHeight: "20px"}}></span>
                <span className={"CathaySansRg"}
                      style={{fontWeight: 500, fontSize: "14px", lineHeight: "20px"}}>{remarks.isFuelCheck.label}</span>
              </div>}
            </div>
          </CTableDataCell>
        } else if (data[1] === "roboMonoRg") {
          return <CTableDataCell align="top" key={`flightMessageAndProcessHistory${index}`}
                                 className={"RobotoMonoRg px-0"} style={{
            paddingTop: "10px",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000000",
            background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
          }}>
            <div className={"paddingch4ff7"}>
              {data[0] || "--"}
            </div>
          </CTableDataCell>
        } else if (data[1] === "isShow") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={`CathaySansRg px-0 eff-link`}
                                 style={{
                                   fontWeight: 500, fontSize: "14px", lineHeight: "18px", width: "40px",
                                   background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                 }}>
          {isShowDetailIndex === data[0] ? 
            <DownArrowIcon height={"24px"} width={"24px"} onClick={() => onClickShow(data[0])} /> : 
            <CenteredRightArrowIcon height={"24px"} width={"24px"} onClick={() => onClickShow(data[0])}  />
          }
          </CTableDataCell>
        } else if (data[1] === "greyString") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"} style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
            padding: "0px",
            background: "#F5F5F5"
          }}>{data[0]}</CTableDataCell>
        } else {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"} style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000000",
            padding: noPadding ? "0px" : "auto",
            background: `${isCloseStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
          }}>{data[0]}</CTableDataCell>
        }
      })}
      {hasArrow && <CTableDataCell style={{position: "relative"}}>
        <RightArrowIcon style={{position: "absolute", right: "0px", top: "25%", bottom: "25%"}} width={"24px"}
                        height={"24px"}/>
      </CTableDataCell>}
    </CTableRow>
  }

  const NoData = ({ text }) => {
    return <CRow>
      <CCol>
        <CTable borderless className={"m-0"}>
          <CTableHead>
            <CTableRow>
              {[].map((heading, index) => {
                return <TableHeading text={heading} index={index} />
              })}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {
              <NoDataTableItem colSpan={1} message={text} />
            }
          </CTableBody>
        </CTable>
      </CCol>
    </CRow>
  };

  return (
    <>
      <div className={"breadcrumbs"}>
        <Link to={`${ user === "uo" ? UO_PATHS.closeFlight : CLOSE_FLIGHT_PATHS.closeFlight}`} style={{ margin: "4px" }}> Closed flight</Link> / <span style={{ margin: "4px" }}>Closed flight details </span>
      </div>
      <div className='m-0 d-flex align-items-center' style={{ padding: "19px 4px" }}>
        <div className={"pageHeading CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px", whiteSpace: "pre-wrap" }}> {ufiFormatter(ufi)}</div>
      </div>

      {/* --------------------------------- Start of Close Lido Flight plan detail --------------------------------- */}
      <div className={"simOFPDetailBody"} style={{ margin: "20px 0px", padding: "16px 24px" }}>
        <div style={{ marginBottom: "35px" }}>
          <CRow>
            <CCol xs={1}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Reg</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{focusedCloseFlightPlan?.reg || "--"}</div>
              </div>
            </CCol>
            <CCol xs={1}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Flight no</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{focusedCloseFlightPlan?.flightId || "--"}</div>
              </div>
            </CCol>
            <CCol xs={3}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>STD</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{focusedCloseFlightPlan?.std ? dateFormatter(focusedCloseFlightPlan.std).toUpperCase() : "--"}</div>
              </div>
            </CCol>
            <CCol xs={1}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Dep</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{focusedCloseFlightPlan?.dep || "--"}</div>
              </div>
            </CCol>
            <CCol xs={1}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Arr</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{focusedCloseFlightPlan?.arr || "--"}</div>
              </div>
            </CCol>
            <CCol xs={2}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Activated ver</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{focusedCloseFlightPlan?.serial || "--"}</div>
              </div>
            </CCol>
            <CCol xs={1}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Log fuel</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                  {!!focusedCloseFlightPlan?.activateAndAcceptFuelHistory?.logFuel ? `${focusedCloseFlightPlan?.activateAndAcceptFuelHistory?.logFuel}` : "--"}
                  <span className={"CathaySansRg"} style={{ fontWeight: 300, fontSize: "10px", lineHeight: "20px", color: "#000000", paddingLeft: "2px" }}>
                    T
                  </span>
                </div>
              </div>
            </CCol>
            <CCol xs={2}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Activated by</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{focusedCloseFlightPlan?.activateAndAcceptFuelHistory?.galacxyId || "--"}</div>
              </div>
            </CCol>
          </CRow>
        </div>
        <CRow>
          <CCol>
            <CTable borderless className={"m-0"}>
              <CTableHead>
                <CTableRow>
                  {[
                    "Close flight history",
                    "Diversion Airport",
                    "Close flight record",
                    "Activated ver",
                    "Closed by",
                    "Close time"
                  ].map((heading, index) => {
                    return <TableHeading text={heading} index={index} isCloseStatusPanel={true} />
                  })}
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {
                  (!!closeFlightList && closeFlightCount !== 0) ?
                    closeFlightList.map((flightPlan: any, index) => {
                      let closeFlightRecord = {
                        type: [flightPlan?.type || "--", "string"],
                        diversionAirport: [flightPlan?.diversionAirport || "--", "string"],
                        closeFlightPackageIdentifier: [flightPlan.closeFlightPackageIdentifier || "--", "link"],
                        serial: [flightPlan?.serial || "--", "string"],
                        closedBy: [flightPlan?.closedBy || "--", "string"],
                        closeTime: [dateFormatter(flightPlan.closedTime) || "--", "date"]
                      };
                      return <TableRow displayData={closeFlightRecord} count={closeFlightCount} index={index} hasArrow={false} isCloseStatusPanel={true} />
                    })
                    :
                    <NoDataTableItem colSpan={14} message={`There is no closed flight plan retrieved.`} />
                }
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>
      </div>
      {/* --------------------------------- End of Close Lido Flight plan detail --------------------------------- */}

      {/* --------------------------------- Start of Close Lido Flight plan heading --------------------------------- */}
      <div style={{ margin: "40px 0px 32px 0px" }}>
        <CRow>
          <CCol xs={4}>
            <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "24px", lineHeight: "28px", whiteSpace: "pre-wrap", textTransform: "uppercase" }}>
              {focusedCloseFlightPlan?.closedTime ? dateFormatter(focusedCloseFlightPlan.closedTime, "DDMMMYYYY") : "--"}
              {"  "}
              {focusedCloseFlightPlan?.closedTime ? dateFormatter(focusedCloseFlightPlan.closedTime, "HH:mm:ss") : "--"}
            </div>
          </CCol>
          <CCol xs={3}>
            <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "24px", lineHeight: "28px" }}>
              {focusedCloseFlightPlan?.closeFlightPackageIdentifier ? focusedCloseFlightPlan.closeFlightPackageIdentifier.slice(-17) : "--"}
            </div>
          </CCol>
        </CRow>
      </div>
      {/* --------------------------------- End of Close Lido Flight plan heading --------------------------------- */}

      {/* --------------------------------- Start of Flight Message and Process History --------------------------------- */}
      <div style={{ margin: "32px 0px 24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px", letterSpacing: "0.27px" }}>
            Flight Message and Process History
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Serial",
                  "Ver",
                  "Receive count",
                  "Flight date",
                  "New plan receive time",
                  "Processed time",
                  "File",
                  "LidoPackage"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                (!!closeFlightDetail?.flightMessageAndProcessHistory && closeFlightDetail?.flightMessageAndProcessHistory.length !== 0) ?
                  closeFlightDetail?.flightMessageAndProcessHistory.map((data: any, index) => {
                    let recordCount = closeFlightDetail.flightMessageAndProcessHistory.length;
                    let record = {
                      serial: [data?.serial || "--", "string"],
                      ver: [data?.ver || "--", "string"],
                      receiveCount: [data?.receiveCount || "--", "string"],
                      flightDate: [dateFormatter(data?.flightDate) || "  ", "date"],
                      firstReceive: [dateFormatter(data?.firstReceive) || "  ", "date"],
                      processedTime: [dateFormatter(data?.processedTime) || "  ", "date"],
                      key: [data?.key || "", "download"],
                      ofpPackage: [data?.lidoOfpKey || "", "downloadOfp"]
                    };
                    return <TableRow displayData={record} count={recordCount} index={index} hasArrow={true} />
                  })
                  :
                  <NoDataTableItem colSpan={14} message={`There is no flight plan history.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Flight Message and Process History --------------------------------- */}

      {/* --------------------------------- Start of Crew Sign On --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Crew Sign On
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Rank",
                  "Badge name",
                  "Last name",
                  "ERN",
                  "Galacxy ID",
                  "SD code",
                  "Sign on time",
                  "Sign on location",
                  "Removed/Signed out"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                (!!closeFlightDetail?.crewSignOn && closeFlightDetail?.crewSignOn.length !== 0) ?
                  closeFlightDetail?.crewSignOn.map((crewData: any, index) => {
                    let recordCount = closeFlightDetail.crewSignOn.length;
                    let flightMessageAndProcessHistory = {
                      category: [crewData?.category || "--", "string"],
                      badgeName: [crewData?.badgeName || "--", "bold string"],
                      lstNm: [crewData?.lstNm || "--", "string"],
                      currentErn: [crewData?.currentErn || "--", "string"],
                      galacxyId: [crewData?.galacxyId || "--", "string"],
                      specialDutyCode: [crewData?.specialDutyCode || "--", "string"],
                      signOnTime: [crewData?.signOnTime || "--", "date"],
                      signOnLocation: [[crewData?.signOnLocation.lat, crewData?.signOnLocation.lng], "location"],
                      removedOrSignOutTime: [crewData?.deleted || "--", "date"],
                    };
                    return <TableRow displayData={flightMessageAndProcessHistory} count={recordCount} index={index} hasArrow={false} />
                  })
                  :
                  <NoDataTableItem colSpan={14} message={`There is no Crew Sign on Data.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- Start of Crew Sign On --------------------------------- */}

      {/* --------------------------------- Start of Loadsheet --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "36px" }}>
            Loadsheet
          </div>
          {(!!closeFlightDetail?.acceptLoadsheet && closeFlightDetail?.acceptLoadsheet.length !== 0) ?
            <div className={"d-flex"}>
              <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
                Accepted Loadsheet
              </div>
              {closeFlightDetail?.acceptLoadsheet.map((loadSheet, index) => {
                const finalFuelVer = loadSheet?.raw.slice(105, 107);
                return <div style={{ marginLeft: "47px" }}>
                  FINAL {finalFuelVer}
                  <div key={`acceptLoadsheet${index}`} className={"RobotoMonoRg"} style={{ border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D" }}>
                    {loadSheet?.raw || ""}
                  </div>
                </div>
              })}
            </div>
            : <NoData text={"No Accepted Loadhsheet retrieved"} />
          }

          <div style={{ background: "#F5F5F5", borderRadius: "16px", padding: "22px 25px", marginTop: "24px" }}>
            <div className={"clickable"} style={{ background: "#F5F5F5", position: "relative" }} onClick={() => { setExpandLoadsheet(!expandLoadsheet) }}>
              <div className={"loadsheetArrow"}>
                {expandLoadsheet ? <DownArrowIcon height={"24px"} width={"24px"} /> : <CenteredRightArrowIcon height={"24px"} width={"24px"} />}
              </div>
              <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", paddingLeft: "25px" }}>All loadsheet versions</div>
            </div>
            {expandLoadsheet && <>
              {(!!closeFlightDetail?.allLoadsheet && closeFlightDetail.allLoadsheet.length !== 0) ?
                <div className={"d-flex"} style={{ overflow: "scroll" }}>
                  {closeFlightDetail.allLoadsheet.map((loadsheet, index) => {
                    return <div style={{ padding: "30px 0px 16px 24px" }} key={`loadsheet${index}`}>
                      <div style={{ marginBottom: "16px" }}>
                        {loadsheet.type === "LOADSHEET" ? <span className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "16px", lineHeight: "20px" }}>{loadsheet.type} - {loadsheet.version}</span>
                          : <span className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "16px", lineHeight: "20px" }}>{loadsheet.type}</span>}
                      </div>
                      <div className={"RobotoMonoRg"} style={{ overflow: "scroll", border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D", background: "#ffffff" }}>
                        {loadsheet.raw || ""}
                      </div>
                    </div>
                  })}
                </div>
                : <NoData text={"No Loadhsheet retrieved"} />}
            </>}
          </div>



        </AppSectionOutline>
      </div >
      {/* --------------------------------- End of Loadsheet --------------------------------- */}

      {/* --------------------------------- Start of Activate History --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Activate History
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Serial",
                  "Galacxy ID",
                  "Device ID",
                  "Activate time"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                (!!closeFlightDetail?.activateAction && closeFlightDetail.activateAction.length !== 0) ?
                  closeFlightDetail.activateAction.map((action, index) => {
                    let acceptFuelHistory = [
                      [action?.serial || "--", "string"],
                      [action?.galacxyId || "--", "string"],
                      [action?.deviceId || "--", "string"],
                      [action?.createAt ? dateFormatter(action.createAt) : "--", "date"]
                    ];
                    return <TableRow key={`acceptFuelAction${index}`} displayData={acceptFuelHistory} count={0} index={0} noLineBreak={true} hasArrow={false} />
                  })
                  :
                  <NoDataTableItem colSpan={14} message={`There is no Activate History Data.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Activate History --------------------------------- */}

      {/* --------------------------------- Start of Accept Fuel History --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Accept Fuel History
          </div>
          <CTable borderless className={"fuel-history-table"}>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col"> </CTableHeaderCell>
                {[
                  "Serial",
                  "Galacxy ID",
                  "Value",
                  "Total Fuel",
                  "Accept fuel time"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                (!!closeFlightDetail?.acceptFuelAction && closeFlightDetail.acceptFuelAction.length !== 0) ?
                  closeFlightDetail.acceptFuelAction.map((action, index) => {
                    let acceptFuelHistory = [
                      [index > -1 ? index : -1, "isShow"],
                      [action?.serial || "--", "greyString"],
                      [action?.userId || "--", "greyString"],
                      [action?.value || "--", "greyString"],
                      [action?.totalFuel || "--", "greyString"],
                      [action?.createAt ? dateFormatter(action.createAt) : "--", "date"]
                    ];
                    if (isShowDetailIndex != index) {
                      return <TableRow key={`acceptFuelAction${index}`} displayData={acceptFuelHistory} count={0} index={0} noLineBreak={true} hasArrow={false} />
                    } else {
                      return (
                        <>
                          <TableRow key={`acceptFuelAction${index}`} displayData={acceptFuelHistory} count={0} index={0} noLineBreak={true} hasArrow={false} />
                          <CTableRow>
                            <CTableHeaderCell colSpan={6}>
                              <div style={{ border: "1px solid #b9bcc0", width: "720px", marginLeft: "32px", borderRadius:"6px", padding: "16px", fontFamily: 'CATHAYSANS_RG', background: "#fff"}}>
                                <div style={{ fontSize: "16px", fontWeight: "600", marginBottom:"10px"}}>
                                  Fuel breakdown
                                </div>
                                <CTable borderless className='closeFlight-fuelBreakdown-table'>
                                  <CTableHead>
                                    <CTableRow>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>Mode</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>ZFW</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>TOW</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>LW</CTableHeaderCell>
                                    </CTableRow>
                                  </CTableHead>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.fuelEditMode == 'string' && action.fuelEditMode.length == 0) ? '--' : action.fuelEditMode}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.ZFW == 'string' && action.ZFW.length == 0) ? '--' : action.ZFW}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.TOW == 'string' && action.TOW.length == 0) ? '--' : action.TOW}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.LW == 'string' && action.LW.length == 0) ? '--' : action.LW}</CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                  <CTableHead>
                                    <CTableRow>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>Taxi</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>Trip fuel</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>Cont</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>Dest hold</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>Additional</CTableHeaderCell>
                                    </CTableRow>
                                  </CTableHead>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.taxi == 'string' && action.taxi.length == 0) ? '--' : action.taxi}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.trip == 'string' && action.trip.length == 0) ? '--' : action.trip}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.contingency == 'string' && action.contingency.length == 0) ? '--' : action.contingency}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.destinationHold == 'string' && action.destinationHold.length == 0) ? '--' : action.destinationHold}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.additional == 'string' && action.additional.length == 0) ? '--' : action.additional}</CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                  <CTableHead>
                                    <CTableRow>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>ALTN</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>Reserve</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>Tankering</CTableHeaderCell>
                                      <CTableHeaderCell className='closeFlight-fuelBreakdown-title'>Extra</CTableHeaderCell>
                                    </CTableRow>
                                  </CTableHead>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.alternative == 'string' && action.alternative.length == 0) ? '--' : action.alternative}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.reserve == 'string' && action.reserve.length == 0) ? '--' : action.reserve}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.tankering == 'string' && action.tankering.length == 0) ? '--' : action.tankering}</CTableDataCell>
                                      <CTableDataCell className='closeFlight-fuelBreakdown-value'>{(typeof action.extra == 'string' && action.extra.length == 0) ? '--' : action.extra}</CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                </CTable>
                                <div style={{ fontSize: "16px", fontWeight: "400", marginBottom:"10px"}}>
                                  Name of the selected ALTN airport
                                </div>
                                <div style={{ fontSize: "16px", fontWeight: "bolder", marginBottom:"10px"}}>
                                  {action?.alternativeAirport || '--'}
                                </div>
                              </div>
                            </CTableHeaderCell>
                          </CTableRow>
                        </>
                      )
                    }
                  })
                  :
                  <NoDataTableItem colSpan={14} message={`There is no Accept Fuel History Data.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Accept Fuel History --------------------------------- */}

      {/* --------------------------------- Start of Fuel Receipt History --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>

          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Fuel Receipt History
          </div>

          {/* Fuel Receipt History Headers */}
          <CRow>
            <CCol xs={2}>
              <div className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C", padding: "0px" }}>
                Fuel Receipt
              </div>
            </CCol>
            <CCol xs={10}>
              <CRow>
                <CCol xs={2}>
                  <div className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C", padding: "0px" }}>
                    Receipt Number
                  </div>
                </CCol>
                <CCol xs={2}>
                  <div className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C", padding: "0px" }}>
                    Fuel company
                  </div>
                </CCol>
                <CCol xs={1}>
                  <div className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C", padding: "0px" }}>
                    Order
                  </div>
                </CCol>
                <CCol xs={1}>
                  <div className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C", padding: "0px" }}>
                    Uplift
                  </div>
                </CCol>
                <CCol xs={1}>
                  <div className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C", padding: "0px" }}>
                    SG
                  </div>
                </CCol>
                <CCol xs={1}>
                  <div className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C", padding: "0px" }}>
                    Reg
                  </div>
                </CCol>
                <CCol xs={2}>
                  <div className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C", padding: "0px" }}>
                    Digital/Manual Refuel
                  </div>
                </CCol>
                <CCol xs={2}>
                  <div className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C", padding: "0px" }}>
                    Status
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          {
            (!!focusedCloseFlightPlan?.activateAndAcceptFuelHistory?.opsForm &&
              focusedCloseFlightPlan?.activateAndAcceptFuelHistory?.opsForm.length !== 0) ?
              focusedCloseFlightPlan?.activateAndAcceptFuelHistory?.opsForm.map((form, index) => {
                return <div key={`opsForm${index}`} style={{ marginTop: "15px" }}>
                  <CRow>
                    <CCol xs={2}>
                      <div style={{ backgroundColor: "#D9D9D9", height: "166px", width: "166px", marginRight: "24px" }}>
                        {(!!form?.photoID) ?
                          <FixedImage user={user} type={"img"} upliftType={form?.type} fileName={
                            encodeURI(`${ufi}/image_${form.photoID}.jpg`)
                          } />
                          :
                          <div className={"w-100 h-100 d-flex align-items-center justify-content-center"}>No Image</div>
                        }
                      </div>
                    </CCol>
                    <CCol xs={10}>
                      <CRow>
                        <CCol xs={2}>
                          <div className={"RobotoMonoRg px-0"} style={{ paddingTop: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "#000000", }}>
                            <div className={"paddingch4ff5"}>
                              {form?.receiptNumber || "--"}
                            </div>
                          </div>
                        </CCol>
                        <CCol xs={2}>
                          <div className={"CathaySansRg px-0"} style={{ paddingTop: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "#000000", }}>
                            <div className={"paddingch4ff7"}>
                              {form?.fuelCompany || "--"}
                            </div>
                          </div>
                        </CCol>
                        <CCol xs={1}>
                          <div className={"RobotoMonoRg px-0"} style={{ paddingTop: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "#000000", }}>
                            <div className={"paddingch4ff5"}>
                              {form?.order || "--"}
                            </div>
                          </div>
                        </CCol>
                        <CCol xs={1}>
                          <div className={"px-0"} style={{ paddingTop: "10px" }}>
                            <span className={"RobotoMonoRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "#000000" }}>
                              {!!form?.uplift ? `${form.uplift}` : "--"}
                            </span>
                            <span className={"CathaySansRg"} style={{ fontWeight: 300, fontSize: "10px", lineHeight: "20px", color: "#000000", paddingLeft: "2px" }}>
                              {!!form?.upliftUnit ? `${form.upliftUnit}` : ""}
                            </span>
                          </div>
                        </CCol>
                        <CCol xs={1}>
                          <div className={"RobotoMonoRg px-0"} style={{ paddingTop: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "#000000", }}>
                            <div className={"paddingch4ff5"}>
                              {form?.sg || "--"}
                            </div>
                          </div>
                        </CCol>
                        <CCol xs={1}>
                          <div className={"CathaySansRg px-0"} style={{ paddingTop: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "#000000", }}>
                            <div className={"paddingch4ff7"}>
                            {form?.registration || "--"}
                            </div>
                          </div>
                        </CCol>
                        <CCol xs={2}>
                          <div className={"CathaySansRg px-0"} style={{ paddingTop: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "#000000", }}>
                            <div className={"paddingch4ff7"}>
                              {(!!form?.receiptNumber) ? `Digital Refuel (${form?.type? `${form?.type}` : "--"})` : "Manual Refuel"}
                            </div>
                          </div>
                        </CCol>
                        <CCol xs={2}>
                          <div className={"CathaySansRg px-0"} style={{ paddingTop: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "#000000", }}>
                            <div className={"paddingch4ff7"}>
                              {form?.state === "TicketRequired" ? `${form.state}\nREMARKS:${form.ticketRemark || ""}` : form.state}
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </div>
              }) :
              <NoData text={"No Fuel Receipt History retrieved"} />}
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Fuel Receipt History --------------------------------- */}

      {/* --------------------------------- Start of Logs --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Logs
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Waypoint",
                  "FL",
                  "FOB / TOT",
                  "USED / CALC",
                  "Usernote",
                  "ATA",
                  "Remarks",
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                (!!focusedCloseFlightPlan?.logs && focusedCloseFlightPlan.logs.length !== 0) ?
                  focusedCloseFlightPlan?.logs.map((log: any, index) => {
                    let recordCount = focusedCloseFlightPlan.logs.length;
                    let time1, time2;
                    if (index === 0) {
                      time1 = {
                        label: "Out",
                        value: log?.action?.time1?.value || ""
                      };
                      time2 = {
                        label: "Off",
                        value: log?.action?.time2?.value || ""
                      };
                    } else if (index === recordCount - 1) {
                      time1 = {
                        label: "On",
                        value: log?.action?.time1?.value || ""
                      }
                      time2 = {
                        label: "In",
                        value: log?.action?.time2?.value || ""
                      };
                    }

                    let logRecord = {
                      wayPoint: [[log?.waypointname || "--", log?.waypointlongname || "--"], "vertical string"],
                      fl: [log?.action?.fl?.value || "--", "roboMonoRg"],
                      fob: [log?.action?.fuel1?.value || "--", "roboMonoRg"],
                      fuelUsed: [(index !== 0) ? (log?.action?.fuel2?.value || "--") : " ", "roboMonoRg"],
                      userNote: [log?.action?.usernote?.value || "--", "roboMonoRg"],
                      ata: [log?.action?.ata?.value || "--", "roboMonoRg"],
                      remarks: [{
                        close: {
                          label: "Close",
                          value: log?.action?.door?.value || ""
                        },
                        time1: time1,
                        time2: time2,
                        reRouteToPos: {
                          label: "Reroute To",
                          note: log?.action?.reRouteToPos?.note || "",
                          value: (!!log?.action?.reRouteToPos?.value) ? focusedCloseFlightPlan.logs[parseInt(log?.action?.reRouteToPos?.value)].waypointname : ""
                        },
                        directToPos: {
                          label: "Direct To",
                          value: (!!log?.action?.directToPos?.value) ? focusedCloseFlightPlan.logs[parseInt(log?.action?.directToPos?.value)].waypointname : ""
                        },
                        isCallATC: {
                          label: "Called ATC",
                          value: log?.action?.isCallATC?.value || ""
                        },
                        isFuelCheck: {
                          label: "Checked fuel",
                          value: log?.action?.isFuelCheck?.value || ""
                        },
                      } || {}, "object"],
                    };
                    return <TableRow displayData={logRecord} count={recordCount} index={index} hasArrow={false} />
                  })
                  :
                  <NoDataTableItem colSpan={14} message={`There is no Crew Sign on Data.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- Start of Logs --------------------------------- */}

      {/* --------------------------------- Start of Clearance --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Clearance
          </div>
          <CRow>
            <Col xs={6}>
              <div style={{ backgroundColor: "#F5F5F5", borderRadius: "16px", padding: "20px" }}>
                <div className={"CathaySansRg"} style={{ marginBottom: "16px", fontWeight: 700, fontSize: "16px", lineHeight: "20px", letterSpacing: "0.266667px", color: "#4C4C4C" }}>
                  Departure
                </div>
                {[[{
                  label: "RWY",
                  value: depClearance?.runway || "--"
                }, {
                  label: "SID",
                  value: depClearance?.sid || "--"
                }, {
                  label: "TRANS",
                  value: depClearance?.trans || "--"
                }, {
                  label: "ROUTE",
                  value: depClearance?.route || "--"
                }], [{
                  label: "LEVEL",
                  value: depClearance?.level || "--"
                }, {
                  label: "SQUAWK",
                  value: depClearance?.squawk || "--"
                }, {
                  label: "COMS",
                  value: depClearance?.coms || "--"
                }, {
                  label: "BAY",
                  value: depClearance?.bay || "--"
                },
                {
                  label: "ATIS",
                  value: depClearance?.atis || "--"
                }]].map((row, index) => {
                  return <CRow xs={{ cols: 4 }} key={`depClearanceRow${index}`}>
                    {
                      row.map((col, index) => {
                        return <CCol key={`depClearanceCol${index}`}>
                          <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                            {col.label}
                          </div>
                          <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                            {col.value}
                          </div>
                        </CCol>
                      })
                    }
                  </CRow>
                })}
                <CRow>
                  <CCol>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      Other
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", whiteSpace: "pre-line", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {depClearance?.other || "--"}
                    </div>
                  </CCol>
                </CRow>
              </div>
            </Col>
            <Col xs={6}>
              <div style={{ backgroundColor: "#F5F5F5", borderRadius: "16px", padding: "20px" }}>
                <div className={"CathaySansRg"} style={{ marginBottom: "16px", fontWeight: 700, fontSize: "16px", lineHeight: "20px", letterSpacing: "0.266667px", color: "#4C4C4C" }}>
                  Arrival
                </div>
                {[[{
                  label: "RWY",
                  value: arrClearance?.runway || "--"
                }, {
                  label: "STAR",
                  value: arrClearance?.sid || "--"
                }, {
                  label: "TRANS",
                  value: arrClearance?.trans || "--"
                }, {
                  label: "ROUTE",
                  value: arrClearance?.route || "--"
                }], [{
                  label: "LEVEL",
                  value: arrClearance?.level || "--"
                }, {
                  label: "SQUAWK",
                  value: arrClearance?.squawk || "--"
                }, {
                  label: "COMS",
                  value: arrClearance?.coms || "--"
                }, {
                  label: "BAY",
                  value: arrClearance?.bay || "--"
                },  {
                  label: "ATIS",
                  value: arrClearance?.atis || "--"
                }, {
                  label: "Approach Type",
                  value: opsLandingSector?.approachType || "--"
                }]].map((row, index) => {
                  return <CRow xs={{ cols: 4 }} key={`arrClearanceRow${index}`}>
                    {
                      row.map((col, index) => {
                        return <CCol key={`arrClearanceCol${index}`}>
                          <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                            {col.label}
                          </div>
                          <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                            {col.value}
                          </div>
                        </CCol>
                      })
                    }
                  </CRow>
                })}
                <CRow>
                  <CCol>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      Other
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", whiteSpace: "pre-line", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {arrClearance?.other || "--"}
                    </div>
                  </CCol>
                </CRow>
              </div>
            </Col>
          </CRow>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- Start of Clearance --------------------------------- */}

      {/* --------------------------------- Start of OPS Report --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            OPS Report
          </div>
          {(!!focusedCloseFlightPlan?.opsReport && focusedCloseFlightPlan?.opsReport.length !== 0) ? focusedCloseFlightPlan?.opsReport.map((report, index) => {
            return <CRow key={`opsReport${index}`}>
              <CCol xs={4}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                  Category
                </div>
                <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                  {report?.category || "--"}
                </div>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                  Subject
                </div>
                <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                  {report?.subject || "--"}
                </div>

              </CCol>
              <CCol xs={8}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                  Comments
                </div>
                <div className={"CathaySansRg"} style={{ marginBottom: "12px", whiteSpace: "pre-line", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                  {report?.comments || "--"}
                </div>
              </CCol>
              <CCol xs={4}></CCol>
              <CCol xs={8}>
                <CRow>
                  <CCol xs={3}>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      Airport
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", whiteSpace: "pre-line", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {report?.airport || "--"}
                    </div>
                  </CCol>
                  <CCol xs={3}>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      Chart ref
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", whiteSpace: "pre-line", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {report?.chartRef || "--"}
                    </div>
                  </CCol>
                  <CCol xs={6}>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      Response required
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", whiteSpace: "pre-line", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {report?.isResponseRequired ? "Yes" : "No"}
                    </div>
                  </CCol>
                </CRow>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                  Photo
                </div>

                {!!report?.photoIDs && <div className={"d-flex"}>
                  {
                    report?.photoIDs.length !== 0 ?
                      report?.photoIDs.map((key, index) => {
                        return <div key={`imgUrl${index}`} className={"text-center"} style={{ marginTop: "8px", marginRight: "8px" }}>
                          <div style={{ backgroundColor: "#D9D9D9", height: "166px", width: "166px", position: "relative" }}>
                            <FixedImage upliftType={"manual"} user={user} type={"img"} fileName={
                              // key
                              encodeURI(`${ufi}/image_${key}.jpg`)
                            } />
                          </div>
                        </div>
                      })
                      :
                      <NoData text={"No Photos retrieved"} />
                  }
                </div>}
              </CCol>
            </CRow>
          })
            : <CRow>
              <CCol>
                <CTable borderless className={"m-0"}>
                  <CTableHead>
                    <CTableRow>
                      {[].map((heading, index) => {
                        return <TableHeading text={heading} index={index} />
                      })}
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {
                      <NoDataTableItem colSpan={1} message={`No OPS Report retrieved`} />
                    }
                  </CTableBody>
                </CTable>
              </CCol>
            </CRow>}
        </AppSectionOutline>
      </div>
      {/* --------------------------------- Start of OPS Report --------------------------------- */}

      {/* --------------------------------- Start of OPS Sector --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            OPS Sector
          </div>
          <CRow>
            <Col xs={6}>
              <div style={{ backgroundColor: "#F5F5F5", borderRadius: "16px", padding: "20px" }}>
                <div className={"CathaySansRg"} style={{ marginBottom: "16px", fontWeight: 700, fontSize: "16px", lineHeight: "20px", letterSpacing: "0.266667px", color: "#4C4C4C" }}>
                  Takeoff
                </div>
                <CRow>
                  <CCol xs={3}>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      RTOW
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {opsTakeOffSector?.RTOW || "--"}
                    </div>
                  </CCol>
                </CRow>

                <div style={{ marginBottom: "12px" }}>
                  <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                    Off block delay
                  </div>
                  <CRow>
                    {
                      (!!opsTakeOffSector?.delayReasons && opsTakeOffSector.delayReasons.length !== 0) ?
                        opsTakeOffSector.delayReasons.map((delayReason, index) => {
                          return <CCol xs={3} key={`opsTakeOffSectorDelayReason${index}`}>
                            <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                              {delayReason.time}m {delayReason.reason}
                            </div>
                          </CCol>
                        })
                        : <div>{"--"}</div>
                    }
                  </CRow>
                </div>

                <div style={{ marginBottom: "12px" }}>
                  <CRow>
                    <CCol xs={6}>
                      <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                        Ground facilities
                      </div>
                    </CCol>
                    <CCol xs={6}>
                      <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                        Ground facilities reason
                      </div>
                    </CCol>
                    <CCol xs={3}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        Air {opsTakeOffSector?.groundFacAir ? "not used" : "used"}
                      </div>
                    </CCol>
                    <CCol xs={3}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        Elec {opsTakeOffSector?.groundFacElec ? "not used" : "used"}
                      </div>
                    </CCol>
                    <CCol xs={6}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        {opsTakeOffSector?.groundFacReason || "--"}
                      </div>
                    </CCol>
                  </CRow>
                </div>

                <div style={{ marginBottom: "12px" }}>
                  <CRow>
                    <CCol xs={12}>
                      <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                        Pilot flying
                      </div>
                    </CCol>
                    <CCol xs={3}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        {opsTakeOffSector?.category || "--"}
                      </div>
                    </CCol>
                    <CCol xs={3}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        {`${opsTakeOffSector?.ERN || "--"}/${opsTakeOffSector?.flyingPilotId || "--"}`}
                      </div>
                    </CCol>
                    <CCol xs={6}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        {opsTakeOffSector?.flyingPilot || "--"}
                      </div>
                    </CCol>
                  </CRow>
                </div>

              </div>
            </Col>
            <Col xs={6}>
              <div style={{ backgroundColor: "#F5F5F5", borderRadius: "16px", padding: "20px" }}>
                <div className={"CathaySansRg"} style={{ marginBottom: "16px", fontWeight: 700, fontSize: "16px", lineHeight: "20px", letterSpacing: "0.266667px", color: "#4C4C4C" }}>
                  Landing
                </div>
                <CRow>
                  <CCol xs={3}>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      Arrival fuel
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {opsLandingSector?.arrivalFuel ? `${(opsLandingSector?.arrivalFuel / 1000).toFixed(1)}T` : "--"}
                    </div>
                  </CCol>
                  <CCol xs={3}>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      Autoland
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {opsLandingSector?.autoland || "--"}
                    </div>
                  </CCol>
                  <CCol xs={3}>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      Approach type
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {opsLandingSector?.approachType || "--"}
                    </div>
                  </CCol>
                  <CCol xs={3}>
                    <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                      DUMP
                    </div>
                    <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                      {opsTakeOffSector?.dumpFuel ? `${(opsTakeOffSector.dumpFuel / 1000).toFixed(1)}T` : "--"}
                    </div>
                  </CCol>
                </CRow>

                <div style={{ marginBottom: "12px" }}>
                  <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                    Off block delay
                  </div>
                  <CRow>
                    {
                      (!!opsLandingSector?.delayReasons && opsLandingSector.delayReasons.length !== 0) ?
                        opsLandingSector.delayReasons.map((delayReason, index) => {
                          return <CCol xs={3} key={`opsLandingSectorDelayReason${index}`}>
                            <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                              {delayReason.time}m {delayReason.reason}
                            </div>
                          </CCol>
                        })
                        : <div>{"--"}</div>
                    }
                  </CRow>
                </div>

                <div style={{ marginBottom: "12px" }}>
                  <CRow>
                    <CCol xs={6}>
                      <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                        Ground facilities
                      </div>
                    </CCol>
                    <CCol xs={6}>
                      <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                        Ground facilities reason
                      </div>
                    </CCol>
                    <CCol xs={3}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        Air {opsLandingSector?.groundFacAir ? "not used" : "used"}
                      </div>
                    </CCol>
                    <CCol xs={3}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        Elec {opsLandingSector?.groundFacElec ? "not used" : "used"}
                      </div>
                    </CCol>
                    <CCol xs={6}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        {opsLandingSector?.groundFacReason || "--"}
                      </div>
                    </CCol>
                  </CRow>
                </div>

                <div style={{ marginBottom: "12px" }}>
                  <CRow>
                    <CCol xs={12}>
                      <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>
                        Pilot flying
                      </div>
                    </CCol>
                    <CCol xs={3}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        {opsLandingSector?.category || "--"}
                      </div>
                    </CCol>
                    <CCol xs={3}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        {`${opsLandingSector?.ERN || "--"}/${opsLandingSector?.flyingPilotId || "--"}`}
                      </div>
                    </CCol>
                    <CCol xs={6}>
                      <div className={"CathaySansRg"} style={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                        {opsLandingSector?.flyingPilot || "--"}
                      </div>
                    </CCol>
                  </CRow>
                </div>

              </div>
            </Col>
          </CRow>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- Start of OPS Sector --------------------------------- */}

      {/* --------------------------------- Start of ASR Report --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            ASR Report
          </div>
          <div style={{ fontFamily: "CathaySans_Rg", fontWeight: 400, fontSize: "16px", lineHeight: "20px", color: "#000000" }}>
            {closeFlightDetail?.reportStatus?.ASRStatus}
          </div>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- Start of ASR Report --------------------------------- */}

      {/* --------------------------------- Start of Safety Hazard Report --------------------------------- */}
            <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Safety Hazard Report
          </div>
          <div style={{ fontFamily: "CathaySans_Rg", fontWeight: 400, fontSize: "16px", lineHeight: "20px", color: "#000000" }}>
            {closeFlightDetail?.reportStatus?.SafetyHazardStatus}
          </div>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- Start of Safety Hazard Report --------------------------------- */}

      {/* --------------------------------- Start of NOTAM --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            NOTAM
          </div>
          <CRow>
            <CCol>
              <CTable borderless className={"m-0"}>
                <CTableHead>
                  <CTableRow>
                    {[
                      "Serial",
                      "Category",
                      "Section",
                      "Bookmark",
                      "Revised Version",
                      "Last Modified Time"
                    ].map((heading, index) => {
                      return <TableHeading text={heading} index={index} />
                    })}
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {
                    (!!focusedCloseFlightPlan?.notams && focusedCloseFlightPlan.notams.length !== 0) ?
                      focusedCloseFlightPlan.notams.map((notam, index) => {
                        let recordCount = focusedCloseFlightPlan.notams.length;
                        let notamRecord = [
                          [notam?.serial || "--", "string"],
                          [notam?.category || "--", "string"],
                          [notam?.section || "--", "string"],
                          [notam?.typeRaw || "--", "string"],
                          [notam?.revisedVersion || "--", "string"],
                          [dateFormatter(notam?.created) || "--", "date"]
                        ];
                        return <TableRow displayData={notamRecord} count={recordCount} index={index} hasArrow={false} />
                      })
                      : <NoDataTableItem colSpan={7} message={`No Notam retrieved`} />
                  }
                </CTableBody>
              </CTable>
            </CCol>
          </CRow>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- Start of NOTAM --------------------------------- */}

    </>
  );
};

export default CloseFlightDetailContent;
