import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { CRow, CCol, CFormCheck } from "@coreui/react";
import { Modal } from "react-bootstrap";
import { CSmartPagination } from '@coreui/react-pro'
import _ from 'lodash';

import { CXButton, CXTextInput } from '../../components/CXForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DataTable from '../../components/Data/DataTable';
import { getDocuments, updateDocument } from '../../services/dataAPI';
import { selectData, setDocuments } from '../../slice/dataSlice';
import CXFPConfigTextInput from "../../components/CXForm/CXFPConfigTextInput";
import TagsInput from "../../components/TagInputs";
import { SUPPORT_DATATYPES, DATA_TYPES } from '../../constants/dataConstant'
import ReminderModal from '../../components/ReminderModal';
import PageHeaderTitle from '../../components/PageHeaderTitle';
import PageContentWithoutPermission from '../../components/PageContentWithoutPermission';

import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';
import { checkIfHasAccess } from '../../utils/roleAccessHelper';
import { selectAuth } from '../../slice/authSlice';

/**
 * Application home page component
 * @returns {JSX}
 */

const DataDetailPage = () => {
  // const params = useParams()
  const { pathname: routePath } = useLocation();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const location = useLocation();
  const history = useHistory();
  let query = useQuery();

  const colName = query.get('colName') || "";
  const createNewFlag = query.get('createNewFlag') || false;
  const dispatch = useAppDispatch();
  const { documents } = useAppSelector(selectData);
  const { userGroupIDs } = useAppSelector(selectAuth);

  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [colList, setColList] = useState<string[]>([]);
  const [isCreateModal, setIsCreateModal] = useState<boolean>(createNewFlag as boolean);
  const [maxDocModal, setMaxDocModal] = useState<object>({}); // as create new doc template
  const [originalTempDocModal, setOriginalTempDocModall] = useState<object>({});// used to compare if any changes when create or edit doc
  const [tempDocModal, setTempDocModal] = useState<object>({});// to save temp doc info when create new or edit  
  const [isSaveReminder, setIsSaveReminder] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);


  const updateURL = () => {
    if (createNewFlag) {
      const params = new URLSearchParams({ colName: colName });
      history.replace({ pathname: location.pathname, search: params.toString() });
    }
  }

  const onClickCreateNewDocBtn = () => {
    setIsCreateModal(true);
    setTempDocModal(maxDocModal);
    setOriginalTempDocModall(maxDocModal);
  }

  const closeModal = () => {
    if (JSON.stringify(tempDocModal) === JSON.stringify(originalTempDocModal)) {
      isCreateModal && setIsCreateModal(false);
      isEditModal && setIsEditModal(false);
    }
  }

  const onClickDocument = (document: any) => {
    setTempDocModal(document);
    setOriginalTempDocModall(document);
    setIsEditModal(true);
  }

  const onUpdateValue = (updatedValue: string | string[] | boolean, objectKey: string) => {
    let targetValObj = tempDocModal[objectKey];
    let targetVal;
    switch (targetValObj?.type.toString().toLowerCase()) {
      case DATA_TYPES.NUMBER:
        targetVal = Number(updatedValue);
        break;
      default:
        targetVal = updatedValue;
        break;
    }

    let docCopy = { ...tempDocModal };
    docCopy[objectKey] = { ...docCopy[objectKey], value: targetVal };
    setTempDocModal(docCopy);
  }

  const onClickCancel = () => {
    if (JSON.stringify(tempDocModal) !== JSON.stringify(originalTempDocModal)) {
      setIsSaveReminder(true);
    } else {
      onConfirmCancelClick();
    }

    createNewFlag && updateURL();
  }

  const onConfirmCancelClick = () => {
    setIsSaveReminder(false);
    isCreateModal && setIsCreateModal(false);
    isEditModal && setIsEditModal(false);
  }

  const onClickSave = () => {
    updateDocument(colName, tempDocModal).then(res => {
      //recall get docs api to get the latest docs
      if (res?.status === 500) {
        console.log('updateDocument failed, res=', res);
      } else {
        callToGetDocs();
        isCreateModal && setIsCreateModal(false);
        isEditModal && setIsEditModal(false);
        createNewFlag && updateURL();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const inputSelector = (key: string, dataType: DATA_TYPES, targetValue: any) => {
    if (dataType === DATA_TYPES.ARRAY) {
      return <TagsInput
        key={key}
        placeholder={`${key} (${dataType.toUpperCase()}) (Use “Enter” key for multiple input)`}
        tagList={targetValue}
        field={key}
        onDispatchValue={onUpdateValue}
        errMsg="Required"></TagsInput>
    } else if ([DATA_TYPES.STRING, DATA_TYPES.NUMBER].includes(dataType)) {
      return <CXFPConfigTextInput
        key={key}
        value={targetValue}
        placeholder={`${key} (${dataType.toUpperCase()})`}
        dataOnBlur={{ onBlur: onUpdateValue, objectKey: key as string }}
        disabled={key === "_id"} />
    } else if (dataType === DATA_TYPES.BOOLEAN) {
      return <CFormCheck
        className='mt-2'
        id={key}
        label={key}
        checked={targetValue || false}
        onChange={() => onUpdateValue(!targetValue, key)} />
    }
  }

  const getMaxKeysObjectFromList = (list) => {
    return _.reduce(list, function (prev, next) {
      return Object.keys(prev).length > Object.keys(next).length ? prev : next;
    }, 0);
  }

  const callToGetDocs = useCallback(() => {
    getDocuments(colName, (pageLimit || 20), (currentPage - 1)).then(res => {
      if (res?.status === 500) {
        console.log('getDocuments failed, res=', res);
      } else if (res?.documents) {
        const respDocs = res.documents;
        dispatch(setDocuments(respDocs));
        let maxDoc = getMaxKeysObjectFromList(respDocs);

        //set col list for documents table
        let tempCols: string[] = [];
        for (let key of Object.keys(maxDoc)) {
          if (SUPPORT_DATATYPES.includes((maxDoc[key]?.type.toString().toLowerCase() || "")))
            tempCols.push(key);
        };
        setColList(tempCols);

        //clear maxDocModal as an empty Doc Modal template
        let tempDoc = { ...maxDoc };
        Object.keys(tempDoc).forEach(key => {
          const tempValObj = tempDoc[key];
          switch (tempValObj?.type.toString().toLowerCase()) {
            case DATA_TYPES.STRING:
              tempDoc[key] = { ...tempValObj, value: "" };
              break;
            case DATA_TYPES.OBJECT:
              tempDoc[key] = { ...tempValObj, value: null };
              break;
            case DATA_TYPES.ARRAY:
              tempDoc[key] = { ...tempValObj, value: [] };
              break;
            case DATA_TYPES.NUMBER:
              tempDoc[key] = { ...tempValObj, value: 0 };
              break;
            case DATA_TYPES.BOOLEAN:
              tempDoc[key] = { ...tempValObj, value: false };
              break;
            default:
              tempDoc[key] = { ...tempValObj, value: "" };
              break;
          }
        });
        setMaxDocModal(tempDoc);

        //check if jump into from data page
        if (createNewFlag) {
          setTempDocModal(tempDoc);
          setOriginalTempDocModall(tempDoc);
        }

        //update for pagination
        setTotalPage(res?.totalPage || 1);
      }
    }).catch(err => {
      console.log(err);
    });
  }, [colName, createNewFlag, currentPage, dispatch, pageLimit]);

  useEffect(() => {
    callToGetDocs();
  }, [callToGetDocs]);

  const renderCreateNewDoc = () => {
    return <>
      <Modal className="snnModal" show={isCreateModal} dialogClassName="modal-90w" onHide={closeModal} size="xl">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>Create New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {colList.map((key: keyof any, index) =>
            <CRow key={`k_create_doc_${key.toString()}`}>
              <CCol sm="auto" style={{ width: "100%" }}>
                {inputSelector(key as string, tempDocModal[key]?.type.toString().toLowerCase(), tempDocModal[key]?.value)}
              </CCol>
            </CRow>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => onClickCancel()} fontSize={"16px"} width={"100%"} /></div>
          <div className='save-button'><CXButton text={"Save"} onClick={() => onClickSave()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>
    </>
  }

  const renderEditDoc = () => {
    return (
      <>
        <Modal className="snnModal" show={isEditModal} dialogClassName="modal-90w" onHide={closeModal} size="xl">
          <Modal.Header className="modal-header" closeButton>
            <Modal.Title>Edit Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {colList.map((key: keyof any, index) =>
              <CRow key={`k_edit_doc_${tempDocModal['_id']?.value}_${key.toString()}`}>
                <CCol sm="auto" style={{ width: "100%" }}>
                  {inputSelector(key as string, tempDocModal[key]?.type.toString().toLowerCase(), tempDocModal[key]?.value)}
                </CCol>
              </CRow>
            )}
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => onClickCancel()} fontSize={"16px"} width={"100%"} /></div>
            <div className='save-button'><CXButton text={"Save"} onClick={() => onClickSave()} fontSize={"16px"} width={"100%"} /></div>
          </Modal.Footer>
        </Modal>
      </>
  )}

  const dataTableProps = {
    documents,
    onClickDocument,
    colList
  };

  const CSmartPaginationProps = {
    className: "mt-4",
    activePage: currentPage,
    pages: totalPage,
    onActivePageChange: setCurrentPage
  };

  const reminderModalProps = {
    isShow: isSaveReminder,
    onHide: () => { setIsSaveReminder(false) },
    onCancelClick: () => { setIsSaveReminder(false) },
    onConfirmCancelClick,
  };

  const renderContent = () => {
    const renderCreateNewDocBtn =
      <CRow className={"mt-4 mb-4"}>
        <CCol sm={2}>
          <Access route={routePath} access={[AccessType.CREATE]}>
            <CXButton outline={true} text={"Create new document"} onClick={() => { onClickCreateNewDocBtn() }} fontSize={"14px"} width={"100%"} />
          </Access>
        </CCol>
      </CRow>
    const renderDataTable = <DataTable {...dataTableProps} />
    const renderPagination =
      <>
        <CRow className={"mt-4"}>
          <CCol sm={2}>
            <CXTextInput placeholder={"Page Records Limit"} value={pageLimit.toString()} onChange={setPageLimit} zIndex={1029} />
          </CCol>
        </CRow>
        <CRow className={"mb-4"}>
          <CCol>
            <CSmartPagination {...CSmartPaginationProps} size='lg' />
          </CCol>
        </CRow>
      </>

    return (
        <>
          <PageHeaderTitle headerTitle={`Documents of Collection [${colName}]`} />

          {renderCreateNewDocBtn}

          {documents && documents.length > 0 ? renderDataTable : null}

          {documents && documents.length > 0 ? renderPagination : null}

          {/* --------- Edit Document---------*/}
          {documents && documents.length > 0 && checkIfHasAccess(routePath, userGroupIDs, [AccessType.UPDATE]) ? renderEditDoc() : null}

          {/* --------- Create Document---------*/}
          {checkIfHasAccess(routePath, userGroupIDs, [AccessType.CREATE])? renderCreateNewDoc(): null}

          <ReminderModal {...reminderModalProps} />
        </>
    )
  }

  return renderContent();
};

export default DataDetailPage;