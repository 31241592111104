import { HTTP_REQUEST_TYPE, FEEDBACK_API, TAGGING_API } from "../constants/api";
import { sendHttpRequest } from "./api";
import { ITagModal } from '../interfaces/crewfeedback/crewFeedbackTagging';

const getFeedbackTags = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        FEEDBACK_API.GET_FEEDBACK_TAGS,
        {}
    );
    return resp;
};

const getFeedbackRunways = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        FEEDBACK_API.GET_FEEDBACK_RUNWAYS,
        {}
    );
    return resp;
};

const getFeedbackLists = async (feedbackSearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        FEEDBACK_API.GET_FEEDBACK_LISTS,
        feedbackSearch ? feedbackSearch : {}
    );
    return resp;
};

interface cfCcsPayload {
    updatedId: string;
    updatedObj: {};
}

const updateFeedback = async (payload: cfCcsPayload) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        FEEDBACK_API.POST_UPDATE_FEEDBACK,
        {
            updatedId: payload.updatedId,
            updatedObj: payload.updatedObj
        }
    );
    return resp;
};

const getFeedbackTaggingList = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        TAGGING_API.GET_TAGGING_LIST,
        {}
    );
    return resp;
};

const updateTagging = async (tagReq: ITagModal) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        TAGGING_API.POST_UPDATE_TAGGING,
        {
           ...tagReq
        }
    );
    return resp;
};

export {
    getFeedbackTags,
    getFeedbackLists,
    updateFeedback,
    getFeedbackTaggingList,
    updateTagging,
    getFeedbackRunways
};
