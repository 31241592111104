import { useRef } from 'react';

const UseFileInput = () => {
  
  const fileInputRef = useRef<HTMLInputElement>(null);

  const triggerFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearFileValue = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return {
    fileInputRef,
    triggerFileSelect,
    clearFileValue,
  };
};

export default UseFileInput;