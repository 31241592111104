import * as React from 'react';
import { CTableRow, CTableDataCell, CFormCheck } from '@coreui/react';

interface ICfCcsCheckBoxProps {
  index: number;
  cfCcs: {
    _id: string,
    ufi: string,
    Autoland: string,
    Destination: string,
    FlightDate: string,
    LandingERN: string,
    Origin: string,
    SectorSeqNum: string,
    TakeoffERN: string,
    cfid: string,
    created: string,
    filename: string,
    sent: string,
    isChecked: boolean
  };
  onUpdateCfCcsIndex: Function;

}

const CfCcsTableItem = ({ index, cfCcs, onUpdateCfCcsIndex }: ICfCcsCheckBoxProps) => {
  
  const onClickCheckbox = (index: number) => {
    onUpdateCfCcsIndex(index);
  }
  
  return <CTableRow>
    <CTableDataCell><CFormCheck id="flexCheckDefault" checked={cfCcs.isChecked || false} onChange={()=> onClickCheckbox(index)}/></CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.ufi || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.Autoland || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.Destination || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.FlightDate || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.LandingERN || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.Origin || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.SectorSeqNum || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.TakeoffERN || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.cfid || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.created || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.filename || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{cfCcs.sent || "-"}</CTableDataCell>
  </CTableRow>
};

export default CfCcsTableItem;
