import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface UserSlice {
    userId: string;
    access: object;
    allowedRoutes: Array<string>;
};

const initialState: UserSlice = {
    userId: "",
    access: {},
    allowedRoutes: [],
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            const { userId, access, allowedRoutes } = payload || {};
            state.userId = userId;
            state.access = access;
            state.allowedRoutes = allowedRoutes;
        },
        clearUser: (state) => {
            state.userId = "";
            state.access = {};
            state.allowedRoutes = [];
        }
    }
});

const selectUser = (state: RootState) => state.user;
const { setUser, clearUser } = userSlice.actions;

export { userSlice, selectUser, setUser, clearUser };
export type { UserSlice };

export default userSlice.reducer;
