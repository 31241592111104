import * as React from "react";
import { CTableRow, CTableDataCell, CCol, CRow } from "@coreui/react";
import { DeleteIcon, EditIcon } from "../../../assets/images";
import { ACTIONS } from "../../../constants/CarReportConstants";
import Access from '../../../components/Access';
import { AccessType } from '../../../constants/roleAccessConstant';
import { useLocation } from "react-router-dom";


interface ICarReportTableItemProps {
  dispatchReducer: React.Dispatch<any>;
  carReportEmail: any;
  colValues: any[];
}

const CarReportTableItem = ({
  dispatchReducer,
  carReportEmail,
  colValues,
}: ICarReportTableItemProps) => {
  const { pathname: routePath } = useLocation();
  const EditButton = () => {
    return (
      <div className={"clickable-icon"} style={{ marginLeft: "4px" }}>
        <EditIcon
          height={40}
          width={40}
          onClick={() => {
            dispatchReducer({
              type: ACTIONS.SHOW_EDIT,
              emailItem: carReportEmail,
            });
          }}
        />
      </div>
    );
  };

  return (
    <CTableRow className={`carReportTableItem`} style={{ borderBottom: "1px solid #D8D8D8" }}>
      {!!colValues &&
        colValues.length !== 0 &&
        Array.isArray(colValues) &&
        colValues.map((col_value, index) => (
          <CTableDataCell
            key={`tableItemContainer${index}`}
            className={`RobotoMonoRg tableItem px-1 ${col_value.key}`}
            style={{ position: "relative" }}
            align="middle"
          >
            {index == colValues.length - 1 ? (
              <CRow>
                <CCol>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    {col_value?.value}
                  </div>
                </CCol>
                <CCol sm="auto">
                    <CRow className="actionIcon">
                      <Access route={routePath} access={[AccessType.UPDATE]}>
                        <CCol sm="auto">
                          <EditButton />
                        </CCol>
                      </Access>
                      <Access route={routePath} access={[AccessType.DELETE]}>
                        <CCol sm="auto">
                          <DeleteIcon
                            className="clickable-icon"
                            height={40}
                            width={40}
                            onClick={() => {
                              dispatchReducer({
                                type: ACTIONS.SHOW_DELETE,
                                emailItem: { carReportEmail },
                              });
                            }}
                          />
                        </CCol>
                    </Access>
                    </CRow>
                </CCol>
              </CRow>
            ) : (
              col_value?.value
            )}
          </CTableDataCell>
        ))}
    </CTableRow>
  );
};

export default CarReportTableItem;
