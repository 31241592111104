import { CRow, CCol, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody } from '@coreui/react-pro';

import { DownArrowIcon } from '../../../assets/images';
import SidStarTableItem from './SidStarTableItem';
import NoDataTableItem from '../../../components/AdminDashboard/NoDataTableItem';
import { ISidStarPageContainerProps } from './interface';
import Access from '../../../components/Access';
import { AccessType } from '../../../constants/roleAccessConstant';


const SidStarPageContainer = ({
  headerTitle = "SID/STAR data",
  sidStarList,
  fileCount,
  sidStarListColumns,
  renderImportDataButton,
  onClickDownload,
  sortByLocale,
  routePath
}: ISidStarPageContainerProps) => {
  const noDataAlertMsg = "There is no SID/STAR Data";

  return (
    <>
      <CRow className='mb-3'>
        {/* header title */}
        <CCol>
          <div
            className={"CathaySansBd"}
            style={{ fontSize: "32px", lineHeight: "36px" }}
          >
            {headerTitle}
          </div>
        </CCol>
        {/* import data button */}
        <Access route={routePath} access={[AccessType.CREATE]}>
          <CCol sm style={{ textAlign: "right" }}>
            {renderImportDataButton()}
          </CCol>
        </Access>
      </CRow>
      {/* file count */}
      <CRow className='mb-3'>
        <CCol sm style={{ fontSize: '18px', fontWeight: '500', lineHeight: '24px' }}>
          <span>No. of records: {fileCount}</span>
        </CCol>
      </CRow>
      {/* sid star table */}
      <CRow>
        <CCol>
          <CTable className="sidStarTable" borderless hover>
            <CTableHead>
              <CTableRow>
                {!!sidStarListColumns &&
                  sidStarListColumns.length > 0 &&
                  sidStarListColumns.map((column, index) =>
                    <CTableHeaderCell key={`sidStarTableHeaderCell_${index}`} scope="col" className={"clickable sidStarHeader"} onClick={() => {
                      sortByLocale(column.key)
                    }}>
                      {column.label}
                      {<DownArrowIcon height={12} width={12} />}
                    </CTableHeaderCell>
                  )}
                <CTableHeaderCell scope="col">
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {!!sidStarList && sidStarList.length !== 0 ?
                sidStarList.map((sidStar, index) =>
                  <SidStarTableItem key={`sidStarTableItem_${index}`} index={index} sidStar={sidStar} onDownloadSidStar={onClickDownload} />)
                :
                (<NoDataTableItem colSpan={3} message={noDataAlertMsg} />)
              }
            </CTableBody>
          </CTable>
        </CCol>
      </CRow>
    </>
  );
};

export default SidStarPageContainer;
