import { CCol, CRow } from '@coreui/react-pro';
import React, { useEffect, useRef, useState } from 'react';
import { DeleteIcon, PDFIcon } from '../../assets/images';
import { getPortAttachment } from '../../services/api';
import { IAttachment, IPortSecItem } from '../../slice/dispatcherSlice';


interface IPortPageAttachmentProps {
  secItems: IPortSecItem[];
  removeAttachment: (itemId: string, fileId: string, fileIndex: number) => void;
  isEditMode: boolean;
  downloadFile: Function;
  portInfo: any;
}

const PortPageAttachment = ({ secItems, removeAttachment, isEditMode, downloadFile, portInfo}: IPortPageAttachmentProps) => {

  const ref = useRef<HTMLDivElement>(null);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);

  const getAllFileUri = async () => {
    if(secItems[0] && secItems[0].files){
      let fileList = secItems[0].files;
      const listPromises = fileList.map(file => {
        const request = {
          ...portInfo,
          fileId: file.fileId
        }
        return getPortAttachment(request).then((res) => {
          const blobFile = new Blob([res], {type: res.type})
          const fileURL = URL.createObjectURL(blobFile);
          return {
            fileURL: fileURL,
            fileType: res.type.split("/")[0]
          };
        }).catch((err) => {
          console.log(err);
        });
      })
      const resultList = await Promise.all(listPromises);
      const finalResult: IAttachment[] = fileList.map((file, index) => {
        const fileName = file.name;
        const resultObj = resultList[index];
        return {
          id: file.fileId,
          name: fileName,
          imageUrl: resultObj?.fileURL as string,
          fileType: resultObj?.fileType
        }
      })
      setAttachments(finalResult);
    }
  }

  useEffect(()=> {

    getAllFileUri();

  },[JSON.stringify(secItems)])

  const download = (id: string, fileName: string) => {
    downloadFile(id, fileName);
  }

  const removeFile = (fileId: string, fileIndex: number) => {
    if(secItems[0] && secItems[0].files){
      let fileIds = secItems[0].files.map(file => file.fileId);
      removeAttachment(secItems[0].id, fileId, fileIndex);
    }
  }

  return (
    <div ref={ref}>
       {attachments.map((attachment,index) => {
        return <CRow className="port-page-attachment" key={`SecItem_${index}`}>
            <CCol xs={4} className={'content-title'}>
                <div style={{ backgroundColor: "#D9D9D9", height: "166px", width: "166px", marginRight: "24px" }}>
                  {attachment.fileType == "image" ?
                    <img src={`${attachment.imageUrl}`} className={"clickable"} alt={`opsFormPhoto`}
                      onClick={()=>{}}
                      style={{
                        width: "166px",
                        height: "166px",
                      }} />
                    :
                    <PDFIcon className={"w-100 h-100 d-flex align-items-center justify-content-center"} style={{backgroundColor: 'white'}}/>
                  }
                </div>
            </CCol>
            <CCol xs={6} style={{textDecoration:"underline", alignSelf:"center"}}>
              <span onClick={(e)=>download(attachment.id, attachment.name)}>{attachment.name}</span>
            </CCol>
            <CCol xs={2} style={{alignSelf:"center"}}>
                {isEditMode &&
                <DeleteIcon className='delete-icon' onClick={()=> removeFile(attachment.id, index)}/>
                }
            </CCol>
        </CRow>}
        )}
    </div>
  );
};

export default PortPageAttachment;
