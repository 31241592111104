import { CCol, CRow } from '@coreui/react';

import { IAircraft } from '../../slice/simSlice';
import { Form } from 'react-bootstrap';
import ViewLabel from '../ViewLabel';


interface IAircraftCardProps {
    aircraft: IAircraft;
}

const AircraftCard = ({ aircraft }: IAircraftCardProps) => {

  return (
    <CRow className="aircraftCard">
        <CCol xs={2}>
            <Form.Label className="aircraftICAO">{aircraft.aircraftICAO || "--"}</Form.Label>
        </CCol>
        <CCol xs={10}>
        <CRow className="">
            <CCol sm="auto">            
                <ViewLabel label="Airline" value={aircraft.airline || "--"} valueFont={"labelValue"}></ViewLabel>
            </CCol>
            <CCol sm="auto">            
                <ViewLabel label="Fleet Type" value={aircraft.fleetType || "--"} valueFont={"labelValue"}></ViewLabel>
            </CCol>
        </CRow>
        <hr/>
        <CRow className="">
            {aircraft.edt && Object.keys(aircraft.edt).map(key =>
                { return key != "_id" &&
                    <CCol sm="auto">
                        <ViewLabel label={key} value={aircraft.edt[key]} valueFont={"labelValue"} ></ViewLabel>
                    </CCol>
                }
            )}
        </CRow>
        <hr/>
        <CRow className="">
            <div className="CathaySansLt" style={{color:"#808285"}}>
                {"Registration"}
            </div>
            <div>
                { Array.isArray(aircraft.regs) && aircraft.regs.map((reg, index) => (
                    <div className="aircraftRegTag cx-tooltip" key={`Tag${index}`}>
                        <span className="text">{reg.reg}</span>
                        <span className="tooltiptext">{reg.aircraftFullname}</span>
                    </div>
                )) }
            </div>
        </CRow>
        </CCol>
    </CRow>
  )
};

export default AircraftCard;