import React, { useCallback, useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { CXButton, CXDatePicker, CXDropDown } from '../../components/CXForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { initialNewSNNRule, initialSNNSearch, ISNNRule, ISNNSearch, selectSim, setSNNRules, ISNNRuleRequest, initialSNNReducer } from '../../slice/simSlice';
import { Modal, } from 'react-bootstrap';
import SNNRulesTable from '../../components/Sim/SNNRulesTable';
import { CRow, CCol, CTable, CTableHeaderCell, CTableRow, CTableHead, CTableBody, CTableDataCell } from '@coreui/react-pro';
import CXTimePicker from '../../components/CXForm/CXTimePicker';
import TagsInput from '../../components/TagInputs';
import ViewLabel from '../../components/ViewLabel';
import { SNN_REQUIRED_FIELDS, SNN_SEARCH_FIELDS, WEEKDAYS_MULTISELECT_VALUE } from '../../constants/simConstant';
import { deleteSnnRule, getSnnRules, postCreateSnnRule, postUpdateSnnRule, sendAlert, sendCancelPolicy } from '../../services/snnAPI';
import ToggleSwitch from '../../components/ToggleSwitch';
import { getAircraftTypeList } from '../../services/commonAPI';
import CXFPConfigTextInput from '../../components/CXForm/CXFPConfigTextInput';
import CXFPConfigTextArea from '../../components/CXForm/CXFPConfigTextArea';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import { selectUser } from '../../slice/userSlice';
import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';
import { useLocation } from "react-router-dom";
import SNNSearchBar from '../../components/Sim/SNNSearchBar';
import { ActiveFlightPoliciesRadio, ActiveFlightPoliciesType, CONFIRM_MODAL_INFO_MAP, FootButtonsInSNNView, FootButtonsInViewObject, FuelPoliciesSupplement } from '../../constants/snnConstant';
import CXRadioButton from '../../components/CXForm/CXRadioButton';
import { pushToast } from '../../slice/appSlice';
import { dateFormatter } from '../../utils/dateHelper';
import './snnRulePage.scss';
import {IfuelPolicyEmailReq} from '../../interfaces/snn'

const SNNRulePage = () => {
  const { pathname: routePath } = useLocation();
  const dispatch = useAppDispatch();
  const { snnRules } = useAppSelector(selectSim);
  const [aircraftTypeList, setAirCraftTypeList] = useState<[{
    text: string,
    value: string
  }]>([{
    text: "",
    value: ""
  }]);
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const [hasChangeField, setHasChangeField] = useState<boolean>(false);

  const [snnForm, setSNNForm] = useState<ISNNRule>(initialNewSNNRule);
  const [searchCriteria, setSearchCriteria] = useState<ISNNSearch>(initialSNNSearch);
  const [filterCriteria, setFilterCriteria] = useState({
    airline: "",
    isInterval: "",
    isActive: "",
    type: "",
  });

  const [hasStartValue, setHasStartValue] = useState<boolean>(false);
  const [hasEndValue, setHasEndValue] = useState<boolean>(false);
  const [hasRangeValue, setHasRangeValue] = useState<boolean>(false);
  const [hasDaysValue, setHasDaysValue] = useState<boolean>(false);

  const { userId } = useAppSelector(selectUser);


  const activenessDropDown = [
    {
      text: "All",
      value: "All"
    },
    {
      text: "Active",
      value: "Active"
    },
    {
      text: "Inactive",
      value: "Inactive"
    }
  ]

  const timeRangeDropDownOptions = [
    {
      text: "BOTH",
      value: "BOTH"
    },
    {
      text: "DEP",
      value: "DEP"
    },
    {
      text: "ARR",
      value: "ARR"
    }
  ];

  const airlineOptions = [
    {
      text: "ALL",
      value: 'ALL'
    },
    {
      text: "CPA",
      value: "CPA"
    },
    {
      text: "AHK",
      value: "AHK"
    }
  ]

  const defaultAircraftTypeOptions = [
    {
      text: "ALL",
      value: "ALL"
    },
    {
      text: "A*",
      value: "A*"
    },
    {
      text: "B*",
      value: "B*"
    }
  ]

  const intervalEndDays = [
    {
      text: "+0 Days",
      value: "0"
    },
    {
      text: "+1 Days",
      value: "1"
    }
  ]

  const intervalDays = WEEKDAYS_MULTISELECT_VALUE;

  const handleSendAlert = () => {
    // const snnRequest: ISNNRuleRequest = _convertToRequest(_.cloneDeep(snnModel.body));
    const req: IfuelPolicyEmailReq = {
      galacxyID: userId,
      snnId: snnModel?.body?._id
    }
    sendAlert(req).then(([isSucceed,]) => {
      if (!isSucceed) {
        return;
      }
      dispatch(pushToast({ type: "success", message: `Email has been successfully sent` }));
      dispatchReducer({ type: "CLOSEALL", snnRule: snnModel.body });
      setHasValidationError(false);
      onClickSearch();
    }
    ).catch((err) => {
      console.log(err);
    });
  }

  const handleCancelPolicy = () => {
    // const snnRequest: ISNNRuleRequest = _convertToRequest(_.cloneDeep(snnForm));
    // if (!snnRequest.fuelPolicies) return
    // snnRequest.fuelPolicies.cancelPolicy = true;
    // snnRequest.endAt = new Date().toISOString();
    // snnRequest.version = snnRequest.version + 1;
    const req: IfuelPolicyEmailReq = {
      galacxyID: userId,
      snnId: snnForm?._id
    }
    sendCancelPolicy(req).then(([isSucceed,]) => {
      if (!isSucceed) {
        return;
      }
      const now = dateFormatter(new Date(), "DD MMM YYYY, HH:mm");
      dispatch(pushToast({ type: "success", message: `SNN is successfully cancelled at ${now}.` }));
      dispatchReducer({ type: "CLOSEALL", snnRule: snnModel.body });
      setHasValidationError(false);
      onClickSearch();
    }).catch((err) => {
      console.log(err);
    });
  }

  const confirmModalInfoMap = {
    ...CONFIRM_MODAL_INFO_MAP,
    action: {
      SEND: handleSendAlert,
      CANCEL: handleCancelPolicy
    }
  }

  const modalFlagMapping = {
    SEND: "isViewSNNRulesLightBoxVisible",
    CANCEL: "isAddNewSNNRulesLightBoxVisible"
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "NEW":
        return {
          ...state,
          body: initialNewSNNRule,
          isAddNewMode: true,
          isAddNewSNNRulesLightBoxVisible: true
        };
      case "EDIT":
        setSNNForm(action.snnRule);
        return {
          ...state,
          isAddNewMode: false,
          isAddNewSNNRulesLightBoxVisible: true
        };
      case "VIEW":
        return {
          ...state,
          body: action.snnRule,
          isViewSNNRulesLightBoxVisible: true
        };
      case "DELETE":
        return {
          ...state,
          body: action.snnRule,
          isDeleteSNNRulesLightBoxVisible: true
        };
      case "DUPLICATED":
        setSNNForm(action.snnRule);
        return {
          ...state,
          isAddNewMode: true,
          isDuplicated: true,
          isAddNewSNNRulesLightBoxVisible: true
        }
      case "CLOSE":
        setHasValidationError(false);
        return state.isViewSNNRulesLightBoxVisible === true ? {
          ...state,
          isAddNewMode: false,
          isDuplicated: false,
          isAddNewSNNRulesLightBoxVisible: false,
          isDeleteSNNRulesLightBoxVisible: false
        } : {
          ...state,
          body: initialNewSNNRule,
          isAddNewMode: false,
          isDuplicated: false,
        }
      case "CLOSEALL":
        setHasValidationError(false);
        return {
          ...state,
          body: initialNewSNNRule,
          isAddNewMode: false,
          isDuplicated: false,
          isAddNewSNNRulesLightBoxVisible: false,
          isViewSNNRulesLightBoxVisible: false,
          isDeleteSNNRulesLightBoxVisible: false,
          isCancelModalVisible: false
        }
      case "SHOW_CONFIRM_MODAL":
        return {
          ...state,
          isCancelModalVisible: true,
          [modalFlagMapping[action.confirmModalType]]: false,
          confirmModalType: action.confirmModalType
        }
      case "HIDE_CONFIRM_MODAL":
        return {
          ...state,
          isCancelModalVisible: false,
          [modalFlagMapping[state.confirmModalType]]: true,
          confirmModalType: ""
        }
      default:
        return state;
    }
  };

  const [snnModel, dispatchReducer] = useReducer(reducer, initialSNNReducer)

  const snnFormValidation = (value: string | string[]) => {
    if (hasValidationError) {
      if (typeof value == "string") {
        return value && value.length > 0;
      } else if (typeof value == "object") {
        return value && value.length > 0;
      }
    }

    return true;
  }

  //Modal
  const handleClose = (modalFlag: string) => {
    setHasChangeField(false);
    setHasStartValue(false);
    setHasEndValue(false);
    setHasRangeValue(false);
    setHasDaysValue(false);
    if (snnModel.isViewSNNRulesLightBoxVisible === true) {
      let tempState = snnModel;
      tempState[modalFlag] = false;
      dispatchReducer({ type: "CLOSE", snnRule: tempState });
    } else {
      dispatchReducer({ type: "CLOSEALL", snnRule: snnModel.body });
      snnModel[modalFlag] = false;
      setSNNForm(initialNewSNNRule);
    }
  };

  const closeModal = () => {
    setHasChangeField(false);
    setHasStartValue(false);
    setHasEndValue(false);
    setHasRangeValue(false);
    setHasDaysValue(false);
    dispatchReducer({ type: "CLOSEALL", snnRule: snnModel.body });
    setSNNForm(initialNewSNNRule);
  }

  const getAircraftDropdown = useCallback(() => {
    getAircraftTypeList().then((res) => {
      let dropdownOptionList = res.map(type => {
        return {
          text: type._id,
          value: type._id
        }
      })
      dropdownOptionList = defaultAircraftTypeOptions.concat(dropdownOptionList);
      setAirCraftTypeList(dropdownOptionList || []);
    }).catch((err) => {
      console.log(err);
    });

  }, []);

  useEffect(() => {
    onClickSearch();
    getAircraftDropdown();
    setHasStartValue(false);
    setHasEndValue(false);
    setHasRangeValue(false);
    setHasDaysValue(false);
    setHasValidationError(false);
  }, []);

  const onClickSearch = () => {
    let tempObj = {};
    for (let key in searchCriteria) {
      if (searchCriteria[key] != "") {
        let searchContent = searchCriteria[key];
        if (key !== 'snn') {
          searchContent = searchContent.toUpperCase();
        }
        tempObj[key] = searchContent;
      }
    }
    tempObj = {
      ...tempObj,
      ...generateFilterParams()
    }
    getSnnRules(tempObj).then((res) => {
      const filterList = res.snnRules.filter(snn => typeof snn.endAt != "string");
      const SNNList = res.snnRules.filter(snn => snn.airline !== 'HKE');
      dispatch(setSNNRules(Object.values(SNNList || {})));
    }).catch((err) => {
      console.log(err);
    });
  }

  const generateFilterParams = () => {
    let params = {};
    for (let key in filterCriteria) {
      if (filterCriteria[key].toUpperCase() !== 'ALL' && filterCriteria[key] !== "") {
        params[key] = filterCriteria[key];
      }
    }
    return params;
  }

  const generateSearchParams = () => {
    let params = {};
    for (let key in searchCriteria) {
      if (searchCriteria[key] != "") {
        let searchContent = searchCriteria[key];
        if (key !== 'snn') {
          searchContent = searchContent.toUpperCase();
        }
        params[key] = searchContent;
      }
    }
    return params;
  }

  const onChangeDropDown = (params: Record<string, string>) => {
    let tempObj = {
      ...filterCriteria,
      ...params,
    };
    setFilterCriteria({ ...tempObj });
    for (let key in tempObj) {
      if (tempObj[key].toUpperCase() === 'ALL' || tempObj[key] === "") {
        delete tempObj[key];
      }
    }

    tempObj = {
      ...generateSearchParams(),
      ...tempObj
    }
    getSnnRules(tempObj).then((res) => {
      const filterList = res.snnRules.filter(snn => typeof snn.endAt != "string");
      const SNNList = res.snnRules.filter(snn => snn.airline !== 'HKE');
      dispatch(setSNNRules(Object.values(SNNList || {})));
    }).catch((err) => {
      console.log(err);
    });
  }

  const onChangeSearchForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempObj = {
      ...searchCriteria
    }
    tempObj[event.target.name] = event.target.value;
    setSearchCriteria(tempObj);
  }

  const onChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    /* for example, this func is to update the object like below one. 
    {
      "airline": "ALL",
      "flightNo": "ALL",
      "aircraftType": "ALL",
      "registration": "ALL",
      "snn": "test20240730",
      "fuelPolicies": {
          "reason": "As per DMIOC",
          "remarks": "* SFP subject",
          "tafRemarks": "TAF Remarks\n\nA new line.",
          "issuedBy": "TEST",
      }
    }
    */
    const fieldIn = event.target.name; // field name in would be like 'airline' or 'fuelPolicies.reason'
    let valIn = event.target.value;
    const fieldsArr = fieldIn.includes('.') ? fieldIn.split('.') : []; // split the field name by '.' to get the nested object field
    let tempObj = _.cloneDeep(snnForm);
    switch (fieldsArr.length) {
      case 2:
        tempObj[fieldsArr[0]][fieldsArr[1]] = valIn;
        break;
      // without nested object field
      case 0:
      case 1:
      default:
        tempObj[fieldIn] = valIn;
        break;
    }
    setHasChangeField(true);
    setSNNForm(tempObj);
  }

  const onChangeEditField = (value: any, field: string, state: any, setState: Function) => {
    let tempObj = {
      ...state
    }
    if (field == "intervalStartTime" || field == "intervalEndTime") {
      value = moment.utc(value);
      tempObj[field] = value.toISOString();
    } else {
      tempObj[field] = value;
    }
    setHasChangeField(true);
    setHasStartValue(false);
    setHasEndValue(false);
    setHasRangeValue(false);
    setHasDaysValue(false);
    setHasValidationError(false);
    setState(tempObj);
  }

  const onChangeField = (value: any, field: string, state: any, setState: Function) => {
    let tempObj = {
      ...state
    }
    const dateTimeRelatedFields = ["startAt", "endAt", "intervalStartTime", "intervalEndTime"];
    if (dateTimeRelatedFields.includes(field)) {
      let utc_moment = moment.utc(value);
      tempObj[field] = utc_moment.toISOString();
    } else {
      tempObj[field] = value;
    }
    setState(tempObj);
  }

  const validationCheckSNNForm = () => {
    let isValid = true;

    for (const field in snnForm) {
      if (SNN_REQUIRED_FIELDS.includes(field) && !snnForm[field] && (snnForm[field] === "" || snnForm[field].length === 0)) {
        setHasValidationError(true);
        isValid = false;
      }
    }

    if (snnForm.type && snnForm.type === ActiveFlightPoliciesType.FUEL_POLICIES) {
      // to check if any value of fuel policies object is empty
      const fuelPolicies = snnForm.fuelPolicies;
      if (!fuelPolicies || !fuelPolicies.reason || !fuelPolicies.remarks || !fuelPolicies.tafRemarks || !fuelPolicies.issuedBy) {
        setHasValidationError(true);
        isValid = false;
      }
    }

    return isValid;
  }

  const onClickCreateOrUpdate = () => {
    if (snnForm.isInterval) {
      if (!snnForm.intervalStartTime) {
        setHasStartValue(true);
      }
      if (!snnForm.intervalEndTime) {
        setHasEndValue(true);
      }
      if (snnForm.intervalEndDay === '0') {
        setHasRangeValue(true);
      }
      if (snnForm.intervalDays.length === 0) {
        setHasDaysValue(true);
      }
    }

    // validation check
    if (!validationCheckSNNForm()) return;

    const isAddNewSNN = snnModel.isAddNewMode;
    // in edit mode, if data not change, do not call api (there is existing logic to handle hasChangeField flag)
    if (!isAddNewSNN && !hasChangeField) {
      console.log('data not change');
      return;
    }

    const snnRequest: ISNNRuleRequest = _convertToRequest(snnForm);
    snnRequest.createBy = userId;
    snnRequest.version = isAddNewSNN ? 1 : snnRequest.version + 1;
    // if add new snn, remove _id field
    if (isAddNewSNN && snnRequest['_id'] !== undefined) {
      delete snnRequest['_id'];
    }

    const apiCall = isAddNewSNN ? postCreateSnnRule(snnRequest) : postUpdateSnnRule(snnForm._id, snnRequest);
    const successMessage = isAddNewSNN ? "New SNN is successfully created" : "SNN is successfully updated";
    apiCall
      .then(([isSucceed,]) => {
        if (!isSucceed) {
          return;
        }
        const now = dateFormatter(new Date(), "DD MMM YYYY, HH:mm");
        dispatch(pushToast({ type: "success", message: `${successMessage} at ${now}.` }));
        dispatchReducer({ type: "CLOSEALL", snnRule: snnModel.body });
        setHasValidationError(false);
        setHasChangeField(false);
        onClickSearch();
      })
      .catch((err) => {
        console.log(err);
      });

    handleClose("isAddNewSNNRulesLightBoxVisible")
  }

  const _convertToRequest = (snnForm: ISNNRule): ISNNRuleRequest => {
    if (snnForm.type === ActiveFlightPoliciesType.SNN && snnForm.fuelPolicies) {
      delete snnForm.fuelPolicies;
    }

    // add field 'updateBy' and 'cancelPolicy' under field 'fuelPolicies' object when edit - save
    const isEditActiveFuelPolicies = !snnModel.isAddNewMode && snnForm.type === ActiveFlightPoliciesType.FUEL_POLICIES;
    const isConfirming = snnModel.confirmModalType !== '';
    if (isEditActiveFuelPolicies && snnForm.fuelPolicies && !isConfirming) {
      snnForm.fuelPolicies = {
        ...snnForm.fuelPolicies,
        updateBy: userId,
        cancelPolicy: false
      };
    }

    return {
      ...snnForm,
      dep: snnForm.dep.join(","),
      arr: snnForm.arr.join(","),
      altn: snnForm.altn && snnForm.altn.trim().toUpperCase(),
      era: snnForm.era && snnForm.era.trim().toUpperCase(),
      intervalDays: snnForm.intervalDays.map(day => day.value),
      waypoint: snnForm.waypoint && snnForm.waypoint.trim().toUpperCase(),
      countryICAOCode: snnForm.countryICAOCode && snnForm.countryICAOCode.trim().toUpperCase(),
    }
  }

  const formatIntervalDaysDisplay = (intervalDays: any[]) => {
    const displayWeekDays: any[] = [];
    intervalDays.forEach((day: any) => {
      if (day.selected) {
        displayWeekDays.push(day.text);
      }
    });
    const result = displayWeekDays.join(', ').toString();
    return !_.isEmpty(result) ? result : '-- ';
  }

  const confirmDelete = () => {
    //delete here
    deleteSnnRule(snnModel.body._id).then((res) => {
      dispatchReducer({ type: "CLOSEALL", snnRule: snnModel.body });
      onClickSearch();
    })
  }

  const RobotoMonoRgDataCell = ({ children }) => {
    return <CTableDataCell className={"RobotoMonoRg"} style={{ position: "relative" }} align='middle'>{children}</CTableDataCell>
  };

  /** Error placeholder */
  const ErrorPlaceholder = ({ errorMessage }) => {
    return (
      <div className="errorPlaceholder mt-3">
        {errorMessage}
      </div>
    )
  }

  const renderFootButtonInView = (buttonType: FootButtonsInSNNView) => {
    switch (buttonType) {
      case FootButtonsInSNNView.DUPLICATE:
        return (
          <Access route={routePath} access={[AccessType.UPDATE]}>
            <div className='save-button'><CXButton outline={true} text={"Duplicate"} onClick={() => dispatchReducer({ type: "DUPLICATED", snnRule: snnModel.body })} fontSize={"16px"} width={"100%"} /></div>
          </Access>
        );
      case FootButtonsInSNNView.DELETE:
        return (
          <Access route={routePath} access={[AccessType.DELETE]}>
            <div className='cancel-button'><CXButton outline={true} text={"Delete"} onClick={() => dispatchReducer({ type: "DELETE", snnRule: snnModel.body })} fontSize={"16px"} width={"100%"} /></div>
          </Access>
        );
      case FootButtonsInSNNView.EDIT:
        return (
          <Access route={routePath} access={[AccessType.UPDATE]}>
            <div className='save-button'><CXButton outline={snnModel.body.type === ActiveFlightPoliciesType.FUEL_POLICIES} text={"Edit"} onClick={() => dispatchReducer({ type: "EDIT", snnRule: snnModel.body })} fontSize={"16px"} width={"100%"} /></div>
          </Access>
        );
      case FootButtonsInSNNView.SEND_ALERT:
        return (
          <Access route={routePath} access={[AccessType.UPDATE]}>
            <div className='save-button'><CXButton text={"Send alert"} onClick={() => dispatchReducer({ type: "SHOW_CONFIRM_MODAL", confirmModalType: "SEND" })} fontSize={"16px"} width={"100%"} /></div>
          </Access>
        );
      default:
        return <></>;
    }
  };

  const renderFootButtonsInView = (type: ActiveFlightPoliciesType, isActive: boolean = true) => {
    let buttons: string[] = [];
    switch (type) {
      case ActiveFlightPoliciesType.FUEL_POLICIES:
        buttons = isActive ?
          FootButtonsInViewObject.activeFuelPolicies
          : FootButtonsInViewObject.inactiveFuelPolicies;
        break;
      case ActiveFlightPoliciesType.SNN:
      case undefined: // for old snn data
      default:
        buttons = FootButtonsInViewObject.snn;
        break;
    }

    const result = [0, 1].map((index) => {
      return (
        <CCol key={`renderFootButtonsInView${index}`}>
          <Modal.Footer className={index === 0 ? "modal-footer-start" : "modal-footer"}>
            {buttons && (index === 0 ? _.dropRight(buttons, 2) : _.drop(buttons, 2)).map((button, idx) => {
              return renderFootButtonInView(button as FootButtonsInSNNView);
            })}
          </Modal.Footer>
        </CCol>
      )
    });

    return result;
  };

  const getLastAlertSendContentInView = (alertArr: Array<string>) => {
    // return format like '21 Sep 2023 12:20pm by IMTCDE'
    let result = "--";
    if (alertArr && alertArr.length > 0) {
      // get last item from array
      const latestAlertObj = alertArr[alertArr.length - 1];
      const alertTime = latestAlertObj['alertAt'];
      const alertSend = latestAlertObj['send'];
      const formattedAlertTime = dateFormatter(alertTime, "DD MMM YYYY HH:mm");
      result = `${formattedAlertTime} by ${alertSend}`;
    }
    return result;
  };

  const handleConfirm = () => {
    confirmModalInfoMap.action[snnModel.confirmModalType]();
  }

  // for add/edit SNN modal - fuel policies supplement fields
  const fuelPoliciesFields = [
    {
      type: 'textarea',
      className: 'snnDes',
      maxRows: 21,
      value: snnForm.fuelPolicies?.reason || '',
      placeholder: 'Reason',
      field: 'fuelPolicies.reason',
      errMsg: 'Reason may not be blank.',
    },
    {
      type: 'textarea',
      className: 'snnDes mt-3',
      maxRows: 21,
      value: snnForm.fuelPolicies?.remarks || '',
      placeholder: 'Remarks',
      field: 'fuelPolicies.remarks',
      errMsg: 'Remarks may not be blank.',
    },
    {
      type: 'textarea',
      className: 'snnDes mt-3',
      maxRows: 21,
      value: snnForm.fuelPolicies?.tafRemarks || '',
      placeholder: 'TAF Remarks',
      field: 'fuelPolicies.tafRemarks',
      errMsg: 'TAF Remarks may not be blank.',
    },
    {
      type: 'input',
      className: 'snnDes mt-3',
      value: snnForm.fuelPolicies?.issuedBy || '',
      placeholder: 'Issued by',
      field: 'fuelPolicies.issuedBy',
      errMsg: 'Issued by may not be blank.',
      zIndex: 1031,
    },
  ];

  return (
    <>
      <div className={"section d-flex justify-content-between"} >
        <div className={"CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>SNN</div>
        <CRow className={"p-2 ms-auto"}>
          <CCol sm>
            <Access route={routePath} access={[AccessType.CREATE]} >
              <CXButton
                onClick={() => {
                  dispatchReducer({ type: "NEW", snnRule: initialNewSNNRule })
                }}
                text={"Add New SNN"}
                outline={true}
                fontSize={"14px"} />
            </Access>
          </CCol>
        </CRow>
      </div>

      <SNNSearchBar
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        filterCriteria={filterCriteria}
        setFilterCriteria={setFilterCriteria}
        onChangeSearchForm={onChangeSearchForm}
        onChangeField={onChangeField}
        onClickSearch={onClickSearch}
        onChangeDropDown={onChangeDropDown}
        airlineOptions={airlineOptions}
      />

      <div className={"section snnTable"}>
        <SNNRulesTable snnRules={snnRules || []} dispatchReducer={dispatchReducer} routePath={routePath} />
      </div>

      {/* --------- Add/Edit SNN Modal ---------*/}
      <Modal className="snnModal" show={snnModel.isAddNewSNNRulesLightBoxVisible} onHide={closeModal} size="xl">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>
            {snnModel.isAddNewMode ? snnModel.isDuplicated ? "Duplicated SNN" : "Add New SNN" : "Edit SNN"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'> Aircraft type and flight details</span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXDropDown
                    value={snnForm.airline}
                    placeholder={"Airline"}
                    onChange={setSNNForm}
                    options={airlineOptions}
                    onChangeField={onChangeEditField}
                    field="airline"
                    state={snnForm}
                    zIndex={1430} />
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    placeholder={"Flight Number"}
                    value={snnForm.flightNo}
                    onChangeField={onChangeModal}
                    field="flightNo"
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}
                    zIndex={1031} />
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={6}>
                  <CXDropDown
                    value={snnForm.aircraftType}
                    placeholder={"Aircraft type"}
                    onChange={setSNNForm}
                    options={aircraftTypeList}
                    onChangeField={onChangeEditField}
                    field="aircraftType"
                    state={snnForm}
                    zIndex={1330} />
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    placeholder={"Registration (Example:ABC)"}
                    value={snnForm.registration}
                    onChangeField={onChangeModal}
                    field="registration"
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}
                    zIndex={1029} />
                </CCol>
              </CRow>
              <TagsInput placeholder={"Origin (Use “enter” key for multiple input)"} tagList={snnForm.dep} onChangeField={onChangeEditField} field="dep" state={snnForm} setState={setSNNForm} additionalValidation={snnFormValidation} errMsg="Required"></TagsInput>
              <TagsInput placeholder={"Dest (Use “enter” key for multiple input)"} tagList={snnForm.arr} onChangeField={onChangeEditField} field="arr" state={snnForm} setState={setSNNForm} additionalValidation={snnFormValidation} errMsg="Required"></TagsInput>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Effective start and end dates (UTC) </span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXDatePicker
                    placeholder={"Effective start date"}
                    value={snnForm.startAt}
                    onChange={setSNNForm}
                    zIndex={1320}
                    onChangeField={onChangeEditField}
                    field="startAt"
                    timepicker
                    state={snnForm}
                    hasDefault={false}
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"} />
                </CCol>
                <CCol xs={6}>
                  <CXDatePicker
                    placeholder={"Effective end date"}
                    value={snnForm.endAt}
                    onChange={setSNNForm}
                    zIndex={1320}
                    onChangeField={onChangeEditField}
                    field="endAt"
                    timepicker
                    state={snnForm}
                    hasDefault={false}
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"} />
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={4}>
                  <CXDropDown
                    value={snnForm.timeRange}
                    placeholder={"Time range"}
                    onChange={setSNNForm}
                    options={timeRangeDropDownOptions}
                    onChangeField={onChangeEditField}
                    field="timeRange"
                    state={snnForm}
                    zIndex={1319} />
                </CCol>
              </CRow>
              <CRow>
                <div>
                  <ToggleSwitch name="flexCheckDefault" checked={snnForm.isInterval} onChangeField={onChangeEditField} field="isInterval" state={snnForm} setState={setSNNForm}></ToggleSwitch>
                  Enabled Interval
                </div>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={4}>
                  <CXTimePicker placeholder={"Start interval"} value={snnForm.intervalStartTime} onChange={setSNNForm} onChangeField={onChangeEditField} field="intervalStartTime" state={snnForm} zIndex={1229} disabled={!snnForm.isInterval} />
                  {hasStartValue && <span style={{ fontSize: "14px", margin: "0 0 0 0", color: "red" }}>All fields have to be filled </span>}
                </CCol>
                <CCol xs={4}>
                  <CXTimePicker placeholder={"End interval"} value={snnForm.intervalEndTime} onChange={setSNNForm} onChangeField={onChangeEditField} field="intervalEndTime" state={snnForm} zIndex={1229} disabled={!snnForm.isInterval} />
                  {hasEndValue && <span style={{ fontSize: "14px", margin: "0 0 0 0", color: "red" }}>All fields have to be filled </span>}
                </CCol>
                <CCol xs={4}>
                  <CXDropDown
                    value={snnForm.intervalEndDay}
                    placeholder={"Time range"}
                    onChange={setSNNForm}
                    options={intervalEndDays}
                    onChangeField={onChangeEditField}
                    field="intervalEndDay"
                    state={snnForm}
                    zIndex={1319}
                    disabled={!snnForm.isInterval} />
                  {hasRangeValue && <span style={{ fontSize: "14px", margin: "0 0 0 0", color: "red" }}>All fields have to be filled </span>}
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <MultiSelectDropdown
                  defaultOptions={intervalDays}
                  values={snnForm.intervalDays}
                  zIndex={1319}
                  isDisabled={!snnForm.isInterval}
                  onChangeField={onChangeEditField}
                  field="intervalDays"
                  state={snnForm}
                  onChange={setSNNForm} />
                {hasDaysValue && <span style={{ fontSize: "14px", margin: "0 0 0 0", color: "red" }}>All fields have to be filled </span>}
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Location information</span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    maxLength={4}
                    placeholder={"ALTN"}
                    value={snnForm.altn}
                    onChangeField={onChangeModal}
                    field="altn"
                    zIndex={1029} />
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    maxLength={4}
                    placeholder={"ERA"}
                    value={snnForm.era}
                    onChangeField={onChangeModal}
                    field="era"
                    zIndex={1029} />
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    maxLength={5}
                    placeholder={"Waypoint"}
                    value={snnForm.waypoint}
                    onChangeField={onChangeModal}
                    field="waypoint"
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}
                    zIndex={1029} />
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    maxLength={4}
                    placeholder={"Country code"}
                    value={snnForm.countryICAOCode}
                    onChangeField={onChangeModal}
                    field="countryICAOCode"
                    additionalValidation={snnFormValidation}
                    errMsg={"Required"}
                    zIndex={1029} />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>SNN</span>
            </CCol>
            <CCol className="snnDes" xs={6}>
              <CXFPConfigTextArea
                maxRows={21}
                value={snnForm.snn}
                placeholder={"SNN Description"}
                onChangeField={onChangeModal}
                field="snn"
                additionalValidation={snnFormValidation}
                errMsg={"Required"} />
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Active flight policies</span>
            </CCol>
            <CCol xs={6}>
              {
                ActiveFlightPoliciesRadio && ActiveFlightPoliciesRadio.map((radio, index) => {
                  return (
                    <CXRadioButton
                      key={index}
                      checked={radio.value === snnForm.type}
                      disabled={snnModel.isAddNewMode ? false : true}
                      {...radio}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let tempObj = {
                          ...snnForm
                        }
                        tempObj['type'] = e.target.defaultValue;
                        setHasChangeField(true);
                        setSNNForm(tempObj);
                      }}
                    />
                  );
                })
              }
              {
                hasValidationError && _.isEmpty(snnForm.type) && <ErrorPlaceholder errorMessage={'Active fuel policies may not be blank.'} />
              }
            </CCol>
          </CRow>
          {snnForm.type && snnForm.type === ActiveFlightPoliciesType.FUEL_POLICIES && (
            <>
              <hr className="solid"></hr>
              <CRow>
                <CCol xs={6}>
                  <span className='modal-section-title'>Fuel Policies Supplement</span>
                </CCol>
                <CCol xs={6}>
                  {fuelPoliciesFields.map((field, index) => (
                    <div className={field.className} key={`fuelPoliciesFields${index}`}>
                      {field.type === 'textarea' ? (
                        <CXFPConfigTextArea
                          maxRows={field.maxRows}
                          value={field.value}
                          placeholder={field.placeholder}
                          onChangeField={onChangeModal}
                          field={field.field}
                          additionalValidation={snnFormValidation}
                          errMsg={field.errMsg}
                        />
                      ) : (
                        <CXFPConfigTextInput
                          placeholder={field.placeholder}
                          value={field.value}
                          onChangeField={onChangeModal}
                          field={field.field}
                          additionalValidation={snnFormValidation}
                          errMsg={field.errMsg}
                          zIndex={field.zIndex}
                        />
                      )}
                    </div>
                  ))}
                </CCol>
              </CRow>
            </>
          )}
        </Modal.Body>
        <CRow>
          <CCol>
            {!snnModel.isAddNewMode
              && snnForm.type === ActiveFlightPoliciesType.FUEL_POLICIES
              && snnForm.isActive
              && (
                <Modal.Footer className="modal-footer-start">
                  <Access route={routePath} access={[AccessType.UPDATE]}>
                    <div className='cancel-button'><CXButton outline={true} text={"Cancel policy"}
                      onClick={() => { dispatchReducer({ type: "SHOW_CONFIRM_MODAL", confirmModalType: "CANCEL" }) }}
                      fontSize={"16px"} width={"100%"} /></div>
                  </Access>
                </Modal.Footer>
              )}
          </CCol>
          <CCol>
            <Modal.Footer className="modal-footer">
              <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => handleClose("isAddNewSNNRulesLightBoxVisible")} fontSize={"16px"} width={"100%"} /></div>
              <div className='save-button'><CXButton text={snnModel.isAddNewMode ? "Add" : "Save"} onClick={() => onClickCreateOrUpdate()} fontSize={"16px"} width={"100%"} /></div>
            </Modal.Footer>
          </CCol>
        </CRow>
      </Modal>

      {/* <NewSNNModal snnModel={snnModel} dispatchReducer={dispatchReducer} refreshSNNs={onClickSearch}/> */}
      {/* --------- View SNN Modal ---------*/}
      <Modal className="snnModal" show={snnModel.isViewSNNRulesLightBoxVisible} dialogClassName="modal-90w" onHide={closeModal} size="xl">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>View SNN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Aircraft type and flight details</span>
            </CCol>
            <CCol xs={6}>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="Airline" value={snnModel.body.airline}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="Flight Number" value={snnModel.body.flightNo}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="Airline" value={snnModel.body.aircraftType}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="Registeration" value={snnModel.body.registration}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <>
                  <div className="CathaySansLt" style={{ color: "#808285" }}>
                    {"Origin"}
                  </div>
                  <div className='tags-view-container'>
                    {snnModel.body.dep && snnModel.body.dep.length > 0 && snnModel.body.dep.map((dep, index) =>
                      <div className="tag-item-view" key={`Origin Tag${index}`}>
                        <span className="text">{dep}</span>
                      </div>)}
                  </div>
                </>
              </CRow>
              <CRow className='modalRow'>
                <>
                  <div className="CathaySansLt" style={{ color: "#808285" }}>
                    {"Destination"}
                  </div>
                  <div className='tags-view-container'>
                    {snnModel.body.arr && snnModel.body.arr.length > 0 ? snnModel.body.arr.map((arr, index) =>
                      <div className="tag-item-view" key={`Dest Tag${index}`}>
                        <span className="text">{arr}</span>
                      </div>)
                      :
                      <></>}
                  </div>
                </>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Effective start and end dates</span>
            </CCol>
            <CCol xs={6}>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="Effective start date" value={snnModel.body.startAt ? moment.utc(snnModel.body.startAt)?.format("DDMMMYYYY HH:mm") : "--"}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="Effective end date" value={snnModel.body.endAt ? moment.utc(snnModel.body.endAt)?.format("DDMMMYYYY HH:mm") : "--"}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <ViewLabel label="Time range" value={snnModel.body.timeRange}></ViewLabel>
              </CRow>
              <CRow className='modalRow'>
                <div>
                  <ToggleSwitch name="flexCheckDefault" checked={snnModel.body.isInterval} onChangeField={onChangeField} field="isInterval" state={snnForm} setState={setSNNForm} isDisabled={true}></ToggleSwitch>
                  Enabled Interval
                </div>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={4}>
                  <ViewLabel label="Start interval" value={snnModel.body.intervalStartTime ? moment.utc(snnModel.body.intervalStartTime)?.format("HH:mm") : "-- "}></ViewLabel>
                </CCol>
                <CCol xs={4}>
                  <ViewLabel label="End interval" value={snnModel.body.intervalStartTime ? moment.utc(snnModel.body.intervalEndTime)?.format("HH:mm") : "-- "}></ViewLabel>
                </CCol>
                <CCol xs={4}>
                  <ViewLabel label="End day" value={snnModel.body.isInterval && snnModel.body.intervalEndDay ? `+${snnModel.body.intervalEndDay} Day` : "-- "}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={10}>
                  <ViewLabel label="Interval Days" value={formatIntervalDaysDisplay(snnModel.body.intervalDays)}></ViewLabel>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Location information</span>
            </CCol>
            <CCol xs={6}>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="ALTN" value={snnModel.body.altn}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="ERA" value={snnModel.body.era}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="Waypoint" value={snnModel.body.waypoint}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="Country code" value={snnModel.body.countryICAOCode}></ViewLabel>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>SNN</span>
            </CCol>
            <CCol xs={6}>
              <ViewLabel label="SNN Description" value={snnModel.body.snn} valueFont='content-pre-wrap'></ViewLabel>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Uploaded By</span>
            </CCol>
            <CCol xs={6}>
              {snnModel.body.createBy || '--'}
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Active flight policies</span>
            </CCol>
            <CCol xs={6}>
              <span>{_.find(ActiveFlightPoliciesRadio, { value: snnModel.body.type })?.label || '--'}</span>
            </CCol>
          </CRow>
          {snnModel.body.type && snnModel.body.type === ActiveFlightPoliciesType.FUEL_POLICIES && (
            <>
              <hr className="solid"></hr>
              <CRow>
                <CCol xs={6}>
                  <span className='modal-section-title'>Fuel Policies Supplement</span>
                </CCol>
                <CCol xs={6}>
                  {FuelPoliciesSupplement.map((item, index) => {
                    return (
                      <div key={index} className="mt-3">
                        <ViewLabel label={item.label} value={snnModel.body?.fuelPolicies[item.field] || '--'} valueFont='content-pre-wrap'></ViewLabel>
                      </div>
                    )
                  })}
                </CCol>
              </CRow>
            </>
          )}
          {snnModel.body.type && snnModel.body.type === ActiveFlightPoliciesType.FUEL_POLICIES && snnModel.body.isActive && (
            <>
              <hr className="solid"></hr>
              <CRow>
                <CCol xs={6}>
                  <span className='modal-section-title'>Last alert sent</span>
                </CCol>
                <CCol className="snnDes" xs={6}>
                  {getLastAlertSendContentInView(snnModel.body?.fuelPolicies?.alert || [])}
                </CCol>
              </CRow>
            </>
          )}
        </Modal.Body>
        <Access route={routePath} access={[AccessType.CREATE, AccessType.DELETE, AccessType.UPDATE]} >
          <CRow>
            {renderFootButtonsInView(snnModel.body.type, snnModel.body.isActive)}
          </CRow>
        </Access>
      </Modal>

      {/* --------- Delete SNN Modal ---------*/}
      <Modal className="snnModal" show={snnModel.isDeleteSNNRulesLightBoxVisible} dialogClassName="modal-90w" onHide={closeModal} size="xl">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>Delete SNN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            Are you sure to delete this SNN?
          </CRow>
          <CTable className="snnTable" borderless hover>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  Airline
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  Flt no
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  A/C type
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  Reg
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  <div>Orig</div>
                  <div>Dest</div>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  <div>ALTN</div>
                  <div>ERA</div>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  <div>Eff Start Date (UTC)</div>
                  <div>Eff End Date (UTC)</div>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>
                  SNN
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {(!!snnModel.body && <CTableRow style={{ borderBottom: "1px solid #D8D8D8" }}>
                <RobotoMonoRgDataCell>{snnModel.body.airline || "--"} {snnModel.body.flightNo || "--"}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>{snnModel.body.aircraftType || "--"}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>{snnModel.body.registration || "--"}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>{Array.isArray(snnModel.body.dep) && snnModel.body.dep.map((ori) => ori)}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>{Array.isArray(snnModel.body.arr) && snnModel.body.arr.map((arr) => arr)}</RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>
                  <div>
                    {snnModel.body.altn || "--"}
                  </div>
                  <div>
                    {snnModel.body.era || "--"}
                  </div>
                </RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>
                  <div>
                    {moment.utc(snnModel.body.startAt)?.format("DDMMMYYYY HH:mm") || "--"}
                  </div>
                  <div>
                    {moment.utc(snnModel.body.endAt)?.format("DDMMMYYYY HH:mm") || "--"}
                  </div>
                </RobotoMonoRgDataCell>
                <RobotoMonoRgDataCell>{snnModel.body.snn || "--"}</RobotoMonoRgDataCell>
              </CTableRow>)}
            </CTableBody>
          </CTable>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => handleClose("isDeleteSNNRulesLightBoxVisible")} fontSize={"16px"} width={"100%"} /></div>
          <div className='save-button'><CXButton text={"Delete"} onClick={() => confirmDelete()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>

      {/* --------- Double Confirm Modal ---------*/}
      <Modal className="snnModal" show={snnModel.isCancelModalVisible} onHide={() => dispatchReducer({ type: 'HIDE_CONFIRM_MODAL' })} size="lg" contentClassName='snnCancelModal'>
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>{confirmModalInfoMap.title[snnModel.confirmModalType]}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: 'unset' }}>
          {confirmModalInfoMap.content[snnModel.confirmModalType]}
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className='cancel-button'><CXButton outline={true} text={"Back"} onClick={() => dispatchReducer({ type: 'HIDE_CONFIRM_MODAL' })} fontSize={"16px"} width={"100%"} /></div>
          <div className='save-button'><CXButton text={"Confirm"} onClick={() => handleConfirm()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SNNRulePage;
