import { HTTP_REQUEST_TYPE, SUPPORT_API } from "../constants/api";
import { sendHttpRequest } from "./api";

const getCfCcsData = async (search?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        SUPPORT_API.GET_SUPPORT_DATA_CFCCS,
        search ? search : {}
    );
    return resp;
};

interface cfCcsPayload {
    idArr: string[];
    ufiArr: string[];
    updateObj: {};
}

const updateCfCcsData = async (payload: cfCcsPayload) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        SUPPORT_API.POST_UPDATE_SUPPORT_DATA_CFCCS,
        {
            idArr: payload.idArr,
            ufiArr: payload.ufiArr,
            updateObj: payload.updateObj,
        }
    );
    return resp;
};

export {
    getCfCcsData, updateCfCcsData
};