const characterOnlyInput = (input) => {
  let characterOnlyChecker = new RegExp(/^[a-zA-Z]+$/);
  if(characterOnlyChecker.test(input)){
    return true;
  }else{
    return false;
  }
};

const numberOnlyInput = (input) => {
  let numberOnlyChecker = new RegExp(/^\d+$/);
  if(numberOnlyChecker.test(input)){
    return true;
  }else{
    return false;
  }
};

const numberOnlyInputOrNull = (input) => {
  let numberOnlyChecker = new RegExp(/^\d+$/);
  if(numberOnlyChecker.test(input) || input === ""){
    return true;
  }else{
    return false;
  }
};

const characterAndNumberOnlyInput = (input) => {
  let characterAndNumberOnlyChecker = new RegExp(/^[A-Z0-9]+$/);
  if(characterAndNumberOnlyChecker.test(input)){
    return true;
  }else{
    return false;
  }
};

const emailFormatterValidation = (email: string) => {
  if (email.length === 0) {
    return false;
  }
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(String(email).toLowerCase());
  return isValid;
};

export {
  characterOnlyInput,
  numberOnlyInput,
  numberOnlyInputOrNull,
  characterAndNumberOnlyInput,
  emailFormatterValidation
};