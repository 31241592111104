import * as React from 'react';
import { CTableRow, CTableDataCell } from '@coreui/react';
import { Link } from 'react-router-dom';
import { CLOSE_FLIGHT_PATHS, UO_PATHS } from '../../constants/path';
import { RightArrowIcon } from '../../assets/images';
import moment from 'moment';

const CloseFlightListTableItem = ({ ufi, std, reg, type, closeFlightPackageIdentifier, created, totalCount, index, user }) => {
  const ufiChecker = () => {
    if(user === "CX") {
      return CLOSE_FLIGHT_PATHS.closeFlightDetail.replace(":ufi", encodeURIComponent(ufi)).replace(":closeFlightPackageIdentifier", encodeURIComponent(closeFlightPackageIdentifier));
    } else if(user === "UO") {
      return UO_PATHS.closeFlightDetail.replace(":ufi", encodeURIComponent(ufi)).replace(":closeFlightPackageIdentifier", encodeURIComponent(closeFlightPackageIdentifier));
    }
  };

  const RobotoMonoRgDataCell = ({ children }) => {
    return <CTableDataCell className={"RobotoMonoRg"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "22px", color: "#000000" }} align="middle"><Link to={`${ufiChecker()}`}>{children}</Link></CTableDataCell>
  };

  const RobotoMonoRgUpperCaseDataCell = ({ children }) => {
    return <CTableDataCell className={"RobotoMonoRg"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "22px", color: "#000000", textTransform: "uppercase" }} align="middle"><Link to={`${ufiChecker()}`}>{children}</Link></CTableDataCell>
  };

  return <CTableRow className={`${((totalCount === (index + 1))) ? "" : "border-bottom"}`}>
    <RobotoMonoRgDataCell>{ufi || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell>{reg || "--"}</RobotoMonoRgDataCell>
    <CTableDataCell className={"CathaySansRg CathaySansRgAlignRobomono"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "22px", color: "#000000" }} align="middle"><Link to={`${ufiChecker()}`}>{type || "--"}</Link></CTableDataCell>
    <RobotoMonoRgUpperCaseDataCell>{moment.utc(std)?.format("DDMMMYYYY HH:mm:ss") || "--"}</RobotoMonoRgUpperCaseDataCell>
    <RobotoMonoRgUpperCaseDataCell>{moment.utc(created)?.format("DDMMMYYYY HH:mm:ss") || "--"}</RobotoMonoRgUpperCaseDataCell>
    <CTableDataCell style={{ position: "relative" }}>
      <RightArrowIcon style={{ position: "absolute", right: "0px", top: "25%", bottom: "25%" }} width={"24px"} height={"24px"} />
    </CTableDataCell>
  </CTableRow>
};

export default CloseFlightListTableItem;