import React, { useState } from 'react';

export const CXScrollBox = ({ children }) => {
  return (
    <div style={{ padding: "30px 0px 16px 24px" }} key={`loadsheet`}>
      <div className={"RobotoMonoRg"} style={{ overflow: "scroll", border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D", background: "#ffffff" }}>
        {children}
      </div>
    </div>
  )
};