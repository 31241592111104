import React, { useCallback, useState, useEffect } from 'react';
import { CRow, CCol } from '@coreui/react-pro';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectApp, setAppFlags } from '../../slice/appSlice';
import { selectSim, clearSimOFPForm, setSimOFPFormInEdit, setAdditionalInfoForm, addCrews, deleteCrew, setDutyCode } from '../../slice/simSlice';
import { CXLightBox, CXDropDown, CXDatePicker, CXTextInput, CXButton, CXTextArea } from '../CXForm';
import { selectAuth } from '../../slice/authSlice';
import { postNewSimPlanUpdate, getAircraftTypes } from '../../services/simAPI';
import SimTraineeInfoListItem from './SimTraineeInfoListItem';
import { characterOnlyInput, numberOnlyInput, numberOnlyInputOrNull } from '../../utils/formValidateHelper';

interface IAddOrEditOFPLightBox {
  postAction?: Function;
  isEdit?: boolean;
  heading: string;
  submitButtonText: string;
  TriggerButton: JSX.Element;
}

const rowStyle = {
  paddingTop: "24px", paddingBottom: "24px",
};

const AddOrEditOFPLightBox = ({ postAction, isEdit = false, heading, submitButtonText, TriggerButton }: IAddOrEditOFPLightBox) => {

  const dispatch = useAppDispatch();
  const { isAddNewSimOFPLightBoxVisible, isEditSimOFPLightBoxVisible } = useAppSelector(selectApp);
  const { simOFPFormInEdit, simFlightPlanTemplates } = useAppSelector(selectSim);
  const { userName } = useAppSelector(selectAuth);
  const [crewlistString, setCrewlistString] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [aircraftTypeOption, setAircraftTypeOption] = useState([]);
  const [selectTag, setSelectTag] = useState("");
  const [selectTemplateDropDownOptions, setSelectTemplateDropDownOptions] = useState<{
    text: string,
    value: number,
  }[]>([]);
  const [templateTagDropDownOptions, setTemplateTagDropDownOptions] = useState<{
    text: string,
    value: string,
  }[]>([]);
  const simFlightPlanTemplateList = Object.values(simFlightPlanTemplates)

  const notSelectedTemplate = !simOFPFormInEdit?.ufi;
  const readyToSubmit = !!simOFPFormInEdit?.std
    && !!simOFPFormInEdit?.aircraftType
    && !!simOFPFormInEdit?.aircraftReg
    && !!simOFPFormInEdit?.ufi
    && !!simOFPFormInEdit?.flightNum
    && numberOnlyInput(simOFPFormInEdit?.flightNum)
    && !!simOFPFormInEdit?.flightNumSuffix
    && characterOnlyInput(simOFPFormInEdit?.flightNumSuffix)
    && simOFPFormInEdit?.crewlist?.length !== 0
    && simFlightPlanTemplateList.length !== 0;

  useEffect(() => {
    const ufiOptionsArr = simFlightPlanTemplateList?.map((template, index) => {
      return { text: `${template.ufi}`, value: index };
    }) || [];
    setSelectTemplateDropDownOptions(ufiOptionsArr);
    const tagOptionsArr:{ text: string, value: string }[] = []
    if (simFlightPlanTemplateList) {
      simFlightPlanTemplateList.forEach(list => {
        if (list.tag && (tagOptionsArr.findIndex((item) => item.text === list.tag) === -1) ) tagOptionsArr.push({ text: list.tag, value: list.tag })
      });
      tagOptionsArr.push({text: "ALL", value: "ALL"});
    }
    setTemplateTagDropDownOptions(tagOptionsArr);
  }, [])

  const SimTraineeInfoListItemComponent = (crew, index) => {
    return <SimTraineeInfoListItem
      key={`SimTraineeInfoListItem${index}`}
      galacxyID={crew?.galacxyId || ""}
      dutyCode={crew?.dutyCode || ""}
      onChange={(dutyCode) => {
        dispatch(setDutyCode({ galacxyId: crew.galacxyId, dutyCode }));
      }}
      onClick={() => {
        dispatch(deleteCrew({ galacxyId: crew.galacxyId }));
      }}
    />
  };

  const onChangeTag = (tag) => {
    setSelectTag(tag);
    if (tag !== "ALL") {
      const listOptionsArr:{ text: string, value: number }[] = []
      if (simFlightPlanTemplateList) {
        simFlightPlanTemplateList.forEach((list, index) => {
          if (list.tag === tag) listOptionsArr.push({ text: `${list.ufi}`, value: index });
        });
      }
      setSelectTemplateDropDownOptions(listOptionsArr);
    } else {
      const listOptionsArr = simFlightPlanTemplateList?.map((template, index) => {
        return { text: `${template.ufi}`, value: index };
      }) || [];
      setSelectTemplateDropDownOptions(listOptionsArr);
    }
    dispatch(setSimOFPFormInEdit({
      aircraftType: "",
      aircraftOp: "",
      aircraftReg: "",
      ufi: "",
      status: "",
      flightNum: "",
      flightNumSuffix: "",
      createdBy: "",
      createTime: "",
      simCode: "",
      crewlist: [],
      templateName: '',
      additionalInfo: {
        extraFuel: 0,
        snn: "",
        loadSheet: [],
        unsavedLoadsheet: [],
        loadSheetTemplate: [],
        digitalRefueling: [],
      },
    }));
  }

  const getAircraftTypeOptions = useCallback(async () => {
    let response = await getAircraftTypes();

    if (!!response) {
      let optionDatas = response.aircraftTypes.map((aircraftType) => {
        return { value: aircraftType };
      });
      setAircraftTypeOption(optionDatas);
    } else {
      console.log(`Failed to load Aircraft Type Option Data`);
    }
  }, []);

  const loadTemplate = (templateIndex: number) => {
    let aircraftType = simFlightPlanTemplateList[templateIndex].aircraftType;
    let aircraftOp = simFlightPlanTemplateList[templateIndex].aircraftOp;
    let aircraftReg = simFlightPlanTemplateList[templateIndex].aircraftReg;
    let ufi = simFlightPlanTemplateList[templateIndex].ufi;
    let status = simFlightPlanTemplateList[templateIndex].status;
    let flightNum = simFlightPlanTemplateList[templateIndex].flightNum;
    flightNum = flightNum.padStart(3, "0");
    if (flightNum.length > 3) {
      flightNum = flightNum.substr(flightNum.length - 3);
    }
    let flightNumSuffix = simFlightPlanTemplateList[templateIndex].flightNumSuffix.toUpperCase();
    if (flightNumSuffix.length > 1) {
      flightNumSuffix = flightNumSuffix.substr(flightNumSuffix.length - 1);
    }
    let createdBy = simFlightPlanTemplateList[templateIndex].createdBy;
    let createTime = simFlightPlanTemplateList[templateIndex].createTime;
    let simCode = simFlightPlanTemplateList[templateIndex].simCode;
    let crewlist = simFlightPlanTemplateList[templateIndex].crewlist;
    let additionalInfo = simFlightPlanTemplateList[templateIndex].additionalInfo;
    dispatch(setSimOFPFormInEdit({
      aircraftType,
      aircraftOp,
      aircraftReg,
      ufi,
      status,
      flightNum,
      flightNumSuffix,
      createdBy,
      createTime,
      simCode,
      crewlist,
      additionalInfo,
    }));
  };

  const selectAircraftType = (option) => {
    let selectedOption = option.split("-");
    let aircraftReg = selectedOption[0];
    let aircraftType = selectedOption[1];
    dispatch(setSimOFPFormInEdit({ aircraftReg, aircraftType }));
  };

  const addSimTrainee = () => {
    let processedCrewlistString = crewlistString.replaceAll(/ +/g, "").toUpperCase();
    dispatch(addCrews({ crewlistString: processedCrewlistString }));
    setCrewlistString("");
  };

  const changeFlightNumber = (flightNum) => {
    let checkedFlightNum: string = flightNum;
    checkedFlightNum = checkedFlightNum.padStart(3, "0");
    if (flightNum.length > 3) {
      checkedFlightNum = flightNum.substr(flightNum.length - 3);
    }
    dispatch(setSimOFPFormInEdit({ flightNum: checkedFlightNum }));
  };

  const crewListDuplicateChecker = useCallback(() => {
    let processedCrewlist = crewlistString.replaceAll(/ +/g, "").toUpperCase().split(",");
    let currentCrewlist = simOFPFormInEdit.crewlist.map(crew => crew.galacxyId);
    let duplicated = processedCrewlist.filter(crew => currentCrewlist.includes(crew)).length !== 0;
    if(duplicated) {
      return true
    } 
    return false
  }, [crewlistString, simOFPFormInEdit.crewlist]);

  const changeFlightNumberSuffix = (flightNumSuffix) => {
    let checkedFlightNumSuffix: string = flightNumSuffix;
    if (flightNumSuffix.length > 1) {
      checkedFlightNumSuffix = flightNumSuffix.substr(flightNumSuffix.length - 1);
    }
    dispatch(setSimOFPFormInEdit({ flightNumSuffix: checkedFlightNumSuffix.toUpperCase() }))
  };

  const clearForm = () => {
    dispatch(clearSimOFPForm());
    setErrorMsg("");
  };

  const submitNewSimOFP = () => {
    // Clear duplicate ufi error message on submit 
    setErrorMsg("");
    let newSimOFPForm = {
      ...simOFPFormInEdit,
      createTime: moment().toISOString(),
      createdBy: userName,
    };
    postNewSimPlanUpdate(newSimOFPForm).then((res) => {
      if (res.status === 500) {
        throw res;
      }
      if (!!postAction) {
        postAction(res);
      }
    }).catch((err) => {
      if (err.status === 500 && err.detail === "Duplicate ufi") {
        setErrorMsg("This ufi is already existed in database. Please use other suffix.");
      } else {
        console.log(err);
      }
    });
  };

  return <CXLightBox
    visible={isEdit ? isEditSimOFPLightBoxVisible : isAddNewSimOFPLightBoxVisible}
    TriggerButton={TriggerButton}
    heading={heading}
    submitButtonText={submitButtonText}
    submitButtonDisabled={!readyToSubmit}
    setVisible={visible => {
      if (!isEdit) {
        clearForm();
      }
      dispatch(isEdit ? setAppFlags({ field: "isEditSimOFPLightBoxVisible", value: visible }) : setAppFlags({ field: "isAddNewSimOFPLightBoxVisible", value: visible }));
    }}
    submit={() =>
      submitNewSimOFP()
    }
    clear={() => {
      if (!isEdit) {
        clearForm()
      }
    }}
  >
    <div style={rowStyle}>
      <CRow>
        <CCol xs={6}></CCol>
        <CCol xs={3}>
          <CXDropDown
            zIndex={1060}
            value={selectTag}
            placeholder={"Tag"}
            onChange={(tag) => onChangeTag(tag)} 
            options={templateTagDropDownOptions} 
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={6}>
          <div className={"CathaySansMd"} style={{ fontSize: "18px", lineHeight: "22px" }}>Aircraft type and flight plan template</div>
        </CCol>
        <CCol xs={4}>
          <CXDropDown
            value={simOFPFormInEdit?.ufi || ""}
            placeholder={"Select template"}
            onChange={(index) => loadTemplate(index)} 
            options={selectTemplateDropDownOptions} 
          />
        </CCol>
        <CCol xs={2}>
          <CXDropDown
            value={(simOFPFormInEdit?.aircraftReg && simOFPFormInEdit.aircraftType) ? `${simOFPFormInEdit.aircraftReg}-${simOFPFormInEdit.aircraftType}` : ""}
            placeholder={"Aircraft type"}
            preAction={() => dispatch(setAppFlags({ field: "isBackgroundLoading", value: true }))}
            postAction={() => dispatch(setAppFlags({ field: "isBackgroundLoading", value: false }))}
            fetchData={getAircraftTypeOptions}
            onChange={(option) => selectAircraftType(option)}
            noOptionMsg={"Loading..."}
            options={aircraftTypeOption} 
          />
        </CCol>
      </CRow>
    </div>

    <hr className={"m-0"} />

    <div style={rowStyle}>
      <CRow>
        <CCol xs={6}>
          <div className={"CathaySansMd"} style={{ fontSize: "18px", lineHeight: "22px" }}>STD and Flight number</div>
        </CCol>
        <CCol xs={6}>
          <CRow>
            <CCol xs={6}>
              <CXDatePicker placeholder={"STD"} value={simOFPFormInEdit?.std} onChange={(date) => {
                dispatch(setSimOFPFormInEdit({ std: date }))
              }} timepicker={true} />
            </CCol>
            <CCol xs={4}>
              <CXTextInput
                disabled={notSelectedTemplate}
                value={simOFPFormInEdit?.flightNum || ""}
                additionalValidation={numberOnlyInput}
                errMsg={"Number Only"}
                placeholder={"Flight Number"}
                onChange={(flightNum) => changeFlightNumber(flightNum)} />
            </CCol>
            <CCol xs={2}>
              <CXTextInput
                disabled={notSelectedTemplate}
                value={simOFPFormInEdit?.flightNumSuffix || ""}
                additionalValidation={characterOnlyInput}
                errMsg={"Character Only"}
                placeholder={"Suffix"}
                onChange={(flightNumSuffix) => changeFlightNumberSuffix(flightNumSuffix)} />
            </CCol>
          </CRow>
          {!!errorMsg && <CRow>
            {/* Show ufi related error message */}
            <CCol><div className={'error-message'}>{errorMsg}</div></CCol>
          </CRow>}
        </CCol>
      </CRow>
    </div>

    <hr className={"m-0"} />

    <div style={rowStyle}>
      <CRow>
        <CCol xs={6}>
          <div className={"CathaySansMd"} style={{ fontSize: "18px", lineHeight: "22px" }}>Add GalaCXy ID of required trainee here</div>
        </CCol>
        <CCol xs={6}>
          <CRow>
            <CCol xs={12}>
              <div className={"cx-form-helper"}>Please input the GalaCXy ID here and separate each by a comma ‘,’.</div>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={9}>
              <CXTextInput 
                disabled={notSelectedTemplate} 
                value={crewlistString} 
                placeholder={"For example CRWABCD,CRWDEF,CRWGHI"} 
                onChange={setCrewlistString} 
                hasLabel={false} 
                additionalValidation={() => !crewListDuplicateChecker()}
                errMsg={"Could not add duplicated trainee"}
              />
            </CCol>
            <CCol xs={3}>
              <CXButton text={"Add"} width={"100%"} disabled={!crewlistString || crewListDuplicateChecker()} onClick={() => addSimTrainee()} />
            </CCol>
          </CRow>
          {/* -------------------------- 29/4 Trainee infos -------------------------- */}
          {!!simOFPFormInEdit?.crewlist?.length && <>
            <CRow>
              <CCol xs={9}>
                <div style={{ fontFamily: "CathaySans_Rg", fontStyle: "normal", fontWeight: 400, fontSize: "12px", lineHeight: "16px" }}>
                  Trainee
                </div>
              </CCol>
              <CCol xs={3}>
                <div style={{ fontFamily: "CathaySans_Rg", fontStyle: "normal", fontWeight: 400, fontSize: "12px", lineHeight: "16px" }}>
                  Duty code
                </div>
              </CCol>
            </CRow>

            {simOFPFormInEdit?.crewlist.map((crew, index) => {
              if (index > 0) {
                return <div key={`SimTraineeInfoListItemComponentBlock${index}`}>
                  <hr className={"m-0"} />
                  {SimTraineeInfoListItemComponent(crew, index)}
                </div>;
              }
              return <div key={`SimTraineeInfoListItemOnlyOneComponentBlock${index}`}>{SimTraineeInfoListItemComponent(crew, index)}</div>;
            })}

          </>}
          {/* -------------------------- 29/4 Trainee infos -------------------------- */}
        </CCol>
      </CRow>
    </div>

    <hr className={"m-0"} />

    <div style={rowStyle}>
      <CRow>
        <CCol xs={6}>
          <div className={"CathaySansMd"} style={{ fontSize: "18px", lineHeight: "22px" }}>Additional info</div>
        </CCol>
        <CCol xs={6}>
          <CRow >
            <CCol xs={4}>
              <CXTextInput disabled={notSelectedTemplate} value={simOFPFormInEdit?.simCode || ""} placeholder={"Sim code"} onChange={(simCode) => {
                dispatch(setSimOFPFormInEdit({ simCode }))
              }} />
            </CCol>
            <CCol xs={4}>
              <CXTextInput disabled={notSelectedTemplate} value={(!!simOFPFormInEdit?.additionalInfo?.extraFuel) ? simOFPFormInEdit?.additionalInfo?.extraFuel.toString() : ""} placeholder={"Extra fuel"} label={"Extra fuel (Optional)"} unit={"Kg"} onChange={(extraFuel) => {
                if(numberOnlyInputOrNull(extraFuel)){
                  dispatch(setAdditionalInfoForm({ extraFuel: parseInt(extraFuel) }))
                }
              }} />
            </CCol>
          </CRow>
          <div style={{ marginTop: "16px" }}>
            <CRow >
              <CCol xs={12}>
                <CXTextArea disabled={notSelectedTemplate} value={simOFPFormInEdit?.additionalInfo?.snn || ""} placeholder={"SNN"} onChange={(snn) => {
                  dispatch(setAdditionalInfoForm({ snn }))
                }} />
              </CCol>
            </CRow>
          </div>
        </CCol>
      </CRow>
    </div>

  </CXLightBox>



};

export default AddOrEditOFPLightBox;