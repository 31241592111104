import React from "react";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
} from "@coreui/react";
import { DownArrowIcon } from "../../../assets/images";
import CarReportTableItem from "./tableItemContainer";
import NoDataTableItem from "../../../components/AdminDashboard/NoDataTableItem";
import { ACTIONS } from "../../../constants/CarReportConstants";

interface ICarReportRulesTableProps {
  dispatchReducer: React.Dispatch<any>;
  carReportEmails: any;
  colNames: string[];
  colValues: any;
}

const CarReportTable = ({
  dispatchReducer,
  carReportEmails,
  colNames,
  colValues,
}: ICarReportRulesTableProps) => {
  const sortByLocale = (key: string) => {
    dispatchReducer({ type: ACTIONS.SORT, key: key });
  };

  return (
    <CTable className="appendixTable" borderless hover>
      <CTableHead>
        <CTableRow>
          {!!colNames &&
            colNames.length !== 0 &&
            Array.isArray(colNames) &&
            colNames.map((col_name, index) => (
              <CTableHeaderCell
                key={`tableContainer${index}`}
                scope="col"
                className={"CathaySansLt px-0 clickable appendixHeader"}
                onClick={() => {
                  sortByLocale(`${col_name}`);
                }}
              >
                {col_name}
                <DownArrowIcon height={12} width={12} />
              </CTableHeaderCell>
            ))}
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {!!carReportEmails &&
        carReportEmails.length !== 0 &&
        Array.isArray(carReportEmails) ? (
          carReportEmails.map((carReportEmail, index) => {
            return (
              <CarReportTableItem
                key={`CarReportTableItem_${index}`}
                dispatchReducer={dispatchReducer}
                carReportEmail={carReportEmail}
                colValues={colValues[index]}
              />
            );
          })
        ) : (
          <NoDataTableItem
            colSpan={10}
            message={"There is no car report email data."}
          />
        )}
      </CTableBody>
    </CTable>
  );
};

export default CarReportTable;
