import * as React from 'react';
import { CTableRow, CTableDataCell } from '@coreui/react';

interface PortListTableItemProps {
	index: number;
  port: {
		airline: 				string,
		icao: 					string,
		iata: 					string,
		name: 					string,
		cityName: 			string,
		countryName: 		string,
		version: 				number
	};
	onLinkPort: Function;
}

const PortListTableItem = ({ index, port, onLinkPort}: PortListTableItemProps) => {
  return <CTableRow onClick={() => onLinkPort(port.icao)} style={{cursor: "pointer"}}>
    <CTableDataCell className={"RobotoMonoRg"}>{port.airline || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{port.iata || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{port.icao || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{port.countryName || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{port.cityName || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{port.name || "-"}</CTableDataCell>
  </CTableRow>
};

export default PortListTableItem;