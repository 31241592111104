const stringAlphabeticSort = (a: string, b: string): number => {
  if (a && b) {
    return a.localeCompare(b);
  }
  return 0;
};

const sortAutoItems = (
  sortItems: any[],
  sortKey:  any,
  direction?: "asc" | "desc",
  compareFn?: (a: any, b: any) => number
) => {

  // This function takes in an array of items (sortItems) and a key (sortKey).
  const sortedValue = (sortItems, sortKey) => {
    const values = sortItems.map((item) => {
      const value = item[sortKey];
      return Array.isArray(value) ? (value[0] || "") : (value || "");
    }).flat();
    return values;
  }

  // It checks if the array is sorted in descending order.
  const isItemsDesc = (sortedValue) => {
    const isDesc = sortedValue.every(
      (value, i, arr) => (i === 0) || (arr[i - 1]?.localeCompare(value,undefined,{ numeric: true }) >= 0)
    );
    return isDesc
  }
  
  // This function sorts an array of items based on a given key and direction.
  const itemsToSort = (itemsToSort: any[], key:  any, direction: "asc" | "desc", compare?: (a: any, b: any) => number) => {
    try {
      return [...itemsToSort].sort((a, b) => {
        if (compare) {
          const result = compare(a, b);
          if (typeof result !== "number") {
            throw new Error("compareFn must return a number");
          }
          return direction === "asc" ? result : -result;
        } else {
          const aKey = Array.isArray(a[key]) ? (a[key]?.[0] || "") : (a[key] || "");
          const bKey = Array.isArray(b[key]) ? (b[key]?.[0] || "") : (b[key] || "");
          if (typeof aKey !== "string" || typeof bKey !== "string") {
            throw new Error("sortKey must be a string property of the objects", bKey);
          }
          const compareResult = aKey.localeCompare(bKey, undefined,{ numeric: true });
          return direction === "asc" ? compareResult : -compareResult;
        }
      });
    } catch (error) {
      console.log(error);
      return itemsToSort; // fallback to the original array if sorting fails
    }
  };

  if (!Array.isArray(sortItems) || sortItems.length === 0) {
    console.log("newItems must be an array");
    return { sortedList: sortItems, order: direction };
  }

  if (compareFn && typeof compareFn !== "function") {
    console.log("compareFn must be a function");
    return { sortedList: sortItems, order: direction };
  }

  const isDescending = isItemsDesc(sortedValue(sortItems, sortKey));
  const sortDirection = direction || (isDescending ? "asc" : "desc");
  const newSortedItems = itemsToSort(sortItems,sortKey,sortDirection,compareFn);
  return { sortedList: newSortedItems, order: sortDirection };
};

export { stringAlphabeticSort, sortAutoItems };
