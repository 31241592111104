import { DISPATCHER_PATHS, CLOSE_FLIGHT_PATHS, FLIGHT_PLAN_CONFIG_PATHS } from "../../constants/path";
import { routeConfig } from "../../interfaces/route";
import CloseFlightDetailPage from "./CloseFlightDetailPage";
import DispatcherPage from "./DispatchPage";
import FlightStatusPage from "./FlightStatusPage/FlightStatusPage";
import SearchCloseFlightPage from "./SearchCloseFlightPage";
import SNNRulePage from "./SNNRulePage";
import HKESNNRulePage from "./HKESNNRulePage";
import AppendixRulePage from "./AppendixRulePage";
import FlightDetailPage from "./FlightDetailPage";
import PortPageNotamPage from "./PortPageNotamPage";
import FeedbackPage from "./crewfeedback/FeedbackPage";
import AircraftInformationPage from "../flightPlanConfig/AircraftInformationPage";
import SharedNotamPage from '../shared/SharedNotamPage'
import SharedDefectsPage from '../shared/SharedDefectsPage'
// import SidStarPage from './SidStarPage'  // This import is not used, instead with the new one shared with uo sidstar
import SidStarPage from "../dataConfig/SidStarPage/SidStarPage";



const dispatchRouterConfig: routeConfig[] = [
    {
        path: DISPATCHER_PATHS.flightPlan,
        component: DispatcherPage,
        exact: true,
    },{
        path: DISPATCHER_PATHS.status,
        component: FlightStatusPage,
        exact: true,
    },{
        path: DISPATCHER_PATHS.flightDetail,
        component: FlightDetailPage,
    },{
        path: CLOSE_FLIGHT_PATHS.closeFlight,
        component: SearchCloseFlightPage,
    },{
        path: CLOSE_FLIGHT_PATHS.closeFlightDetail,
        component: CloseFlightDetailPage,
    },{
        path: FLIGHT_PLAN_CONFIG_PATHS.aircraft,
        component: AircraftInformationPage,
    },{
        path: FLIGHT_PLAN_CONFIG_PATHS.snn,
        component: SNNRulePage,
    },{
        path: FLIGHT_PLAN_CONFIG_PATHS.hkeSnn,
        component: HKESNNRulePage,
    },{
        path: FLIGHT_PLAN_CONFIG_PATHS.appendix,
        component: AppendixRulePage,
    }, {
        path: FLIGHT_PLAN_CONFIG_PATHS.portPageNotam,
        component: PortPageNotamPage,
    }, {
        path: FLIGHT_PLAN_CONFIG_PATHS.oeNotam,
        component: SharedNotamPage,
    }, {
        path: FLIGHT_PLAN_CONFIG_PATHS.nsuNotam,
        component: SharedNotamPage,
    }, {
        path: FLIGHT_PLAN_CONFIG_PATHS.defects,
        component: SharedDefectsPage,
    }, {
        path: FLIGHT_PLAN_CONFIG_PATHS.feedback,
        component: FeedbackPage,
    }, {
        path: FLIGHT_PLAN_CONFIG_PATHS.sidStar,
        component: SidStarPage,
    }
];

export default dispatchRouterConfig;
