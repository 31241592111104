import React from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from '@coreui/react';
import { DownArrowIcon } from '../../assets/images';
import SimOFPTableItem from './SimOFPTableItem';
import { ISimFlightPlan, setSimFlightPlans, setSimFlightPlanTemplates } from '../../slice/simSlice';
import { useAppDispatch } from '../../app/hooks';
import SimOFPTemplateTableItem from './SimOFPTemplateTableItem';
import { sortAutoItems } from '../../utils/sortHelper';

import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';

interface ISimOFPTableProps {
  simOFPs: ISimFlightPlan[];
  isSimTemplateConfiguration?: boolean;
  routePath: string;
}


const SimOFPTable = ({ simOFPs, isSimTemplateConfiguration = false, routePath }: ISimOFPTableProps) => {
  let sortedSimOFPs = [...simOFPs];
  const dispatch = useAppDispatch();
  const dispatchSortAction = (key: string) => {
    const { sortedList } = sortAutoItems(sortedSimOFPs, key);
    sortedSimOFPs = sortedList.map(item => ({ ...item }));
    if (isSimTemplateConfiguration) {
      dispatch(setSimFlightPlanTemplates(sortedSimOFPs));
    } else {
      dispatch(setSimFlightPlans(sortedSimOFPs));
    }
  };


  return <CTable borderless hover>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col" className={"clickable"} style={{ fontSize: "1rem" }} onClick={() => {
          dispatchSortAction("std");
        }}>
          STD
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"clickable"} style={{ fontSize: "1rem" }} onClick={() => {
          dispatchSortAction("aircraftType");
        }}>
          Aircraft
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"clickable"} style={{ fontSize: "1rem" }} onClick={() => {
          dispatchSortAction("aircraftReg");
        }}>
          Reg
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        {isSimTemplateConfiguration && <CTableHeaderCell style={{ fontSize: "1rem" }} scope="col">Tag</CTableHeaderCell>}
        <CTableHeaderCell scope="col" className={"clickable"} style={{ fontSize: "1rem" }} onClick={() => {
          dispatchSortAction("ufi");
        }}>
          SIM OFP UFI
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        {
          !isSimTemplateConfiguration && <CTableHeaderCell scope="col" style={{ fontSize: "1rem" }} className={"clickable"} onClick={() => {
            dispatchSortAction("status");
          }}>
            Status
            <DownArrowIcon height={12} width={12} />
          </CTableHeaderCell>
        }
        {/* Hide “FileName” column in table view for temporary */}
        {/* {
          isSimTemplateConfiguration && <CTableHeaderCell scope="col" style={{ fontSize: "1rem" }} className={"clickable"} >
            File Name
          </CTableHeaderCell>
        } */}
        <CTableHeaderCell style={{ fontSize: "1rem" }} scope="col">Created by</CTableHeaderCell>
        <Access route={routePath} access={[AccessType.DELETE]}>
          {isSimTemplateConfiguration && <CTableHeaderCell style={{ fontSize: "1rem" }} scope="col">Action</CTableHeaderCell>}
        </Access>
        <CTableHeaderCell style={{ fontSize: "1rem" }} scope="col"></CTableHeaderCell>
      </CTableRow>
    </CTableHead>
    <CTableBody>
      {(!!sortedSimOFPs && sortedSimOFPs.length !== 0) ?
        (isSimTemplateConfiguration ?
          sortedSimOFPs.map((simOFP, index) => <SimOFPTemplateTableItem routePath={routePath} key={`SimOFPTemplateTableItem${index}`} {...simOFP} />)
          :
          sortedSimOFPs.map((simOFP, index) => <SimOFPTableItem key={`SimOFPTableItem${index}`} {...simOFP} />)) :
        (<CTableRow>
          <CTableDataCell align={"middle"} colSpan={isSimTemplateConfiguration ? 7 : 6} className={"CathaySansRg text-center"} style={{ fontSize: "25px", height: "200px", fontWeight: 'bold' }}>There is no Sim {isSimTemplateConfiguration?"Template":"Flight plan"}.</CTableDataCell>
        </CTableRow>)}
    </CTableBody>
  </CTable>
};

export default SimOFPTable;
