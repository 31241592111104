import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CRow, CCol } from '@coreui/react-pro';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CXButton, CXTextArea, CXTextInput } from '../../components/CXForm';
import { clearSimOFPForm, selectSim } from '../../slice/simSlice';
import { SIM_PATHS } from '../../constants/path';

const PublishSimTemplatePage = () => {
  const dispatch = useAppDispatch();
  const params = useParams()
  const ufi: string = params.ufi;
  const { simFlightPlanTemplates } = useAppSelector(selectSim);
  const simTemplateDetail = Object.values(simFlightPlanTemplates)?.find(simFLightPlan => simFLightPlan.ufi === ufi);

  useEffect(() => {
    dispatch(clearSimOFPForm());
  }, [ufi, simFlightPlanTemplates, dispatch]);

  const publishSimTemplate = () => {
  };

  return (
    <>
      <div className={"breadcrumbs"}>
        <Link to={`${SIM_PATHS.simTemplateConfiguration}`} style={{ margin: "4px" }}> SIM Template list</Link> / <span style={{ margin: "4px" }}>Publish SIM Template</span>
      </div>

      <div className={"d-flex justify-content-between"} style={{ margin: "15px 0px" }}>
        <div className={"CathaySansBd"} style={{ fontWeight: 700, fontSize: "32px", lineHeight: "36px" }}>{ufi}</div>
      </div>

      <div className={"simOFPDetailBody"} style={{ margin: "17px 0px", padding: "16px" }}>
        <CRow>
          <CCol xs={2}>
            <div className={"simOFPDetailBodyHeader CathaySansRg"}>
              Aircraft type
            </div>
            <div className={"simOFPDetailBodyContent RobotoMonoBd"}>
              {simTemplateDetail?.aircraftType || "--"}
            </div>
          </CCol>
          <CCol xs={8}>
            <div className={"simOFPDetailBodyHeader CathaySansRg"}>
              Flight number
            </div>
            <div className={"simOFPDetailBodyContent RobotoMonoBd"}>
              {simTemplateDetail?.flightNum || "--"}
            </div>
          </CCol>
          <CCol xs={2} className={"text-end"}>
            {/* <CXButton disabled={false} outline={false} text={"Publish"} onClick={() => publishSimTemplate()} fontSize="14px" width={"180px"} /> */}
          </CCol>
        </CRow>
        <CRow>
          <CCol style={{ margin: "4px 0px" }}>
            <div>
              Created {!!simTemplateDetail?.createTime ? `${moment(simTemplateDetail?.createTime).format("DDMMMYYYY HH:mm")}Z` : "--"} by {simTemplateDetail?.createdBy || "--"}
            </div>
          </CCol>
        </CRow>
      </div>

      <div className={"simTemplateBlock"}>
        <CRow>
          <CCol xs={3}>
            <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "18px", lineHeight: "22px", color: "#4C4C4C" }}>
              Additional Info
            </div>
          </CCol>
          <CCol xs={9}>
            <div style={{ height: "48px", width: "144px" }}>
              <CXTextInput value={simTemplateDetail?.additionalInfo?.extraFuel.toString() || ""} placeholder={"Extra fuel (Optional)"} onChange={() => { }} unit={"T"} readOnly={true} />
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol xs={3}></CCol>
          <CCol xs={9}>
            <div style={{ marginTop: "24px", width: "464px" }}>
              <CXTextArea value={simTemplateDetail?.additionalInfo?.snn || ""} placeholder={"SNN (Optional)"} onChange={() => { }} readOnly={true} />
            </div>
          </CCol>
        </CRow>




        {simTemplateDetail?.additionalInfo?.loadSheetTemplate.map((template, index) => {
          let rowLength: number = template.raw.split("\n").length;
          return <div style={{ marginTop: "24px" }} key={`loadSheetTemplate${index}`}>
            <CRow >
              <CCol xs={3}>
                <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "18px", lineHeight: "22px", color: "#4C4C4C" }}>
                  {(!!template.version) ? `${template.type} - ${template.version}` : template.type}
                </div>
              </CCol>
              <CCol xs={9}>
                <div style={{ width: "356px", height: `${(19 * rowLength) + 24}px` }}>
                  <CXTextArea value={template.raw || ""} placeholder={""} maxRows={21} onChange={() => { }} readOnly={true} showAll={true} />
                </div>
              </CCol>
            </CRow>
          </div>
        })}
      </div>

    </>
  );
};

export default PublishSimTemplatePage;
