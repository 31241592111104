import React from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell, CCol, CRow } from '@coreui/react';
import { Link } from "react-router-dom";

import { DeleteIcon, RightArrowLgIcon } from '../../assets/images';
import { SIM_PATHS } from '../../constants/path';
import { getSimPlanTemplates, deleteSimTemplate, updateSimTemplate  } from '../../services/simAPI';
import { setSimFlightPlanTemplates } from '../../slice/simSlice';
import { useAppDispatch } from '../../app/hooks';
import { characterAndNumberOnlyInput } from '../../utils/formValidateHelper';
import CXFPConfigTextInput from '../CXForm/CXFPConfigTextInput';

import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';


interface ISimOFPTemplateTableItemProps {
  std:              string;
  aircraftType:     string;
  aircraftReg:      string;
  ufi:              string;
  tag:              string;
  flightNum:        string;
  flightNumSuffix:  string;
  createdBy:        string;
  createTime:       string;
  simCode:          string;
  templateName:     string;
  routePath:        string;
}

const SimOFPTemplateTableItem = ({ std, aircraftType, aircraftReg, ufi, tag, flightNum, flightNumSuffix, createdBy, createTime, simCode, templateName, routePath }: ISimOFPTemplateTableItemProps) => {
  const dispatch = useAppDispatch();
  const deleteTemplate = (ufi: string) => {
    if (window.confirm(`Are you sure to delete SIM Template(${ufi})?`)) {
      deleteSimTemplate(ufi).then((res) => {
        getSimPlanTemplates().then((res) => {
          dispatch(setSimFlightPlanTemplates(Object.values(res)));
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  const onBlurTag = (content: any, objectKey: string) => {
    updateSimTemplate(objectKey, {tag: content}).then((res) => {
      getSimPlanTemplates().then((res) => {
        dispatch(setSimFlightPlanTemplates(Object.values(res)));
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  const ufiChecker = () => {
    if (characterAndNumberOnlyInput(ufi)) {
      return SIM_PATHS.simTemplateDetail.replace(":ufi", ufi)
    } else {
      return encodeURIComponent(SIM_PATHS.simTemplateDetail.replace(":ufi", ufi))
    }
  };

  const RobotoMonoRgDataCell = ({ children }) => {
    return <CTableDataCell style={{ position: "relative", fontSize: "1rem" }} className={"RobotoMonoRg"} align='middle'><Link to={`${ufiChecker()}`}>{children}</Link></CTableDataCell>
  };

  const RobotoMonoRgUpperCaseDataCell = ({ children }) => {
    return <CTableDataCell style={{ textTransform: "uppercase", position: "relative", fontSize: "1rem" }} className={"RobotoMonoRg"} align='middle'><Link to={`${ufiChecker()}`}>{children}</Link></CTableDataCell>
  };

  return <CTableRow key={`${std}${ufi}`} style={{ borderBottom: "1px solid #D8D8D8" }}>
    <RobotoMonoRgUpperCaseDataCell>{moment.utc(std)?.format("DDMMMYYYY HH:mm") || "--"}</RobotoMonoRgUpperCaseDataCell>
    <RobotoMonoRgDataCell>{aircraftType || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell>{aircraftReg || "--"}</RobotoMonoRgDataCell>
    <CTableDataCell style={{width: "250px"}}>
      <CXFPConfigTextInput hasLabel={false} value={tag} dataOnBlur={{ onBlur: onBlurTag, objectKey: ufi }} />
    </CTableDataCell>
    <RobotoMonoRgDataCell>{ufi || "--"}</RobotoMonoRgDataCell>
    {/* Hide “FileName” column in table view for temporary */}
    {/* <RobotoMonoRgDataCell>{templateName || "--"}</RobotoMonoRgDataCell> */}
    <RobotoMonoRgDataCell><CRow>
      <CCol>{createdBy || "--"}</CCol>
    </CRow>
      <CRow>
        <CCol>{moment.utc(createTime)?.format("DDMMMYYYY HH:mm") || "--"}</CCol>
      </CRow>
    </RobotoMonoRgDataCell>
    <CTableDataCell align='middle'>
      <div className={"d-flex align-items-center p-0"}>
      <Access route={routePath} access={[AccessType.DELETE]}>
        <div className={"clickable"} style={{ marginLeft: "4px" }}>
          <DeleteIcon height={40} width={40} onClick={() => deleteTemplate(ufi)} />
        </div>
      </Access>
        </div>
    </CTableDataCell>
    <RobotoMonoRgDataCell>
      <RightArrowLgIcon style={{ position: "absolute", right: "8px", top: "15.5px", bottom: "15.5px" }} width={"31px"} height={"31px"} />
    </RobotoMonoRgDataCell>
  </CTableRow>
};

export default SimOFPTemplateTableItem;
