import COLORS from '../../constants/color';
import { CustomTableLayout } from 'pdfmake/interfaces';

const subHeaderLayout = {
    defaultBorder: false,
    paddingLeft: function (i, node) { return 4 },
    paddingRight: function (i, node) { return 4 },
    paddingTop: function (i, node) { return 0 },
    paddingBottom: function (i, node) { return 0 }
} as CustomTableLayout;

const containerLayout = {
    hLineWidth: function (i, node) {
        return (i === 0 || i === node.table.body.length) ? 2 : 1;
    },
    vLineWidth: function (i, node) {
        return (i === 0 || i === node.table?.widths?.length) ? 2 : 0;
    },
    hLineColor: function (i, node) {
        return 'black';
    },
    vLineColor: function (i, node) {
        return 'black';
    },
    paddingLeft: function (i, node) { return 0; },
    paddingTop: function (i, node) { return 0; },
    paddingRight: function (i, node) { return 0; },
} as CustomTableLayout;

const secTitleLayout = {
    defaultBorder: false,
    paddingLeft: function (i, node) { if (i === 0) { return 4 } return 0 },
    paddingRight: function (i, node) { if (i === 0) { return 4 } return 0 },
    paddingTop: function (i, node) { return 0 },
    paddingBottom: function (i, node) { return 0 },
    hLineWidth: function (i, node) { return 0; },
    vLineWidth: function (i, node) { return 0; },
} as CustomTableLayout;

const secContentLayout = {
    hLineWidth: function (i, node) {
        return 0.5;
    },
    vLineWidth: function (i, node) {
        return 0.5;
    },
    hLineColor: function (i, node) {
        return COLORS.black;
    },
    vLineColor: function (i, node) {
        return COLORS.black;
    },
    fillColor: function (rowIndex, node, columnIndex) { return rowIndex === 0 ? COLORS.gbGray : null; }
} as CustomTableLayout;

export { subHeaderLayout, containerLayout, secTitleLayout, secContentLayout };
