import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { CModal, CModalBody, CModalHeader, CModalTitle, CRow, CCol } from '@coreui/react';

import './style/EDGComparison.scss';
import { getEDGComparisonDetail, postDownloadEDGFile } from '../../../services/dispatchAPI';
import { timeFormatter } from '../../../utils/dateHelper';
import EDGComparisonSrcDetail from './EDGComparisonSrcDetail';
import { EDGComparisonObject, EDGComparisonSrcDetailProps, EDGDetail, EDGDownLoadAPIParams, edgComparisonInitialState } from './interfaces/IEDGComparison';

interface EDGComparisonProps {
  params: {
    ufi: string;
    serial: string;
  };
  onCloseView: () => void;
}

const EDGComparison = ({ params, onCloseView }: EDGComparisonProps) => {
  const { ufi, serial } = params;
  const [edgComparison, setEDGComparison] = useState<EDGComparisonObject>(edgComparisonInitialState);
  const [edgDataLoad, setEDGDataLoad] = useState(false);

  useEffect(() => {
    fetchEDGComparisonDetail(ufi, serial);
  }, [ufi, serial]);

  const fetchEDGComparisonDetail = (ufi: string, serial: string) => {
    getEDGComparisonDetail(ufi, serial).then(res => {
      if (res?.status === 500) {
        console.log('get EDG Comparison data failed, res=', res);
        setEDGDataLoad(false);
      } else {
        setEDGComparison(res);
        setEDGDataLoad(true);
      }
    }).catch(err => {
      console.log(err);
    });
  };

  const edgComparisonSourceDtlFormat = (edgSourceDtl: EDGDetail) => {
    const { edg_generated, generated_time, received_time, generate_duration, edg_file, edg_file_bucket } = edgSourceDtl;

    /*
        AC 1: Last Received Time and generate time shows as UTC
        AC 2: Time Duration
        If duration is less than 1 minute, show “<1 min”
        Duration show as minute, do not round up or round down. E.g. 12 mins 45 seconds, show 12 mins
    */
    const defaultChart = '--';
    const edg_generatedFormat = edg_generated ? "Yes" : "No";
    const generated_timeFormat = generated_time ? timeFormatter(generated_time) : defaultChart;
    const received_timeFormat = received_time ? timeFormatter(received_time) : defaultChart;
    let generate_durationFormat = defaultChart;
    if (generate_duration) {
      generate_durationFormat = _.toNumber(generate_duration) >= 1 ? `${_.toInteger(generate_duration)} mins` : '<1 min';
    }
    const edg_fileFormat = edg_file ? edg_file : [];

    return {
      edg_generated: _.isBoolean(edg_generated) ? edg_generatedFormat : defaultChart,
      generated_time: generated_timeFormat,
      received_time: received_timeFormat,
      generate_duration: generate_durationFormat,
      edg_file: edg_fileFormat,
      edg_file_bucket
    }
  }

  const renderEDGComparison = (edgComparison) => {
    const edgComparisonSrcAWS: EDGComparisonSrcDetailProps = {
      edg_title: 'Source 1: AWS',
      ...edgComparisonSourceDtlFormat(edgComparison.AWS),
      edg_file_status: !_.isEmpty(edgComparison.AWS) && !_.isEmpty(edgComparison.Azure) && edgComparison.AWS.edg_generated && edgComparison.Azure.edg_generated,
      onClickDownload
    };
    const edgComparisonSrcAzure: EDGComparisonSrcDetailProps = {
      edg_title: 'Source 2: Azure',
      ...edgComparisonSourceDtlFormat(edgComparison.Azure),
      onClickDownload
    };
    const edgComparisonSources = [edgComparisonSrcAWS, edgComparisonSrcAzure];
    const edgComparisonTitle = `View EDG of ${ufi}`;

    return (
      <CModal
        size="xl"
        backdrop="static"
        alignment="center"
        visible={true}
        onClose={onCloseView}
        className="edgModal"
      >
        <CModalHeader>
          <CModalTitle>{edgComparisonTitle}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            {edgComparisonSources.map((edgComparisonSrc, index) => {
              return (
                <CCol key={index}>
                  <EDGComparisonSrcDetail key={`edg-comparison-src-detail${index}`} {...edgComparisonSrc} />
                </CCol>
              );
            })
            }
          </CRow>
        </CModalBody>
      </CModal>
    )
  };

  const onClickDownload = useCallback(async (bucket: string, filepath: string, filename: string) => {
    if (!bucket || !filepath || !filename)
      return;
    
    const requestBody: EDGDownLoadAPIParams = {
      bucket,
      filepath,
      filename
    };
    postDownloadEDGFile(requestBody).then((res) => {
      const blobFile = new Blob([res], { type: res.type })
      const fileURL = URL.createObjectURL(blobFile);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = filename;
      alink.target = "_blank";
      alink.click();
    }).catch((err) => {
      console.log(err);
    });
  }, []);


  return (
    <>
      {
        edgDataLoad && renderEDGComparison(edgComparison)
      }
    </>
  );
};

export default EDGComparison;