import { HTTP_REQUEST_TYPE, SIM_API } from "../constants/api";
import { ISimFlightPlan } from "../slice/simSlice";
import { sendHttpRequest } from "./api";


const getSimPlans = async (date: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        SIM_API.GET_SIM_PLANS,
        {
            std: date
        }
    );
    return resp;
};

const getSimPlanTemplates = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        SIM_API.GET_SIM_PLAN_TEMPLATES,
        {}
    );
    return resp;
};

const getAircraftTypes = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        SIM_API.GET_SIM_AIRCRAFT_TYPES,
        {}
    );
    return resp;
};

const postNewSimPlanUpdate = async (simFlightPlan: ISimFlightPlan) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        SIM_API.POST_SIM_PLAN_UPDATE,
        {
            ...simFlightPlan
        }
    );
    return resp;
};

interface simDigitalRefuelingPayload {
    ufi: string;
    isSummary: boolean;
}

const postSimDigitalRefueling = async (payload: simDigitalRefuelingPayload) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        SIM_API.POST_SIM_DIGITAL_REFUELING,
        {
            ufi: payload.ufi,
            isSummary: payload.isSummary,
        }
    );
    return resp;
};

const postSimTemplate = async (bodyFormData: FormData) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        SIM_API.POST_SIM_TEMPLATES,
        bodyFormData,
        true,
        true
    );
    return resp;
};

const deleteSimTemplate = async (ufi: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.delete,
        SIM_API.DELETE_SIM_TEMPLATES,
        {
            ufi
        }
    );
    return resp;
};

const updateSimTemplate = async (updatedId, updatedObj) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        SIM_API.UPDATE_SIM_TEMPLATES,
        {
            updatedId: updatedId,
            updatedObj: updatedObj
        }
    );
    return resp;
};

export {
    getSimPlans,
    getSimPlanTemplates,
    getAircraftTypes,
    postNewSimPlanUpdate,
    postSimDigitalRefueling,
    postSimTemplate,
    deleteSimTemplate,
    updateSimTemplate
};