import React from 'react';
import CXFPConfigTextInput from '../../components/CXForm/CXFPConfigTextInput';
import CXDropDown from '../../components/CXForm/CXDropDown';
import CXDatePicker from "../../components/CXForm/CXDatePicker";

import { CCol} from '@coreui/react-pro';



const InputBoxGenerator = ({ inputType, gridSize, className="", inputBoxProps }) => {
  const generateInputBox = () => {
    switch (inputType) {
      case 'text':
        return <CXFPConfigTextInput {...inputBoxProps} />;
      case 'dropdown':
        return <CXDropDown {...inputBoxProps} />;
      case 'date':
        return <CXDatePicker {...inputBoxProps} />;
      default:
        return null;
    }
  };

  const colProps = {
    ...(gridSize !== undefined && gridSize !== 0 && { sm: gridSize }),
    ...(className !== undefined && className !== "" && { className: className })
  };
  
  return (
    <CCol {...colProps}>
      {generateInputBox()}
    </CCol>
  );
};

export default InputBoxGenerator;