import React from 'react';
import { CCol, CRow } from '@coreui/react';
import { BinIcon } from '../../../assets/images';
import CXDropDown from '../../CXForm/CXDropDown';
import { CXButton, CXTextArea, CXTextInput } from '../../CXForm';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { deleteUnsavedLoadsheet, ILoadsheet, selectSim, setUnsavedLoadsheet, setUnsavedLoadsheetRaw, setUnsavedLoadsheetZfw, updateSimFlightPlans } from '../../../slice/simSlice';
import { postNewSimPlanUpdate } from '../../../services/simAPI';
import { pushToast } from '../../../slice/appSlice';
import { numberOnlyInput } from '../../../utils/formValidateHelper';

interface ILoadsheetTemplateFragmentProps {
  index: number;
  loadsheet: ILoadsheet;
  locked?: boolean;
}

const LoadsheetTemplateFragment = ({ index, loadsheet, locked = false }: ILoadsheetTemplateFragmentProps) => {
  const dispatch = useAppDispatch();
  const { simOFPFormInEdit } = useAppSelector(selectSim);

  const distinctLoadsheetType = new Set(simOFPFormInEdit?.additionalInfo?.loadSheetTemplate.map((loadsheet) => { return loadsheet.type }));
  const loadsheetTypeDropDown = Array.from(distinctLoadsheetType).map(loadsheet => { return { value: loadsheet } });

  const distinctLoadsheetVersion = new Set(simOFPFormInEdit?.additionalInfo?.loadSheetTemplate.map((loadsheet) => { return loadsheet.version }).filter(version => version !== ""));
  const loadsheetVersionDropDown = Array.from(distinctLoadsheetVersion).map(loadsheet => { return { value: loadsheet } });

  const savedLoadsheetCount = (simOFPFormInEdit?.additionalInfo?.loadSheet?.length || 0) + 1;

  const loadLoadsheetTemplateByType = (type) => {
    let loadsheetTemplate;
    if (type === "LOADSHEET") {
      // 2 sub cases for handling LOADSHEET, load the first template
      loadsheetTemplate = simOFPFormInEdit?.additionalInfo?.loadSheetTemplate.find(loadsheet => loadsheet.type === type);
    } else {
      loadsheetTemplate = simOFPFormInEdit?.additionalInfo?.loadSheetTemplate.find(loadsheet => loadsheet.type === type);
    }
    dispatch(setUnsavedLoadsheet({ index, loadsheet: loadsheetTemplate }));
  };

  const loadLoadsheetTemplateByVersion = (version) => {
    let loadsheetTemplate = simOFPFormInEdit?.additionalInfo?.loadSheetTemplate.find(loadsheet => loadsheet.version === version);
    dispatch(setUnsavedLoadsheet({ index, loadsheet: loadsheetTemplate }));
  };

  const setLoadsheetRaw = (raw) => {
    dispatch(setUnsavedLoadsheetRaw({ index, raw }));
  };

  const setLoadsheetZfw = (zfw) => {
    dispatch(setUnsavedLoadsheetZfw({ index, zfw }));
  };

  const sendLoadsheet = () => {
    let originalLoadsheet = simOFPFormInEdit?.additionalInfo?.loadSheet || [];
    if (!!loadsheet) {
      let additionalInfo = {
        ...simOFPFormInEdit.additionalInfo,
        loadSheet: [
          ...originalLoadsheet,
          loadsheet
        ],
      };
      let currentSimOFP = {
        ...simOFPFormInEdit,
        additionalInfo: additionalInfo,
      };
      // console.log(currentSimOFP);
      postNewSimPlanUpdate(currentSimOFP).then((res) => {
        // console.log(res);
        dispatch(updateSimFlightPlans(res));
        dispatch(deleteUnsavedLoadsheet({ index }));
        dispatch(pushToast({type: "success", message: `Loadsheet sent.`}));
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  return <div key={`LoadsheetTemplateFragment${index}`}
    style={{
      height: "591px",
      width: "642px",
      background: "#F6F6F6",
      border: "1px solid #C6C2C1",
      borderRadius: "8px",
      marginTop: "20px",
      padding: "29px 20px 20px 20px",
      position: "relative"
    }}>
    <CRow>
      <CCol xs={5}>
        <div className={"CathaySansBd"} style={{ fontSize: "18px", lineHeight: "22px", color: "#4C4C4C" }}>
          Loadsheet {locked ? index + 1 : index + savedLoadsheetCount}
        </div>
      </CCol>
      <CCol xs={7}>
        <div className='d-flex justify-content-between'>
          {!locked && <div className={"CathaySansBd"} onClick={() => loadLoadsheetTemplateByType(loadsheet?.type)} style={{ height: "10px", width: "86px", color: "#116F9A", fontSize: "14px", lineHeight: "18px", cursor: "pointer" }}>
            Reset all edit
          </div>}
          {!locked && <BinIcon style={{ cursor: "pointer" }} onClick={() => dispatch(deleteUnsavedLoadsheet({ index: index }))} />}
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={5} style={{ marginTop: "16px" }}>
        <CXDropDown zIndex={1030} value={loadsheet?.type || ""} placeholder={"Loadsheet Type"} onChange={(type) => loadLoadsheetTemplateByType(type)} options={loadsheetTypeDropDown} readOnly={locked} />
        {!!loadsheet?.version && <CXDropDown zIndex={1029} value={loadsheet.version || ""} placeholder={"Loadsheet Version"} onChange={(version) => loadLoadsheetTemplateByVersion(version)} options={loadsheetVersionDropDown} readOnly={locked} />}
        <CXTextInput 
          zIndex={1020} 
          value={loadsheet?.zfw?.toString() || ""} 
          placeholder={"ZFW"} 
          onChange={(zfw) => setLoadsheetZfw(zfw)} 
          unit={"kg"} 
          readOnly={locked} 
          additionalValidation={numberOnlyInput}
          errMsg={"Please input a number"}
        />
      </CCol>
      <CCol xs={7} style={{ marginTop: "16px" }}>
        <CXTextArea maxRows={21} value={loadsheet?.raw || ""} placeholder={""} onChange={(raw) => setLoadsheetRaw(raw)} readOnly={locked} />
      </CCol>
    </CRow>
    <div style={{ position: "absolute", bottom: "20px", right: "20px" }}>
      <CXButton text={`Send ${loadsheet?.type || ""}`} onClick={() => sendLoadsheet()} fontSize={"14px"} outline={false} textColor={"#FFFFFF"} backgroundColor={"#116F9A"} borderRadius={"8px"} height={"48px"} disabled={locked || (!loadsheet?.zfw || !numberOnlyInput(loadsheet?.zfw))} />
    </div>
  </div>
};

export default LoadsheetTemplateFragment;
