import React from 'react';
import { loadAnimationGif } from '../assets/images';

const AppLoadingScreen = () => {
  return <div className={'mx-auto justify-content-center align-items-center'} style={{
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(76, 76, 76, 0.5)',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 3000,
  }}>
    <img style={{ borderRadius: '16px' }} height={"200px"} width={"200px"} src={loadAnimationGif} alt={"Loading Gif"} />
  </div>
};

export default AppLoadingScreen;