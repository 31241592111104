const needOneMoreInput = key => ['UTC'].includes(key.toUpperCase());

const displayPrefixValue = (key, value, prefixInputCopy = false) => {
    if (needOneMoreInput(key)) {
      const VALUE_DIVIDER = '__',
            OLD_VALUE_DIVIDER = ' ',
            ADOPTED_NEW_FORMAT = checkIfValueAdoptNewFormat(value, VALUE_DIVIDER),
            USE_VALUE_DIVIDER = ADOPTED_NEW_FORMAT? VALUE_DIVIDER: OLD_VALUE_DIVIDER;
      const utcInputs = value ? value.split(USE_VALUE_DIVIDER) : [];

      return prefixInputCopy
        ? (utcInputs.length > 1 ? utcInputs[1] : '')
        : (utcInputs.length > 0 ? utcInputs[0] : value);
    } else
      return value;

    function checkIfValueAdoptNewFormat($concatOriginalValue: string, $divider: string): boolean{
      if(!$concatOriginalValue) return false;
      return $concatOriginalValue.includes($divider);
    }
  };

export {
    needOneMoreInput,
    displayPrefixValue
};
