import { CMultiSelect } from '@coreui/react-pro';
import { useEffect, useState } from 'react'

interface IMultiSelectDropdown {
  defaultOptions: { value: number; text: string; }[];
  values: any[];
  zIndex: number;
  isDisabled?: boolean;
  onChangeField?: Function;
  field?: string;
  state?: any;
  onChange?: any;
}
const MultiSelectDropdown = ({defaultOptions, values = [], zIndex, isDisabled = false, onChangeField, field, state, onChange}:IMultiSelectDropdown) => {

  const [displayOptions, setDisplayOptions] = useState<any[]>(values);
  const [previousOptions, setPreviousOptions] = useState<any[]>([]);

    const onChangeValue = (itemList: any[]) => {
      if(onChangeField && (JSON.stringify(previousOptions) != JSON.stringify(itemList))){
        setPreviousOptions(itemList)
        onChangeField(itemList, field, state, onChange);
      }
    }

    return (
      <div className={isDisabled ? "multi-select-disabled" : "multi-select"} style={isDisabled ? {pointerEvents: "none"} : {}}>
        <CMultiSelect style={{zIndex: !!zIndex ? zIndex : 1039}} onChange={(items)=>onChangeValue(items)} options={values.length > 0 ? displayOptions : defaultOptions} selectionType="text"/>
      </div>
    )
}

export default MultiSelectDropdown;