// actionTypes.ts
const ACTIONS = {
  SHOW_ADD: "ACTION_SHOW_ADD",
  SHOW_EDIT: "ACTION_SHOW_EDIT",
  SHOW_DELETE: "ACTION_SHOW_DELETE",
  SORT: "ACTION_SORT",
  ADD_NEW: "ACTION_ADD_NEW",
  EDIT: "ACTION_EDIT",
  DROP: "ACTION_DROP",
  CLOSE: "ACTION_CLOSE",
};

const CXAirLineTypeOptions = [
  {
    text: "CPA",
    value: "CPA",
  },
  {
    text: "AHK",
    value: "AHK",
  },
];

const UOAirLineTypeOptions = [
  {
    text: "HKE",
    value: "HKE",
  }
];

const TypeOptions = [
  {
    text: "ALL",
    value: "ALL",
  },
  {
    text: "Sender",
    value: "Sender",
  },
  {
    text: "Default recipient",
    value: "Default recipient",
  },
];

const DropdownTypeList = [
  {
    text: "Sender",
    value: "Sender",
  },
  {
    text: "Default recipient",
    value: "Default recipient",
  },
];

export {
  ACTIONS,
  CXAirLineTypeOptions,
  UOAirLineTypeOptions,
  TypeOptions,
  DropdownTypeList,
};
