/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, Switch } from "react-router-dom";
import { routeConfig } from "../interfaces/route";
import NotFoundPage from '../pages/NotFoundPage'
import AccessForbiddenPage from '../pages/AccessForbiddenPage';
import { checkIfHasAccess, getPageTitle } from '../utils/roleAccessHelper';
import { selectAuth } from '../slice/authSlice';
import { useAppSelector } from '../app/hooks';

interface RouteSwitchProps {
    routes: routeConfig[];
}

const RouteWithSubRoutes = ({
    path,
    routes,
    component: Component,
    exact = true,
}: routeConfig) => {
    const { userGroupIDs } = useAppSelector(selectAuth);
    const hasAccess = checkIfHasAccess(path, userGroupIDs);
    const pageTitle = getPageTitle(path);

    // to be update to redirect to access denied page
    return (
        <>
            {hasAccess ?
                <Route
                    path={path}
                    render={(props) => <Component {...props} routes={routes} exact={exact} />}
                />
                :
                <Route component={() => <AccessForbiddenPage title={pageTitle}></AccessForbiddenPage>} />}
        </>
    );
};

const RouteSwitch = ({ routes }: RouteSwitchProps) => (
    <Switch>
        {routes.map((route) => (
            <RouteWithSubRoutes key={route.path} {...route} />
        ))}
        <Route component={NotFoundPage} />
    </Switch>
);

export default RouteSwitch;