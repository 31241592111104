enum TAG_FIELDS_KEY {
    TAG = "tag",
    PORT = "port",
    TAB = "tab",
    HIDDEN = "hidden"
}

enum OPERATION_TYPE {
    ADD_NEW = 'add',
    EDIT = 'edit',
    HIDE = 'hide',
    MODAL_CANCEL = 'cancel',
    MODAL_ADD_NEW = 'modalAdd',
    MODAL_EDIT_CONFIRM = 'modalEditConfirm'
}

const TAB_OPTIONS = [
    {
        value: "ALL",
        text: "ALL",
    },
    {
        value: "DEP",
        text: "DEP",
    },
    {
        value: "ARR",
        text: "ARR",
    }
]

//for MultiSelectDropdown
const TAB_OPTIONS_MUTIL_SEL_DD = [
    {
        value: 1,
        text: "DEP",
    },
    {
        value: 2,
        text: "ARR",
    }
]

const TAB_OPTIONS_MUTIL_SEL_DD_VALUE_MAPPING = [
    {
        value: 1,
        text: 'DEP',
        selected: true,
        order: 1
    },
    {
        value: 2,
        text: 'ARR',
        selected: true,
        order: 2
    }
]

const TAG_LIST_FIELDS = [
    {
        key: TAG_FIELDS_KEY.TAG,
        display: "Tags",
    },
    {
        key: TAG_FIELDS_KEY.PORT,
        display: "Port",
    },
    {
        key: TAG_FIELDS_KEY.TAB,
        display: "Tab",
    },
    {
        key: TAG_FIELDS_KEY.HIDDEN,
        display: "",
    },
]

const TAGS_LIST_MOCK_DATA = [
    {
        tag: "#important",
        port: ["ALL"],
        tab: ["ARR", "DEP"],
        hidden: false,

    },
    {
        tag: "#arrival",
        port: ["ALL"],
        tab: ["ARR"],
        hidden: true,

    }, {
        tag: "#test1",
        port: ["VHHH"],
        tab: ["ARR"],
        hidden: true,

    }, {
        tag: "#test2",
        port: ["VHHH", "KSDD"],
        tab: ["DEP"],
        hidden: true,

    }, {
        tag: "#test3",
        port: ["ALL"],
        tab: ["DEP"],
        hidden: true,

    }
]

const TAG_REQUIRED_FIELDS: string[] = [
    TAG_FIELDS_KEY.TAG,
    TAG_FIELDS_KEY.PORT,
    TAG_FIELDS_KEY.TAB,
    TAG_FIELDS_KEY.HIDDEN
]

const TAG_EXISTS_ALERT_MSG = 'Tag already exists. Please refer/edit existing tag.';

const HIDE_TAG_CONFIRM_MSG = 'Are you sure you want to disable/enable the tag?';

const NO_ACCESS_ALERT_MSG = '"You have no permission to access crew feedback tagging page.\nPlease contract admin to grant access.';

const TAG_MODAL_VALIDATION_MSG = 'Please fill in the blanks.';

export {
    TAB_OPTIONS,
    TAB_OPTIONS_MUTIL_SEL_DD,
    TAB_OPTIONS_MUTIL_SEL_DD_VALUE_MAPPING,
    TAGS_LIST_MOCK_DATA,
    TAG_LIST_FIELDS,
    TAG_REQUIRED_FIELDS,
    TAG_EXISTS_ALERT_MSG,
    HIDE_TAG_CONFIRM_MSG,
    NO_ACCESS_ALERT_MSG,
    TAG_MODAL_VALIDATION_MSG,
    OPERATION_TYPE,
    TAG_FIELDS_KEY
}