import React, { useEffect, useState } from 'react';
import { CXButton, CXTextInput } from '../../components/CXForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectSim, setAircrafts, IAircraftRequest } from '../../slice/simSlice';
import { CRow, CCol } from '@coreui/react-pro';
import { getAircrafts, postCreateAircraft } from '../../services/aircraftAPI';
import AddOrEditOFPLightBox from '../../components/Sim/AddOrEditOFPLightBox';
import { Modal } from 'react-bootstrap';
import AircraftTable from '../../components/AircraftInformation/AircraftTable';
import { useLocation } from "react-router-dom";
import { AccessType } from '../../constants/roleAccessConstant';
import Access from '../../components/Access';

const AircraftInformationPage = () => {
  const { pathname: routePath } = useLocation();
  const dispatch = useAppDispatch();
  const { aircrafts } = useAppSelector(selectSim);

  const [regSearch, setRegSearch] = useState<string>("");

  const [isModal, setIsModal] = useState<boolean>(false);

  const [airline, setAirline] = useState<string>("");
  const [aircraftICAO, setAircraftICAO] = useState<string>("");
  const [fleetType, setFleetType] = useState<string>("");
  const [registration, setRegistration] = useState<string>("");
  const [aircraftFullname, setAircraftFullname] = useState<string>("");

  const closeModal = () => {
    setIsModal(false)
  }

  useEffect(() => {
    onClickSearch();
  }, []);

  const AddNewSNNButton = () => {
    return <CXButton 
      onClick={() => {
        setIsModal(true);
      }} 
      text={"Add new aircraft"}
      outline={true} 
      fontSize={"14px"} />
  };

  const onClickSearch = () => {
      getAircrafts(regSearch).then((res) => {
        dispatch(setAircrafts(Object.values(res || {})));
      }).catch((err) => {
        console.log(err);
      });
  }


  const onClickCreate = () => {
      // create new snn
      const aircraftRequest: IAircraftRequest = {
        aircraftICAO: aircraftICAO,
        airline: airline,
        fleetType: fleetType,
        reg: registration,
        aircraftFullname: aircraftFullname
      }; 

      postCreateAircraft(aircraftRequest).then((res) => {
        onClickSearch();
      }).catch((err) => {
        console.log(err);
      });

      setIsModal(false);
  }


  return (
    <>
      <div className={"section d-flex justify-content-between"} >
        <div className={"CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>Aircraft Information</div>
          <CRow className={"p-2 ms-auto"}>
            <CCol sm>
              <Access route={routePath} access={[AccessType.CREATE]}>
                <AddOrEditOFPLightBox TriggerButton={AddNewSNNButton()} heading={"Add new aircraft"} submitButtonText={"Add new aircraft"}/>
              </Access>
            </CCol>
          </CRow>
      </div>

      <div className={"justify-content-between snnFilter"} >
              <CRow>
                <CCol sm="2">
                  <div className={"snn"}>
                    <CXTextInput placeholder={"Registration"} value={regSearch} onChange={setRegSearch} zIndex={1029} />
                  </div>
                </CCol>
                <CCol sm="1" className='filterCol'>
                  <div className={"filter"}>
                    <div><CXButton text={"Search"} onClick={()=>{onClickSearch()}} fontSize={"16px"} width={"100%"} /></div>
                  </div>
                </CCol>
              </CRow>
        </div>

      <div className={"section snnTable"}>
        <AircraftTable aircrafts={aircrafts || []} />
      </div>

      {/* --------- Add Aircraft ---------*/}
      <Modal className="aircraftModal" show={isModal} onHide={closeModal} size="xl">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>
            {"Add New Aircraft"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow> 
            <CCol xs={6}>
              Basic information
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXTextInput placeholder={"Airline"} value={airline} onChange={setAirline} zIndex={1029} />
                </CCol>
                <CCol xs={6}>
                  <CXTextInput placeholder={"Aircraft ICAO"} value={aircraftICAO} onChange={setAircraftICAO}  zIndex={1029} />
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={6}>
                  <CXTextInput placeholder={"Fleet Type"} value={fleetType} onChange={setFleetType} zIndex={1029} />
                </CCol>
                <CCol xs={6}>
                  <CXTextInput placeholder={"Registration"} value={registration} onChange={setRegistration} zIndex={1029} />
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={12}>
                  <CXTextInput placeholder={"Aircraft Full Name"} value={aircraftFullname} onChange={setAircraftFullname} zIndex={1029} />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Cancel"} onClick={()=> setIsModal(false)} fontSize={"16px"} width={"100%"} /></div>
          <div style={{ bottom: "40px", right: "40px", width: "200px" }}><CXButton text={"Add"} onClick={()=> onClickCreate()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AircraftInformationPage;
