import { SIM_PATHS } from "../../constants/path";
import { routeConfig } from "../../interfaces/route";
import PublishSimTemplatePage from "./PublishSimTemplatePage";
import ReleaseSimOFPPage from "./ReleaseSimOFPPage";
import SimLandingPage from "./SimLandingPage";
import SimTemplateConfigurationPage from "./SimTemplateConfigurationPage";


const simRouterConfig: routeConfig[] = [
    {
        path: SIM_PATHS.landing,
        component: SimLandingPage,
        exact: true,
    }, {
        path: SIM_PATHS.releaseSimOFP,
        component: ReleaseSimOFPPage,
    }, {
        path: SIM_PATHS.simTemplateConfiguration,
        component: SimTemplateConfigurationPage,
        exact: true
    }, {
        path: SIM_PATHS.simTemplateDetail,
        component: PublishSimTemplatePage,
    }
];

export default simRouterConfig;