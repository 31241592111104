import { useEffect, useReducer, useState } from "react";
import { CXButton, CXDropDown, CXTextInput } from "../../components/CXForm";
import { useAppSelector } from "../../app/hooks";
import { CRow, CCol } from "@coreui/react-pro";
import { selectAuth } from "../../slice/authSlice";
import { useLocation } from "react-router-dom";
import { DATA_CONFIGURATION_PATHS } from "../../constants/path";
import CarReportTable from "./sharedComponents/tableContainer";
import { CarEditItemModal } from "./sharedComponents/addEditModal";
import { CarDeleteModal } from "./sharedComponents/deleteModal";
import moment from "moment";
import "./sharedComponents/deleteModal.scss";
import "./sharedComponents/tableItemContainer.scss";
import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';


import {
  getCarReport,
  deleteCarReport,
  updateCarReport,
  createCarReport,
} from "../../services/carReportEmailsAPI";
import {
  ACTIONS,
  UOAirLineTypeOptions,
  CXAirLineTypeOptions,
  TypeOptions,
  DropdownTypeList,
} from "../../constants/CarReportConstants";

import {
  initialCarReportEmailRule,
  initialCarReportEmailReducer,
  ICarReportRule,
} from "../../interfaces/carReport";

import { emailFormatterValidation } from "../../utils/formValidateHelper";
import { sortAutoItems } from "../../utils/sortHelper";

const CarReportEmailPage = () => {
  const { userName } = useAppSelector(selectAuth);
  const { pathname } = useLocation();
  const isCXCarReport = pathname === DATA_CONFIGURATION_PATHS.carReportEmail;

  const AirLineTypeOptions = isCXCarReport
    ? CXAirLineTypeOptions
    : UOAirLineTypeOptions;

  const [carReportEmails, setCarReportEmails] = useState<ICarReportRule[]>([]);
  const [editCarReportEmail, setEditCarReportEmail] = useState<ICarReportRule>(
    initialCarReportEmailRule
  );
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const [openValidation, setOpenValidation] = useState<boolean>(false);

  //search content
  const [searchEmail, setSearchEmail] = useState<string>("");
  const [searchEmailType, setSearchEmailType] = useState<string>("");

  // onClick Search
  const searchReportByEmail = async () => {
    console.log(`Search Car report Email: ${searchEmail}, ${searchEmailType}`);
    await getCarReportEmail(searchEmail, searchEmailType);
  };

  const getCarReportEmail = async (email = searchEmail, type = searchEmailType) => {
    await getCarReport(isCXCarReport, email, type)
      .then(([isSucceed, msg, res]) => {
        // if (!isSucceed) {
        //   dispatch(pushToast({ type: "error", message: `${msg}` }));
        //   return;
        // }
        setCarReportEmails(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCarReportEmail = async (id: string) => {
    if (id === "") {
      return;
    }
    await deleteCarReport(id, isCXCarReport)
      .then(([isSucceed, msg]) => {
        // if (!isSucceed) {
        //   dispatch(pushToast({ type: "error", message: `${msg}` }));
        //   return;
        // }
        getCarReportEmail();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addNewEmail = async (item: any) => {
    if (!item?.Type || !item?.Email || !item?.Airline) {
      return;
    }
    item["galacxyId"] = userName;
    await createCarReport(item, isCXCarReport)
      .then(([isSucceed, msg]) => {
        // if (!isSucceed) {
        //   dispatch(pushToast({ type: "error", message: `${msg}` }));
        //   return;
        // }
        getCarReportEmail();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateEmail = async (item: any) => {
    item["galacxyId"] = userName;
    await updateCarReport(item, isCXCarReport)
      .then(([isSucceed, msg]) => {
        // if (!isSucceed) {
        //   dispatch(pushToast({ type: "error", message: `${msg}` }));
        //   return;
        // }
        getCarReportEmail();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sortByLocale = (key: string) => {
    const sortKey = key === "Last Updated" ? "updatedAt" : key;
    const { sortedList } = sortAutoItems([...carReportEmails], sortKey);
    setCarReportEmails(sortedList);
  };

  const carReportReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.SHOW_ADD:
        setEditCarReportEmail(initialCarReportEmailRule);
        return {
          ...state,
          isAddNewMode: true,
          isAddBoxVisible: true,
        };
      case ACTIONS.SHOW_EDIT:
        setEditCarReportEmail(action.emailItem);
        return {
          ...state,
          isAddNewMode: false,
          isAddBoxVisible: true,
        };
      case ACTIONS.SHOW_DELETE:
        setEditCarReportEmail(action.emailItem.carReportEmail);
        return {
          ...state,
          isDeleteBoxVisible: true,
        };
      case ACTIONS.SORT:
        sortByLocale(action.key);
        return state;
      case (ACTIONS.ADD_NEW, ACTIONS.EDIT, ACTIONS.DROP, ACTIONS.CLOSE):
        return initialCarReportEmailReducer;
      default:
        return initialCarReportEmailReducer;
    }
  };

  const [carReportState, dispatchReducer] = useReducer(
    carReportReducer,
    initialCarReportEmailReducer
  );

  const onClickAction = (type: string, item: any) => {
    if (type === ACTIONS.DROP) {
      deleteCarReportEmail(item);
    } else if (type === ACTIONS.ADD_NEW) {
      addNewEmail(item);
    } else if (type === ACTIONS.EDIT) {
      updateEmail(item);
    }
  };

  const emailValidation = (value: string | string[]) => {
    if (!openValidation || value.length === 0) {
      return true;
    }
    const isMailValid = emailFormatterValidation(editCarReportEmail?.Email);
    return isMailValid;
  };

  // check data validation
  const cheackDataValidation = () => {
    const isMailValid = emailFormatterValidation(editCarReportEmail?.Email);
    const isAirline = editCarReportEmail?.Airline?.length > 0;
    const isType = editCarReportEmail?.Type?.length > 0;
    const isValid = isAirline && isType && isMailValid;
    return isValid;
  };

  useEffect(() => {
    getCarReportEmail();
  }, []);

  useEffect(() => {
    setHasValidationError(!cheackDataValidation());
  }, [editCarReportEmail]);

  useEffect(() => {
    setOpenValidation(carReportState.isAddBoxVisible);
  }, [carReportState.isAddBoxVisible]);

  //<-----------------------------------Modal------------------------------------------->
  return (
    <>
      <div className={"section d-flex justify-content-between"}>
        <div
          className={"CathaySansBd"}
          style={{ fontSize: "32px", lineHeight: "36px" }}
        >
          CAR Report Emails
        </div>
        <Access route={pathname} access={[AccessType.CREATE]}>
          <CRow className={"p-2 ms-auto"}>
              <CXButton
                onClick={() => {
                  dispatchReducer({ type: ACTIONS.SHOW_ADD });
                }}
                text={"Add New email"}
                outline={true}
                fontSize={"14px"}
              />
          </CRow>
        </Access>
      </div>
      {
        <>
          <div className={"justify-content-between appendixFilter"}>
            <CRow>
              <CCol sm="auto">
                <div className={"airline"} style={{ width: 462 }}>
                  <CXTextInput
                    placeholder={"Search by email"}
                    value={searchEmail}
                    onChange={setSearchEmail}
                    zIndex={1029}
                  />
                </div>
              </CCol>
              <CCol sm="auto">
                <div className={"searchButton"}>
                  <div>
                    <CXButton
                      text={"Search"}
                      onClick={() => {
                        searchReportByEmail();
                      }}
                      fontSize={"16px"}
                      width={"100%"}
                    />
                  </div>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <div>
                <CCol sm={1} className="ps-0" style={{ minWidth: "190px" }}>
                  <CXDropDown
                    value={searchEmailType}
                    placeholder={"Type"}
                    onChange={setSearchEmailType}
                    options={TypeOptions}
                    zIndex={1000}
                  />
                </CCol>
              </div>
            </CRow>
          </div>

          <div className={"section"}>
            <CarReportTable
              dispatchReducer={dispatchReducer}
              carReportEmails={carReportEmails}
              colNames={["Airline", "Type", "Email", "Last Updated"]}
              colValues={carReportEmails.map(
                (carReportEmail,) => {
                  const colValues = [
                    { key: "airLine", value: carReportEmail?.Airline || "--" },
                    { key: "type", value: carReportEmail?.Type || "--" },
                    { key: "email", value: carReportEmail?.Email || "--" },
                    {
                      key: "updatedAt",
                      value:`${carReportEmail?.galacxyId || "--"}` +"\n" +`${moment.utc(carReportEmail?.updatedAt)?.format("DDMMMYYYY HH:mm") || "--"}`,
                    },
                  ];
                  return colValues;
                }
              )}
            />
          </div>
        </>
      }

      {/* Edit file */}
      <CarEditItemModal
        isShow={carReportState.isAddBoxVisible}
        isEdit={!carReportState.isAddNewMode}
        modalTitle={
          carReportState.isAddNewMode
            ? "Add new CAR report email"
            : "Edit CAR report email"
        }
        editingItem={editCarReportEmail}
        dispatchReducer={dispatchReducer}
        onClickAction={onClickAction}
        isEnable={!hasValidationError}
        textInputProps={[{
          value: editCarReportEmail?.Email || "",
          placeholder: "Email",
          onChange: setEditCarReportEmail,
          errMsg: "Please enter valid email addresses",
          validation: emailValidation,
          field: "Email",
          state: editCarReportEmail,
          itemWidth: "100%",
        
        }]}
        dropProps={[
          {
            value: editCarReportEmail?.Airline || "",
            placeholder: "Airline",
            onChange: setEditCarReportEmail,
            options: AirLineTypeOptions,
            field: "Airline",
            state: editCarReportEmail,
            itemWidth: "100%",
          },
          {
            value: editCarReportEmail?.Type || "",
            placeholder: "Type",
            onChange: setEditCarReportEmail,
            options: DropdownTypeList,
            field: "Type",
            state: editCarReportEmail,
            itemWidth: "100%",
          },
        ]}
      />

      {/* Delete */}
      <CarDeleteModal
        isShow={carReportState.isDeleteBoxVisible}
        itemId={editCarReportEmail._id}
        colNames={["Airline", "Type", "Email"]}
        colValues={[
          editCarReportEmail.Airline || "--",
          editCarReportEmail.Type || "--",
          editCarReportEmail?.Email || "--",
        ]}
        dispatchReducer={dispatchReducer}
        onClickAction={onClickAction}
      />
    </>
  );
};

export default CarReportEmailPage;
