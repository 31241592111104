const ROOT_PATHS = {
    landing: "/",
    notFound: "/404-not-found",
};

const DISPATCHER_PATHS = {
    flightPlan: "/dispatch/flightplan",
    status: "/dispatch/status",
    flightDetail: "/dispatch/flight-detail"
};

const UO_PATHS = {
    flightPlan: "/dispatch-uo/flightplan",
    snnRule: "/dispatch-uo/snn-rule",
    fuelAdv: "/dispatch-uo/fuel/advisory",
    defect: "/dispatch-uo/defect",
    crewlist: "/dispatch-uo/crewlist",
    nsuNotam: "/dispatch-uo/nsu-notam",
    oeNotam: "/dispatch-uo/oe-notam",
    appendixRule: "/dispatch-uo/appendix-rule",
    // flightDetail: "/dispatch-uo/flight-detail",
    closeFlight: "/dispatch-uo/close-flight/",
    closeFlightDetail: "/dispatch-uo/close-flight-detail/ufi=:ufi&closeFlightPackageIdentifier=:closeFlightPackageIdentifier",
};

const GUEST_PATHS = {
    root: "/testGuest",
};

const SIM_PATHS = {
    landing: "/sim-page",
    releaseSimOFP: "/release-sim-ofp/:ufi",
    simTemplateConfiguration: "/sim-template-configuration",
    simTemplateDetail: "/sim-template/:ufi",
}

const FLIGHT_PLAN_CONFIG_PATHS = {
    snn: "/snn",
    hkeSnn: "/hke-snn",
    appendix:'/appendix',
    airport: "/airport",
    aircraft:'/aircraft',
    defects: "/dispatch/defect",
    companyNotam:'/notam/company',
    oeNotam:"/dispatch/oe-notam",
    portPageNotam:'/notam/port',
    data: '/data',
    dataDetail: '/dataDetail',
    nsuNotam:'/dispatch/nsu-notam',
    feedback: '/deparr/feedback',
    sidStar: '/sidstar',
}

const CLOSE_FLIGHT_PATHS = {
    closeFlight: "/dispatch/close-flight/",
    closeFlightDetail: "/dispatch/close-flight-detail/ufi=:ufi&closeFlightPackageIdentifier=:closeFlightPackageIdentifier",
}

const DATA_CONFIGURATION_PATHS = {
    carReportEmail: '/car-report-email',
    uoCarReportEmail: '/uo-car-report-email',
    carReportTopic: '/car-report-topic',
    uoCarReportTopic: '/uo-car-report-topic',
    cxSidStar: "/cx-sidstar",
    uoSidStar: "/uo-sidstar",
}

const Internal_Support_PATHS = {
    dataCfCcs: "/support/cfccs"
}

const FLIGHT_POLICY_PATHS = {
    fuelPolicy: "/policy/fuel",
    noFlyZonesPolicy: "/policy/noFlyZones",
    tankeringPolicy: "/policy/tankering",
    volcanicAshesPolicy: "/policy/volcanicAsh",
}

export {
    ROOT_PATHS,
    DISPATCHER_PATHS,
    GUEST_PATHS,
    SIM_PATHS,
    UO_PATHS,
    FLIGHT_PLAN_CONFIG_PATHS,
    CLOSE_FLIGHT_PATHS,
    Internal_Support_PATHS,
    FLIGHT_POLICY_PATHS,
    DATA_CONFIGURATION_PATHS
};
