import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slice/authSlice";
import userReducer from "../slice/userSlice";
import appReducer from "../slice/appSlice";
import dispatcherReducer from "../slice/dispatcherSlice";
import simReducer from "../slice/simSlice";
import dataReducer from "../slice/dataSlice";
import portPageRunwaySlice from '../slice/portPageRunwaySlice'

const persistConfig = {
  key: "eff-client",
  version: 1,
  storage,
};

const reducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  dispatch: dispatcherReducer,
  sim: simReducer,
  data: dataReducer,
  portPageRunway: portPageRunwaySlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: (!!window?.config?.REACT_APP_NODE_ENV && window?.config?.REACT_APP_NODE_ENV !== "production") || process.env.REACT_APP_NODE_ENV !== "production" || false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;