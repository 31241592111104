import React, { useRef, useState } from "react";
import "./style/style.scss";

interface ICXTextInputProps {
  disabled?: boolean;
  zIndex?: number;
  onChange: Function;
  additionalValidation?: Function;
  errMsg?: string;
  placeholder?: string;
  value: string;
  unit?: string;
  label?: string;
  hasLabel?: boolean;
  readOnly?: boolean;
  onChangeField?: Function;
  field?: string;
  state?: any;
  maxLength?: number;
  onBlurFunction?: Function;
  portOnBlur?: {onBlur: Function, portSecItemIndex: number};
  dataOnBlur?: {onBlur: Function, objectKeyIndex: number};
  customStyle?: string;
  className?: string;
}

const CXTextInput = ({
  disabled = false,
  zIndex,
  onChange,
  additionalValidation = () => {},
  errMsg,
  placeholder,
  value,
  unit,
  label,
  hasLabel = true,
  readOnly = false,
  onChangeField, 
  field, 
  state, 
  maxLength=524288,
  onBlurFunction,
  portOnBlur,
  dataOnBlur,
  customStyle,
  className
}: ICXTextInputProps) => {
  const [focus, setFocus] = useState(false);
  const textInputRef = useRef<HTMLInputElement>(null);
  /*
    ----------------------------- Sample component ------------------------------
    const [text1, setText1] = useState("");
    <CXTextInput value={text1} placeholder={"Flight Number"} onChange={setText1} />
    ----------------------------- Sample component ------------------------------
  */

  const toggleFocus = () => {
    if (!disabled && !readOnly) {
      setFocus(!focus);
      if (textInputRef?.current) {
        textInputRef.current.focus();
      }
    }
  };

  const onChangeValue = (e) => {
    if(onChangeField){
      onChangeField(e, field, state, onChange)
    }else{
      onChange(e)
    }
  }

  const onFocus = () => {
    if (!disabled && !readOnly) {
      setFocus(true);
    }
  };

  const onBlur = (e) => {
    if (!disabled && !readOnly) {
      setFocus(false);
    }
    if(onBlurFunction){
      onBlurFunction();
    }

    if(portOnBlur){
      portOnBlur.onBlur(e, portOnBlur.portSecItemIndex)
    }

    if(dataOnBlur){
      dataOnBlur.onBlur(e, dataOnBlur.objectKeyIndex)
    }
  };

  const onValidate = () => {
    if (!!additionalValidation && !additionalValidation(value)) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`cx-textinput ${className ? className : ""}`}
      style={{
        zIndex: !!zIndex ? zIndex : 1030,
      }}
    >
      <div className={"cx-form-control"} style={{ overflow: "hidden", background: disabled? "#F2F2F3" : "transparent" }}>
        <input
          ref={textInputRef}
          className={`${
            hasLabel ? "cx-form-input" : "cx-form-input-without-label"
          } ${focus ? "focused" : ""} ${disabled ? "cx-disabled" : ""} ${customStyle ? customStyle : ""}`}
          value={value}
          onChange={(e) => onChangeValue(e.target.value)}
          onFocus={() => onFocus()}
          onBlur={(e) => onBlur(e.target.value)}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
        />
        {focus || !!value ? (
          <div>
            {hasLabel && (
              <div className={customStyle ? "" : "focused-label"} onClick={toggleFocus}>
                {label || placeholder}
              </div>
            )}
            <div className={"unit"} onClick={toggleFocus}>
              {unit || ""}
            </div>
          </div>
        ) : (
          <div
            className={`text-ellipsis ${
              disabled ? "not-focused-label-disabled" : "not-focused-label"
            }`}
            onClick={toggleFocus}
          >
            {placeholder}
          </div>
        )}
      </div>
      {onValidate() && !!errMsg && (
        <div className={"cx-error-message"}>{errMsg}</div>
      )}
    </div>
  );
};

export default CXTextInput;
