import React, { useEffect, useState } from 'react';
import { CathayPacificLogoIcon } from '../assets/images';
import { CXButton, CXToast } from '../components/CXForm';
import { loginRequest, msalInstance } from '../config/authConfig';

localStorage.setItem('redirectReason', 'logout');

/**
 * Application home page component
 * @returns {JSX}
 */
const LoginRedirectPage = () => {

  const redirectReason = localStorage.getItem('redirectReason');
  const isRootPath = window.location.pathname === '/';

  return <div
    className='loginRedirectPage'
    style={{
      position: 'fixed',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      backgroundColor: 'white',
    }}>
    <div
      className='mx-auto d-flex justify-content-center align-items-center flex-column'
      style={{
        position: "relative",
        marginTop: "10%",
        height: "500px",
        width: "500px",
        backgroundColor: "white",
        borderRadius: '16px'
      }}>
      <div style={{ marginTop: "20px" }}>
        <CathayPacificLogoIcon width={"50px"} height={"61.4px"} />
      </div>
      <div style={{ marginTop: "25px" }}>
        <div className={"CathaySansRg"} style={{ fontSize: "30px", lineHeight: "21.76px", color: "#4C4C4C" }}>Flight Folder Console</div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <CXButton
          text={"Sign In"}
          onClick={() => {
            msalInstance.loginRedirect(loginRequest);
          }}
          fontSize={"20px"}
          outline={false}
          textColor={"#FFFFFF"}
          backgroundColor={"#116F9A"}
          borderRadius={"8px"}
          height={"48px"}
        />
      </div>

    </div>
    {(redirectReason === 'logout' && !isRootPath  ) && <CXToast autoHide={true} className='errorToast' type='error' message='Access unauthorized. Please contact administrator.'></CXToast>}
  </div>;
};

export default LoginRedirectPage;