import { CCol } from '@coreui/react-pro';
import React from 'react';
import CXTextInput from '../CXForm/CXTextInput';
import { DeleteIcon } from '../../assets/images';
import _ from 'lodash';

interface ISpecialStyle {
  col: string;
  styleName: string;
}

interface ISectionContentItemProps {
  isEditMode: boolean;
  fixedValueColumns: Array<string>,
  rowItem: object,
  rowIndex: number,
  changeValue: Function,
  removeItem?: Function,
  specialStyles?: Array<ISpecialStyle>,
  itemsLength: number;
}

const SectionContentItem = ({ isEditMode, fixedValueColumns, rowItem, rowIndex, changeValue, removeItem, specialStyles, itemsLength }: ISectionContentItemProps) => {
  const renderItem = () => {
    const isLastItem = itemsLength === 1;
    var colsInOneRow = Object.keys(rowItem).map(colName => {
      const specStyleObj = specialStyles && _.find(specialStyles, function (obj) { return obj.col === colName });
      const specStyle = specStyleObj && specStyleObj?.col ? specStyleObj?.styleName : '';

      return <CCol key={`ItemCol${colName}${rowIndex}`} className={`align-center ${specStyle}`}>
        {isEditMode ? (
          fixedValueColumns.includes(colName) ?
            rowItem[colName] :
            <CXTextInput
              value={rowItem[colName]}
              onChange={valueChanged => {
                changeValue(valueChanged, rowIndex, colName)
              }}
              hasLabel={false}
              customStyle={!!rowItem[colName] ? 'cx-form-input-with-value' : ''}
            />
        )
          :
          rowItem[colName]
        }
      </CCol>
    });

    return (
      <>
        {colsInOneRow}
        {isEditMode && removeItem && <CCol>
          <div className={"d-flex p-0 mt-2"}>
            <div className={"clickable"} style={{marginLeft: "4px"}}>
              {!isLastItem && <DeleteIcon height={40} width={40} onClick={() => removeItem(rowIndex)}/>}
            </div>
          </div>
        </CCol>}
      </>
    );
  }

  return <>
    {renderItem()}
  </>
};

export default SectionContentItem;
