import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CXButton, CXTextInput } from '../../components/CXForm';
import SimOFPTable from '../../components/Sim/SimOFPTable';
import { pushToast } from '../../slice/appSlice';
import { selectSim, setSimFlightPlanTemplates } from '../../slice/simSlice';
import { getSimPlanTemplates, postSimTemplate } from '../../services/simAPI';
import { selectAuth } from '../../slice/authSlice';
import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';
import { useLocation } from 'react-router-dom';


enum ResponseType {
  OK="ok",
  REIMPORT="reimport"
}

const SimTemplateConfigurationPage = () => {
  const { pathname: routePath } = useLocation();
  const dispatch = useAppDispatch();
  const [searchString, setSearchString] = useState("");
  const { simFlightPlanTemplates } = useAppSelector(selectSim);
  const { userName } = useAppSelector(selectAuth);
  const importNewTemplateRef = useRef<HTMLInputElement>(null);

  const getTemplates = useCallback(() => {
    getSimPlanTemplates().then((res) => {
      dispatch(setSimFlightPlanTemplates(Object.values(res)));
    }).catch((err) => {
      console.log(err);
    });
  }, [dispatch]);

  useEffect(() => {
    if (searchString === "") {
      getTemplates();
    }
  }, [searchString, getTemplates])

  const triggerFileExplorer = () => {
    if (importNewTemplateRef?.current) {
      importNewTemplateRef.current.click();
    }
  };

  const selectFile = (e) => {
    if (!!e.target.files?.[0] && userName) {
      let bodyFormData = new FormData();
      bodyFormData.append("eff-file", e.target.files?.[0]);
      bodyFormData.append("created-by", userName);
      postSimTemplate(bodyFormData).then(res => {
        if (res.status === 500) {
          throw res;
        }
        if (res.status === ResponseType.REIMPORT) {
          dispatch(pushToast({ type: "success", message: "The SIM template re-imported" }));
        } else if (res.status === ResponseType.OK) {
          dispatch(pushToast({ type: "success", message: "New SIM template added" }));
        }

        getTemplates();
      }).catch(err => {
        if (err.status === 500) {
          dispatch(pushToast({ type: "error", message: "Import SIM template failed" }));
        } else {
          console.log(err);
        }
      })
    }

    // fix: avoid select File not firing
    e.target.value = "";
  };

  const search = () => {
    if (searchString !== "") {
      dispatch(setSimFlightPlanTemplates((simFlightPlanTemplates).filter(template => template.ufi.indexOf(searchString.toUpperCase()) !== -1)));
    }
  };

  return (
    <>
      <div className={"CathaySansBd"} style={{ fontSize: "2rem", lineHeight: "2.25rem" }}>Sim Template Configuration</div>
      <div className={"section d-flex justify-content-between"} >
        <div className={"p-2 ps-0 d-flex"}>
          <div style={{ width: "360px", marginRight: "16px" }}>
            <CXTextInput value={searchString} placeholder={"Search by template number"} onChange={setSearchString} />
          </div>
          <CXButton outline={false} text={"Search"} onClick={() => search()} />
        </div>
        <div className={"p-2"}>
          <Access route={routePath} access={[AccessType.CREATE]}>
            <CXButton outline={true} text={"Import New Template"} onClick={() => triggerFileExplorer()} />
            <input ref={importNewTemplateRef} onChange={selectFile} type="file" accept={".eff"} hidden multiple></input>
          </Access>
        </div>
      </div>
      <div className={"section"}>
        <SimOFPTable simOFPs={simFlightPlanTemplates || []} isSimTemplateConfiguration={true} routePath={routePath}/>
      </div>
    </>
  );
};

export default SimTemplateConfigurationPage;
