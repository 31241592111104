import { useRef, useState } from 'react'

interface ViewLabelProps {
    label: string;
    value: string;
    headerFont?: string;
    valueFont?: string;
    valueStyle?: object;
}
const ViewLabel = ({label, value, headerFont="CathaySansLt", valueFont="view-label-value", valueStyle={color:"#2D2D2D"}}:ViewLabelProps) => {
    
    return (
        <>
            <div className={headerFont} style={{color:"#808285"}}>
                {label || "--"}
            </div>
            <div className={valueFont} style={valueStyle}>
                {value || "--"}
            </div>
        </>
    )
}

export default ViewLabel;