import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { SEC_TITLES } from '../constants/portPageRunwayConstant';
import _ from 'lodash';

interface ILvoApproachObject {
    type: string;
    subType: string;
    info: Array<any>;
}

interface IApproachObject {
    type: string;
    info: Array<any>;
}

interface ITakeOffLightsObject {
    type: string;
    info: Array<any>;
}

interface ITakeOffPerformanceObject {
    type: string;
    info: Array<any>;
}

interface ITakeOffObject {
    lights: Array<ITakeOffLightsObject>;
    performance: Array<ITakeOffPerformanceObject>;
}

interface IPortPageRunway {
    _id?: string;
    uuid?: string;
    airline: string;
    icao: string;
    airportName: string;
    runway: string;
    notes: string;
    lvoApproach: Array<ILvoApproachObject>;
    lvoApproachRemark: string;
    approach: Array<IApproachObject>;
    approachRemark: string;
    takeoff: ITakeOffObject;
    takeoffRemark: string;
    engineFailure: string;
    version?: number;
    created?: string;
    __v?: number;
    userName?: string;
}

interface IPortPageRunwaySave {
    airline: string;
    icao: string;
    airportName: string;
    runway: string;
    notes: string;
    lvoApproach: Array<ILvoApproachObject>;
    lvoApproachRemark: string;
    approach: Array<IApproachObject>;
    approachRemark: string;
    takeoff: ITakeOffObject;
    takeoffRemark: string;
    engineFailure: string;
}

interface PortPageRunwaySlice {
    originalRunways: Array<IPortPageRunway>;
    tempRunways: Array<IPortPageRunway>;
    tempRunwayNames: Array<string>;
}

interface IPortPageRunwaySecItemsPayload {
    tabIndex: number;
    secTitle: string;
    rowIndex: number;
    columnName: string;
    value: string;
}

const initialState: PortPageRunwaySlice = {
    tempRunways: [],
    tempRunwayNames: [],
    originalRunways: []
}

const portPageRunwaySlice = createSlice({
    name: "portPageRunway",
    initialState,
    reducers: {
        loadRunways: (state, { payload }) => {
            state.originalRunways = payload || initialState.originalRunways;
            state.tempRunways = payload || initialState.tempRunways;
            let runwayNames: string[] = [];
            _.forEach(payload, (runwayObj: IPortPageRunway) => {
              runwayNames.push(runwayObj.runway);
            })
            state.tempRunwayNames = runwayNames;
        },
        addNewRunway: (state, { payload: { cleanObj } }) => {
            state.tempRunways.push(cleanObj);
            state.tempRunwayNames.push(cleanObj.runway);
        },
        duplicateRunway: (state, { payload: { tabIndex } }) => {
            let newRunway = { ...state.tempRunways[tabIndex] };
            newRunway.runway = state.tempRunways[tabIndex].runway + '(2)';
            delete newRunway._id;
            delete newRunway.created;
            delete newRunway.version;
            delete newRunway.__v;
            delete newRunway.userName;
            delete newRunway.uuid;

            state.tempRunways.push(newRunway);
            state.tempRunwayNames.push(newRunway.runway);
        },
        resetRunways: (state, { payload: { tabIndex } }) => {
          if (tabIndex >= state.originalRunways.length) {
            //is would be new/duplicate runway
            state.tempRunways.splice(tabIndex, 1);
            state.tempRunwayNames.splice(tabIndex, 1);
          } else {
            state.tempRunways[tabIndex] = state.originalRunways[tabIndex];
          }
        },
        updateSecItems: (state, { payload }: PayloadAction<IPortPageRunwaySecItemsPayload>) => {
            const { tabIndex, secTitle, rowIndex, columnName, value } = payload || {};
            let editItemInfo;
            let infoItemKey;
            switch (secTitle) {
                case SEC_TITLES.lvoApproach:
                    editItemInfo = state.tempRunways[tabIndex].lvoApproach[rowIndex].info;
                    infoItemKey = 'desc';
                    break;
                case SEC_TITLES.approach:
                    const approachObj = state.tempRunways[tabIndex].approach[rowIndex];
                    editItemInfo = approachObj.info;
                    infoItemKey = 'aircraftType';

                    if (columnName === 'type')
                        approachObj.type = value;
                    break;
                case SEC_TITLES.takeOff_Lights:
                    const lightsObj = state.tempRunways[tabIndex].takeoff.lights[rowIndex];
                    editItemInfo = lightsObj.info;
                    infoItemKey = 'desc';

                    if (columnName === 'type')
                        lightsObj.type = value;
                    break;
                case SEC_TITLES.takeOff_Performance:
                    editItemInfo = state.tempRunways[tabIndex].takeoff.performance[rowIndex].info;
                    infoItemKey = 'subType';
                    break;
                default:
                    break;
            }

            const targetItem = editItemInfo.find(item => item[infoItemKey] === columnName);
            if (targetItem) {
                targetItem.value = value;
            } else if(columnName !== 'type'){//no exists, so need to create and insert
                const newInfoItem = {};
                newInfoItem[infoItemKey] = columnName;
                newInfoItem['value'] = value;

                editItemInfo.push(newInfoItem);
            }
        },
        updateSecText: (state, { payload }: PayloadAction<{ tabIndex: number, secTitle: string, value: string }>) => {
            const { tabIndex, secTitle, value } = payload || {}
            const tempRunway = state.tempRunways[tabIndex];
            if (secTitle === SEC_TITLES.runwayName)
                tempRunway.runway = value;
            else if (secTitle === SEC_TITLES.notes)
                tempRunway.notes = value;
            else if (secTitle === SEC_TITLES.engineFailure)
                tempRunway.engineFailure = value;
            else if (secTitle === SEC_TITLES.lvoApproach)
                tempRunway.lvoApproachRemark = value;
            else if (secTitle === SEC_TITLES.approach)
                tempRunway.approachRemark = value;
            else if (secTitle === SEC_TITLES.takeOff)
                tempRunway.takeoffRemark = value;
        },
        updateRunwayName: (state, { payload }: PayloadAction<{ tabIndex: number, secTitle: string, value: string }>) => {
            const { tabIndex, secTitle, value } = payload || {}
            state.tempRunwayNames[tabIndex] = value;
        },
        removeSecItem: (state, { payload }: PayloadAction<{ tabIndex: number, secTitle: string, rowIndex: number }>) => {
            const { tabIndex, secTitle, rowIndex } = payload || {};
            const tempRunway = state.tempRunways[tabIndex];
            if (secTitle === SEC_TITLES.approach) {
                tempRunway.approach.splice(rowIndex, 1);
            } else if (secTitle === SEC_TITLES.takeOff_Lights) {
                tempRunway.takeoff.lights.splice(rowIndex, 1);
            }
        },
        addSecItem: (state, { payload }: PayloadAction<{ tabIndex: number, secTitle: string, newItem: any }>) => {
            const { tabIndex, secTitle, newItem } = payload || {};
            const tempRunway = state.tempRunways[tabIndex];
            if (secTitle === SEC_TITLES.lvoApproach) {
                tempRunway.lvoApproach.push(newItem);
            } else if (secTitle === SEC_TITLES.approach) {
                tempRunway.approach.push(newItem);
            } else if (secTitle === SEC_TITLES.takeOff_Lights) {
                tempRunway.takeoff.lights.push(newItem);
            } else if (secTitle === SEC_TITLES.takeOff_Performance) {
                tempRunway.takeoff.performance.push(newItem);
            }
        },
    }
});

const selectPortPageRunway = (state: RootState) => state.portPageRunway;
const {
    loadRunways,
    addNewRunway,
    duplicateRunway,
    resetRunways,
    updateSecItems,
    updateSecText,
    removeSecItem,
    addSecItem,
    updateRunwayName
} = portPageRunwaySlice.actions;

export { portPageRunwaySlice, selectPortPageRunway, loadRunways, addNewRunway, duplicateRunway, resetRunways, updateSecItems, updateSecText, removeSecItem, addSecItem, updateRunwayName };
export type { PortPageRunwaySlice, IPortPageRunwaySave };

export default portPageRunwaySlice.reducer;
