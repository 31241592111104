import { CCol, CRow } from '@coreui/react-pro';
import React from 'react';
import SectionContentItem from './SectionContentItem';
import assign from 'lodash/assign';

interface ISpecialStyle {
  col: string;
  styleName: string;
}

interface IPortPageRunwaySecContentItemsProps {
  key: string;
  isEditMode: boolean;
  headersNotDisplay: Array<string>;
  fixedValueColumns: Array<string>;
  items: Array<object>;
  remark?: string;
  changeValue: Function,
  removeItem?: Function,
  addNewItem?: Function,
  specialStyles?: Array<ISpecialStyle>,
  listTitleStyle?: string;
}

const SectionContentItems = ({ key, isEditMode, headersNotDisplay, fixedValueColumns = [], items, changeValue, removeItem, addNewItem, specialStyles, listTitleStyle = 'sec-content-list-title' }: IPortPageRunwaySecContentItemsProps) => {
  const newItemObj = assign({}, items ? items[0] : {});

  const renderHeader = () => {
    return (
      <CRow className={listTitleStyle}>
        {newItemObj && Object.keys(newItemObj).map(col => {
          newItemObj[col] = '';
          return headersNotDisplay.includes(col) ? <CCol></CCol> :
            <CCol className='align-center'>{col}</CCol>
        })}
        {isEditMode && removeItem && <CCol></CCol>}
      </CRow>)
  }

  const renderBody = () => {
    var body = items && items.map((rowItem, rowIndex) => {
      const sectionContentItemProps = {
        isEditMode,
        rowIndex,
        rowItem,
        fixedValueColumns,
        changeValue,
        removeItem,
        specialStyles,
        itemsLength: items.length,
      };
      return (
        <CRow key={`${key}Item${rowIndex}`} className='mt-2'>
          <SectionContentItem {...sectionContentItemProps} />
        </CRow >
      );
    })
    return body;
  }

  const renderSecItems = () => {
    return (
      <>
        {renderHeader()}
        {renderBody()}
      </>
    )
  }

  return (
    <>
      {renderSecItems()}
      {isEditMode && addNewItem &&
        <CRow className='mt-2'>
          <CCol className='dynamic-add-row-label'>
            <span onClick={() => addNewItem(newItemObj)}>+ Add new row</span>
          </CCol>
        </CRow>}
    </>
  );
};

export default SectionContentItems;
