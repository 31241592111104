import * as React from 'react';
import { CTableRow, CTableDataCell, CCol, CRow } from '@coreui/react';
import { DeleteIcon, DownloadIcon } from '../../assets/images';
import { IAppendixFile } from '../../slice/simSlice';
import { useCallback } from 'react';
import { getResource } from '../../services/api';
import { deleteAppendixFile, deleteHKEAppendixFile } from '../../services/appendixAPI';
import { AirLines } from '../../constants/genericTableConstant';

interface IAppendixRulesTableItemProps {
    appendixFile: IAppendixFile;
    dispatchReducer: React.Dispatch<any>;
    type: string;
    deleteFileRefresh: Function;
}

const AppendixRulesFileItem = ({ appendixFile, dispatchReducer, type, deleteFileRefresh }: IAppendixRulesTableItemProps) => {

  const downloadPDFHandler = useCallback(async (fileName) => {
    const airLineParam = type === AirLines.HKE ? "uo" : "cx";
    const resp = await getResource(airLineParam, "pdf", encodeURIComponent(fileName), "appendix");
    const fileURL = URL.createObjectURL(resp);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = fileName;
    alink.click();
  }, []);

  const removeAppendixFile = async (key: string) => {
    if (type === AirLines.HKE) await deleteHKEAppendixFile(key);
    else await deleteAppendixFile(key);
    // window.location.reload();
    deleteFileRefresh(true);
  }

  const DownloadButton = () => {
    return <div className={"clickable"} style={{ marginLeft: "4px" }}>
      <DownloadIcon height={40} width={40} onClick={() => downloadPDFHandler(appendixFile.pdfName)} />
    </div>
  };


  const RobotoMonoRgDataCell = ({ children }) => {
    return <CTableDataCell className={"RobotoMonoRg"} style={{ position: "relative" }} align='middle'>{ children }</CTableDataCell>
  };

  const triggerFileDeletionPopup = (fileName: string) => {
    if (window.confirm(`Are you sure to delete this file?\n${fileName}`)) {
      removeAppendixFile(fileName)
    }
  }

  return <CTableRow style={{ borderBottom: "1px solid #D8D8D8" }}>
    <RobotoMonoRgDataCell>{appendixFile.pdfName || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell>{appendixFile.uploadDate || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell>
      <CRow>
        <CCol sm="auto"><DeleteIcon height={40} width={40} onClick={() => triggerFileDeletionPopup(appendixFile.pdfName)} /></CCol>
        <CCol sm="auto"><DownloadButton/></CCol>
      </CRow>
    </RobotoMonoRgDataCell>
  </CTableRow>
};

export default AppendixRulesFileItem;