import { Internal_Support_PATHS } from "../../constants/path";
import { routeConfig } from "../../interfaces/route";
import DataCfCcsPage from "./DataCfCcsPage";


const supportRouterConfig: routeConfig[] = [
    {
        path: Internal_Support_PATHS.dataCfCcs,
        component: DataCfCcsPage,
    }
];

export default supportRouterConfig;