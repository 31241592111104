import React, { useEffect, useRef, useState } from 'react';
import './style/style.scss';

interface ICXTextAreaProps {
  placeholder: string;
  value: string;
  minRows?: number;
  maxRows?: number;
  disabled?: boolean;
  zIndex?: number;
  unit?: string;
  label?: string;
  hasLabel?: boolean;
  readOnly?: boolean;
  showAll?: boolean;

  onChangeField?: Function;
  field?: string;
  additionalValidation?: Function;
  errMsg?: string;
}

const CXFPConfigTextArea = ({
    disabled = false,
    zIndex, minRows = 1,
    maxRows = 6,
    placeholder,
    value,
    unit,
    label,
    hasLabel = true,
    readOnly = false,
    showAll=false,
    onChangeField,
    field, 
    additionalValidation,
    errMsg}: ICXTextAreaProps) => {
  const [focus, setFocus] = useState(false);
  const [rows, setRows] = useState(minRows);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textValue, setTextValue] = useState<string>(value || "");

  useEffect(() => {
    if(value){
      const rowLength = value.split("\n").length;
      if(showAll){
        setRows(rowLength);
      }else{
        if (rowLength > minRows) {
          if (rowLength > maxRows) {
            setRows(maxRows);
          } else {
            setRows(rowLength);
          }
        }else{
          setRows(minRows);
        }
      }
    }
  }, [value, maxRows, minRows, showAll])
  /*
    ----------------------------- Sample component ------------------------------
    const [text1, setText1] = useState("");
    <CXTextArea value={text1} placeholder={"Flight Number"} onChange={setText1} />
    ----------------------------- Sample component ------------------------------
  */

  const toggleFocus = () => {
    if (!disabled && !readOnly) {
      setFocus(!focus);
      if(textAreaRef?.current){
        textAreaRef.current.focus();
      }
    }
  };

  const onFocus = () => {
    if (!disabled && !readOnly) {
      setFocus(true)
    }
  }

  const onBlur = (e) => {
    if(onChangeField){
        onChangeField(e)
      }
    if (!disabled && !readOnly) {
      setFocus(false)
    }
  }

  return (
    <>
        <div className={"cx-textArea"} style={{ zIndex: !!zIndex ? zIndex : 1030 }}>
        <div className={"cx-form-control"} style={{ overflow: "hidden", background: disabled? "#F2F2F3" : "transparent" }}>
            <textarea
            ref={textAreaRef}
            name={field}
            rows={rows}
            className={`
            ${hasLabel ? "cx-textarea-form-input" : "cx-textarea-form-input-without-label"} 
            ${focus ? "focused" : ""} 
            ${disabled ? "cx-disabled" : ""} 
            ${additionalValidation && !additionalValidation(value) ? "error-border": "" }`}
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
            onFocus={() => onFocus()}
            onBlur={(e) => onBlur(e)}
            disabled={disabled}
            readOnly={readOnly}
            />
            {  ((focus || !!value) ?
            <div>
                {hasLabel && <div className={"focused-label"} onClick={toggleFocus}>{label || placeholder}</div>}
                <div className={"unit"} onClick={toggleFocus}>
                    {unit || ""}
                </div>
            </div>
            :
            <div className={`text-ellipsis ${disabled ? "not-focused-label-disabled" : "not-focused-label-area"}`} onClick={toggleFocus}>{placeholder}</div>)
            }

        </div>
        </div>
        {additionalValidation && !additionalValidation(value) && !!errMsg && (
            <div className={"cx-error-message"}>{errMsg}</div>
        )}
    </>
  );
};

export default CXFPConfigTextArea;