enum ActiveFlightPoliciesType {
    SNN = "SNN",
    FUEL_POLICIES = "FUEL_POLICIES",
    TANKERING_POLICIES = "TANKERING_POLICIES",
}

enum FootButtonsInSNNView {
    DUPLICATE = "Duplicate",
    EDIT = "Edit",
    DELETE = "Delete",
    SEND_ALERT = "Send Alert",
}

// map to value of BE field [type] = SNN, FUEL_POLICIES, TANKERING_POLICIES
const ActiveFlightPoliciesRadio = [{
    name: "ActiveFlightPoliciesRadio",
    id: "ActiveFlightPoliciesSNN",
    value: ActiveFlightPoliciesType.SNN,
    label: "No related flight policy",
}, {
    name: "ActiveFlightPoliciesRadio",
    id: "ActiveFlightPoliciesFuelPolicies",
    value: ActiveFlightPoliciesType.FUEL_POLICIES,
    label: "Fuel policies",
}
// , {
//     name: "ActiveFlightPoliciesRadio",
//     id: "ActiveFlightPoliciesTankeringPolicies",
//     value: ActiveFlightPoliciesType.TANKERING_POLICIES,
//     label: "Tankering policies",
//     disabled: true,
// }
];

const FuelPoliciesSupplement = [{
    label: "Reason",
    field: "reason",
}, {
    label: "Remarks",
    field: "remarks",
}, {
    label: "TAF Remarks",
    field: "tafRemarks",
}, {
    label: "Issued By",
    field: "issuedBy",
}];

const FootButtonsInViewObject = {
    activeFuelPolicies: [FootButtonsInSNNView.DUPLICATE, FootButtonsInSNNView.DELETE, FootButtonsInSNNView.EDIT, FootButtonsInSNNView.SEND_ALERT],
    inactiveFuelPolicies: ['', '', FootButtonsInSNNView.DUPLICATE, FootButtonsInSNNView.DELETE],
    snn: [FootButtonsInSNNView.DUPLICATE, '', FootButtonsInSNNView.DELETE, FootButtonsInSNNView.EDIT]
};

const CONFIRM_MODAL_INFO_MAP = {
    title: {
        SEND: "Send alert",
        CANCEL: "Cancel policy"
    },
    content: {
        SEND: "Are you confirm to send email alert to relevant recipients?",
        CANCEL: "Are you sure to cancel this policy? Once the policy is cancelled, SNN will become inactive and an email notification will be sent to all relevant parties."
    },
}

export {
    ActiveFlightPoliciesType,
    FootButtonsInSNNView,
    ActiveFlightPoliciesRadio,
    FuelPoliciesSupplement,
    FootButtonsInViewObject,
    CONFIRM_MODAL_INFO_MAP
};
