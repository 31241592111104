import { ROOT_PATHS } from "../constants/path";
import { routeConfig } from "../interfaces/route";
import LandingPage from "./LandingPage";

const rootRouterConfig: routeConfig[] = [{
    path: ROOT_PATHS.landing,
    component: LandingPage,
    exact: true,
}];

export default rootRouterConfig;