import { BEKOL_API, HTTP_REQUEST_TYPE, SIDSTAR_API } from "../constants/api";
import { sendHttpRequest, handleRequestData } from "./api";

const getSidStarFiles = async (isCXSidStar: boolean = true) => {
	const [, resp] = await sendHttpRequest(
		HTTP_REQUEST_TYPE.get,
		isCXSidStar ? SIDSTAR_API.GET_SIDSTAR_DATA : BEKOL_API.GET_SIDSTAR_DATA,
		{}
	);
	return resp;
};

// get sidstar file count
const getSidStarFileCount = async (isCXSidStar: boolean = true) => {
	const [, resp] = await sendHttpRequest(
		HTTP_REQUEST_TYPE.get,
		isCXSidStar ? SIDSTAR_API.GET_RECORD_COUNT : BEKOL_API.GET_RECORD_COUNT,
		{}
	);
	return resp;
};

const postUploadSidStarFile = async (bodyFormData: FormData, isCXSidStar: boolean = true) => {
	const UPLOAD_TIMEOUT = 90000;
	const [, resp] = await sendHttpRequest(
		HTTP_REQUEST_TYPE.post,
		isCXSidStar ? SIDSTAR_API.POST_SIDSTAR_UPLOAD_FILE : BEKOL_API.POST_SIDSTAR_UPLOAD_FILE,
		bodyFormData,
		true,
		true,
		UPLOAD_TIMEOUT
	);
	const [isSucceed, errorMsg, respData, status] = handleRequestData(resp);
	return [isSucceed, errorMsg, respData, status];
};

const postDownloadSidStarFile = async (key: string, filename: string, isCXSidStar: boolean = true) => {
	let requestBody = {
		key: key,
		filename: filename,
		responseType: 'blob'
	}

	const [, resp] = await sendHttpRequest(
		HTTP_REQUEST_TYPE.post,
		isCXSidStar ? SIDSTAR_API.POST_SIDSTAR_DOWNLOAD_FILE : BEKOL_API.POST_SIDSTAR_DOWNLOAD_FILE,
		requestBody,
	);

	return resp;
};


export {
	getSidStarFiles,
	postUploadSidStarFile,
	postDownloadSidStarFile,
	getSidStarFileCount,
};