import React from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody } from '@coreui/react';
import NoDataTableItem from '../AdminDashboard/NoDataTableItem';
import CrewFeedbackTaggingTableItem from './CrewFeedbackTaggingTableItem';
import { DownArrowIcon } from '../../assets/images';

interface ICrewFeedbackTaggingTableProps {
  type: string;
  fieldNames: Array<any>;
  data: any;
  onClickRowBtn: Function;
  sortData: Function;
}

const CrewFeedbackTaggingTable = ({ type, data, fieldNames, onClickRowBtn, sortData }: ICrewFeedbackTaggingTableProps) => {
  const TaggingTableItems = () => {
    let TableItem;
    TableItem = data.map((tagsItem: any, index) =>
      <CrewFeedbackTaggingTableItem
        key={`CrewFeedbackTaggingTableItem${index}`}
        tagsItem={tagsItem}
        onClickRowBtn={onClickRowBtn}
      />);
    return TableItem
  };

  return (
    <CTable hover borderless className='align-middle'>
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell key={`${CrewFeedbackTaggingTable}${fieldNames[0].key}`} scope="col">{fieldNames[0].display}</CTableHeaderCell>
          <CTableHeaderCell key={`${CrewFeedbackTaggingTable}${fieldNames[1].key}`} scope="col" className={"clickable"} onClick={() => sortData('port', data)}>
            {fieldNames[1].display}
            <DownArrowIcon height={12} width={12} />
          </CTableHeaderCell>
          <CTableHeaderCell key={`${CrewFeedbackTaggingTable}${fieldNames[2].key}`} scope="col" className={"clickable"} onClick={() => sortData('tab', data)}>
            {fieldNames[2].display}
            <DownArrowIcon height={12} width={12} />
          </CTableHeaderCell>
          <CTableHeaderCell key={`${CrewFeedbackTaggingTable}${fieldNames[3].key}`} scope="col">{fieldNames[3].display}</CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {
          (!!data && Object.values(data).length !== 0) ?
            <TaggingTableItems />
            :
            <NoDataTableItem colSpan={14} message={`There is no ${type} retrieved.`} />
        }
      </CTableBody>
    </CTable>
  )
};

export default CrewFeedbackTaggingTable;
