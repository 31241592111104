import { CCol, CRow, CCallout, CButton } from '@coreui/react-pro';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { IPortSec, IPortSecItem } from '../../slice/dispatcherSlice';
import { DeleteIcon, EditIcon, ConfirmIcon, CancelIcon } from '../../assets/images';
import { CXButton } from '../../components/CXForm';
import CXFPConfigTextInput from '../CXForm/CXFPConfigTextInput';
import CXTextInput from '../CXForm/CXTextInput';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from "rehype-raw";
import '@mdxeditor/editor/style.css'
import { MDXEditor, headingsPlugin, listsPlugin, quotePlugin, tablePlugin, toolbarPlugin, thematicBreakPlugin, 
  BoldItalicUnderlineToggles, UndoRedo, ListsToggle, BlockTypeSelect, InsertTable, diffSourcePlugin, DiffSourceToggleWrapper,
  markdownShortcutPlugin, frontmatterPlugin } from '@mdxeditor/editor'
import { formatMDXEditorContent } from '../../utils/mdxEditorHelper';
import {needOneMoreInput, displayPrefixValue} from './PortPageDataDisplayHelper';

interface IPortPageContentProps {
  name: string;
  secItems: IPortSecItem[];
  tabIndex: number;
  secIndex: number;
  blurPrefix: Function;
  updateNotam: Function;
  isDeletable: boolean;
  editTabSecs: IPortSec[];
  dispatchReducer: React.Dispatch<any>;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  removeTab: (index: number) => void;
}

interface DragItem {
  secIndex: number
  id: string
  type: string
}

const PortPageContentView = ({ name, secItems = [], tabIndex, secIndex, blurPrefix, updateNotam, isDeletable, editTabSecs, dispatchReducer, moveCard, removeTab }: IPortPageContentProps) => {

  const [sectionTitle, setSectionTitle] = useState<string>(name);
  const [items, setItems] = useState<IPortSecItem[]>(secItems);
  const [editValue, setEditValue] = useState<any>("");
  const [editSecItemIndex, setEditSecItemIndex] = useState<number>(-1);
  const [editValueIndex, setEditValueIndex] = useState<number>(-1);
  const [isShowEditor, setIsShowEditor] = useState<boolean>(false);
  const [inputBoxOriginalVal, setInputBoxOriginalValue] = useState<any>("");

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'input',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.secIndex
      const hoverIndex = secIndex

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      //   if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //     console.log("cannot move down")
      //     return
      //   }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)


      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.secIndex = hoverIndex
    },
    drop(item: DragItem, monitor) {
      dispatchReducer({ type: "RE_ORDER_SECS", tabIndex: tabIndex, tabSecs: editTabSecs });
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: "input",
    item: () => {
      return { secIndex, items }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    drag(drop(ref));
  }, [])

  useEffect(() => {
    setSectionTitle(name);
    setItems(secItems);
  },[name, secItems])

  const purePrefix = prefix => prefix && prefix.replace(':',''); 

  const onClickEdit = (value, secItemIndex, valueIndex) => {
    setInputBoxOriginalValue(value);
    setEditValue(value);
    setEditSecItemIndex(secItemIndex);
    setEditValueIndex(valueIndex);
    setIsShowEditor(true);
  };

  const onChangeEditor = (content: string) => {
    const contentFormatted = formatMDXEditorContent(content);
    setEditValue(contentFormatted);
  };

  const onConfirmEdit = (tabIndex, secIndex, secItemIndex, valueIndex) => {
    updateNotam('edit', editValue, tabIndex, secIndex, secItemIndex, valueIndex);
    setInputBoxOriginalValue("");
    setEditValue('');
    setEditSecItemIndex(-1);
    setEditValueIndex(-1);
    setIsShowEditor(false);
  };

  const closeModal = (tabIndex, secIndex, secItemIndex, valueIndex) => {
    if (window.confirm(`Are you sure to cancel?`)) {
      updateNotam('edit', inputBoxOriginalVal, tabIndex, secIndex, secItemIndex, valueIndex);
      setInputBoxOriginalValue("");
      setEditValue('');
      setEditSecItemIndex(-1);
      setEditValueIndex(-1);
      setIsShowEditor(false);
    }
  };

  // const displayPrefixValue = (key, value, prefixInputCopy = false) => {
  //   if (needOneMoreInput(key)) {
  //     const VALUE_DIVIDER = '__',
  //           OLD_VALUE_DIVIDER = ' ',
  //           ADOPTED_NEW_FORMAT = checkIfValueAdoptNewFormat(value, VALUE_DIVIDER),
  //           USE_VALUE_DIVIDER = ADOPTED_NEW_FORMAT? VALUE_DIVIDER: OLD_VALUE_DIVIDER;
  //     const utcInputs = value ? value.split(USE_VALUE_DIVIDER) : [];

  //     return prefixInputCopy
  //       ? (utcInputs.length > 1 ? utcInputs[1] : '')
  //       : (utcInputs.length > 0 ? utcInputs[0] : value);
  //   } else
  //     return value;

  //   function checkIfValueAdoptNewFormat($concatOriginalValue: string, $divider: string): boolean{
  //     console.warn("$concatOriginalValue: ", $concatOriginalValue);
  //     if(!$concatOriginalValue) return false;
  //     return $concatOriginalValue.includes($divider);
  //   }
  // };

  // const displayMdValue = (value) => {
  //   return value.replaceAll(/\n/g, "   \n");
  // };

  // const needOneMoreInput = key => ['UTC'].includes(key.toUpperCase());

  const onBlurPrefix = (content: any, secItemIndex: number, portInputItem: number) => {
    const isCopy = (portInputItem !== 0);
    blurPrefix(content, tabIndex, secIndex, secItemIndex, isCopy);
  }


  const tableStyles = `
    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
    }
    th, td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
      word-wrap: break-word;
    }
  `;

  return (
    <>
    <div className="port-page-content port-page-content-view">
      <CRow ref={ref} style={ {cursor: 'move', opacity: isDragging ? 0 : 1} } data-handler-id={handlerId} > 
          <CCol xs={2} className={'content-title draggable'}>
            {isDeletable ?
              <CXTextInput
                placeholder={"Section title"}
                value={sectionTitle}
                onChange={setSectionTitle}
                onBlurFunction={() => dispatchReducer({ type: "CHANGE_SEC_TITLE", tabIndex: tabIndex, index: secIndex, value: sectionTitle })}
                zIndex={1029} />
              :
              sectionTitle
            }
          </CCol>
          <CCol xs={9}>
            {items != undefined && items.length > 0 && items.map((item,secItemIndex) => {
              return (
              <React.Fragment key={`portSecItem${secItemIndex}`}>
                <CRow> 
                {item.type == "TEXT" && item.prefix &&
                  <Col xs="auto" className='content-prefix CathaySansIt'>
                    <span>{purePrefix(item.prefix)}</span>
                  </Col>
                }
                {item.type == "TEXT" &&
                  <Col className='content-input'>
                    <CXFPConfigTextInput value={displayPrefixValue(purePrefix(item.prefix), item.value)} placeholder={`${purePrefix(item.prefix)}`} portOnBlur={{ onBlur: onBlurPrefix, portSecItemIndex: secItemIndex, portInputItem: 0 }} />
                  </Col>
                }
                {item.type == "TEXT" &&
                  needOneMoreInput(purePrefix(item.prefix)) &&
                  <Col className='content-input'>
                    <CXFPConfigTextInput value={displayPrefixValue(purePrefix(item.prefix), item.value, true)} placeholder={`${purePrefix(item.prefix)}`} portOnBlur={{ onBlur: onBlurPrefix, portSecItemIndex: secItemIndex, portInputItem: 1 }} />
                  </Col>
                }
                {item.type == "TEXT" && item.suffix &&
                  <Col xs="auto" className='content-prefix CathaySansIt'>
                    <span>{item.suffix}</span>
                  </Col>
                }
                </CRow>
                {item.type == "MD" && item.value && item.value.length && item.value.map((value,valueIndex) => {
                    return (
                      <>
                      <CRow>
                        {(!isShowEditor || (valueIndex !== editValueIndex)) && <CCallout color="secondary" style={{ width: '1100px', marginRight: "10px"}}>
                          <style>{tableStyles}</style>
                          <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{value}</ReactMarkdown>
                        </CCallout>}
                        {isShowEditor && valueIndex === editValueIndex && <CCallout color="secondary" style={{ width: '1100px', background: "#FFFFFF", marginRight: "10px"}}>
                        <MDXEditor
                          onChange={(e) => onChangeEditor(e)}
                          markdown={editValue}
                          plugins={[
                            listsPlugin(),
                            headingsPlugin(),
                            quotePlugin(),
                            tablePlugin(),
                            thematicBreakPlugin(),
                            diffSourcePlugin({ diffMarkdown: value }),
                            markdownShortcutPlugin(),
                            frontmatterPlugin(),
                            toolbarPlugin({
                              toolbarContents: () => ( <> <DiffSourceToggleWrapper><UndoRedo /><BoldItalicUnderlineToggles /><ListsToggle /><BlockTypeSelect /><InsertTable /></DiffSourceToggleWrapper></>)
                            }),
                          ]}
                        />
                        </CCallout>}
                        {!isShowEditor && <CCol sm={6} style={{ marginTop: '5px', padding: "0px" }}>
                          <EditIcon style={{ height: "3rem", width: '3rem', cursor: "pointer", marginLeft: "10px" }} onClick={() => onClickEdit(value, secItemIndex, valueIndex)} />
                          <DeleteIcon style={{ height: "3rem", width: '3rem', cursor: "pointer", marginLeft: "10px" }} onClick={() => updateNotam('del', '', tabIndex, secIndex, secItemIndex, valueIndex)} />
                        </CCol>}
                        {(isShowEditor && (valueIndex !== editValueIndex)) && <CCol sm={6} style={{ marginTop: '5px', padding: "0px" }}>
                          <EditIcon style={{ backgroundColor: "#EFEFEF", height: "3rem", width: '3rem', marginLeft: "10px" }}/>
                          <DeleteIcon style={{ backgroundColor: "#EFEFEF", height: "3rem", width: '3rem', marginLeft: "10px" }} />
                        </CCol>}
                        {(isShowEditor && (valueIndex === editValueIndex)) && <CCol sm={6} style={{ marginTop: '5px', padding: "0px" }}>
                          <ConfirmIcon style={{ height: "40px", width: '40px', cursor: "pointer", marginLeft: "10px", marginTop: '-18px' }} onClick={() => onConfirmEdit(tabIndex, secIndex, secItemIndex, valueIndex)} />
                          <CancelIcon style={{ height: "40px", width: '40px', cursor: "pointer", marginLeft: "10px", marginTop: '-18px' }} onClick={() => closeModal(tabIndex, secIndex, secItemIndex, valueIndex)} />
                        </CCol>}
                      </CRow>
                      </>
                    )
                  })
                }
                {item.type == "MD" &&
                  <CRow> 
                    {!isShowEditor && <CCol style={{ marginTop: '10px' }}>
                      <CXButton text={"+ Add new notam"} 
                      onClick={() => { updateNotam('add', '', tabIndex, secIndex, secItemIndex, 0) }} 
                      fontSize={"18px"} width={"20%"} height='38px' outline={true} />
                      </CCol> }
                      { isShowEditor && <CCol style={{ marginTop: '10px' }}>
                        <CXButton text={ "+ Add new notam" } disabled={ true } fontSize={ "18px" } width={ "20%" } height='38px' />
                      </CCol> }
                  </CRow>
                }
              </React.Fragment>
              )
            })
            }
          </CCol>
          <CCol xs={1} className={'draggable'}>
            {isDeletable &&
              <DeleteIcon style={{ cursor: "pointer", marginLeft:"10px" }} onClick={() => removeTab(secIndex)} />
            }
          </CCol>
      </CRow>
    </div>
    </>
    )
};

export default PortPageContentView;