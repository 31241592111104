import * as React from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell, CCol, CRow } from '@coreui/react';

import { DeleteIcon, EditIcon, RightArrowLgIcon } from '../../assets/images';

import { ISNNRule } from '../../slice/simSlice';
import Access from '../Access';
import { AccessType } from '../../constants/roleAccessConstant';
import { ActiveFlightPoliciesType } from '../../constants/snnConstant';


interface ISNNRulesTableItemProps {
  snnRule: ISNNRule;
  dispatchReducer: React.Dispatch<any>;
  routePath: string;
}

const SNNRulesTableItem = ({ snnRule, dispatchReducer, routePath }: ISNNRulesTableItemProps) => {
  const isActive = snnRule.isActive;
  const isFuelPolicies = snnRule.type === ActiveFlightPoliciesType.FUEL_POLICIES;

  const EditButton = () => {
    const isInactiveFuelPolicies = !isActive && isFuelPolicies;
    let className = isInactiveFuelPolicies ? 'unClickable' : 'clickable';
    className += !isActive ? " snnTableRow" : "";
    return <div className={className} style={{ marginLeft: "4px" }}>
      <EditIcon height={40} width={40} onClick={() => {
        if (isInactiveFuelPolicies) return; // general snn (both active and inactive) and active fuel policies are editable
        dispatchReducer({ type: "EDIT", snnRule: snnRule });
      }} />
    </div>
  };

  const RobotoMonoRgDataCell = ({ children, type, className = '' }) => {
    return <CTableDataCell className={`RobotoMonoRg tableItem px-1 ${type} ${className}`} style={{ position: "relative" }} align='middle'>{children}</CTableDataCell>
  };


  return <CTableRow className={`${!isActive ? "snnTableRow" : ""}`} style={{ borderBottom: "1px solid #D8D8D8" }}>
    <RobotoMonoRgDataCell type="airline" className={isFuelPolicies ? 'fuelPolicyIndicator' : ''}>
      <div>
        {snnRule.airline || "--"}
      </div>
      <div>
        {snnRule.flightNo || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="aircraftType">{snnRule.aircraftType || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="registration">{snnRule.registration || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="dep">
      {Array.isArray(snnRule.dep) && snnRule.dep.length > 0 ? snnRule.dep.map((ori, index) =>
        <div key={`ori_${index}`}>{ori}</div>
      )
        :
        <div>{"--"}</div>
      }
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="arr">
      {Array.isArray(snnRule.arr) && snnRule.arr.length > 0 ? snnRule.arr.map((arr, index) =>
        <div key={`arr${index}`}>{arr}</div>
      )
        :
        <div>{"--"}</div>
      }
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="altn">
      <div>
        {snnRule.era || "--"}
      </div>
      <div>
        {snnRule.altn || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="startAt">
      <div>
        {moment.utc(snnRule.startAt)?.format("DDMMMYYYY HH:mm") || "--"}
      </div>
      <div>
        {moment.utc(snnRule.endAt)?.format("DDMMMYYYY HH:mm") || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="timeRange">
      <div>
        {snnRule.timeRange || "--"}
      </div>
      <div>
        {snnRule.isInterval && snnRule.isInterval === true ? "Y" : "N" || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="waypoint">
      <div>
        {snnRule.waypoint || "--"}
      </div>
      <div>
        {snnRule.countryICAOCode || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="createBy">{snnRule.createBy || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="version">{snnRule.version || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="snn">{snnRule.snn || "--"}</RobotoMonoRgDataCell>
    <Access route={routePath} access={[AccessType.UPDATE, AccessType.DELETE]} >
      <RobotoMonoRgDataCell type="action">
        <CRow>
          <Access route={routePath} access={[AccessType.UPDATE]} >
            <CCol sm="auto"><EditButton /></CCol>
          </Access>
          <Access route={routePath} access={[AccessType.DELETE]} >
            <CCol sm="auto">
              <div className={!isActive ? "snnTableRow clickable" : "clickable"}>
                <DeleteIcon height={40} width={40} onClick={() => { dispatchReducer({ type: "DELETE", snnRule: snnRule }) }} />
              </div>
            </CCol>
          </Access>
        </CRow>
      </RobotoMonoRgDataCell>
    </Access>
    <RobotoMonoRgDataCell type="view">
      <RightArrowLgIcon onClick={() => { dispatchReducer({ type: "VIEW", snnRule: snnRule }) }} width={"31px"} height={"31px"} />
    </RobotoMonoRgDataCell>
  </CTableRow>
};

export default SNNRulesTableItem;
