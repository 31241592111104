import * as React from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell } from '@coreui/react';

const OeNotamTableItem = ({ acType, acSubType, text, startz, endz, iata, icao }) => {
  return <CTableRow>
    <CTableDataCell className={"RobotoMonoRg"}>{acType || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{acSubType || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg formattedText"}>{text || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(startz).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(endz).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{iata || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{icao || "-"}</CTableDataCell>
  </CTableRow>
};

export default OeNotamTableItem;