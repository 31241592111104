import React from 'react';
import _ from 'lodash';
import { CTableRow, CTableDataCell } from '@coreui/react';
import { EyeIcon, EyeCloseIcon, EditIcon } from '../../assets/images';
import { OPERATION_TYPE } from '../../constants/crewFeedbackTaggingPageConstant';

interface ICrewFeedbackTaggingTableItemProps {
  tagsItem: any;
  onClickRowBtn: Function;
}

const CrewFeedbackTaggingTableItem = ({ tagsItem, onClickRowBtn }: ICrewFeedbackTaggingTableItemProps) => {
  const { tag, port, tab, hidden } = tagsItem;

  const displayArrayContent = (dataArr, separator = ', ') => {
    return _.isArray(dataArr) && !_.isEmpty(dataArr) ? _.join(dataArr, separator) : '';
  }

  // return <CTableRow className={`${hidden ? 'hidden-tag' : ''} border-bottom`}>
  return <CTableRow key={`${tag}`} color={`${hidden ? 'light' : ''}`} className='border-bottom'>
    <CTableDataCell>
      <span className='tags-content'>
        {tag}
      </span>
    </CTableDataCell>
    <CTableDataCell>
      {displayArrayContent(port)}
    </CTableDataCell>
    <CTableDataCell>{displayArrayContent(tab)}</CTableDataCell>
    <CTableDataCell align='middle'>
      <div className={'d-flex align-items-center flex-row-reverse p-0'}>
        <div className={"clickable"}>
          {!!hidden ?
            <EyeIcon height={40} width={40} onClick={() => { onClickRowBtn(OPERATION_TYPE.HIDE, tagsItem) }} />
            : <EyeCloseIcon height={40} width={40} onClick={() => { onClickRowBtn(OPERATION_TYPE.HIDE, tagsItem) }} />}
        </div>
        <div className={"clickable mx-2"}>
          <EditIcon height={40} width={40} onClick={() => { onClickRowBtn(OPERATION_TYPE.EDIT, tagsItem) }} />
        </div>
      </div>
    </CTableDataCell>
  </CTableRow >
};

export default CrewFeedbackTaggingTableItem;
