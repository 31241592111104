import pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions, Margins, PageOrientation, PageSize } from 'pdfmake/interfaces';

import pdfFonts from './PDFGenFonts';
import PDFGenStyles from './PDFGenStyles';

pdfMake.vfs = pdfFonts;
pdfMake.fonts = {
    CathaySans: {
        normal: 'CathaySans_Rg.ttf',
        bold: 'CathaySans_Bd.ttf',
        italics: 'CathaySans_It.ttf',
        bolditalics: 'CathaySans_Md.ttf',
    },
};

// const pageSize = 'A4' as PageSize;;
const A4_WIDTH = 595;
const pageSize = {width: A4_WIDTH, height: 'auto'};
const pageOrientation = 'portrait' as PageOrientation;
const pageMargins = [24, 12, 24, 38] as Margins;
const defaultStyle = {
    font: 'CathaySans',
};

// const getPageNumber = function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; }

export const generatePdf = ({
    content,
    footer
}) => {
    const reportOptions = {
        pageSize: pageSize,
        styles: PDFGenStyles,
        defaultStyle: defaultStyle,
        footer: footer ? footer : '',
        pageMargins: pageMargins,
        pageOrientation: pageOrientation,
        content: content,
    } as TDocumentDefinitions;

    return pdfMake.createPdf(reportOptions);
};
