import { COMMON_API, HTTP_REQUEST_TYPE } from "../constants/api";
import { sendHttpRequest } from "./api";


const getAircraftTypeList = async () => {
  const [, resp] = await sendHttpRequest(
    HTTP_REQUEST_TYPE.get,
    COMMON_API.GET_AIRCRAFT_TYPE,
    {}
  );
  return resp;
};
export {
  getAircraftTypeList
};