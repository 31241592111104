import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { CXButton, } from "../../../../components/CXForm";

interface ModalProps {
    /** Whether to show the modal */
    show: boolean;
    /** Function to hide the modal */
    onHide: () => void;
    /** Function to execute when the action button is clicked */
    onAction?: () => Promise<void>;
    /** Function to execute when the cancel button is clicked */
    onCancel?: () => void
    /** Title of the modal */
    title: string;
    /** Custom class name for the modal */
    className?: string;
    /** Custom class name for the modal dialog */
    dialogClassName?: string;
    /** Text for the action button */
    actionButtonText?: string;
    /** Whether the action button is enabled */
    isActionEnable?: boolean;
    /** Size of the modal */
    size?: 'sm' | 'lg' | 'xl'
    /** Content of the modal */
    children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ show, onHide, onAction, onCancel, title, children, className, dialogClassName, isActionEnable = true, size = 'xl', actionButtonText = 'Confirm' }) => {

    let [loading, setLoading] = React.useState(false)

    const hiddenModal = async (cb) => {
        setLoading(true)
        try {
            if (cb) {
                await cb()
            }
        } catch (error) {
            console.error('Error when doing the action', error)
        } finally {
            onCancel && onCancel()
            setLoading(false);
        }
    }

    const handleAction = () => {
        hiddenModal(onAction)
    }

    return (
        <BootstrapModal
            className={className}
            dialogClassName={dialogClassName}
            size={size}
            show={show}
            onHide={onHide}>
            <BootstrapModal.Header className="modal-header" closeButton>
                <BootstrapModal.Title>{title}</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>
                {children}
            </BootstrapModal.Body>
            <BootstrapModal.Footer className="modal-footer">
                <div className="cancel-button">
                    <CXButton
                        outline={true}
                        text={"Cancel"}
                        onClick={onHide}
                        fontSize={"16px"}
                        width={"100%"}
                    />
                </div>
                <div className="save-button">
                    <CXButton
                        disabled={!isActionEnable || loading}
                        text={loading ? `Loading...` : actionButtonText}
                        onClick={() => handleAction()}
                        fontSize={"16px"}
                        width={"100%"}
                    />
                </div>
            </BootstrapModal.Footer>
        </BootstrapModal>
    )
}

export { Modal };
