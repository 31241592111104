import * as React from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell } from '@coreui/react';
import { IDefectObject } from '../../slice/dispatcherSlice';

const DefectTableItem = ({ aircraft, addNumber, addTitle, addDetail, melReference, deletedAt, created, expiryDate, __v }: IDefectObject) => {
  return <CTableRow>
    <CTableDataCell className={"RobotoMonoRg"}>{aircraft || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{addNumber || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{addTitle || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg formattedText"} style={{ maxWidth: "350px" }} >{addDetail || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{melReference || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(expiryDate).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(created).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{deletedAt?"true":"false"}</CTableDataCell>
  </CTableRow>
};

export default DefectTableItem;