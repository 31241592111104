import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CTableRow, CTableDataCell, CCol, CRow } from '@coreui/react';

import { DeleteIcon, EditIcon, RightArrowLgIcon } from '../../assets/images';
import { SIM_PATHS } from '../../constants/path';
import AddOrEditOFPLightBox from './AddOrEditOFPLightBox';
import { pushToast, selectApp, setAppFlags } from '../../slice/appSlice';
import { clearSimOFPForm, selectSim, updateSimFlightPlans, setSimOFPFormInEdit } from '../../slice/simSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { characterAndNumberOnlyInput } from '../../utils/formValidateHelper';

interface ISimOFPTableItemProps {
  std: string;
  aircraftType: string;
  aircraftReg: string;
  ufi: string;
  status: string;
  flightNum: string;
  flightNumSuffix: string;
  createdBy: string;
  createTime: string;
  simCode: string;
}

const SimOFPTableItem = ({ std, aircraftType, aircraftReg, ufi, status, flightNum, flightNumSuffix, createdBy, createTime, simCode }: ISimOFPTableItemProps) => {
  const dispatch = useAppDispatch();
  const { isEditSimOFPLightBoxVisible } = useAppSelector(selectApp);
  const { simFlightPlans } = useAppSelector(selectSim);
  const editable = status === "created";

  const simOFPDetail = Object.values(simFlightPlans)?.find(simFLightPlan => simFLightPlan.ufi === ufi);

  const postSubmitAction = (res) => {
    dispatch(updateSimFlightPlans(res));
    dispatch(setAppFlags({ field: "isEditSimOFPLightBoxVisible", value: false }));
    dispatch(pushToast({type: "success", message: "SIM OFP updated"}));
    
  };

  const EditButton = () => {
    return <div className={"clickable"} style={{ marginLeft: "4px" }}>
      <EditIcon height={40} width={40} onClick={() => {
        dispatch(clearSimOFPForm());
        dispatch(setSimOFPFormInEdit({ ...simOFPDetail }));
        dispatch(setAppFlags({ field: "isEditSimOFPLightBoxVisible", value: !isEditSimOFPLightBoxVisible }));
      }} />
    </div>
  };

  const ufiChecker = () => {
    if(characterAndNumberOnlyInput(ufi)){
      return SIM_PATHS.releaseSimOFP.replace(":ufi", ufi)
    } else {
      return encodeURIComponent(SIM_PATHS.releaseSimOFP.replace(":ufi", ufi))
    }
  };

  const RobotoMonoRgDataCell = ({ children }) => {
    return <CTableDataCell className={"RobotoMonoRg"} style={{ position: "relative", fontSize: "1rem" }} align='middle'><Link to={`${ufiChecker()}`}>{children}</Link></CTableDataCell>
  };

  const RobotoMonoRgUpperCaseDataCell = ({ children }) => {
    return <CTableDataCell className={"RobotoMonoRg"} style={{ textTransform: "uppercase", position: "relative", fontSize: "1rem" }} align='middle'><Link to={`${ufiChecker()}`}>{children}</Link></CTableDataCell>
  };


  return <CTableRow key={`${std}${ufi}`} style={{ borderBottom: "1px solid #D8D8D8" }}>
    <RobotoMonoRgUpperCaseDataCell>{moment.utc(std)?.format("DDMMMYYYY HH:mm") || "--"}</RobotoMonoRgUpperCaseDataCell>
    <RobotoMonoRgDataCell>{aircraftType || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell>{aircraftReg || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell>{ufi || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell>{status || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell><CRow>
      <CCol>{createdBy || "--"}</CCol>
    </CRow>
      <CRow>
        <CCol>{moment.utc(createTime)?.format("DDMMMYYYY HH:mm") || "--"}</CCol>
      </CRow>
    </RobotoMonoRgDataCell>
    {/* 
    6/7/2022 The edit function for Sim OFP is commented on the frontend and backend because of the lack of resource 
    <CTableDataCell align='middle'>
      <div className={"d-flex align-items-center p-0"}>
        {editable && <AddOrEditOFPLightBox TriggerButton={<EditButton />} postAction={postSubmitAction} isEdit={true} heading={`Edit ${ufi}`} submitButtonText={"Update"} />}
        <div className={"clickable"} style={{ marginLeft: "4px" }}>
          {status !== "released" && <DeleteIcon height={40} width={40} onClick={(e) => { e.preventDefault() }} />}
        </div>
      </div>
    </CTableDataCell> */}
    <RobotoMonoRgDataCell>
      <RightArrowLgIcon style={{ position: "absolute", right: "8px", top: "15.5px", bottom: "15.5px" }} width={"31px"} height={"31px"} />
    </RobotoMonoRgDataCell>
  </CTableRow>
};

export default SimOFPTableItem;