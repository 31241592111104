import React from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody } from '@coreui/react';
import NoDataTableItem from './NoDataTableItem';
import CXDefectTableItem from './CXDefectTableItem';

interface ICXDefectTableProps {
  type: string;
  fieldNames: Array<string>;
  data: any;
  saveRemark: Function;
}

const CXDefectTable = ({ type, data, fieldNames, saveRemark }: ICXDefectTableProps) => {
  const DefectTableItem = () => {
    let TableItem;
    const defectsData = Object.values(data).sort((a: any, b: any) => {
      return new Date(b.createAt).valueOf() - new Date(a.createAt).valueOf()
    });
    TableItem = defectsData.map((defect: any, index) =>
      <CXDefectTableItem
        key={`DefectTableItem${index}`}
        {...defect}
        saveRemark={saveRemark}
      />);
    return TableItem
  };

  return <div style={{ border: "1px solid #b9bcc0", padding: 20, borderRadius: 10, margin: 0 }}>
    <CTable hover>
      <CTableHead>
        <CTableRow>
          {
            Object.values(fieldNames).map((name, index) => {
              return <CTableHeaderCell key={`${CXDefectTable}${name}${index}`} scope="col">{name}</CTableHeaderCell>
            })
          }
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {
          (!!data && Object.values(data).length !== 0) ?
            <DefectTableItem />
            :
            <NoDataTableItem colSpan={14} message={`There is no ${type} retrieved.`} />
        }
      </CTableBody>
    </CTable>
  </div>
};

export default CXDefectTable;