import { Link } from 'react-router-dom';
import { CTableDataCell, CTableRow } from '@coreui/react';
import { CLOSE_FLIGHT_PATHS } from '../constants/path';
import { RightArrowIcon } from '../assets/images';
import { GOOGLE_MAP_API } from '../constants/api';
import { useCallback } from 'react';
import { getResource } from '../services/api';


interface IDetailTableRowProps {
    displayData: any;
    count: number;
    index: number;
    hasArrow: any;
    noLineBreak?: boolean;
    noPadding?: boolean;
    user?: string;
    ufi?: string;
    closeFlightPackageIdentifier?: string;
    isFlightStatusPanel?: boolean;
    clickableFunc?: Function;
}

  const DetailTableRow = ({ displayData, count, index, hasArrow, noLineBreak = false, noPadding = false, user = "", ufi = "",  closeFlightPackageIdentifier, isFlightStatusPanel = false, clickableFunc}: IDetailTableRowProps) => {


    const downloadPDFHandler = useCallback(async (fileName) => {
        const resp = await getResource(user, "pdf", encodeURIComponent(fileName));
        const fileURL = URL.createObjectURL(resp);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      }, []);

    const downloadPackageHandler = useCallback(async (fileName) => {
      const resp = await getResource(user, "zip", encodeURIComponent(fileName));
      const fileURL = URL.createObjectURL(resp);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = fileName;
      alink.click();
    }, []);

    return <CTableRow key={`flightMessageAndProcessHistory${index}`}
                      className={`${((count === (index + 1)) || (noLineBreak)) ? "" : "border-bottom"}`}>
      {Object.values(displayData).map((data: any, index) => {
        if (data[1] === "date") {
          return <CTableDataCell align="top" key={`flightMessageAndProcessHistory${index}`}
                                 className={"RobotoMonoRg px-0"}
                                 style={{fontWeight: 400,
                                   fontSize: "14px",
                                   lineHeight: "18px",
                                   color: "#000000",
                                   background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
                                 }}>
            <div className={"RobotoMonoAlign"}>{data[0].toUpperCase()}</div>
          </CTableDataCell>
        } else if (data[1] === "link") {
          let selected = closeFlightPackageIdentifier === data[0];
          if (selected) {
            return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`} className={"px-0"}>
              <div className={`CathaySansRg px-0 eff-link-disabled`} style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "18px",
                color: "#000000",
                background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
              }}>{data[0].slice(-17)}</div>
            </CTableDataCell>
          } else {
            return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                   className={`CathaySansRg px-0 eff-link`}
                                   style={{fontWeight: 400, fontSize: "14px", lineHeight: "18px",
                                     background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`}}>{<Link
              to={`${CLOSE_FLIGHT_PATHS.closeFlightDetail.replace(":ufi", encodeURIComponent(ufi)).replace(":closeFlightPackageIdentifier", encodeURIComponent(data[0]))}`}>{data[0].slice(-17)}</Link>}</CTableDataCell>
          }
        } else if (data[1] === "download") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={`CathaySansRg px-0 eff-link`}
                                 style={{fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                                   background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`}}>
            <button className={"download"} onClick={() => downloadPDFHandler(data[0])}>Download PDF</button>
          </CTableDataCell>

        } else if (data[1] === "downloadOfp") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={`CathaySansRg px-0 eff-link`}
                                 style={{fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                                   background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`}}>
            <button className={"download"} onClick={() => downloadPackageHandler(data[0])}>Download Package</button>
          </CTableDataCell>

        } else if (data[1] === "location") {
          let redirectLink = `${GOOGLE_MAP_API.REDIRECT.replace(":latlng", encodeURIComponent(`${data[0][0]},${data[0][1]}`))}`;
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg eff-link px-0"}
                                 style={{fontWeight: 400, fontSize: "14px", lineHeight: "22px",
                                   background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`}}><a href={redirectLink}
                                                                                                    target="_blank"
                                                                                                    rel="noreferrer">{data[0][2]}</a></CTableDataCell>

        } else if (data[1] === "bold string") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"} style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
          }}>{data[0]}</CTableDataCell>

        } else if (data[1] === "vertical string") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"}
                                 style={{fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                                   background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`}}>
            <div className={"CathaySansRg px-0 d-flex"}
                 style={{fontWeight: 500, fontSize: "14px", lineHeight: "18px",}}>{data[0][0]}</div>
            <div className={"CathaySansRg px-0 d-flex"}
                 style={{fontWeight: 400, fontSize: "14px", lineHeight: "18px",}}>{data[0][1]}</div>
          </CTableDataCell>
        } else if (data[1] === "unit string") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"}
                                 style={{fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                                   background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`}}>
            <div className={"CathaySansBd px-0 d-flex"}
                 style={{fontWeight: 500, fontSize: "14px", lineHeight: "18px",}}>{data[0][0]}</div>
            <div className={"CathaySansRg px-0 d-flex"}
                 style={{fontWeight: 400, fontSize: "14px", lineHeight: "18px",}}>{data[0][1]}</div>
          </CTableDataCell>
        } else if (data[1] === "clickable") {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={`CathaySansRg px-0 eff-link`}
                                 style={{fontWeight: 500, fontSize: "14px", lineHeight: "18px",
                                   background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`}}>
            <button className={"download"} onClick={() => clickableFunc && clickableFunc(data[0])}>{data[0]}</button>
          </CTableDataCell>

        } else if (data[1] === "object") {
          let remarks = data[0];
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"}
                                 style={{fontWeight: 400, fontSize: "14px", lineHeight: "18px",
                                   background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`}}>
            <div className={"d-flex"}>
              {!!remarks?.close?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.close.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.close.value}</span>z
              </div>}
              {!!remarks?.time1?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.time1.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.time1.value}</span>z
              </div>}
              {!!remarks?.time2?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.time2.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.time2.value}</span>z
              </div>}
              {!!remarks?.reRouteToPos?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.reRouteToPos.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.reRouteToPos.value}</span>
                <span>, through</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks?.reRouteToPos?.note || "--"}</span>
              </div>}
              {!!remarks?.directToPos?.value && <div style={{marginRight: "16px"}}>
                <span>{remarks.directToPos.label}</span><span className={"RobotoMonoRg"} style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
              }}>{remarks.directToPos.value}</span>
              </div>}
              {!!remarks?.isCallATC?.value && <div style={{marginRight: "16px"}}>
                <span className={"RobotoMonoRg"} style={{fontWeight: 500, fontSize: "14px", lineHeight: "20px"}}></span>
                <span className={"CathaySansRg"}
                      style={{fontWeight: 500, fontSize: "14px", lineHeight: "20px"}}>{remarks.isCallATC.label}</span>
              </div>}
              {!!remarks?.isFuelCheck?.value && <div style={{marginRight: "16px"}}>
                <span className={"RobotoMonoRg"} style={{fontWeight: 500, fontSize: "14px", lineHeight: "20px"}}></span>
                <span className={"CathaySansRg"}
                      style={{fontWeight: 500, fontSize: "14px", lineHeight: "20px"}}>{remarks.isFuelCheck.label}</span>
              </div>}
            </div>
          </CTableDataCell>
        } else if (data[1] === "roboMonoRg") {
          return <CTableDataCell align="top" key={`flightMessageAndProcessHistory${index}`}
                                 className={"RobotoMonoRg px-0"} style={{
            paddingTop: "10px",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000000",
            background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
          }}>
            <div className={"paddingch4ff7"}>
              {data[0] || "--"}
            </div>
          </CTableDataCell>
        } else {
          return <CTableDataCell align="middle" key={`flightMessageAndProcessHistory${index}`}
                                 className={"CathaySansRg px-0"} style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000000",
            padding: noPadding ? "0px" : "auto",
            background: `${isFlightStatusPanel ? "#E7F1F5" : "#FFFFFF"}`
          }}>{data[0]}</CTableDataCell>
        }
      })}
      {hasArrow && <CTableDataCell style={{position: "relative"}}>
        <RightArrowIcon style={{position: "absolute", right: "0px", top: "25%", bottom: "25%"}} width={"24px"}
                        height={"24px"}/>
      </CTableDataCell>}
    </CTableRow>

}

export default DetailTableRow;
