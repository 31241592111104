const COLORS = {
    // common
    mediumJade: '#387E79',
    cathayJade: '#005D63',
    brightJade: '#027400',
    paleJade: '#E5EDEC',
    ldYellow: '#D7B80C',
    darkshadeYellow: '#988100',
    lightJade: '#A8C6B7',
    ultraLightJade: '#E6Efef',
    lightShadeGray: '#C4C4C4',
    veryLightShadeGray: '#EDEDED',
    grayDark: '#4C4C4C',
    grayNormal: '#808285',
    grayLight: '#D7D7D7',
    ultraLightGray: '#F0F0F0',
    grayWhite: '#Bcbec0',
    cyanMediumLight: '#AFCBC9',
    cyanBlueLight: '#D6D8D9',
    paleBlue: '#CAE3F5',
    cyanLight: '#D7E5E4',
    shadowGray: '#979797',
    brightRed: '#F43131',
    white: '#FFFFFF',
    backgroundWhite: '#F9F9F9',
    buttonBgWhite: '#E9Eaec',
    borderGray: '#C9Cbcc',
    warningYellow: '#CBAC00',
    hoverGreen: '#116F9A',
    buttonBlue: '#408Bae',
    buttonDarken20: '#005cbf',
    buttonDarken40: '#0d587b',
    buttonLighten20: '#e7f0f5',
    buttonLighten40: '#cfe2ea',
    unselectable: '#4e555b',
    unselectableLighten20: '#c1c1c1',
    warning: '#Fed700',
    backgroundGray: '#E6E7E8',
    errorRed: '#FF1C1C',
    error: '#D11F00',
    secondError: '#AD9306',
    ganttSidePanelTableHeader: '#Ebf2F0',
    ganttSidePanelTableHeaderHover: '#Ccdedf',
    twoColorTable1Dark: '#E5E6E6',
    twoColorTable2Dark: '#Ededed',
    ganttOverlappingWithMaintenance: '#E57F07',
    flightBarPax: '#EFFFFD',
    flightBarPaxIcon: '#51CFC4',
    freightBar: '#EDFAFF',
    freightBarIcon: '#34CCFF',
    otherBar: '#EEF4FF',
    otherBarIcon: '#84A6FD',
    mtceBar: '#FFFCED',
    mtceBarIcon: '#FCDD3E',
    summaryBg: '#F3F2EF',
    summaryBgYellow: '#F9F7EC',
    summaryBgRed: '#FAE9E5',
    summaryBorder: '#DAD2BC',
    summaryShades60: '#877A5C',
    transparent: 'transparent',
    borderGold: '#DAD2BC',
    borderYellow: '#AD9306',
    borderRed: '#D11F00',
    oxleyLighten: '#6FA088',
    lavenderLighten: '#FBF8E7',
    brightGray: '#E5EFEF',
    backgroundJade: '#E6EFEF',
    selectedCardBorder: '#505050',
    tableBorder: '#D7D7D7',
    chatQuoteBackground: '#E5EDEC80',
    itemHighlight: '#FFFDF4',
    chipJade: '#EBF2F1',
    black: '#000000',
    gbGray: '#F2F2F3',

    // banner
    mainWarning: '#988100',
    secondWarning: '#FEFBE6',

    // text
    textDark: '#2D2D2D',
    textLightGray: '#4D4D4D',

    // shadow
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.36)',

    // linkText
    hoverText: '#0D587B',
};

export default COLORS;
