import * as React from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell } from '@coreui/react';

const SnnRulesTableItem = ({ airline, flightNo, aircraftType, dep, arr, altn, era, registration, snn, waypoint, countryICAOCode, createBy, startAt, endAt, timeRange, createAt }) => {
  return <CTableRow>
    <CTableDataCell className={"RobotoMonoRg"}>{airline || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{flightNo || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{aircraftType || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{registration || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{dep || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{arr || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{altn || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{era || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(startAt).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{moment.utc(endAt).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{timeRange || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{waypoint || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{countryICAOCode || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg"}>{createBy || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg formattedText"}>{snn || "-"}</CTableDataCell>
  </CTableRow>
};

export default SnnRulesTableItem;
