import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CRow, CCol } from '@coreui/react-pro';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CXButton } from '../../components/CXForm';
import { postNewSimPlanUpdate } from '../../services/simAPI';
import { clearSimOFPForm, selectSim, setSimOFPFormInEdit, updateSimFlightPlans } from '../../slice/simSlice';
import SimLoadsheetBlock from '../../components/Sim/SimLoadsheetBlock';
import { MILESTONE_TYPES } from '../../constants/simConstant';
import { SIM_PATHS } from '../../constants/path';
import DetailSimTraineeInfoListItemComponent from '../../components/Sim/DetailSimTraineeDetailInfoListItemComponent';
import AddOrEditOFPLightBox from '../../components/Sim/AddOrEditOFPLightBox';
import { selectApp } from '../../slice/appSlice';

const ReleaseSimOFPPage = () => {
  const dispatch = useAppDispatch();
  const params = useParams()
  const ufi: string = params.ufi;
  const { isEditSimOFPLightBoxVisible } = useAppSelector(selectApp);
  const { simFlightPlans, simOFPFormInEdit } = useAppSelector(selectSim);
  const simOFPDetail = Object.values(simFlightPlans)?.find(simFLightPlan => simFLightPlan.ufi === ufi);
  
  useEffect(() => {
    dispatch(clearSimOFPForm());
    dispatch(setSimOFPFormInEdit({ ...simOFPDetail }));
  }, [ufi, simFlightPlans, simOFPDetail, dispatch]);

  let released: boolean = simOFPFormInEdit?.status !== "created";

  const releaseSimOFP = () => {
    if (!!simOFPFormInEdit) {
      let newSimOFPForm = {
        ...simOFPFormInEdit,
        status: "released"
      };
      postNewSimPlanUpdate(newSimOFPForm).then((res) => {
        dispatch(updateSimFlightPlans(res));
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  // 6/7/2022 The edit function for Sim OFP is commented on the frontend and backend because of the lack of resource 
  // const postSubmitAction = (res) => {
  //   console.log(res);
  //   dispatch(updateSimFlightPlans(res));
  //   dispatch(setAppFlags({ field: "isEditSimOFPLightBoxVisible", value: false}));
  //   dispatch(pushToastMessage("SIM OFP updated"));
  // };

  // const EditButton = () => {
  //   return <CXButton 
  //     onClick={() => {
  //       dispatch(setAppFlags({ field: "isEditSimOFPLightBoxVisible", value: !isEditSimOFPLightBoxVisible}));
  //     }} 
  //     disabled={released}
  //     text={"Edit SIM OFP"} 
  //     outline={true} 
  //     fontSize={"14px"} />
  // };

  return (
    <>
      <div className={"breadcrumbs"}>
        <Link to={`${SIM_PATHS.landing}`} style={{ margin: "4px" }}> SIM OFP list</Link> / <span style={{ margin: "4px" }}>Release SIM OFP </span>
      </div>

      <div className={"d-flex justify-content-between"} style={{ margin: "15px 0px" }}>
        <div className={"CathaySansBd"} style={{ fontWeight: 700, fontSize: "32px", lineHeight: "36px" }}>{ufi}</div>
        {/* 6/7/2022 The edit function for Sim OFP is commented on the frontend and backend because of the lack of resource 
        <AddOrEditOFPLightBox TriggerButton={<EditButton />} postAction={postSubmitAction} isEdit={true} heading={`Edit ${ufi}`} submitButtonText={"Update"}/> 
        */}
      </div>

      <div className={"simOFPDetailBody"} style={{ margin: "17px 0px", padding: "16px" }}>
        <CRow>
          <CCol xl={2} xs={4}>
            <div className={"simOFPDetailBodyHeader CathaySansRg"}>
              Aircraft type
            </div>
            <div className={"simOFPDetailBodyContent RobotoMonoBd"}>
              {simOFPFormInEdit?.aircraftType || "--"}
            </div>
          </CCol>
          <CCol xl={2} xs={4}>
            <div className={"simOFPDetailBodyHeader CathaySansRg"}>
              Flight number
            </div>
            <div className={"simOFPDetailBodyContent RobotoMonoBd"}>
              {simOFPFormInEdit?.flightNum || "--"}
            </div>
          </CCol>
          <CCol xl={2} xs={4}>
            <div className={"simOFPDetailBodyHeader CathaySansRg"}>
              SIM code
            </div>
            <div className={"simOFPDetailBodyContent RobotoMonoBd"}>
              {simOFPFormInEdit?.simCode || "--"}
            </div>
          </CCol>
          <CCol xl={4} xs={8}>
            <div className={"simOFPDetailBodyHeader CathaySansRg"} style={{ marginTop: "17px" }}>
              STD
            </div>
            <div className={"simOFPDetailBodyContent RobotoMonoBd"}>
              {simOFPFormInEdit?.std || "--"}
            </div>
          </CCol>
          <CCol xl={2} xs={4} className={"text-end"} style={{ marginTop: "17px" }}>
            <CXButton disabled={released} outline={false} text={"Release"} onClick={() => releaseSimOFP()} fontSize="14px" width={"180px"} />
          </CCol>
        </CRow>
        <CRow>
          <CCol style={{ margin: "8px 0px" }}>
            <div className={"simOFPDetailBodyHeader CathaySansRg"}>
              Code
            </div>
            {simOFPFormInEdit?.crewlist.map((crew, index) => {
              if (index > 0) {
                return <div key={`DetailSimTraineeInfoListItemComponentBlock${index}`}>
                  <hr className={"m-0"} style={{ border: "1px solid #D8D8D8", color: "#D8D8D8" }} />
                  <DetailSimTraineeInfoListItemComponent crew={crew} index={index} />
                </div>;
              }
              return <DetailSimTraineeInfoListItemComponent key={`DetailSimTraineeInfoListItemComponentBlock${index}`} crew={crew} index={index} />;
            })}
          </CCol>
        </CRow>
        <CRow>
          <CCol style={{ margin: "4px 0px" }}>
            <div>
              Created {!!simOFPFormInEdit?.createTime ? `${moment.utc(simOFPFormInEdit?.createTime).format("DDMMMYYYY HH:mm")}Z` : "--"} by {simOFPFormInEdit?.createdBy || "--"}
            </div>
          </CCol>
        </CRow>
      </div>

      {released && <div style={{ margin: "41px 0px", padding: "0px" }}>
        <SimLoadsheetBlock mileStoneType={MILESTONE_TYPES.release} datetime={moment().toISOString()}/>
        <SimLoadsheetBlock mileStoneType={MILESTONE_TYPES.loadsheet}  />
        <SimLoadsheetBlock mileStoneType={MILESTONE_TYPES.refuelingInProgress} />
        <SimLoadsheetBlock mileStoneType={MILESTONE_TYPES.refuelingReceipt} last={true} />
      </div>}
    </>
  );
};

export default ReleaseSimOFPPage;