import { PAGE_TITLE_MAPPING } from "../constants/appConstant";
import { AccessType, ROLE_ACCESS_MAPPING, routesExcludeFromRoleAccessMapping } from "../constants/roleAccessConstant";
import { ISideBarObject } from "../types/app";

// routeIn: the route that user is trying to access, would be "/sim-page", "/release-sim-ofp/:ufi"
function _getMatchingRoleAccessMappingItem(routeIn: string) {
    const colonChart = ":";
    const slashChart = "/";
    const roleAccessMappingItem = ROLE_ACCESS_MAPPING.find((roleAccess) => {
        const routesInConfigHasColon = roleAccess.routes.some(route => route.includes(colonChart));
        if (!routesInConfigHasColon)
            return roleAccess.routes.includes(routeIn);
        else {
            /*
                eg： SIM - SIM OFP -> config routes are [SIM_PATHS.landing, SIM_PATHS.releaseSimOFP], equal to ["/sim-page", "/release-sim-ofp/:ufi"]
                some routes include ":" in ROLE_ACCESS_MAPPING config, but the routeIn does not the one include ":" , like we access route SIM_PATHS.landing "/sim-page"
                some routes include ":" in ROLE_ACCESS_MAPPING config, and the routeIn map in config does include ":", like we access route SIM_PATHS.releaseSimOFP "/release-sim-ofp/:ufi", but for routeIn would be "/release-sim-ofp/123456"
            */
            if (roleAccess.routes.includes(routeIn))
                return true;
            else {
                /*
                    first find out the items that include ":" from config roleAccess.routes, output items like "/release-sim-ofp/:ufi", than get string from left side to the last "/" like "/release-sim-ofp"
                    then check if the routeIn "/release-sim-ofp/123456" includes that string "/release-sim-ofp"
                */
                const matchingRoutes = roleAccess.routes.filter(route => route.includes(colonChart));
                const matchingStrings = matchingRoutes.map(route => route.substring(0, route.lastIndexOf(slashChart)));
                return matchingStrings.some(matchingString => routeIn.includes(matchingString));
            }
        }
    });
    return roleAccessMappingItem;
}

// check if user has access to the route, even if the route includes parameters
// for example, if the user has access to "/release-sim-ofp", then he should have access to "/release-sim-ofp/:ufi" as well
const checkIfHasAccess = (routeIn: string, currentUserGroupIDs: string[], accessArrIn?: AccessType[]): boolean => {
    if (routesExcludeFromRoleAccessMapping.includes(routeIn))
        return true;

    const roleAccessMappingItem = _getMatchingRoleAccessMappingItem(routeIn);
    let hasAccess = false;
    if (accessArrIn) {
        // for button access checking, like edit, delete, etc.
        hasAccess = currentUserGroupIDs && currentUserGroupIDs.some(userGroupID => {
            const roleAccessItem = roleAccessMappingItem && roleAccessMappingItem.roleAccessList.find((roleAccess) => roleAccess.userGroupID === userGroupID);
            return accessArrIn.some(access => roleAccessItem && roleAccessItem.access.includes(access));
        });
    } else {
        // for side bar menu and route access checking
        hasAccess = currentUserGroupIDs && currentUserGroupIDs.some(userGroupID => {
            const roleAccessItem = roleAccessMappingItem && roleAccessMappingItem.roleAccessList.find((roleAccess) => roleAccess.userGroupID === userGroupID);
            return roleAccessItem && roleAccessItem.access && roleAccessItem.access.length > 0; // if access array is not empty, then it means user has access
        });
    }

    return hasAccess;
}

const getAccessibleNavData = (inputNavData: Array<ISideBarObject>, userGroupIDs: string[]) => {
    const filteredNavData = inputNavData && inputNavData.filter(navData => {
        const isRouteExcluded = routesExcludeFromRoleAccessMapping.includes(navData.link);
        return isRouteExcluded ? true : checkIfHasAccess(navData.link, userGroupIDs);
    });
    return filteredNavData;
}

// to check if the user has access to the route and have some special roles
function checkIfHasSpecialRoles(route: string, currentUserGroupIDs: string[], requiredRoles: string[]): boolean {
    // check if the user has access to the route and have some special role
    let hasSpecialRoleAccess = false;
    const roleAccessMappingItem = _getMatchingRoleAccessMappingItem(route);
    hasSpecialRoleAccess = requiredRoles && requiredRoles.some(requiredRole => {
        const currentUserHasMatchedRole = currentUserGroupIDs.includes(requiredRole);
        const requiredRoleFoundInConfig = roleAccessMappingItem && roleAccessMappingItem.roleAccessList.find((roleAccess) => roleAccess.userGroupID === requiredRole);
        return currentUserHasMatchedRole && requiredRoleFoundInConfig;
    });
    return hasSpecialRoleAccess;
}

// get page title
const getPageTitle = (route: string): string => {
    const pageTitle = PAGE_TITLE_MAPPING[route];
    return pageTitle || 'Welcome!';
}

export { checkIfHasAccess, getAccessibleNavData, checkIfHasSpecialRoles, getPageTitle }
