import React, {useEffect, useState} from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody } from '@coreui/react';
import { DownArrowIcon } from '../../assets/images';
import { IAppendixFile, setAppendixFiles } from '../../slice/simSlice';
import { useAppDispatch } from '../../app/hooks';
import NoDataTableItem from '../AdminDashboard/NoDataTableItem';
import AppendixRulesFileItem from './AppendixRulesFileItem';
import { sortAutoItems } from '../../utils/sortHelper';

interface IAppendixFilesTableProps {
  appendixFiles: IAppendixFile[];
  dispatchReducer: React.Dispatch<any>;
  type: string;
  deleteFileRefresh: Function; 
}


const AppendixFilesTable = ({ appendixFiles, dispatchReducer, type, deleteFileRefresh }: IAppendixFilesTableProps) => {
  const [fileList, setFileList] = useState<any[]>([]);

  const sortByLocale = (inputList: any[], key: string, order?:"asc" | "desc") => {
    const { sortedList } = sortAutoItems(inputList, key, order);
    setFileList(sortedList);
    }

  useEffect(()=>{
    sortByLocale(appendixFiles, 'uploadDate')
  },[appendixFiles])

  return <CTable borderless hover>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col" className={"clickable"} onClick={() => {
            sortByLocale(fileList, 'pdfName');
        }}>
          File name
        <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"clickable"} onClick={() => {sortByLocale(fileList, 'uploadDate')}}>
          Upload date
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable"} style={{ fontSize: "16px", lineHeight: "22px", letterSpacing: "0.3px" }}>
          Action
        </CTableHeaderCell>
      </CTableRow>
    </CTableHead>
    <CTableBody>
        {!!fileList && fileList.length !==0 ? 
            fileList.map((appendixFile, index) => {
              return <AppendixRulesFileItem 
                key={`AppendixFilesTableItem${index}`} 
                appendixFile={appendixFile} 
                dispatchReducer ={dispatchReducer}
                type={type}
                deleteFileRefresh={deleteFileRefresh}/>
            }) 
    
            : 
            (
              <NoDataTableItem colSpan={11} message={"There is no Appendix Files"} />
            ) 
        }
    </CTableBody>
  </CTable>
};

export default AppendixFilesTable;