import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { CXButton, CXDatePicker } from '../../components/CXForm';
import SimOFPTable from '../../components/Sim/SimOFPTable';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearSimOFPForm, selectSim, setSimFlightPlans, setSimFlightPlanTemplates } from '../../slice/simSlice';
import { getSimPlans, getSimPlanTemplates } from '../../services/simAPI';
import { pushToast, selectApp, setAppFlags } from '../../slice/appSlice';
import AddOrEditOFPLightBox from '../../components/Sim/AddOrEditOFPLightBox';
import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';
import { useLocation } from 'react-router-dom';



const SimLandingPage = () => {
  const { pathname: routePath } = useLocation();
  const dispatch = useAppDispatch();
  const { simFlightPlans } = useAppSelector(selectSim);
  const { isAddNewSimOFPLightBoxVisible } = useAppSelector(selectApp);
  const [displayDate, setDisplayDate] = useState(moment().startOf('day').add(8, "hours").toISOString());

  const search = useCallback(() => {
    getSimPlans(moment(displayDate).format("YYYY-MM-DD")).then((res) => {
      dispatch(setSimFlightPlans(Object.values(res || {})));
    }).catch((err) => {
      console.log(err);
    });
    getSimPlanTemplates().then((res) => {
      dispatch(setSimFlightPlanTemplates(Object.values(res || {})));
    }).catch((err) => {
      console.log(err);
    });
  },[displayDate, dispatch]);

  useEffect(() => {
    search();
  }, []);

  

  const postSubmitAction = () => {
    dispatch(setAppFlags({ field: "isAddNewSimOFPLightBoxVisible", value: false}));
    dispatch(clearSimOFPForm());
    dispatch(pushToast({type: "success", message: "New SIM OFP added"}));
    search();
  };

  const AddNewSimOFPButton = () => {
    return (
        <CXButton 
          onClick={() => {
            dispatch(clearSimOFPForm());
            dispatch(setAppFlags({ field: "isAddNewSimOFPLightBoxVisible", value: !isAddNewSimOFPLightBoxVisible}));
          }} 
          text={"Add New SIM OFP"}
          outline={true}  />
    )
  };

  return (
    <>
      <div className={"CathaySansBd"} style={{ fontSize: "2rem", lineHeight: "2.25px" }}>Sim OFP</div>
      <div className={"section d-flex justify-content-between"} >
        <div className={"p-2 ps-0"}>
          <CXDatePicker placeholder={"STD"} value={displayDate} onChange={setDisplayDate} zIndex={1029} />
        </div>
        <div className={"p-2"}>
          <CXButton outline={false} text={"Search"} onClick={() => search()} />
        </div>
        <div className={"p-2 ms-auto"}>
        <Access route={routePath} access={[AccessType.CREATE]}>
            <AddOrEditOFPLightBox TriggerButton={AddNewSimOFPButton()} postAction={postSubmitAction} heading={"Add New SIM OFP"} submitButtonText={"Create SIM OFP"}/>
        </Access>
        </div>
      </div>
      <div className={"section"}>
        <SimOFPTable routePath={routePath} simOFPs={simFlightPlans || []} />
      </div>

    </>
  );
};

export default SimLandingPage;