import { DISPATCHER_PATHS, FLIGHT_PLAN_CONFIG_PATHS, DATA_CONFIGURATION_PATHS, CLOSE_FLIGHT_PATHS, FLIGHT_POLICY_PATHS, SIM_PATHS, UO_PATHS, Internal_Support_PATHS } from "./path";

// page title mapping
const PAGE_TITLE_MAPPING = {
    [DISPATCHER_PATHS.flightPlan]: 'EFF Dashboard (Beta)',
    [DISPATCHER_PATHS.status]: 'Flight Status',
    [DISPATCHER_PATHS.flightDetail]: 'Flight Detail',
    [FLIGHT_POLICY_PATHS.fuelPolicy]: 'Fuel policies',
    [FLIGHT_POLICY_PATHS.noFlyZonesPolicy]: 'No fly zones',
    [FLIGHT_POLICY_PATHS.tankeringPolicy]: 'Tankering policies',
    [FLIGHT_POLICY_PATHS.volcanicAshesPolicy]: 'Volcanic ash',
    [FLIGHT_PLAN_CONFIG_PATHS.appendix]: 'Appendix Rules',
    [FLIGHT_PLAN_CONFIG_PATHS.snn]: 'SNN',
    [FLIGHT_PLAN_CONFIG_PATHS.aircraft]: 'Aircraft Information',
    [FLIGHT_PLAN_CONFIG_PATHS.portPageNotam]: 'Port Page',
    [FLIGHT_PLAN_CONFIG_PATHS.defects]: 'Defects',
    [FLIGHT_PLAN_CONFIG_PATHS.nsuNotam]: 'NSU Notam',
    [FLIGHT_PLAN_CONFIG_PATHS.oeNotam]: 'OE Notam',
    [FLIGHT_PLAN_CONFIG_PATHS.feedback]: 'Crew Feedback',
    [FLIGHT_PLAN_CONFIG_PATHS.sidStar]: 'SID/STAR data',
    [DATA_CONFIGURATION_PATHS.carReportTopic]: 'Car Report Topic',
    [DATA_CONFIGURATION_PATHS.carReportEmail]: 'Car Report Email',
    [CLOSE_FLIGHT_PATHS.closeFlight]: 'Closed Flight',
    [CLOSE_FLIGHT_PATHS.closeFlightDetail]: 'Closed Flight Detail',
    [SIM_PATHS.landing]: 'Sim OFP',
    [SIM_PATHS.simTemplateDetail]: 'Sim Template Detail',
    [SIM_PATHS.simTemplateConfiguration]: 'Sim Template Configuration',
    [UO_PATHS.flightPlan]: 'HKE Dashboard',
    [UO_PATHS.closeFlight]: 'Closed Flight',
    [UO_PATHS.closeFlightDetail]: 'Closed Flight Detail',
    [FLIGHT_PLAN_CONFIG_PATHS.hkeSnn]: 'HKE SNN',
    [UO_PATHS.fuelAdv]: 'Fuel Advisory',
    [UO_PATHS.defect]: 'Defects',
    [UO_PATHS.crewlist]: 'Crewlist',
    [UO_PATHS.nsuNotam]: 'NSU Notam',
    [UO_PATHS.oeNotam]: 'OE Notam',
    [UO_PATHS.appendixRule]: 'HKE Appendix Rules',
    [Internal_Support_PATHS.dataCfCcs]: 'Data-cf_ccs',
    [DATA_CONFIGURATION_PATHS.uoCarReportTopic]: 'CAR Report Topic',
    [DATA_CONFIGURATION_PATHS.uoCarReportEmail]: 'CAR Report Email',
    [DATA_CONFIGURATION_PATHS.uoSidStar]: 'SID/STAR data',
};

export {
    PAGE_TITLE_MAPPING
}