import React from 'react';
import { CXButton } from '../../CXForm';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { addUnsavedLoadsheet, selectSim } from '../../../slice/simSlice';
import LoadsheetTemplate from './LoadsheetTemplate';

const Loadsheet = () => {
  const dispatch = useAppDispatch();
  const { simOFPFormInEdit } = useAppSelector(selectSim);

  const addLoadsheetToUnsavedLoadsheet = () => {
    let defaultLoadsheet = simOFPFormInEdit.additionalInfo.loadSheetTemplate[0];
    if(!!defaultLoadsheet) {
      dispatch(addUnsavedLoadsheet({ loadsheet: defaultLoadsheet }));
    }
  };

  return <>

    {/* Saved loadsheet */}
    {simOFPFormInEdit?.additionalInfo?.loadSheet?.map((loadsheet, index) => <LoadsheetTemplate key={`LoadsheetSavedLoadsheet${index}`} index={index} loadsheet={loadsheet} locked={true}/>)}

    {/* Unsaved loadsheet */}
    {simOFPFormInEdit?.additionalInfo?.unsavedLoadsheet?.map((loadsheet, index) => <LoadsheetTemplate key={`LoadsheetTemplate${index}`} index={index} loadsheet={loadsheet} />)}
    
    <div style={{ padding: "40px 0px" }}>
      <CXButton text={"Add loadsheet"} onClick={() => addLoadsheetToUnsavedLoadsheet()} fontSize={"14px"} outline={true} textColor={"#FFFFFF"} backgroundColor={"#116F9A"} borderRadius={"8px"} height={"48px"} />
    </div>
  </>
};

export default Loadsheet;
