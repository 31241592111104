import * as React from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell } from '@coreui/react';
import { Link } from 'react-router-dom';
import './FlightPlanTable.scss';

import { AHKSmallIcon, CXSmallIcon, HKESmallIcon, RightArrowIcon } from '../../assets/images';
import { DISPATCHER_PATHS, UO_PATHS } from '../../constants/path';

enum AirLine {
  CX = "CPA",
  AHK = "AHK",
  HKE = "HKE",
}

const FlightPlanTableItem = ({ flightid, std, serial, reg, ufi, created, isDraftMZFW, isStop, missingAdj, hasError, aircraftChanged, isAOS, fileName, user }) => {

  const ufiChecker = () => {
    if(user === "CX" || user === "LD") {
      return `${DISPATCHER_PATHS.flightDetail}?ufi=${encodeURIComponent(ufi)}`;
    }
    // else if(user === "UO") {
    //   return `${UO_PATHS.flightDetail}?ufi=${encodeURIComponent(ufi)}`;
    // }
  };

  return <CTableRow className={`border-bottom ${hasError ? "hasErrorStyle" : ""}`} >
    <CTableDataCell className={"RobotoMonoBd tableItem"} style={{ fontWeight: 'bold', position: 'relative', paddingLeft: "20px" }}>
      {flightid && flightid.includes(AirLine.CX) && <CXSmallIcon style={{ position: 'absolute', left: '0px', top: '14px' }} height={16} width={16} />}
      {flightid && flightid.includes(AirLine.AHK) && <AHKSmallIcon style={{ position: 'absolute', left: '0px', top: '14px' }} height={16} width={16} />}
      {flightid && flightid.includes(AirLine.HKE) && <HKESmallIcon style={{ position: 'absolute', left: '0px', top: '14px' }} height={16} width={16} />}
      {flightid || "-"}
    </CTableDataCell>
    <CTableDataCell className={"RobotoMonoBd tableItem px-0"} style={{ fontWeight: 'bold' }}>{serial || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem px-0"}>{reg || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem px-0"}>{!hasError ? ( <Link to={`${ufiChecker()}`}> {ufi || "-"} </Link>) : (<div >{fileName}</div>)}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem px-0"}>{moment.utc(created).format("DDMMMYYYY HH:mm:ss") || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem px-0"}>{(!!std) ? moment.utc(std).format("DDMMMYYYY HH:mm:ss") : "-"}</CTableDataCell>
    <CTableDataCell className={"CathaySansMd tableItem px-0"} style={{ fontSize: "12px", lineHeight: "22px", display: "flex" }}>
      <button
        style={{
          display: (isDraftMZFW) ? "block" : "none",
          color: "white",
          backgroundColor: "#1b3668",
        }}
        className='tag'>MZFW</button>
      <button
        style={{
          display: (isStop) ? "block" : "none",
          color: "white",
          backgroundColor: "#1b3668",
        }}
        className='tag'
      >Stop</button>
      <button
        style={{
          display: (missingAdj) ? "block" : "none",
          color: "white",
          backgroundColor: "#116f9a",
        }}
        className='tag'
      >Missing ADJ</button>
      <button
        style={{
          display: (aircraftChanged) ? "block" : "none",
          color: "white",
          backgroundColor: "#6f9eb3",
        }}
        className='tag'
      >Changed</button>
      <button
        style={{
          display: (isAOS) ? "block" : "none",
          color: "white",
          backgroundColor: "#669da1",
        }}
        className='tag'
      >A.O.S.</button>
      <button
        style={{
          display: (hasError) ? "block" : "none",
          color: "white",
          backgroundColor: "#da4b33",
        }}
        className='tag'
      >Error</button>
    </CTableDataCell>
    <CTableDataCell style={{ position: "relative" }}>
      <RightArrowIcon style={{ position: "absolute", right: "0px", top: "25%", bottom: "25%" }} width={"24px"} height={"24px"} />
    </CTableDataCell>

  </CTableRow>
};

export default FlightPlanTableItem;
