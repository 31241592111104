import React, { useEffect, useState, useCallback } from 'react';
import { getResource } from '../../services/api';
import { getCommResource } from '../../services/commAPI';
import { resetFlightPlans } from '../../slice/dispatcherSlice';

interface IFixedImageProps {
  user: string;
  type: string;
  fileName: string;
  upliftType: string;
}

const FixedImage = ({ user, type, fileName, upliftType }: IFixedImageProps) => {

  const [img, setImg] = useState("");

  const resourceLoader = useCallback(async () => {
    let resp: Blob;
    resp = await getResource(user, type, fileName);

    // CX old closeFlight does not have ufi in the image url
    if (!resp.size) {
      const fileName2 = fileName.replace(/^.*\//, '');
      resp = await getResource(user, type, fileName2);
    }
    const blobImage = new Blob([resp],  {type: "image/jpeg"});
    const imgURL = URL.createObjectURL(blobImage);
    setImg(imgURL);
  }, [user, type, fileName, upliftType]);

  const openImageInNewWindowHandler = useCallback(async () => {
    if(img){
      let alink = document.createElement('a');
      alink.href = img;
      alink.target = "_blank";
      alink.click();
    }
  }, [img]);

  useEffect(() => {
    resourceLoader();
  }, [resourceLoader]);

  return <img src={`${img}`} className={"clickable"} alt={`opsFormPhoto`}
    onClick={openImageInNewWindowHandler} 
    style={{
      width: "166px",
      height: "166px",
    }} />
};

export default FixedImage;