import React from 'react';
import moment from 'moment';
import { CCol, CRow } from '@coreui/react';
import { EllipseIcon } from '../../../assets/images';
import RefuelingReceipt from './RefuelingReceipt';
import { MILESTONE_TYPES } from '../../../constants/simConstant';
import Loadsheet from './Loadsheet';
import RefuelingInProgress from './RefuelingInProgress';

interface ISimLoadsheetBlockProps {
  mileStoneType: string;
  datetime?: string;
  last?: boolean;
}

const SimLoadsheetBlock = ({ mileStoneType, datetime, last = false }: ISimLoadsheetBlockProps) => {
  return <CRow>
    <CCol xs={2}>
      <div>
        <span className='RobotoMonoRg'>{!!datetime ? moment(datetime).format("DDMMMYYYY").toUpperCase() : "--"}</span>
        {" "}
        <span className='RobotoMonoBd'>{!!datetime ? `${moment(datetime).format("HH:mm")}z` : "--"}</span>
      </div>
    </CCol>
    <CCol xs={10} style={{ borderLeft: last ? "0px" : "1px solid #D8D8D8", paddingBottom: "40px", paddingLeft: "16px", position: "relative" }}>
      <div className='CathaySansBd' style={{ fontSize: "18px", lineHeight: "22px", }}>
        {mileStoneType === MILESTONE_TYPES.release && "Release SIM OFP"}
        {mileStoneType === MILESTONE_TYPES.loadsheet && "Loadsheet"}
        {mileStoneType === MILESTONE_TYPES.refuelingInProgress && "Start Refueling"}
        {mileStoneType === MILESTONE_TYPES.refuelingReceipt && "Refuel Receipt"}
      </div>


      <div style={{ position: "absolute", left: "-6px", top: "-2.5px" }}>
        <EllipseIcon height={12} width={12} />
      </div>
      {last && <div style={{ position: "absolute", left: "0px", top: "0px", width: "0px", height: "5px", borderLeft: "1px solid #D8D8D8" }}></div>}

      {mileStoneType === MILESTONE_TYPES.loadsheet && <Loadsheet />}
      {mileStoneType === MILESTONE_TYPES.refuelingInProgress && <RefuelingInProgress />}
      {mileStoneType === MILESTONE_TYPES.refuelingReceipt && <RefuelingReceipt />}

    </CCol>
  </CRow>
};

export default SimLoadsheetBlock;