import * as React from 'react';
import { CCol, CRow } from '@coreui/react';

import { CloseIcon } from '../../assets/images';

interface ISimTraineeInfoListItemProps {
  galacxyID: string;
  name?: string;
  email?: string;
  dutyCode: string;
  onChange: Function;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const SimTraineeInfoListItem = ({ galacxyID, name, email, dutyCode, onChange, onClick }: ISimTraineeInfoListItemProps) => {
  return <CRow key={`${galacxyID}`}>
  <CCol xs={9} >
    {/* design 1
    <div style={{ position: "relative", height: "55.9px" }}>
      <div className={"CathaySansBd"} style={{ position: "absolute", left: "0px", top: "10px", fontWeight: 700, fontSize: "14px", lineHeight: "18px" }}>{galacxyID}</div>
      <div className={"CathaySansRg"} style={{ position: "absolute", left: "80px", top: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", textTransform: "uppercase" }}>{name}</div>
      <div className={"CathaySansRg"} style={{ position: "absolute", left: "0px", top: "28px", fontWeight: 400, fontSize: "14px", lineHeight: "18px" }}>{email}</div>
    </div> 
    */}
    <div style={{ position: "relative", height: "55.9px" }}>
      <div className={"CathaySansBd"} style={{ position: "absolute", left: "0px", top: "18.9px", fontWeight: 700, fontSize: "14px", lineHeight: "18px" }}>{galacxyID}</div>
    </div> 
  </CCol>
  <CCol xs={3} style={{ height: "55.9px", position: "relative" }}>
    <input style={{
      height: "40px",
      width: "60px",
      background: "#FFFFFF",
      border: "1px solid #4C4C4C",
      boxSizing: "border-box",
      borderRadius: "4px",
      padding: "11px 8px 10px 8px",
      margin: "8px 0px",
      color: "#2D2D2D",
    }} type={"text"} value={dutyCode} onChange={(e)=>onChange(e.target.value)} />
    <CloseIcon style={{ position: "absolute", right: "0px", bottom: "16px", cursor: "pointer" }} height={"24px"} width={"24px"} onClick={onClick}/>
  </CCol>
</CRow>
};

export default SimTraineeInfoListItem;