import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getClosedFlightDetail } from '../../services/dispatchAPI';
import { selectDispatcher, setCloseFlightDetail } from '../../slice/dispatcherSlice';
import CloseFlightDetailContent from '../../components/AdminDashboard/CloseFlightDetailContent';

/**
 * Application home page component
 * @returns {JSX}
 */

const CloseFlightDetailPage = () => {
  const params = useParams()
  const { ufi, closeFlightPackageIdentifier } = params;
  const dispatch = useAppDispatch();
  const { closeFlightDetail } = useAppSelector(selectDispatcher);

  useEffect(() => {
    getClosedFlightDetail(ufi).then(res => {
      dispatch(setCloseFlightDetail(res));
    }).catch(err => {
      console.log(err);
    });
  }, [ufi, dispatch]);

  return (
    <>
      <CloseFlightDetailContent user={"cx"} ufi={ufi} closeFlightPackageIdentifier={closeFlightPackageIdentifier} closeFlightDetail={closeFlightDetail}/>
    </>
  );
};

export default CloseFlightDetailPage;