import React from "react";
import { useAppSelector } from "../app/hooks";
import { selectAuth } from "../slice/authSlice";

interface ReactProps {
  children?: React.ReactNode;
}

const ApiContextProvider = ({ children }: ReactProps) => {
  const { isLogin } = useAppSelector(selectAuth);

  return <>{isLogin ? children : null}</>;
};

export default ApiContextProvider;