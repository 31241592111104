import React, { useEffect, useRef, useState } from 'react';
import './style/style.scss';

interface ICXTextAreaProps {
  onChange: Function;
  placeholder?: string;
  value: string;
  minRows?: number;
  maxRows?: number;
  disabled?: boolean;
  zIndex?: number;
  unit?: string;
  label?: string;
  hasLabel?: boolean;
  readOnly?: boolean;
  showAll?: boolean;
  customStyleObj?: {
    lineHeight: number,
    paddingTop: number,
    paddingBottom: number
  }
}

const CXTextArea = ({ disabled = false, zIndex, minRows = 1, maxRows = 6, onChange, placeholder, value, unit, label, hasLabel = true, readOnly = false, showAll = false, customStyleObj }: ICXTextAreaProps) => {
  const { lineHeight = 19, paddingTop = 22, paddingBottom = 7 } = customStyleObj || {};
  const [focus, setFocus] = useState(false);
  const [rows, setRows] = useState(minRows);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const containerHeight = paddingTop + paddingBottom + lineHeight * rows + 2; // default：padding-top 22px, padding-bottom 7px, border 1px

  useEffect(() => {
    const rowLength = value.split("\n").length;
    if (showAll) {
      setRows(rowLength);
    } else {
      if (rowLength > minRows) {
        if (rowLength > maxRows) {
          setRows(maxRows);
        } else {
          setRows(rowLength);
        }
      } else {
        setRows(minRows);
      }
    }
  }, [value, maxRows, minRows, showAll])
  /*
    ----------------------------- Sample component ------------------------------
    const [text1, setText1] = useState("");
    <CXTextArea value={text1} placeholder={"Flight Number"} onChange={setText1} />
    ----------------------------- Sample component ------------------------------
  */

  const toggleFocus = () => {
    if (!disabled && !readOnly) {
      setFocus(!focus);
      if (textAreaRef?.current) {
        textAreaRef.current.focus();
      }
    }
  };

  const onFocus = () => {
    if (!disabled && !readOnly) {
      setFocus(true)
    }
  }

  const onBlur = () => {
    if (!disabled && !readOnly) {
      setFocus(false)
    }
  }

  return (
      <div className={"cx-form-control-text-area"} style={{ height: containerHeight, background: disabled ? "#F2F2F3" : "transparent" }}>
      <textarea
          ref={textAreaRef}
          rows={rows}
          className={`${hasLabel ? "cx-textarea-form-input" : "cx-textarea-form-input-without-label"} ${focus ? "focused" : ""} ${disabled ? "cx-disabled" : ""}`}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
          disabled={disabled}
          readOnly={readOnly}
      />
        {!!placeholder && ((focus || !!value) ?
            <div>
              {hasLabel && <div className={"focused-label"} onClick={toggleFocus}>{label || placeholder}</div>}
              <div className={"unit"} onClick={toggleFocus}>{unit || ""}</div>
            </div>
            :
            <div className={`text-ellipsis ${disabled ? "not-focused-label-disabled" : "not-focused-label"}`} onClick={toggleFocus}>{placeholder}</div>)
        }

      </div>
  );
};

export default CXTextArea;
