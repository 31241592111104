import { AlertIcon } from '../assets/images';

const AccessForbiddenPage = ({ title }) => {
    return (
        <>
            <div>
                <div
                    className={"CathaySansBd"}
                    style={{ fontSize: "32px", lineHeight: "36px" }}
                >
                    {title}
                </div>
            </div>

            <div style={{
                fontSize: "16px",
                fontWeight: 700,
                marginTop: '30px',
            }}>
                <AlertIcon></AlertIcon>
                <span style={{ marginLeft: '8px', marginRight: '4px' }}>Access forbidden.</span>
                <span style={{ fontWeight: 400 }}> You are not authorized to access this page. Please contact administrator for access request if needed.</span>
            </div>
        </>
    );
};

export default AccessForbiddenPage;