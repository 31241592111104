import { CCol, CFormInput, CInputGroup, CInputGroupText, CRow } from "@coreui/react-pro";
import React, { useEffect, useRef, useState } from "react";
import { DeleteIcon, DragIcon } from "../../assets/images";
import { useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
import { IPortSec, IPortTab } from "../../slice/dispatcherSlice";
import { EditTab } from "./DragContainer";

const style = {
    cursor: 'move',
  }
interface IDragableInputProps {
  disabled?: boolean;
  placeholder?: string;
  value: string;
  index: number;
  isDeletable?: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onBlur: (target: string, index: number) => void;
  removeTab: (index: number, editTabs: EditTab[]) => void;
  dispatchReducer: React.Dispatch<any>;
  editTabs: EditTab[];
}

interface DragItem {
    index: number
    id: string
    type: string
  }

const DragableInput = ({
  disabled = false,
  value,
  index,
  isDeletable = false,
  moveCard,
  onBlur,
  removeTab,
  dispatchReducer,
  editTabs
}: IDragableInputProps) => {


  const [inputValue, setInputValue] = useState<string>(value || "");


  useEffect(()=> {
    setInputValue(value)
  },[value])

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'input',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      console.log(dragIndex, hoverIndex ,hoverClientY, hoverMiddleY)
    //   if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    //     console.log("cannot move down")
    //     return
    //   }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    drop(item: DragItem, monitor){
      dispatchReducer({type:"RE_ORDER_TABS", tabs: editTabs});
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: "input",
    item: () => {
      return { id: value, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <CRow ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
        <CCol sm={4}>
        <CInputGroup className="mb-3 port-page-drag">
            <CFormInput 
              className="drag-input"
              placeholder="Tab Name"
              value={inputValue}
              aria-label={value}
              aria-describedby="basic-addon2"
              disabled={disabled}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={(e) => onBlur(e.target.value, index)}/>
            <CInputGroupText id="basic-addon2" className="drag-text"><DragIcon/></CInputGroupText>
        </CInputGroup>
        </CCol>
        <CCol sm={8}>
            {isDeletable && 
                <DeleteIcon className='delete-icon' onClick={()=> removeTab(index, editTabs)}/>
            }
        </CCol>
    </CRow>
  );
};

export default DragableInput;
