enum REFUELING_CONTENT_TYPE {
    ID1_SUM = 1,
    EBITS_SUM = 2,
    MF_SUM = 3,
    ID1_MILESTONE = 4,
    EBITS_MILESTONE = 5,
    MF_MILESTONE = 6,
}

const ID1_SUMMARIES_KEYS_MAPPING = {
    state: "State",
    stateTime: "State Time",
    ufi: "UFI",
    registration: "Registration",
    deliveryDate: "Delivery Date",
    orderId: "Order Id",
    equipmentId: "Equipment Id",
    receiptId: "Receipt Id",
    intoPlaneAgentCode: "Agent Code",
    supplierCode: "Supplier Code",
    supplierName: "Supplier Name",
    density: "Density",
    quantity: "Quantity",
    quantityUnitType: "Quantity Unit",
    productType: "Product Type",
    remarks: "Remarks",
    manualSG: "Manual SG",
    emailTime: "Email Time",
    pushTime: "Push Time",
    updatedAt: "Updated At",
    created: "Created",
    messageTime: "Message Time",
    messageId: "Message Id",
};

const EBITS_SUMMARIES_KEYS_MAPPING = {
    state: "State",
    stateTime: "State Time",
    ufi: "UFI",
    index: "Index",
    firstReceive: "First Receive",
    receiveCount: "Receive Count",
    created: "Created",
    dataTimestamp: "Timestamp",
    deliveryDateTime: "Delivery Date",
    occurrence: "Occurrence",
    registration: "Registration",
    aircraftType: "Aircraft Type",
    receiptNumber: "Receipt Number",
    operation: "Operation",
    equipment: "Equipment",
    density: "Density",
    densityType: "Density Type",
    densityUnitType: "Density Unit Type",
    temperature: "Tempurature",
    temperatureUnitType: "Tempurature Unit Type",
    temperatureType: "Tempurature Type",
    agentCode: "Agent Code",
    agentName: "Agent Name",
    supplierCode: "Supplier Code",
    supplierName: "Supplier Name",
    quantity: "Quantity",
    quantityUnitType: "Quantity Unit",
    productType: "Product Type",
    remarks: "Remarks",
    manualSG: "Manual SG",
    emailTime: "Email Time",
    pushTime: "Push Time",
    messageTime: "Message Time",
    messageId: "Message Id",
}

const MF_SUMMARIES_KEYS_MAPPING = {
    state: "State",
    stateTime: "State Time",
    ufi: "UFI",
    acknowledgeBy: "Acknowledged By",
    created: "Created",
    emailTime: "Email Time",
    imageId: "Image Id",
    manualSG: "Manual SG",
    quantity: "Quantity",
    quantityUnitType: "Quantity Unit",
    receiptId: "Receipt Id",
    registration: "Registration",
    requestId: "Request Id",
    supplierCode: "Supplier Code",
    supplierName: "Supplier Name",
    thumbnail: "Thumbnail",
    updatedAt: "Updated at",
    equipmentId: "Equipment Id",
    remarks: "Remarks",
    ccsufi: "CCSUFI",
    pushTime: "Push Time",
    messageTime: "Message Time",
    messageId: "Message Id",
}

const ID1_MILESTONES_KEYS_MAPPING = {
    type: "Type",
    ufi: "UFI",
    equipmentId: "Equipment Id",
    orderId: "Order Id",
    created: "Created",
    dataTimestamp: "Timestamp",
    messageId: "Message Id",
}

const EBITS_MILESTONES_KEYS_MAPPING = {
    type: "Type",
    ufi: "UFI",
    equipment: "Equipment",
    firstReceive: "First Receive",
    receiveCount: "Receive Count",
    index: "Index",
    agentCode: "Agent Code",
    agentName: "Agent Name",
    supplierCode: "Supplier Code",
    supplierName: "Supplier Name",
    operator: "Operator",
    currentDeliveredQuantity: "Current Delivered Quantity",
    deliveredQuantityUnit: "Delivered Quantity Unit",
    created: "Created",
    occurrence: "Occurrence",
    dataTimestamp: "Timestamp",
    messageId: "Message Id",
}

const MF_MILESTONES_KEYS_MAPPING = {
    type: "Type",
    ufi: "UFI",
    ccsufi: "CCSUFI",
    equipmentId: "Equipment Id",
    acknowledgedAt: "Acknowledged At",
    acknowledgedBy: "Acknowledged By",
    created: "Created",
    requestId: "Request Id",
    messageId: "Message Id",
}

export {
    REFUELING_CONTENT_TYPE,
    ID1_SUMMARIES_KEYS_MAPPING,
    EBITS_SUMMARIES_KEYS_MAPPING,
    MF_SUMMARIES_KEYS_MAPPING,
    ID1_MILESTONES_KEYS_MAPPING,
    EBITS_MILESTONES_KEYS_MAPPING,
    MF_MILESTONES_KEYS_MAPPING
}
