import React, { useEffect, useRef, useState } from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CCol, CFormCheck, CRow } from '@coreui/react';
import { DownArrowIcon, LeftArrowIcon } from '../../assets/images';
import { Modal } from 'react-bootstrap';
import { CXTextInput, CXButton, CXDatePicker } from '../CXForm';
import { useAppSelector } from '../../app/hooks';
import { selectSim } from '../../slice/simSlice';
import { selectAuth } from '../../slice/authSlice';
import FileCheckBoxRow from './FileCheckBoxRow';
import NoDataTableItem from '../AdminDashboard/NoDataTableItem';
import { uploadAppendixFile } from '../../constants/simConstant';
import UseFileInput from './UseFileInputElement';
import { AirLines } from '../../constants/genericTableConstant';

interface ISelectFileModalProps {
  isShow: boolean;
  onHide: () => void;
  dispatchReducer: React.Dispatch<any>;
  onSelectFile: Function;
  onUploadFile?: Function;
  type: string;
  filesList: Array<string>;
}


const SelectFileModal = ({ isShow, onHide, dispatchReducer, onSelectFile, type, filesList,onUploadFile }: ISelectFileModalProps) => {

  const { userName } = useAppSelector(selectAuth);
  const { appendixFiles } = useAppSelector(selectSim);
  let localFileList: {
    pdfName: string,
    uploadDate: string,
    isChecked: boolean
  }[] = appendixFiles.map(file => {
    return {
      pdfName: file.pdfName,
      uploadDate: file.uploadDate,
      isChecked: false
    }
  });
  const [fileName, setFileName] = useState<string>("");
  const [uploadDate, setUploadDate] = useState<string>("");
  const [fileList, setFileList] = useState<{
    pdfName: string,
    uploadDate: string,
    isChecked: boolean
  }[]>([]);

  const [selectedFileList, setSelectedFileList] = useState<string[]>(filesList);
  const importNewTemplateRef = useRef<HTMLInputElement>(null);
  
  const { fileInputRef, triggerFileSelect, clearFileValue } = UseFileInput();

  const onUploadFromLocal = () => {
    triggerFileSelect();
  }

  const onSearchFiles = () => {
    if(fileName === "" && uploadDate === ""){
      setFileList(localFileList);
    }
    let filteredFileList: {
      pdfName: string,
      uploadDate: string,
      isChecked: boolean
    }[] = [...localFileList];
    if(fileName && fileName != ""){
      filteredFileList = filteredFileList.filter(file => file.pdfName.includes(fileName));
    }
    if(uploadDate && uploadDate != ""){
      filteredFileList = filteredFileList.filter(file => (file.uploadDate.split('T')[0]).includes(uploadDate.split('T')[0]));
    }
    setFileList(filteredFileList);
  }

  const onClickCheckbox = (fileName: string, isChecked: boolean) => {
    let checkList = [...selectedFileList];
    const selectedFileListIndex = checkList.indexOf(fileName);
    if(!isChecked || selectedFileListIndex !== -1){
      checkList.splice(selectedFileListIndex, 1);
    } else {
      checkList.push(fileName);
    }
    setSelectedFileList(checkList);
    //       if (isdelete) {
    //   var valueIndex = checkList.indexOf(selectedFileindex);
    //   if(valueIndex !== -1){
    //     checkList.splice(valueIndex,1)
    //   }
    // } else {
    //     if(checkList.includes(selectedFileindex)){
    //     var valueIndex = checkList.indexOf(selectedFileindex);
    //     if(valueIndex !== -1){
    //       checkList.splice(valueIndex,1)
    //     }
    //   }else{
    //     checkList.push(selectedFileindex);
    //   }
    // }
  }

  const saveFiles = () => {
    let sortedSelectedFileList = selectedFileList.sort();
    let selectedAppendixFile = sortedSelectedFileList.map((fileName) => {
      return appendixFiles.find(file => file.pdfName === fileName);
    })
    // let fileNameList = tempFileIndexList.map((index) => {
    //   return appendixFiles[index];
    // })
    onSelectFile(selectedAppendixFile);
    dispatchReducer({type:"SAVE_FILE", fileNameList: selectedAppendixFile});
  }


  useEffect(()=> {
    setFileList(localFileList);
  },[appendixFiles])
  
  useEffect(() => {
    // const indexList: Array<number> = [];
    // for (const index in localFileList) {
    //   if (filesList.includes(localFileList[index].pdfName)) indexList.push(+index);
    // }
    setSelectedFileList(filesList);
    setFileList(localFileList);
    setFileName("");
    setUploadDate("");
},[isShow])

  const selectFile = async (e) => {
    let res: any;
    const isHKE = (type === AirLines.HKE);
    // if (type === AirLines.HKE) {
    //   res = await uploadHKEAppendixFile(e, userName,localFileList);
    // } else {
    //   res = await uploadAppendixFile(e, userName,localFileList)
    // };
    res = await uploadAppendixFile(e, userName,localFileList, isHKE);
    // if (!res || !res.status.key) return;
    onUploadFile?.();
    clearFileValue();
  };


  return  <Modal className="snnModal" show={isShow} dialogClassName="modal-90w" onHide={onHide} size="xl">
  <Modal.Header className="modal-header" closeButton>
      <Modal.Title> <LeftArrowIcon className="clickable-icon" onClick={()=>{dispatchReducer({type:"BACK_EDIT"})}}/>Back to Edit   <div>Select File</div></Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <div className={"justify-content-between"} >
      <CRow className={"p-2 ps-0"}>
        <CCol className="snnKeyword" sm>
          <CXTextInput placeholder={"File name"} value={fileName} onChange={setFileName} zIndex={1029} />
        </CCol>
        <CCol className="snnKeyword" sm>
          <CXDatePicker placeholder={"Upload date"} value={uploadDate} onChange={setUploadDate} hasDefault={false} zIndex={1029} />
        </CCol>
        <CCol sm>
          <CXButton outline={false} text={"Search"} onClick={() => onSearchFiles()} fontSize={"14px"} />
        </CCol>
        <CCol sm style={{textAlign: "right"}}>
          <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Upload new file"} onClick={()=> onUploadFromLocal()} fontSize={"16px"} width={"100%"} /></div>
          <input ref={fileInputRef} onChange={selectFile} type="file" hidden multiple></input>
        </CCol>
      </CRow>
    </div>
    <CRow>
      <CCol xs={8} className={'appendix-file-list'}>
        <CTable borderless hover>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">
                
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">
                File name
                <DownArrowIcon height={12} width={12} />
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">
                Upload Date
                <DownArrowIcon height={12} width={12} />
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
        
          <CTableBody>
            {!!fileList && fileList.length !==0? 
                fileList.map((appendixFile, index) => 
                <FileCheckBoxRow key={`fileCheckBox_${index}`} index={index} appendixFile={appendixFile} isSelected={selectedFileList.includes(appendixFile.pdfName)} onUpdateFileIndex={onClickCheckbox}/>)
                : 
                (<NoDataTableItem colSpan={3} message={"There is no Appendix Files"} />) 
              }
          </CTableBody>
        </CTable>
      </CCol>
    </CRow>
  </Modal.Body>
  <Modal.Footer className="modal-footer">
    <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Cancel"} onClick={()=> dispatchReducer({type:"BACK_EDIT"})} fontSize={"16px"} width={"100%"} /></div>
    <div style={{ bottom: "40px", right: "40px", width: "200px" }}><CXButton text={`Save (${selectedFileList.length})`} onClick={()=> saveFiles()} fontSize={"16px"} width={"100%"} /></div>
  </Modal.Footer>
</Modal>
};

export default SelectFileModal;