import { HTTP_REQUEST_TYPE, CARREPORT_TOPIC_API, BEKOL_API } from "../constants/api";
import { sendHttpRequest } from "./api";
import ServerError from "./serverError";

export interface ICarReportTopic {
  Airline: string
  Category: string
  Emails: string[]
  Subject: string
  createdAt: string
  galacxyId: string
  updatedAt: string,
  _id: string
}

export type TTopicCreate = Omit<ICarReportTopic, 'createdAt' | 'updatedAt' | '_id'>
export type TTopicUpdate = Omit<ICarReportTopic, 'createdAt' | 'updatedAt'>


/** get car report topic categories */
const getCarReportTopicCategories = async (isUO = false) => {
  const [, resp] = await sendHttpRequest(
    HTTP_REQUEST_TYPE.get,
    isUO ? BEKOL_API.GET_TOPIC_CATEGORY_LIST : CARREPORT_TOPIC_API.GET_TOPIC_CATEGORY_LIST,
    {}
  );
  return resp
}

/** get car report topics */
const getCarReportTopics = async (keyword: string, category: string, isUO = false) => {
  const [, resp] = await sendHttpRequest(
    HTTP_REQUEST_TYPE.get,
    isUO ? BEKOL_API.GET_TOPIC_LIST : CARREPORT_TOPIC_API.GET_TOPIC_LIST,
    {
      ...(keyword && { Keyword: keyword }),
      ...(category && { Category: category }),
    }
  );
  return resp
}

/** create car report topic */
const createCarReportTopic = async (topic: TTopicCreate, isUO = false) => {
  const [, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, isUO ? BEKOL_API.CREATE_TOPIC : CARREPORT_TOPIC_API.CREATE_TOPIC, topic);
  return handelResponse(resp)
};

/** update car report topic */
const updateCarReportTopic = async (topic: TTopicUpdate, isUO = false) => {
  const [, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, isUO ? BEKOL_API.UPDATE_TOPIC : CARREPORT_TOPIC_API.UPDATE_TOPIC, topic);
  return handelResponse(resp)
};

/** delete car report topic */
const deleteCarReportTopic = async (id: string, isUO = false) => {
  const [, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.delete, `${isUO ? BEKOL_API.DELETE_TOPIC : CARREPORT_TOPIC_API.DELETE_TOPIC}?_id=${id}`);
  return handelResponse(resp)
};

/** handle response and error */
const handelResponse = (resp: any) => {
  if (resp instanceof ServerError) {
    throw new Error(resp?.detail || resp?.message);
  }
  return resp;
};

export {
  getCarReportTopicCategories,
  getCarReportTopics,
  createCarReportTopic,
  updateCarReportTopic,
  deleteCarReportTopic,
};
