import React, { useRef, useState } from "react";
import "./style/style.scss";

interface ICXFPConfigTextInputProps {
  disabled?: boolean;
  zIndex?: number;
  additionalValidation?: Function;
  errMsg?: string;
  placeholder?: string;
  value: string;
  unit?: string;
  label?: string;
  hasLabel?: boolean;
  readOnly?: boolean;
  onChangeField?: Function;
  promptChangeFieldTrigger?: boolean;
  field?: string;
  maxLength?: number;
  dataOnBlur?: {onBlur: Function, objectKey: string};
  portOnBlur?: {onBlur: Function, portSecItemIndex: number, portInputItem: number};

}

const CXFPConfigTextInput = ({
  disabled = false,
  zIndex,
  additionalValidation,
  errMsg,
  placeholder,
  value,
  unit,
  label,
  hasLabel = true,
  readOnly = false,
  onChangeField, 
  promptChangeFieldTrigger = false,
  field, 
  maxLength=524288,
  dataOnBlur,
  portOnBlur 
}: ICXFPConfigTextInputProps) => {
  const [focus, setFocus] = useState(false);
  const textInputRef = useRef<HTMLInputElement>(null);
  const [textValue, setTextValue] = useState<string>(value || "");

  /*
    ----------------------------- Sample component ------------------------------
    const [text1, setText1] = useState("");
    <CXFPConfigTextInput value={text1} placeholder={"Flight Number"} onChange={setText1} />
    ----------------------------- Sample component ------------------------------
  */

  const toggleFocus = () => {
    if (!disabled && !readOnly) {
      setFocus(!focus);
      if (textInputRef?.current) {
        textInputRef.current.focus();
      }
    }
  };

  const onFocus = () => {
    if (!disabled && !readOnly) {
      setFocus(true);
    }
  };

  const onChange = (e) => {
    if(promptChangeFieldTrigger && onChangeField){
      onChangeField(e)
    }
    setTextValue(e.target.value)
  }

  const onBlur = (e) => {
    if(onChangeField){
      onChangeField(e)
    }
    if (!disabled && !readOnly) {
      setFocus(false);
    }
    if(dataOnBlur){
      dataOnBlur.onBlur(e.target.value, dataOnBlur.objectKey)
    }
    if(portOnBlur){
      portOnBlur.onBlur(e.target.value, portOnBlur.portSecItemIndex, portOnBlur.portInputItem)
    }
  };

  return (
    <div
      className={"cx-textinput"}
      style={{
        zIndex: !!zIndex ? zIndex : 1030,
      }}
    >
      <div className={"cx-form-control"} style={{ overflow: "hidden", background: disabled? "#F2F2F3" : "transparent" }}>
        <input
          ref={textInputRef}
          name={field}
          className={`
          ${hasLabel ? "cx-form-input" : "cx-form-input-without-label"
          } ${focus ? "focused" : ""} 
          ${disabled ? "cx-disabled" : ""} 
          ${additionalValidation && !additionalValidation(value) ? "error-border": "" }`}
          value={textValue}
          onChange={(e) => onChange(e)}
          onFocus={() => onFocus()}
          onBlur={(e) => onBlur(e)}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
        />
        {focus || !!value ? (
          <div>
            {hasLabel && (
              <div className={"focused-label"} onClick={toggleFocus}>
                {label || placeholder}
              </div>
            )}
            <div className={"unit"} onClick={toggleFocus}>
              {unit || ""}
            </div>
          </div>
        ) : (
          <div
            className={`text-ellipsis ${
              disabled ? "not-focused-label-disabled" : "not-focused-label"
            }`}
            onClick={toggleFocus}
          >
            {placeholder}
          </div>
        )}
      </div>
      {additionalValidation && !additionalValidation(value) && !!errMsg && (
        <div className={"cx-error-message"}>{errMsg}</div>
      )}
    </div>
  );
};

export default CXFPConfigTextInput;
