import * as React from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell, CCol, CRow } from '@coreui/react';

import { DeleteIcon, EditIcon, RightArrowLgIcon } from '../../assets/images';
import { IAppendixRule } from '../../slice/simSlice';
import Access from '../Access';
import { AccessType } from '../../constants/roleAccessConstant';


interface IAppendixRulesTableItemProps {
    appendixRule: IAppendixRule;
    dispatchReducer: React.Dispatch<any>;
    routePath: string;
}

const AppendixRulesTableItem = ({ appendixRule, dispatchReducer, routePath }: IAppendixRulesTableItemProps) => {
  const EditButton = () => {
    return <div className={"clickable-icon"} style={{ marginLeft: "4px" }}>
      <EditIcon height={40} width={40} onClick={() => {
        dispatchReducer({type: "EDIT", appendix: appendixRule})
      }} />
    </div>
  };


  const RobotoMonoRgDataCell = ({ children, type }) => {
    return <CTableDataCell className={`RobotoMonoRg tableItem px-1 ${type}`} style={{ position: "relative" }} align='middle'>{ children }</CTableDataCell>
  };


  return <CTableRow style={{ borderBottom: "1px solid #D8D8D8" }}>
    <RobotoMonoRgDataCell type="airline"> 
      <div>
        {appendixRule.airline || "--"}
      </div>
      <div>
        {appendixRule.flightNo || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="aircraftType">{appendixRule.aircraftType || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="registration">{appendixRule.registration || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="dep">
      {Array.isArray(appendixRule.dep) && appendixRule.dep.length > 0 ? appendixRule.dep.map((ori, index) => 
        <div key={`depCell_${index}`}>{ori}</div>
      )
      :
      <div>{"--"}</div>
      }
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="arr">
      {Array.isArray(appendixRule.arr) && appendixRule.arr.length > 0 ? appendixRule.arr.map((arr, index) => 
        <div key={`arrCell_${index}`}>{arr}</div>
      )
      :
      <div>{"--"}</div>
      }
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="altn">
      <div>
        {appendixRule.altn || "--"} 
      </div>
      <div>
        {appendixRule.era || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="expiry">{ appendixRule.expiry ? moment.utc(appendixRule.expiry)?.format("DDMMMYYYY HH:mm") || "--" : "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="review">{appendixRule.review ? moment.utc(appendixRule.review)?.format("DDMMMYYYY HH:mm") || "--" : "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="files">{(appendixRule.files && appendixRule.files.length > 0? appendixRule.files.join(",") : "--")}</RobotoMonoRgDataCell>
    <Access route={routePath} access={[AccessType.UPDATE, AccessType.DELETE]}>
      <RobotoMonoRgDataCell type="action">
        <CRow>
          <Access route={routePath} access={[AccessType.UPDATE]}>
            <CCol sm="auto"><EditButton/></CCol>
          </Access>
          <Access route={routePath} access={[AccessType.DELETE]}>
            <CCol sm="auto"><DeleteIcon className="clickable-icon" height={40} width={40} onClick={() => {dispatchReducer({type: "DELETE", appendix: appendixRule}) }} /></CCol>
          </Access>
        </CRow>
      </RobotoMonoRgDataCell>
    </Access>
    <RobotoMonoRgDataCell type="view">
      <RightArrowLgIcon className="clickable-icon" onClick={() => { dispatchReducer({type:"VIEW", appendix: appendixRule}) }} width={"31px"} height={"31px"} />
    </RobotoMonoRgDataCell>
  </CTableRow>
};

export default AppendixRulesTableItem;