import _ from 'lodash';

import EDGComparisonSrcDetailItem from "./EDGComparisonSrcDetailItem";
import { TickFilledIcon, CrossFilledIcon } from "../../../assets/images";
import { EDGComparisonSrcDetailProps } from './interfaces/IEDGComparison';

const EDGComparisonSrcDetail = ({ 
  edg_title, 
  edg_generated, 
  generated_time, 
  received_time, 
  generate_duration, 
  edg_file, 
  edg_file_status = false,
  edg_file_bucket,
  onClickDownload,
 }: EDGComparisonSrcDetailProps) => {
  const defaultChart = '--';
  const srcDetailItems = [
    { field: "EDG generated?", value: edg_generated},
    { field: "Time of generation", value: generated_time },
    { field: "Receive EDG time", value: received_time },
    { field: "Duration to generate", value: generate_duration },
  ];

  return (
    <div className="edg-src-dtl">
      {/* Source title */}
      <div className="edg-src-dtl-title">{edg_title}</div>
        
      {/* Source EDG details */}
      {srcDetailItems.map((item, index) => {
        return (
          <EDGComparisonSrcDetailItem key={`${edg_title}-edg-src-dtl-item-${index}`} field={item.field} value={item.value} />
        );
      })}
      
      {/* EDG file */}
      <div className="mt-4">
        <div className="edg-src-dtl-item-field">EDG file</div>
        {_.isArray(edg_file) && edg_file.length > 0 ?
          edg_file.map((file, index) => {
            return (
              <div key={`${edg_title}-edg-file-${index}`} className="d-flex align-items-center mb-1">
                <div className="me-2 download edg-src-dtl-item-value edg-src-dtl-item-filename" 
                onClick={
                  () => onClickDownload(edg_file_bucket, file.filepath, file.filename)
                }>
                  {file.filename || defaultChart}
                </div>
                {edg_file_status &&
                  <div className={`d-flex align-items-center edg-src-dtl-item-filename-status ${file.matched ? "edg-src-dtl-item-filename-status-match" : "edg-src-dtl-item-filename-status-not-match"}`}>
                    {file.matched
                      ? <>
                        <div><TickFilledIcon /></div>
                        <div className="edg-src-dtl-item-filename-status-label">Match</div>
                      </>
                      : <>
                        <div><CrossFilledIcon /></div>
                        <div className="edg-src-dtl-item-filename-status-label">Not Match</div>
                      </>}
                  </div>
                }
              </div>
            )
          })
        : <div className="edg-src-dtl-item-value">{defaultChart}</div>
        }
      </div>
    </div>
  );
};

export default EDGComparisonSrcDetail;
export type { EDGComparisonSrcDetailProps };