import moment from "moment";

const dateFormatter = (date, format="DDMMMYYYY HH:mm:ss") => {
  if(!date) return "--";

  // Return UTC date with given format
  return moment.utc(date)?.format(format);
};

const timeFormatter = (date, timeFormat = "HH:mm") => {
  if(!date) return "--";

  // Return UTC time with given format
  return moment.utc(date)?.format(timeFormat);
};

export {
  dateFormatter,
  timeFormatter
};
