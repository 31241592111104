import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CXDatePicker from "../../components/CXForm/CXDatePicker";
import { CXButton, CXDropDown } from "../../components/CXForm";
import { getUOClosedFlightList } from "../../services/bekolDispatchAPI";
import {
  selectDispatcher,
  setCloseFlightList,
  setCloseFlightSearch,
} from "../../slice/dispatcherSlice";
import CloseFlightListTable from "../../components/AdminDashboard/CloseFlightListTable";
import {
  clearCloseFlightFilter,
  selectApp,
  setCloseFlightFilter,
} from "../../slice/appSlice";
import { stringArrayToUniqueDropDownOptions } from "../../components/CXForm/utils";
import { CButton } from "@coreui/react-pro";
import { stringAlphabeticSort } from "../../utils/sortHelper";

/**
 * Application home page component
 * @returns {JSX}
 */
const UOSearchCloseFlightPage = () => {
  const dispatch = useAppDispatch();
  const { closeFlightFilter } = useAppSelector(selectApp);
  const { closeFlightList, closeFlightSearch } = useAppSelector(selectDispatcher);

  const allowFilter = !!closeFlightList && closeFlightList?.length === 0;

  const today = moment().utc().startOf("day").format("YYYY-MM-DD");
  const [displayDateStart, setDisplayDateStart] = useState(today);
  const [displayDateEnd, setDisplayDateEnd] = useState(today);
  const [filteredCloseFlightList, setFilteredCloseFlightList]: Array<any> =
    useState([]);

  const [airlineOptions, setAirlineOptions]: Array<any> = useState([]);
  const [flightNoOptions, setFlightNoOptions]: Array<any> = useState([]);
  const [regOptions, setRegOptions]: Array<any> = useState([]);
  const [closeFlightTypeOptions, setCloseFlightTypeOptions]: Array<any> =
    useState([]);
  const [originOptions, setOriginOptions]: Array<any> = useState([]);
  const [destOptions, setDestOptions]: Array<any> = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  const search = useCallback(() => {
    const uoStdStart = moment.utc(displayDateStart).format("YYYY-MM-DD");
    const uoStdEnd = moment.utc(displayDateEnd).format("YYYY-MM-DD");
    getUOClosedFlightList(uoStdStart, uoStdEnd)
      .then((res) => {
        if (res.status === 500) {
          setErrorMsg(res.data?.errors?.detail || '');
          return;
        }

        setErrorMsg('');
        dispatch(setCloseFlightList(res));
        dispatch(setCloseFlightSearch({ stdStart: closeFlightSearch?.stdStart, stdEnd: closeFlightSearch?.stdEnd, uoStdStart, uoStdEnd }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [displayDateStart, displayDateEnd, dispatch, closeFlightSearch?.stdStart, closeFlightSearch?.stdEnd]);

  useEffect(() => {
    if (!!closeFlightList) {
      setAirlineOptions(
        stringArrayToUniqueDropDownOptions(
          [
            ...closeFlightList.map((closeFlight) =>
              closeFlight.ufi.slice(0, 3)
            ),
          ].sort((a, b) => stringAlphabeticSort(a, b))
        )
      );
      setFlightNoOptions(
        stringArrayToUniqueDropDownOptions(
          [
            ...closeFlightList.map((closeFlight) =>
              closeFlight.ufi.slice(3, 7)
            ),
          ].sort((a, b) => stringAlphabeticSort(a, b))
        )
      );
      setRegOptions(
        stringArrayToUniqueDropDownOptions(
          [...closeFlightList.map((closeFlight) => closeFlight.reg)].sort(
            (a, b) => stringAlphabeticSort(a, b)
          )
        )
      );
      setCloseFlightTypeOptions(
        stringArrayToUniqueDropDownOptions([
          ...closeFlightList.map((closeFlight) => closeFlight.type),
        ])
      );
      setOriginOptions(
        stringArrayToUniqueDropDownOptions(
          [...closeFlightList.map((closeFlight) => closeFlight?.origin)].sort(
            (a, b) => stringAlphabeticSort(a, b)
          )
        )
      );
      setDestOptions(
        stringArrayToUniqueDropDownOptions(
          [...closeFlightList.map((closeFlight) => closeFlight?.destination)].sort(
            (a, b) => stringAlphabeticSort(a, b)
          )
        )
      );
    }
  }, [closeFlightList]);

  useEffect(() => {
    search();
    return () => {
      dispatch(setCloseFlightList({ closeFlightList: [] }));
      dispatch(clearCloseFlightFilter());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!!closeFlightFilter && closeFlightList) {
      let closeFlightListCopy = closeFlightList;
      if (!!closeFlightFilter?.airline) {
        closeFlightListCopy = closeFlightListCopy.filter(
          (closeFlight) =>
            closeFlight.ufi.slice(0, 3) === closeFlightFilter.airline
        );
      }
      if (!!closeFlightFilter?.flightNo) {
        closeFlightListCopy = closeFlightListCopy.filter(
          (closeFlight) =>
            closeFlight.ufi.slice(3, 7) === closeFlightFilter.flightNo
        );
      }
      if (!!closeFlightFilter?.reg) {
        closeFlightListCopy = closeFlightListCopy.filter(
          (closeFlight) => closeFlight.reg === closeFlightFilter.reg
        );
      }
      if (!!closeFlightFilter?.closeFlightType) {
        closeFlightListCopy = closeFlightListCopy.filter(
          (closeFlight) =>
            closeFlight.type === closeFlightFilter.closeFlightType
        );
      }
      if (!!closeFlightFilter?.origin) {
        closeFlightListCopy = closeFlightListCopy.filter(
          (closeFlight) =>
            closeFlight.origin === closeFlightFilter.origin
        );
      }
      if (!!closeFlightFilter?.destination) {
        closeFlightListCopy = closeFlightListCopy.filter(
          (closeFlight) =>
            closeFlight.destination === closeFlightFilter.destination
        );
      }
      setFilteredCloseFlightList(closeFlightListCopy);
    }
  }, [closeFlightList, closeFlightFilter.airline, closeFlightFilter.flightNo, closeFlightFilter.reg, closeFlightFilter.closeFlightType, closeFlightFilter]);

  return (
    <>
      <div
        className={"CathaySansBd"}
        style={{ fontSize: "32px", lineHeight: "36px" }}
      >
        Closed Flight
      </div>
      <div className={"section d-flex"}>
        <div className={"p-2 ps-0"}>
          <CXDatePicker
            placeholder={"STD Start"}
            value={displayDateStart}
            onChange={setDisplayDateStart}
            zIndex={1029}
          />
        </div>
        <div className={"p-2 ps-0"}>
          <CXDatePicker
            placeholder={"STD End"}
            value={displayDateEnd}
            onChange={setDisplayDateEnd}
            zIndex={1029}
          />
        </div>
        <div className={"p-2"}>
          <CXButton
            outline={false}
            text={"Search"}
            onClick={() => search()}
            fontSize={"14px"}
          />
        </div>
      </div>
      <div className={"d-flex"}>
        <div className={"p-2 ps-0"} style={{ width: "120px" }}>
          <CXDropDown
            value={closeFlightFilter?.airline || ""}
            readOnly={allowFilter}
            zIndex={1028}
            placeholder={"Airline"}
            onChange={(airline: string) =>
              dispatch(setCloseFlightFilter({ airline }))
            }
            options={airlineOptions}
          />
        </div>
        <div className={"p-2 ps-0"} style={{ width: "120px" }}>
          <CXDropDown
            value={closeFlightFilter?.flightNo || ""}
            readOnly={allowFilter}
            zIndex={1028}
            placeholder={"Flight no."}
            onChange={(flightNo: string) =>
              dispatch(setCloseFlightFilter({ flightNo }))
            }
            options={flightNoOptions}
          />
        </div>
        <div className={"p-2 ps-0"} style={{ width: "120px" }}>
          <CXDropDown
            value={closeFlightFilter?.reg || ""}
            readOnly={allowFilter}
            zIndex={1028}
            placeholder={"Reg"}
            onChange={(reg: string) => dispatch(setCloseFlightFilter({ reg }))}
            options={regOptions}
          />
        </div>
        <div className={"p-2 ps-0"} style={{ width: "220px" }}>
          <CXDropDown
            value={closeFlightFilter?.closeFlightType || ""}
            readOnly={allowFilter}
            zIndex={1028}
            placeholder={"Close flight type"}
            onChange={(closeFlightType: string) =>
              dispatch(setCloseFlightFilter({ closeFlightType }))
            }
            options={closeFlightTypeOptions}
          />
        </div>
        <div className={"p-2 ps-0"} style={{ width: "120px" }}>
          <CXDropDown
            value={closeFlightFilter?.origin || ""}
            readOnly={allowFilter}
            zIndex={1028}
            placeholder={"Origin"}
            onChange={(origin: string) => dispatch(setCloseFlightFilter({ origin }))}
            options={originOptions}
          />
        </div>
        <div className={"p-2 ps-0"} style={{ width: "220px" }}>
          <CXDropDown
            value={closeFlightFilter?.destination || ""}
            readOnly={allowFilter}
            zIndex={1028}
            placeholder={"Destination"}
            onChange={(destination: string) => dispatch(setCloseFlightFilter({ destination }))}
            options={destOptions}
          />
        </div>
        <div
          className={"p-2 ps-0 d-flex justify-content-center"}
          style={{ width: "130px" }}
        >
          <CButton
            color="link"
            style={{
              padding: "0px",
              visibility:
                !!closeFlightFilter?.airline ||
                  !!closeFlightFilter?.flightNo ||
                  !!closeFlightFilter?.reg ||
                  !!closeFlightFilter?.closeFlightType ||
                  !!closeFlightFilter?.origin ||
                  !!closeFlightFilter?.destination
                  ? "visible"
                  : "hidden",
            }}
            className={"linkButton clickable"}
            onClick={() => dispatch(clearCloseFlightFilter())}
          >
            Clear all filters
          </CButton>
        </div>
      </div>
      <div className={"section"}>
        <CloseFlightListTable
          closeFlightList={filteredCloseFlightList || []}
          searchError={errorMsg}
          user={"UO"}
        />
      </div>
    </>
  );
};

export default UOSearchCloseFlightPage;
