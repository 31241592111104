import { IAircraft } from '../../slice/simSlice';
import NoDataTableItem from '../AdminDashboard/NoDataTableItem';
import AircraftCard from './AircraftCard';

interface IAircraftTableProps {
  aircrafts: IAircraft[];
}


const AircraftTable = ({ aircrafts }: IAircraftTableProps) => {

  return (
    <>
        {!!aircrafts && aircrafts.length !==0 ? 
            aircrafts.map((aircraft, index) => {
                return <AircraftCard key={`AircraftItem${index}`} aircraft={aircraft}></AircraftCard>
              
            }) 
    
            : 
            (
              <NoDataTableItem colSpan={11} message={"There is no Aircrafts"} />
            ) 
        }
    </>
  )
};

export default AircraftTable;