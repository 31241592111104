import { HTTP_REQUEST_TYPE, PORT_API } from "../constants/api";
import { IDeletePortFileRequest, IDownloadPortFileRequest, IReOrderTabRequest, ISavePortElementRequest, IUploadPortFilesRequest } from "../slice/dispatcherSlice";
import { IAddNewPortRequest, IAddPortElementRequest, IDeletePortElementRequest } from "../slice/simSlice";
import { sendHttpRequest } from "./api";

const getPort = async (portSearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        PORT_API.GET_PORT,
        portSearch ? portSearch : {}
    );
    return resp;
};

const getPorts = async (portsSearch?) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        PORT_API.GET_PORT_LIST,
        portsSearch ? portsSearch : {}
    );
    return resp;
};

const postCreateNewPort = async (addNewPortRequest :IAddNewPortRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        PORT_API.POST_PORT_ADD,
        {
            ...addNewPortRequest
        }
    );
    return resp;
};

const postAddPortElement= async (addPortElementRequest: IAddPortElementRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        PORT_API.POST_PORT_ADD_ELEMENT,
        {
            ...addPortElementRequest
        }
    );
    return resp;
};

const postDeletePortElement= async (deletePortElementRequest: IDeletePortElementRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        PORT_API.POST_PORT_DELETE_ELEMENT,
        {
            ...deletePortElementRequest
        }
    );
    return resp;
};

const postSavePortElement= async (savePortElementRequest: ISavePortElementRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        PORT_API.POST_PORT_SAVE_ELEMENT,
        {
            ...savePortElementRequest
        }
    );
    return resp;
};

const postReOrderTabs = async (reOrderTabRequest: IReOrderTabRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        PORT_API.POST_PORT_RE_ORDER_TAB,
        {
            ...reOrderTabRequest
        }
    );
    return resp;
};

const postUploadPortFile = async (uploadPortFilesRequest: FormData) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        PORT_API.POST_PORT_UPLOAD_FILE,
        uploadPortFilesRequest,
        true,
        true
    );
    return resp;
};

const postDownloadPortFile = async (downloadPortFileRequest: IDownloadPortFileRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        PORT_API.POST_PORT_DOWNLOAD_FILE,
        {
            ...downloadPortFileRequest
        }
    );
    return resp;
};

const postDeletePortFile = async (deletePortFileRequest: IDeletePortFileRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        PORT_API.POST_PORT_DELETE_FILE,
        deletePortFileRequest
    );
    return resp;
};

export {
    getPort,
    getPorts,
    postCreateNewPort,
    postAddPortElement,
    postDeletePortElement,
    postSavePortElement,
    postReOrderTabs,
    postUploadPortFile,
    postDownloadPortFile,
    postDeletePortFile
};