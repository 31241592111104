import { DATA_API, HTTP_REQUEST_TYPE } from "../constants/api";
import { DB_COLLECTIONS } from "../constants/dataConstant";
import { sendHttpRequest } from "./api";
import { DocumentReqBody } from '../slice/dataSlice'


const getCollections = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DATA_API.GET_COLLECTIONS,
        { colNames: DB_COLLECTIONS }
    );
    return resp;
};

const getDocuments = async (colName: string, limit: number, offset: number) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DATA_API.GET_DOCUMENTS,
        {
            colName,
            limit,
            offset
        }
    );
    return resp;
};

const updateDocument = async (colName, document) => {
    const reqBody: Array<DocumentReqBody> = [
        {
            colName: colName,
            documents: [document]
        }
    ];

    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        DATA_API.UPDATE_DOCUMENT,
        reqBody
    );
    return resp;
}

export {
    getCollections,
    getDocuments,
    updateDocument
};