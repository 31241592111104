import { HTTP_REQUEST_TYPE, ADMIN_API } from "../constants/api";
import { sendHttpRequest } from "./api";

interface IAdjustFlightPlanRequest {
    ufi: string;
    isHidden: boolean;
}
const postAdjustFlightPlan = async (bodyFormData: IAdjustFlightPlanRequest) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        ADMIN_API.ADJUST_FLIGHT_PLAN,
        bodyFormData,
        true,
        false
    );
    return resp;
};

export{
    postAdjustFlightPlan
}