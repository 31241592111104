interface EDGFile {
  filepath: string;
  filename: string;
  matched: boolean;
}

interface EDGDetail {
  edg_generated: boolean;
  generated_time: string;
  received_time: string;
  generate_duration: string;
  edg_file: EDGFile[];
  edg_file_bucket: string;
}

interface EDGComparisonObject {
  AWS: EDGDetail;
  Azure: EDGDetail;
}

interface EDGComparisonSrcDetailProps {
  edg_title: string;
  edg_generated: string;
  generated_time: string;
  received_time: string;
  generate_duration: string;
  edg_file: EDGFile[];
  edg_file_bucket: string;
  edg_file_status?: boolean;
  onClickDownload: (bucket: string, filepath: string, filename: string) => void;
}

interface EDGDownLoadAPIParams {
  filepath: string;
  bucket: string;
  filename: string;
}

const edgComparisonInitialState: EDGComparisonObject = {
  AWS: {
    edg_generated: false,
    generated_time: "",
    received_time: "",
    generate_duration: "",
    edg_file: [
      {
        filepath: "",
        filename: "",
        matched: false
      }
    ],
    edg_file_bucket: ""
  },
  Azure: {
    edg_generated: false,
    generated_time: "",
    received_time: "",
    generate_duration: "",
    edg_file: [
      {
        filepath: "",
        filename: "",
        matched: false
      }
    ],
    edg_file_bucket: ""
  }
};

export type { EDGComparisonObject, EDGDetail, EDGFile, EDGComparisonSrcDetailProps, EDGDownLoadAPIParams };
export { edgComparisonInitialState };
