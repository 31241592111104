import COLORS from '../../constants/color';
import { StyleDictionary, Style } from 'pdfmake/interfaces';

//map to pdfmake font
const fontBd = {
    bold: true,
}

//map to pdfmake font
const fontMd = {
    bold: true,
    italics: true,
}

const headerLeft = {
    ...fontBd,
    alignment: 'left',
    fontSize: 14,
    lineHeight: 1.29,
} as Style;

const headerRight = {
    ...fontMd,
    alignment: 'right',
    fontSize: 14,
    lineHeight: 1.14,
} as Style;

const subHeader = {
    ...fontMd,
    fontSize: 12,
    lineHeight: 1.333,
    color: COLORS.black,
} as Style;

const subHeaderR = {
    alignment: 'right',
    fontSize: 14,
    lineHeight: 1.286,
    color: COLORS.white
} as Style;

/* start for container */
const container = {
    color: COLORS.black,
} as Style;

const secTitle = {
    ...fontMd,
    alignment: 'right',
    fontSize: 10,
    lineHeight: 1.4,
    color: COLORS.white
} as Style;

const secContentBase = {
    fontSize: 8,
    lineHeight: 1.5,
} as Style;

const secContentRemarks_Title = {
    ...fontBd,
    fontSize: 6,
    lineHeight: 1.67,
} as Style;

const ReportStyle = {
    headerLeft,
    headerRight,
    subHeader,
    subHeaderL: {
        ...subHeader,
        characterSpacing: 0.3
    },
    subHeaderMR: {
        fontSize: 10,
        lineHeight: 1.4,
        color: COLORS.black,
    },
    subHeaderR,

    container,
    secTitle,
    secContentBase,
    secContentItems: {
        ...secContentBase
    },
    secContentItems_Header: {
        ...secContentBase,
        characterSpacing: 0.3
    },
    secContentItems_ColType: {
        ...secContentBase,
        ...fontMd,
    },
    secContentRemarks_Title,
    secContentRemarks_Content: {
        ...secContentBase
    },
    secContentEngineFailure:{
        ...secContentBase,
        lineHeight: 1
    }
} as StyleDictionary;

export default ReportStyle;
