import { useCallback, useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import { CXButton, CXDatePicker, CXDropDown } from '../../components/CXForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IAppendFile, IAppendixRule, IAppendixRuleRequest, IAppendixSearch, initialAppendixReducer, initialAppendixRuleSearch, initialNewAppendixRule, selectSim, setAppendixFiles, setAppendixFilesMap, setAppendixRules, setSimFlightPlans, setSimFlightPlanTemplates } from '../../slice/simSlice';
import { Form, Modal, Nav, Row } from 'react-bootstrap';
import AppendixRulesTable from '../../components/Sim/AppendixRulesTable';
import { CRow, CCol, CTable, CTableHead, CTableHeaderCell, CTableRow, CTableBody, CTableDataCell, CButton } from '@coreui/react-pro';
import { APPENDIX_REQUIRED_FIELDS, APPENDIX_SEARCH_FIELDS } from '../../constants/simConstant';
import { CloseIcon, UploadIcon } from '../../assets/images';
import ViewLabel from '../../components/ViewLabel';
import SelectFileModal from '../../components/Sim/SelectFileModal';
import { deleteAppendixRule, getAppendixFiles, getAppendixRules, postCreateAppendixRule, postUpdateAppendixRule } from '../../services/appendixAPI';
import AppendixFileLibrary from '../../components/Sim/AppendixFileLibrary';
import CXFPConfigTextInput from '../../components/CXForm/CXFPConfigTextInput';
import TagsInput from '../../components/TagInputs';
import { getAircraftTypeList } from '../../services/commonAPI';
import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';
import { useLocation } from "react-router-dom";

let mockMap = new Map<string, string>();

const AppendixRulePage = () => {
  const { pathname: routePath } = useLocation();
  const dispatch = useAppDispatch();
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const { appendixRules } = useAppSelector(selectSim);
  const [aircraftTypeList, setAirCraftTypeList] = useState<[{
    text: string,
    value: string
  }]>([{
    text: "",
    value: ""
  }]);
  const [searchCriteria, setSearchCriteria] = useState<IAppendixSearch>(initialAppendixRuleSearch);

  //Tab
  const [isRuleConfig, setIsRuleConfig] = useState<boolean>(true);

  //Modal
  const [appendixRule, setAppendixRule] = useState<IAppendixRule>(initialNewAppendixRule);

  const closeModal = () => {
    dispatchReducer({type:"CLOSE_ALL", appendix: appendixModel.body})
    setAppendixRule(initialNewAppendixRule);
    // setCurrentModalState(initialNewSNNRule);
  };

  const defaultAircraftTypeOptions = [
    {
      text: "ALL",
      value: "ALL"
    },
    {
      text: "CPA",
      value: "CPA"
    },
    {
      text: "AHK",
      value: "AHK"
    }
  ];

  const reducer = (state, action) => {
    let tempAppendix;
    let tempFiles;
    switch (action.type) {
      case "NEW":
        return {
          ...state,
          body: initialAppendixReducer,
          isAddNewMode: true,
          isAddNewAppendixLightBoxVisible: true
        };
      case "EDIT":
        tempAppendix = {...action.appendix}
        tempFiles = [...tempAppendix.files];

        if(tempFiles.length > 0 && !tempFiles.includes(null)){
          tempFiles = tempFiles.map(file => {
            return {
              pdfName: file.pdfName ? file.pdfName : file,
              uploadDate: mockMap.get(file)
            }
          })
        }
        tempAppendix = {
          ...tempAppendix,
          files: [...tempFiles]
        }

        setAppendixRule(tempAppendix);

        return {
          ...state,
          isAddNewMode: false,
          isAddNewAppendixLightBoxVisible: true
        };
      case "VIEW":
        tempAppendix = {...action.appendix}
        tempFiles = [...tempAppendix.files];

        if(tempFiles.length > 0 && !tempFiles.includes(null)){
          tempFiles = tempFiles.map(file => {
            return {
              pdfName: file.pdfName ? file.pdfName : file,
              uploadDate: mockMap.get(file)
            }
          })
        }

        tempAppendix = {
          ...tempAppendix,
          files: [...tempFiles]
        }
        setAppendixRule(tempAppendix);
        return {
          ...state,
          body: tempAppendix,
          isViewAppendixLightBoxVisible: true
        };
      case "DELETE":
        return {
          ...state,
          body: action.appendix,
          isDeleteAppendixLightBoxVisible: true
        };
      case "DUPLICATED":
        setAppendixRule(action.appendix);
        return {
          ...state,
          isAddNewMode: true,
          isDuplicated: true,
          isAddNewAppendixLightBoxVisible: true
        }
      case "SELECT_FILE":
        return {
          ...state,
          isSelectAppendixLightBoxVisible: true
        };
      case "SAVE_FILE":
        return {
          ...state,
          isSelectAppendixLightBoxVisible: false
        };
      case "BACK_EDIT":
        return {
          ...state,
          isSelectAppendixLightBoxVisible: false
        };
      case "CLOSE":
        return state.isViewAppendixLightBoxVisible === true ?  {
          ...state,
          isAddNewMode: false,
          isDuplicated: false,
          isAddNewSNNRulesLightBoxVisible: false,
          isDeleteSNNRulesLightBoxVisible: false
        } : {
          ...state,
          body: initialAppendixReducer,
          isAddNewMode: false,
          isDuplicated: false,
        }
      case "CLOSE_ALL":
        return {
          ...state,
          isAddNewMode: false,
          isDuplicated: false,
          isAddNewAppendixLightBoxVisible: false,
          isViewAppendixLightBoxVisible: false,
          isSelectAppendixLightBoxVisible: false,
          isDeleteAppendixLightBoxVisible: false
        };
      default:
        return state;
    }
  };

  const [appendixModel, dispatchReducer] = useReducer(reducer, initialAppendixReducer);

  const appendixValidation = (value: string | string[]) => {
    if(hasValidationError) {
      if(typeof value == "string"){
        return value && value.length > 0;
      } else if (typeof value == "object"){
        return value && value.length > 0;
      }
    }

    return true;
  }

  const handleClose = (modalFlag: string) => {
    if(appendixModel.isViewAppendixLightBoxVisible === true){
      let tempState = appendixModel;
      tempState[modalFlag] = false;
      dispatchReducer({type:"CLOSE", appendix: tempState});
    } else {
      dispatchReducer({type:"CLOSE_ALL", appendix: appendixModel.body});
      appendixModel[modalFlag] = false;
      setAppendixRule(initialNewAppendixRule);
    }
  };


  // onClick Search Appendix Rules
  const searchAppendix = () => {
    let tempObj = {};
    for (let key in searchCriteria) {
      console.log(`search key: ${key}`);
      if(searchCriteria[key] != ""){
        let searchContent = searchCriteria[key];
        if(key !== 'files' && key !== 'expiry' && key !== 'review'){
          searchContent = searchContent.toUpperCase();
        }
        tempObj[key] = searchContent;
      }
    }
    console.log(`object: ${JSON.stringify(tempObj)}`);
    getAppendixRules(tempObj).then((res) => {
      const appendixList: Array<any> = [];
      for (const appendixRules of res.appendixRules) {
        if (appendixRules.airline !== 'HKE') appendixList.push(appendixRules);
      }
      dispatch(setAppendixRules(Object.values(appendixList || [])));
    }).catch((err) => {
      console.log(err);
    });

  };

  // onClick Search Appendix Files
  const searchAppendixFile = () => {
    let tempObj = {};
    for (let key in searchCriteria) {
      if(searchCriteria[key] != ""){
        tempObj[key] = searchCriteria[key];
      }
    }
    getAppendixFiles(tempObj).then((res) => {
      res.forEach(file => {
        mockMap.set(file.pdfName, file.uploadDate);
      });
      dispatch(setAppendixFiles(Object.values(res || [])));
    }).catch((err) => {
      console.log(err);
    });

  };

  const onUploadFile = () => {
    searchAppendixFile()
  }
  
  const onChangeSearchForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempObj = {
      ...searchCriteria
    }
    tempObj[event.target.name] = event.target.value;
    setSearchCriteria(tempObj);
  }

  const onChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempObj = {
      ...appendixRule
    }
    tempObj[event.target.name] = event.target.value;
    setAppendixRule(tempObj);
  }

  const onChangeField = (value: string, field: string, state: any, setState: Function) => {
    let tempObj = {
      ...state
    }
    tempObj[field] = value;
    setState(tempObj);
  }

  const getAircraftDropdown = useCallback(() => {
    getAircraftTypeList().then((res) => {
      let dropdownOptionList = res.map(type => {
        return {
          text:type._id,
          value: type._id
        }
      })
      dropdownOptionList = defaultAircraftTypeOptions.concat(dropdownOptionList);
    setAirCraftTypeList(dropdownOptionList || []);
    }).catch((err) => {
      console.log(err);
    });

  },[]);

  useEffect(() => {
    searchAppendix();
    searchAppendixFile();
    getAircraftDropdown();
  }, [isRuleConfig]);

  useEffect(() => {
    console.log(searchCriteria);
  }, [searchCriteria]);

  const onClickAddAppendix = () => {

    for (const field in appendixRule) {
      if(APPENDIX_REQUIRED_FIELDS.includes(field) && ( !appendixRule[field] || appendixRule[field] == "" || appendixRule[field].length == 0)) {
        setHasValidationError(true);
        return;
      }
    }

    let appendixRequest = _convertToRequest(appendixRule);
    if(appendixModel.isAddNewMode){
      if(appendixRequest['_id'] != undefined) {
        delete appendixRequest['_id'];
      }
      postCreateAppendixRule(appendixRequest).then((res) => {
        handleClose("isAddNewAppendixLightBoxVisible")
        dispatchReducer({type:"CLOSE_ALL"});
        searchAppendix();
      }).catch((err) => {
        console.log(err);
      });
    } else{
        postUpdateAppendixRule(appendixRule._id, appendixRequest).then((res) => {
          dispatchReducer({type:"CLOSE_ALL"});
          searchAppendix();
        }).catch((err) => {
          console.log(err);
        });
      }
  }

  //Convert content to request body
  const _convertToRequest = (appendixForm: IAppendixRule): IAppendixRuleRequest=> {
    return {
      ...appendixForm,
      dep: appendixForm.dep.join(','),
      arr: appendixForm.arr.join(','),
      altn: appendixForm.altn && appendixForm.altn.trim().toUpperCase(),
      era: appendixForm.era && appendixForm.era.trim().toUpperCase(),
      files: appendixForm.files.map(file => file.pdfName)
    }
  };

  //Confirm Delete Appendix Rule
  const confirmDelete = () => {
    deleteAppendixRule(appendixModel.body._id).then((res)=>{
      dispatchReducer({type:"CLOSE_ALL", appendix: appendixModel.body});
      searchAppendix();
    })
  }

  //<-----------------------------------Modal------------------------------------------->

  //Add Files to appendix rule
  const onSelectFile = (fileList: IAppendFile[]) => {
    let tempAppendixRule = {
      ...appendixRule,
      files: [...fileList]
    }
    setAppendixRule(tempAppendixRule);
  };

  //Remove files in appendix rule
  const onRemoveFile = (index: number) => {
    let tempObj = {...appendixRule};
    let tempList = [...tempObj.files];
    tempList.splice(index, 1);
    setAppendixRule({
      ...tempObj,
      files: tempList
    });
  };

  const RobotoMonoRgDataCell = ({ children }) => {
    return <CTableDataCell className={"RobotoMonoRg"} style={{ position: "relative" }} align='middle'>{ children }</CTableDataCell>
  };

  return (
    <>
    <div className={"section d-flex justify-content-between"} >
      <div className={"CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>Appendix Rules</div>
        <CRow className={"p-2 ms-auto"}>
          {isRuleConfig && 
            <Access route={routePath} access={[AccessType.CREATE]}>
              <CXButton
                onClick={()=>{dispatchReducer({type:"NEW"})}}
                text={"Add New Rule"}
                outline={true}
                fontSize={"14px"} />
            </Access>
          }
        </CRow>
    </div>
      <Row className='tabRow'>
          <Nav variant="pills" className={"p-2 ps-0"}>
              <CButton className={`${isRuleConfig ? "selectedTab" : "tabButton"}`} onClick={()=>setIsRuleConfig(true)}> Rule configuration</CButton>              
              <Access route={routePath} access={[AccessType.UPDATE]}>
                <CButton className={`${!isRuleConfig ? "selectedTab" : "tabButton"}`} onClick={()=>setIsRuleConfig(false)}>File library</CButton>
              </Access>
          </Nav>
      </Row>
    {/* </Tab.Container> */}
    {isRuleConfig ?
    < >
    <div className={"justify-content-between appendixFilter"} >
                <CRow>
                  <CCol sm="auto">
                    <div className={"airline"}>
                      <CXFPConfigTextInput
                        value={searchCriteria.airline}
                        placeholder={"Airline"}
                        onChangeField={onChangeSearchForm}
                        field={APPENDIX_SEARCH_FIELDS.airline}/>
                    </div>
                  </CCol>
                  <CCol sm="auto">
                    <div className={"aircraftType"}>
                      <CXFPConfigTextInput
                        placeholder={"Aircraft type"}
                        value={searchCriteria.aircraftType}
                        onChangeField={onChangeSearchForm}
                        field={APPENDIX_SEARCH_FIELDS.aircraftType}
                        zIndex={1029} />
                    </div>
                  </CCol>
                  <CCol sm="auto">
                    <div className={"registeration"}>
                      <CXFPConfigTextInput
                        placeholder={"Registration"}
                        value={searchCriteria.registration}
                        onChangeField={onChangeSearchForm}
                        field={APPENDIX_SEARCH_FIELDS.registration}
                        zIndex={1029} />
                    </div>
                  </CCol>
                  <CCol sm="auto">
                    <div className={"dep"}>
                      <CXFPConfigTextInput
                        value={searchCriteria.dep}
                        placeholder={"Origin (multiple)"}
                        onChangeField={onChangeSearchForm}
                        field={APPENDIX_SEARCH_FIELDS.dep}/>
                    </div>
                  </CCol>
                  <CCol sm="auto">
                    <div className={"arr"}>
                      <CXFPConfigTextInput
                        value={searchCriteria.arr}
                        placeholder={"Dest (multiple)"}
                        onChangeField={onChangeSearchForm}
                        field={APPENDIX_SEARCH_FIELDS.arr}/>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="auto">
                    <div className={"files"}>
                      <CXFPConfigTextInput
                        placeholder={"Files"}
                        value={searchCriteria.files}
                        onChangeField={onChangeSearchForm}
                        field={APPENDIX_SEARCH_FIELDS.files}
                        zIndex={1029} />
                    </div>
                  </CCol>
                  <CCol sm="auto">
                    <div className={"altn"}>
                      <CXFPConfigTextInput
                        value={searchCriteria.altn}
                        placeholder={"Altn"}
                        onChangeField={onChangeSearchForm}
                        field={APPENDIX_SEARCH_FIELDS.altn}/>
                    </div>
                  </CCol>
                  <CCol sm="auto">
                    <div className={"era"}>
                      <CXFPConfigTextInput
                        value={searchCriteria.era}
                        placeholder={"ERA"}
                        onChangeField={onChangeSearchForm}
                        field={APPENDIX_SEARCH_FIELDS.era}/>
                    </div>
                  </CCol>
                  <CCol sm="auto" style={{zIndex: 1000}}>
                    <div className={"expiryDate"}>
                      <CXDatePicker placeholder={"Expiry date"}
                          value={searchCriteria.expiry}
                          onChange={setSearchCriteria}
                          onChangeField={onChangeField}
                          field={APPENDIX_SEARCH_FIELDS.expiry}
                          state={searchCriteria}
                          zIndex={1040}
                          hasDefault={false} />
                    </div>
                  </CCol>
                  <CCol sm="auto" style={{zIndex: 1000}}>
                    <div className={"reviewDate"}>
                      <CXDatePicker placeholder={"Review date"}
                          value={searchCriteria.review}
                          onChange={setSearchCriteria}
                          onChangeField={onChangeField}
                          field={APPENDIX_SEARCH_FIELDS.review}
                          state={searchCriteria}
                          zIndex={1040}
                          hasDefault={false} />
                    </div>
                  </CCol>
                  <CCol sm="auto">
                    <div className={"searchButton"}>
                      <div><CXButton text={"Search"} onClick={()=>{searchAppendix()}} fontSize={"16px"} width={"100%"} /></div>
                    </div>
                  </CCol>
                </CRow>
              </div>

              <div className={"section appendixTable"}>
                <AppendixRulesTable
                  appendixRules={appendixRules || []}
                  dispatchReducer={dispatchReducer}
                  routePath={routePath} />
              </div>
              </>
        :
      // User have "update" access can do any action in File Library tab page       
      <Access route={routePath} access={[AccessType.UPDATE]}>
        <AppendixFileLibrary dispatchReducer={dispatchReducer} type="ALL"/>
      </Access>
    }
    {/* --------- Add Appendix Modal ---------*/}
    <Modal className="appendixModal" show={appendixModel.isAddNewAppendixLightBoxVisible} dialogClassName="modal-90w" onHide={closeModal} size="xl">

        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>{appendixModel.isAddNewMode ? appendixModel.isDuplicated ? "Duplicated Appendix Rule" : "Add New Appendix Rule" : "Edit Appendix Rule"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Aircraft type and flight details</span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXDropDown
                    value={appendixRule.airline}
                    placeholder={"Airline"}
                    onChange={setAppendixRule}
                    options={defaultAircraftTypeOptions}
                    onChangeField={onChangeField}
                    field="airline"
                    state={appendixRule}
                    zIndex={1431}/>
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    value={appendixRule.flightNo}
                    placeholder={"Flight no"}
                    onChangeField={onChangeModal}
                    field="flightNo"
                    additionalValidation={appendixValidation}
                    errMsg={"Required"}
                    zIndex={1430}/>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={6}>
                  <CXDropDown
                    value={appendixRule.aircraftType}
                    placeholder={"Aircraft type"}
                    onChange={setAppendixRule}
                    options={aircraftTypeList}
                    onChangeField={onChangeField}
                    field="aircraftType"
                    state={appendixRule}
                    zIndex={1430}/>
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    value={appendixRule.registration}
                    placeholder={"Registration (Example:ABC)"}
                    onChangeField={onChangeModal}
                    field="registration"
                    additionalValidation={appendixValidation}
                    errMsg={"Required"}
                    zIndex={1360}/>
                </CCol>
              </CRow>
                <TagsInput placeholder={"Origin (Use “enter” key for multiple input)"} tagList={appendixRule.dep} onChangeField={onChangeField} field="dep" state={appendixRule} setState={setAppendixRule} additionalValidation={appendixValidation} errMsg="Required"></TagsInput>
                <TagsInput placeholder={"Destination (Use “enter” key for multiple input)"} tagList={appendixRule.arr} onChangeField={onChangeField} field="arr" state={appendixRule} setState={setAppendixRule} additionalValidation={appendixValidation} errMsg="Required"></TagsInput>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Expiry and review dates</span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXDatePicker
                    placeholder={"Expiry Date"}
                    value={appendixRule.expiry}
                    onChange={setAppendixRule}
                    zIndex={1330}
                    onChangeField={onChangeField}
                    field="expiry"
                    state={appendixRule}
                    hasDefault={false}
                    additionalValidation={appendixValidation}
                    errMsg={"Required"}/>
                </CCol>
                <CCol xs={6}>
                  <CXDatePicker
                    placeholder={"Review Date"}
                    value={appendixRule.review}
                    onChange={setAppendixRule}
                    zIndex={1330}
                    onChangeField={onChangeField}
                    field="review"
                    state={appendixRule}
                    hasDefault={false}
                    additionalValidation={appendixValidation}
                    errMsg={"Required"}/>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Other information</span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    value={appendixRule.altn}
                    placeholder={"ALTN"}
                    onChangeField={onChangeModal}
                    additionalValidation={appendixValidation}
                    errMsg={"Required"}
                    field="altn"/>
                </CCol>
                <CCol xs={6}>
                  <CXFPConfigTextInput
                    value={appendixRule.era}
                    placeholder={"ERA"}
                    onChangeField={onChangeModal}
                    additionalValidation={appendixValidation}
                    errMsg={"Required"}
                    field="era"/>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow>
            <CCol xs={6}>
              <span className='modal-section-title'>Upload files</span>
            </CCol>
            <CCol className="snnDes" xs={6}>
              <div className={`cancel-button`}>
                <CXButton outline={true} text={"Select or upload file"} onClick={()=> dispatchReducer({type:"SELECT_FILE"})} fontSize={"16px"} width={"100%"} prefixIcon={<UploadIcon></UploadIcon>}/>
              </div>
              {appendixRule.files.length > 0 &&
                <>
                  <div>Files upload</div>
                    {appendixRule.files.map((file, index) =>
                      <CRow key={`file_${index}`} className='pdfRow'>
                        <CCol xs={8}>
                          <div className='pdfName'>
                            {file.pdfName}
                          </div>
                        </CCol>
                        <CCol xs={3}>
                          {file.uploadDate ? moment.utc(file.uploadDate).format("YYYY-MM-DD") : "--"}
                        </CCol>
                        <CCol xs={1}>
                          <CloseIcon onClick={() => onRemoveFile(index)}/>
                        </CCol>
                      </CRow>
                    )}
                </>
              }
              {hasValidationError && appendixRule.files.length ==0 &&
                <div className={"cx-error-message"}>{"Required"}</div>
              }
            </CCol>
          </CRow>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={()=> handleClose("isAddNewAppendixLightBoxVisible")} fontSize={"16px"} width={"100%"} /></div>
          <div className='save-button'><CXButton text={appendixModel.isAddNewMode ? "Add" : "Save"} onClick={()=> onClickAddAppendix()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>

    {/*View Appendix */}
    <Modal className="snnModal" show={appendixModel.isViewAppendixLightBoxVisible} dialogClassName="modal-90w" onHide={closeModal} size="xl">
        <Modal.Header className="modal-header" closeButton>
            <Modal.Title>View Appendix Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow className='modalRow'>
            <CCol xs={6}>
              <span className='modal-section-title'>Aircraft type and flight details</span>
            </CCol>
            <CCol xs={6}>
              <CRow className='modalRow'>
                <CCol xs={4}>
                  <ViewLabel label="Airline" value={appendixModel.body.airline}></ViewLabel>
                </CCol>
                <CCol xs={4}>
                  <ViewLabel label="Flight no" value={appendixModel.body.flightNo}></ViewLabel>
                </CCol>
                <CCol xs={4}>
                  <ViewLabel label="Aircraft type" value={appendixModel.body.aircraftType}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <CCol xs={4}>
                  <ViewLabel label="Resgistration" value={appendixModel.body.registration}></ViewLabel>
                </CCol>
              </CRow>
              <CRow className='modalRow'>
                <>
                  <div className="CathaySansLt" style={{color:"#808285"}}>
                    {"Origin (Use “enter” key for multiple input)"}
                  </div>
                  <div className='tags-view-container'>
                    {appendixModel.body.dep && appendixModel.body.dep.length > 0 && appendixModel.body.dep.map((dep, index) =>
                      <div className="tag-item-view" key={`Origin_Tag${index}`}>
                          <span className="text">{dep}</span>
                      </div>)}
                  </div>
                </>
              </CRow>
              <CRow className='modalRow'>
                <>
                  <div className="CathaySansLt" style={{color:"#808285"}}>
                    {"Destination (Use “enter” key for multiple input)"}
                  </div>
                  <div className='tags-view-container'>
                    {appendixModel.body.arr && appendixModel.body.arr.length > 0? appendixModel.body.arr.map((arr, index) =>
                      <div className="tag-item-view" key={`Dest_Tag${index}`}>
                          <span className="text">{arr}</span>
                      </div>)
                      :
                      <></>}
                  </div>
                </>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow className='modalRow'>
            <CCol xs={6}>
              <span className='modal-section-title'>Expiry and review dates</span>
            </CCol>
            <CCol xs={6}>
              <CRow className='modalRow'>
                <CCol xs={6}>
                  <ViewLabel label="Expiry date" value={appendixModel.body.expiry ? moment.utc(appendixModel.body.expiry)?.format("DDMMMYYYY HH:mm") : "--"}></ViewLabel>
                </CCol>
                <CCol xs={6}>
                  <ViewLabel label="Reviews date"  value={appendixModel.body.review ? moment.utc(appendixModel.body.review)?.format("DDMMMYYYY HH:mm") : "--"}></ViewLabel>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow className='modalRow'>
            <CCol xs={6}>
              <span className='modal-section-title'>Other information</span>
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={4}>
                  <ViewLabel label="ALTN" value={appendixModel.body.altn}></ViewLabel>
                </CCol>
                <CCol xs={4}>
                  <ViewLabel label="ERA" value={appendixModel.body.era}></ViewLabel>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <hr className="solid"></hr>
          <CRow className='modalRow'>
            <CCol xs={6}>
              <span className='modal-section-title'>Files uploaded</span>
            </CCol>
            <CCol className="pdfRow" xs={6}>
              {appendixModel.body.files && appendixModel.body.files.map((file,index) =>
                <CRow key={`file_${index}`} className='pdfRow'>
                  <CCol xs={9}>
                    <div className='pdfName'>
                      {file.pdfName}
                    </div>
                  </CCol>
                  <CCol xs={3}>
                    {file.uploadDate ? moment.utc(file.uploadDate).format("YYYY-MM-DD") : "--"}
                  </CCol>
                </CRow>
                )}
            </CCol>
          </CRow>
        </Modal.Body>
        <CRow>
          <CCol>
            <Modal.Footer className="modal-footer-start">
              <Access route={routePath} access={[AccessType.UPDATE]}>
                <div style={{ bottom: "40px", left: "40px", width: "200px" }}><CXButton outline={true} text={"Duplicate"} onClick={()=> dispatchReducer({type:"DUPLICATED", appendix: appendixModel.body})} fontSize={"16px"} width={"100%"} /></div>
              </Access>
            </Modal.Footer>
          </CCol>
          <CCol>
            <Modal.Footer className="modal-footer">
              <Access route={routePath} access={[AccessType.UPDATE, AccessType.DELETE]}>
                <Access route={routePath} access={[AccessType.DELETE]}>
                  <div className='cancel-button'><CXButton outline={true} text={"Delete"} onClick={()=> dispatchReducer({type:"DELETE", appendix: appendixModel.body})} fontSize={"16px"} width={"100%"} /></div>
                </Access>
                <Access route={routePath} access={[AccessType.UPDATE]}>
                  <div className='save-button'><CXButton text={"Edit"} onClick={()=> dispatchReducer({type:"EDIT", appendix: appendixModel.body})} fontSize={"16px"} width={"100%"} /></div>
                </Access>
              </Access>
            </Modal.Footer>            
          </CCol>
        </CRow>
      </Modal>

    {/* Edit file */}
    <SelectFileModal
      isShow={appendixModel.isSelectAppendixLightBoxVisible}
      onHide={closeModal}
      dispatchReducer={dispatchReducer}
      onSelectFile={onSelectFile}
      onUploadFile={onUploadFile}
      filesList={appendixRule.files.map(file => {return file.pdfName})}
      type="ALL"/>

    {/* Delete appendix */}
    <Modal className="appendixModal" show={appendixModel.isDeleteAppendixLightBoxVisible} dialogClassName="modal-90w" onHide={closeModal} size="xl">
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title>Delete Appendix Rule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CRow>
          Are you sure to delete this appendix rule?
        </CRow>
        <CTable className="appendixTable" borderless hover>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>
                Airline
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>
                Flt no
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>
                A/C type
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>
                Reg
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>
                <div>Orig</div>
                <div>Dest</div>
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>
                <div>ALTN</div>
                <div>ERA</div>
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>
                <div>Expiry Date (UTC)</div>
                <div>Review Date (UTC)</div>
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>
                Files
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {(!!appendixModel.body && <CTableRow style={{ borderBottom: "1px solid #D8D8D8" }}>
              <RobotoMonoRgDataCell>{appendixModel.body.airline || "--"} {appendixModel.body.flightNo || "--"}</RobotoMonoRgDataCell>
              <RobotoMonoRgDataCell>{appendixModel.body.aircraftType || "--"}</RobotoMonoRgDataCell>
              <RobotoMonoRgDataCell>{appendixModel.body.registration || "--"}</RobotoMonoRgDataCell>
              <RobotoMonoRgDataCell>{Array.isArray(appendixModel.body.dep) && appendixModel.body.dep.map((ori,index) => <div key={`ori_${index}`}>{ori}</div>)}</RobotoMonoRgDataCell>
              <RobotoMonoRgDataCell>{Array.isArray(appendixModel.body.arr) && appendixModel.body.arr.map((arr,index) => <div key={`arr_${index}`}>{arr}</div>)}</RobotoMonoRgDataCell>
              <RobotoMonoRgDataCell>
                <div>
                  {appendixModel.body.altn || "--"}
                </div>
                <div>
                  {appendixModel.body.era || "--"}
                </div>
              </RobotoMonoRgDataCell>
              <RobotoMonoRgDataCell>
              <div>
                {moment.utc(appendixModel.body.expiry)?.format("DDMMMYYYY HH:mm") || "--"}
              </div>
              <div>
                {moment.utc(appendixModel.body.review)?.format("DDMMMYYYY HH:mm") || "--"}
              </div>
            </RobotoMonoRgDataCell>
            <RobotoMonoRgDataCell>{appendixModel.body.files && appendixModel.body.files.join(",") || "--"}</RobotoMonoRgDataCell>
            </CTableRow>)}
          </CTableBody>
        </CTable>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={()=> handleClose("isDeleteAppendixLightBoxVisible")} fontSize={"16px"} width={"100%"} /></div>
        <div className='save-button'><CXButton text={"Delete"} onClick={()=> confirmDelete()} fontSize={"16px"} width={"100%"} /></div>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default AppendixRulePage;
