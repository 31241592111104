import React from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from '@coreui/react';
import { DownArrowIcon } from '../../assets/images';
import SNNRulesTableItem from './SNNRulesTableItem';
import { ISNNRule, setSNNRules } from '../../slice/simSlice';
import { useAppDispatch } from '../../app/hooks';
import NoDataTableItem from '../AdminDashboard/NoDataTableItem';
import { sortAutoItems } from '../../utils/sortHelper';
import Access from '../Access';
import { AccessType } from '../../constants/roleAccessConstant';

interface ISNNRulesTableProps {
  snnRules: ISNNRule[];
  activeStatus: string;
  airline?: string;
  interval?: string;
  activeCategory?: string;
  dispatchReducer: React.Dispatch<any>;
  routePath: string;
}


const SNNRulesTable = ({ snnRules, activeStatus, airline, interval, activeCategory, dispatchReducer, routePath }: ISNNRulesTableProps) => {
  let sortedSnnRules = [...snnRules];
  const dispatch = useAppDispatch();

  const dispatchSortAction = () => {
    dispatch(setSNNRules(sortedSnnRules));
  };

  const sortByLocale = (key: string) => {
    const { sortedList } = sortAutoItems(sortedSnnRules, key);
    sortedSnnRules = sortedList.map(item => ({ ...item }));
    dispatchSortAction();
  }

  const filterData = () => {
    const isActiveStatus = activeStatus === "Active";
    const isInterval = interval === "Enabled";
    return snnRules.filter(item => {
      if (activeStatus && activeStatus?.toUpperCase() !== "ALL" && activeStatus !== "" && item.isActive !== isActiveStatus) {
        return false;
      }
      if (airline && airline?.toUpperCase() !== "ALL" && airline !== "" && item.airline !== airline) {
        return false;
      }
      if (interval && interval?.toUpperCase() !== "ALL" && interval !== "" && item.isInterval !== isInterval) {
        return false;
      }
      if (activeCategory && activeCategory?.toUpperCase() !== "ALL" && activeCategory !== "" && item.type !== activeCategory) {
        return false;
      }
      return true;
    });
  }

  return <CTable className="snnTable" borderless hover>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Airline
          </div>
          <div>
            Flt no
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snnHeader"} onClick={() => {
          sortByLocale("aircraftType")
        }}>
          A/C type
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snnHeader"} onClick={() => {
          sortByLocale('registration');
        }}>
          Reg
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snnHeader"} onClick={() => {
          sortByLocale("dep");
        }}>
          Ori
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snnHeader"} onClick={() => {
          sortByLocale("arr");
        }}>
          Dest
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            ERA
          </div>
          <div>
            ALTN
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Eff Start UTC
          </div>
          <div>
            Eff End UTC
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Time rng
          </div>
          <div>
            Interval
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Waypoint
          </div>
          <div>
            Country Code
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Uploaded By
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Version No
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snn snnHeader"} onClick={() => {
          sortByLocale("snn")
        }}>
          SNN
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <Access route={routePath} access={[AccessType.UPDATE, AccessType.DELETE]} >
          <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>Action</CTableHeaderCell>
        </Access>
      </CTableRow>
    </CTableHead>
    <CTableBody>
      {!!snnRules && snnRules.length !== 0 ? (
        filterData().map((snnRule, index) => {
          return (
            <SNNRulesTableItem
              key={`SNNRulesTableItem${index}`}
              snnRule={snnRule}
              dispatchReducer={dispatchReducer}
              routePath={routePath}
            />
          )
        }
        )) : (
        <NoDataTableItem colSpan={11} message={"There is no SNN Rules"} />
      )}
    </CTableBody>
  </CTable>
};

export default SNNRulesTable;
