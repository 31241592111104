import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getFlightDetail } from '../../services/dispatchAPI';
import { selectDispatcher, setFlightDetail } from '../../slice/dispatcherSlice';
import FlightDetailContent from '../../components/AdminDashboard/FlightDetailContent';
import React from 'react';
import { Airline2IATA } from "../../constants/genericTableConstant";

/**
 * Application home page component
 * @returns {JSX}
 */

const FlightDetailPage = () => {
  // const params = useParams()
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const ufi  = query.get('ufi') || "";
  const airlineICAO = ufi.substring(0, 3);
  const airlineIATA = Airline2IATA[airlineICAO];
  const dispatch = useAppDispatch();
  const { flightDetail } = useAppSelector(selectDispatcher);

  useEffect(() => {
    getFlightDetail(ufi, airlineIATA).then(res => {
      dispatch(setFlightDetail(res));
    }).catch(err => {
      console.log(err);
    });
  }, [ufi, airlineIATA, dispatch]);

  return (
    <>
      <FlightDetailContent user={airlineIATA.toLowerCase()} ufi={ufi} flightDetail={flightDetail}/>
    </>
  );
};

export default FlightDetailPage;
