import React, {useEffect, useMemo, useReducer, useState} from 'react';

import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell} from '@coreui/react';
import _ from 'lodash';
import {getUOFuelAdvisoryConfig, updateUOFuelAdvisoryConfig} from '../../services/bekolDispatchAPI';
import {
  IFuelAdvisoryConfigObject,
  resetFuelAdvisoryConfig,
  selectDispatcher,
  setFuelAdvisoryConfig
} from '../../slice/dispatcherSlice';
import {selectUser} from '../../slice/userSlice';
import { selectAuth } from '../../slice/authSlice';
import {CCol, CRow} from "@coreui/react-pro";
import {CXButton} from "../../components/CXForm";
import {Modal} from "react-bootstrap";
import CXFPConfigTextInput from "../../components/CXForm/CXFPConfigTextInput";
import ToggleSwitch from "../../components/ToggleSwitch";
import NoDataTableItem from "../../components/AdminDashboard/NoDataTableItem";
import FuelAdvConfigTableItem from "../../components/AdminDashboard/FuelAdvisoryConfigTableItem";
import TagsInput from "../../components/TagInputs";

import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';
import { checkIfHasAccess } from '../../utils/roleAccessHelper';
import { useLocation } from 'react-router-dom';


/**
 * Application home page component
 * @returns {JSX}
 */


const UOFuelAdvisoryConfigPage = () => {
    const { pathname: routePath } = useLocation();
    const dispatch = useAppDispatch();
    const { userGroupIDs } = useAppSelector(selectAuth);
    // all configs retrieved from remote
    const {fuelAdvConfigs} = useAppSelector(selectDispatcher);
    const {userId} = useAppSelector(selectUser);
    const initialFuelAdvisoryForm: IFuelAdvisoryConfigObject = {
        icao: "",
        galacxyId: "",
        emails: [],
        active: false,
        lastUpdated: new Date(),
    }
    const [selectedFuelAdv, setFuelAdvForSubmit] = useState<IFuelAdvisoryConfigObject>(initialFuelAdvisoryForm);
    const tableColName: Array<string> = [
        "Airport ICAO",
        "Last Updated",
        "Last Updated GID",
        "Emails",
        "Active",
        "Action"
    ];
    const displayColName: Array<string> = (checkIfHasAccess(routePath, userGroupIDs, [AccessType.UPDATE]))? tableColName: tableColName.slice(0,5);
    const initFuelAdvReducer = {
        data: initialFuelAdvisoryForm,
        isAddNewMode: false,
        isAddNewFuelAdvLightBoxVisible: false,
    }

  const reducer = (state, action) => {
    switch (action.type) {
      case "NEW":
        return {
          ...state,
          data: initialFuelAdvisoryForm,
          isAddNewMode: true,
          isAddNewFuelAdvLightBoxVisible: true
        };
      case "EDIT":
        setFuelAdvForSubmit(action.updatedConfigItem);
        return {
          ...state,
          isAddNewMode: false,
          isAddNewFuelAdvLightBoxVisible: true
        };
      case "CLOSE_POP_DIALOG":
        setFuelAdvForSubmit(action.updatedConfigItem);
        return {
          ...state,
          data: initialFuelAdvisoryForm,
          isAddNewMode: false,
          isAddNewFuelAdvLightBoxVisible: false
        };
      default:
        return state;
    }
  };

  const [fuelAdvReducer, dispatchReducer] = useReducer(reducer, initFuelAdvReducer)

  const closeModal = () => {
    dispatchReducer({type: "CLOSE_POP_DIALOG", updatedConfigItem: fuelAdvReducer.data});
    setFuelAdvForSubmit(initialFuelAdvisoryForm);
  }

  const _convertToRequest = (fuelAdvForm: any): IFuelAdvisoryConfigObject => {
    return {
      ...fuelAdvForm,
      icao: fuelAdvForm.icao.toUpperCase(),
      lastUpdated: new Date(),
      emails: fuelAdvForm.emails || [],
      active: fuelAdvForm.active,
      galacxyId: userId
    }
  }

  const onClickCreate = (isAddNew) => {
    // create new snn
    const request: IFuelAdvisoryConfigObject = _convertToRequest(selectedFuelAdv);
    updateUOFuelAdvisoryConfig(request).then((res: IFuelAdvisoryConfigObject) => {
      dispatchReducer({type: "CLOSE_POP_DIALOG", updatedConfigItem: fuelAdvReducer.data});
      let updatedData: Array<IFuelAdvisoryConfigObject> = _.clone(fuelAdvConfigs);
      if (isAddNew) {
        updatedData.unshift(...[res]);
      } else {
        updatedData = updatedData.map((item) => {
          return item.icao === res.icao ? res : item;
        });
      }
      dispatch(setFuelAdvisoryConfig({fuelAdvConfigs: updatedData}));
    }).catch((err) => {
      console.log(err);
    });
  }

  const onChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempObj = {
      ...selectedFuelAdv
    }
    tempObj[event.target.name] = event.target.value;
    setFuelAdvForSubmit(tempObj);
  }

  const onEmailsChanged = (value: any, field: string) => {
    let tempObj = {
      ...selectedFuelAdv
    }
    tempObj[field] = value;
    setFuelAdvForSubmit(tempObj);
  }

  const onChangeField = (value: any, field: string, state: any, setState: Function) => {
    let tempObj = {
      ...state
    }
    tempObj[field] = value;
    setState(tempObj);
  }

  const generateItemsDisplay = () => {
    const displayArr = _.isArray(fuelAdvConfigs) ? fuelAdvConfigs : [];
    if (!_.isEmpty(displayArr)) {
      return fuelAdvConfigs.map((config, index) => {
        return <FuelAdvConfigTableItem
          key={`FuelAdvConfigTableItem${index}`}
          fuelAdv={config}
          dispatchReducer={dispatchReducer}
          routePath={routePath}
        />
      })
    } else {
      return <NoDataTableItem colSpan={11} message={"There is no Fuel Advisory Configs"}/>
    }
  }

  useEffect(() => {
    dispatch(resetFuelAdvisoryConfig());
    const getFuelAdvConfigs = async () => {
      const response = await getUOFuelAdvisoryConfig();
      if (!!response) {
        dispatch(setFuelAdvisoryConfig({fuelAdvConfigs: response}));
      }
    }
    getFuelAdvConfigs();
  }, [dispatch])

  return (
    <>
      <div className={"section d-flex justify-content-between"}>
        <div className={"CathaySansBd"} style={{fontSize: "32px", lineHeight: "36px"}}>Fuel Advisory</div>
        <CRow className={"p-2 ms-auto"}>
          <CCol sm>
            <Access route={routePath} access={[AccessType.CREATE]}>
              <CXButton
                onClick={() => {
                  dispatchReducer({type: "NEW", updatedConfigItem: initialFuelAdvisoryForm})
                }}
                text={"Add New Config"}
                outline={true}
                fontSize={"14px"}/>
            </Access>
          </CCol>
        </CRow>
      </div>

      {/* --------- Add new fuel advisory config ---------*/}
      <Modal show={fuelAdvReducer.isAddNewFuelAdvLightBoxVisible} onHide={closeModal}>
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>
            {fuelAdvReducer.isAddNewMode ? "Add New Config" : "Edit Config"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flex: '1', flexDirection: 'column' }}>
          <CRow>
            <CXFPConfigTextInput
              placeholder={"Airport ICAO"}
              value={selectedFuelAdv.icao}
              onChangeField={onChangeModal}
              field="icao"
              errMsg={"Required"}
              zIndex={1031}/>
            <div >
            <TagsInput
              placeholder={"Emails (Use “enter” key for multiple input)"}
              tagList={selectedFuelAdv.emails}
              onChangeField={onEmailsChanged}
              field="emails"
              errMsg="Required"></TagsInput>
            </div>
            <div style={{marginTop: "20px"}}>
              <ToggleSwitch
                name="flexCheckDefault"
                checked={selectedFuelAdv.active}
                onChangeField={onChangeField}
                field="active"
                state={selectedFuelAdv}
                setState={setFuelAdvForSubmit}></ToggleSwitch>
              <label style={{ marginLeft: '8px' }}>Active Advisory</label>
            </div>
          </CRow>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => closeModal()}
                                                   fontSize={"16px"} width={"100%"}/></div>
          <div className='save-button'><CXButton text={fuelAdvReducer.isAddNewMode ? "Add" : "Save"}
                                                 onClick={() => onClickCreate(fuelAdvReducer.isAddNewMode)}
                                                 fontSize={"16px"}
                                                 width={"100%"}/></div>
        </Modal.Footer>
      </Modal>

      <CTable className="snnTable" hover>
        <CTableHead>
          <CTableRow>
            {
              displayColName.map((title, index) => {
                return <CTableHeaderCell className={"CathaySansLt px-0 snnHeader"}
                                         key={`FuelAdvConfigTableHeader${index}`}
                                         onClick={() => {
                                         }}>
                  <div>
                    {title}
                  </div>
                </CTableHeaderCell>
              })
            }
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {generateItemsDisplay()}
        </CTableBody>
      </CTable>
    </>
  );
};

export default UOFuelAdvisoryConfigPage;
