import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { IFilterObject, ICloseFlightFilterObject } from "../interfaces/app";

interface IAppFlagPayload {
  field: string;
  value: boolean;
}

interface IToastObject {
  type: string;
  message: string;
  width?: string;
  className?: string;
}
interface AppSlice {
  isAddNewSimOFPLightBoxVisible:  boolean;
  isBackgroundLoading:            boolean;
  isEditSimOFPLightBoxVisible:    boolean;
  is401Unauthorized:              boolean;
  isLoading:                      boolean;
  isSidebarVisible:               boolean;
  filter:                         IFilterObject;
  selectedTag:                    string;
  toast:                          IToastObject;
  isOpenAddNewAppendixRuleModal: boolean;
  closeFlightFilter:              ICloseFlightFilterObject;
}

const initalFilterState: IFilterObject = {
  isDraftMZFW: false,
  isStop: false,
  missingAdj: false,
  hasError: false,
  aircraftChanged: false,
  isAOS: false,
};

const initialCloseFlightFilterObject: ICloseFlightFilterObject = {
  airline:          "",
  flightNo:         "",
  reg:              "",
  closeFlightType:  "",
  origin:           "",
  destination:      "",
};

const initalToastState: IToastObject = {
  type: "",
  message: "",
  width: '',
  className: "",
};

const initialState: AppSlice = {
  isAddNewSimOFPLightBoxVisible: false,
  isBackgroundLoading: false,
  isEditSimOFPLightBoxVisible: false,
  is401Unauthorized: false,
  isLoading: false,
  isSidebarVisible: true,
  filter: initalFilterState,
  selectedTag: "",
  toast: initalToastState,
  isOpenAddNewAppendixRuleModal: false,
  closeFlightFilter: initialCloseFlightFilterObject,
};



const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppFlags: (state, { payload }: PayloadAction<IAppFlagPayload>) => {
      const { field, value } = payload;
      state[field] = value;
    },
    setIsAddNewSimOFPLightBoxVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddNewSimOFPLightBoxVisible = payload;
    },
    setIsEditSimOFPLightBoxVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditSimOFPLightBoxVisible = payload;
    },
    setFilter: (state, { payload }: PayloadAction<IFilterObject>) => {
      state.filter = initalFilterState;
      state.filter = {
        ...state.filter,
        ...payload
      };
      let [tag, val] = Object.entries(payload)[0];
      if (val === true) {
        state.selectedTag = tag;
      } else {
        state.selectedTag = "";
      }
    },
    clearFilter: (state) => {
      state.filter = initalFilterState;
      state.selectedTag = "";
    },
    pushToast: (state, { payload }: PayloadAction<IToastObject>) => {
      state.toast = payload;
    },
    clearToast: (state) => {
      state.toast = initalToastState;
    },
    setCloseFlightFilter: (state, { payload }: PayloadAction<ICloseFlightFilterObject>) => {
      state.closeFlightFilter = {
        ...state.closeFlightFilter,
        ...payload
      };
    },
    clearCloseFlightFilter: (state) => {
      state.closeFlightFilter = initialCloseFlightFilterObject;
    },
  }
});

const selectApp = (state: RootState) => state.app;
const {
  setAppFlags,
  setFilter,
  clearFilter,
  pushToast,
  clearToast,
  setCloseFlightFilter,
  clearCloseFlightFilter,
} = appSlice.actions;

export { appSlice, selectApp, setAppFlags, setFilter, clearFilter, pushToast, clearToast, setCloseFlightFilter, clearCloseFlightFilter };
export type { AppSlice };

export default appSlice.reducer;