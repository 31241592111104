interface ICarReportRule {
  _id: string;
  Airline: string;
  Email: string;
  Type: string;
  galacxyId: string;
  updatedAt: string;
  createdAt: string
}

const initialCarReportEmailRule: ICarReportRule = {
  _id: "",
  Airline: "",
  Email: "",
  Type: "",
  galacxyId: "",
  updatedAt: "",
  createdAt: "",
};

const initialCarReportEmailReducer = {
  isAddNewMode: false,
  isAddBoxVisible: false,
  isDeleteBoxVisible: false,
};

export type { ICarReportRule };
export {
  initialCarReportEmailRule,
  initialCarReportEmailReducer,
};