interface EDGComparisonSrcDetailItemProps {
    field: string;
    value: string;
}

const EDGComparisonSrcDetailItem = ({ field, value }: EDGComparisonSrcDetailItemProps) => {
    return (
        <div className="mt-4">
            <div className="edg-src-dtl-item-field">{field || '--'}</div>
            <div className="edg-src-dtl-item-value">{value || '--'}</div>
        </div>
    );
};

export default EDGComparisonSrcDetailItem;