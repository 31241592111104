import { FLIGHT_PLAN_CONFIG_PATHS } from "../../constants/path";
import { routeConfig } from "../../interfaces/route";
import DataDetailPage from "./DataDetailPage";
import DataPage from "./DataPage";



const dataRouterConfig: routeConfig[] = [
    {
        path: FLIGHT_PLAN_CONFIG_PATHS.data,
        component: DataPage,
    },
    {
        path: FLIGHT_PLAN_CONFIG_PATHS.dataDetail,
        component: DataDetailPage,
    }
];

export default dataRouterConfig;