import * as React from 'react';
import { CCard, CCardBody, CCardText, CCardTitle } from '@coreui/react';
import { setFilter } from '../slice/appSlice';
import { useAppDispatch } from '../app/hooks';

const AppFilterButton = ({ count, description, tag, selected}) => {
    const dispatch = useAppDispatch();
    const hasError = (count > 0) && description === "Flight plan error";
    return  <button className={"clickable"} onClick={() => dispatch(setFilter({[`${tag}`]: !selected}))}>
        <CCard className={`${selected? "selectedFilterButton" : "filterButton"}`}>
            <CCardBody className={`filterButtonBody ${hasError && "errorFilterButtonBody"}`}>
                <CCardTitle className={"filterButtonCount CathaySansMd"}>{count}</CCardTitle>
                <CCardText className={"filterButtonTitle CathaySansRg"}>
                    {description}   
                </CCardText>
            </CCardBody>
        </CCard>
    </button>
};

export default AppFilterButton;