import { CCol, CRow, CCallout, CButton } from '@coreui/react-pro';
import React, { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { DeleteIcon, EditIcon } from '../../assets/images';
import { IPortSec, IPortSecItem } from '../../slice/dispatcherSlice';
import CXTextInput from '../CXForm/CXTextInput';
import CXFPConfigTextInput from '../CXForm/CXFPConfigTextInput';
import { CXButton } from '../../components/CXForm';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import rehypeRaw from "rehype-raw";
import {needOneMoreInput, displayPrefixValue} from './PortPageDataDisplayHelper';


const style = {
  cursor: 'move',
}
interface IPortPageContentProps {
  name: string;
  secItems: IPortSecItem[];
  index: number;
  isDeletable: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  removeTab: (index: number) => void;
  editTabSecs: IPortSec[];
  dispatchReducer: React.Dispatch<any>;
  tabIndex: number;
}

interface DragItem {
  index: number
  id: string
  type: string
}

const PortPageContent = ({ name, secItems = [], index, isDeletable, moveCard, removeTab, editTabSecs, dispatchReducer, tabIndex }: IPortPageContentProps) => {

  const [sectionTitle, setSectionTitle] = useState<string>(name);
  const [items, setItems] = useState<IPortSecItem[]>(secItems);

  useEffect(() => {
    setSectionTitle(name);
    setItems(secItems);
  }, [name, secItems])

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'input',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      //   if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //     console.log("cannot move down")
      //     return
      //   }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    drop(item: DragItem, monitor) {
      dispatchReducer({ type: "RE_ORDER_SECS", tabIndex: tabIndex, tabSecs: editTabSecs });
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: "input",
    item: () => {
      return { index, items }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const purePrefix = prefix => prefix && prefix.replace(':', '');

  // const needOneMoreInput = key => ['UTC'].includes(key.toUpperCase());

  // const displayPrefixValue = (key, value, prefixInputCopy = false) => {
  //   if (needOneMoreInput(key)) {
  //     const VALUE_DIVIDER = '__',
  //           OLD_VALUE_DIVIDER = ' ',
  //           ADOPTED_NEW_FORMAT = checkIfValueAdoptNewFormat(value, VALUE_DIVIDER),
  //           USE_VALUE_DIVIDER = ADOPTED_NEW_FORMAT? VALUE_DIVIDER: OLD_VALUE_DIVIDER;
  //     const utcInputs = value ? value.split(USE_VALUE_DIVIDER) : [];

  //     return prefixInputCopy
  //       ? (utcInputs.length > 1 ? utcInputs[1] : '')
  //       : (utcInputs.length > 0 ? utcInputs[0] : value);
  //   } else
  //     return value;

  //   function checkIfValueAdoptNewFormat($concatOriginalValue: string, $divider: string): boolean{
  //     return $concatOriginalValue.includes($divider);
  //   }
  // };

  // const displayMdValue = (value) => {
  //   return value.replaceAll(/\n/g, "   \n");
  // };

  const tableStyles = `
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    word-wrap: break-word;
  }
  `;

  return (
    <CRow ref={ref} style={{ ...style, opacity }} className="port-page-content" data-handler-id={handlerId}>
      <CCol xs={2} className={'content-title'}>
          {name}
      </CCol>
      <CCol xs={9}>
        {items != undefined && items.length > 0 && items.map((item, secItemIndex) => {
          return (
            <React.Fragment key={`portSecItem${secItemIndex}`}>
              <CRow>
                {item.type == "TEXT" && item.prefix &&
                  <Col xs="auto" className='content-prefix CathaySansIt'>
                    <span>{purePrefix(item.prefix)}</span>
                  </Col>
                }
                {item.type == "TEXT" &&
                  <Col className='content-input'>
                    <CXFPConfigTextInput disabled={true} hasLabel={false} value={displayPrefixValue(purePrefix(item.prefix), item.value)} />
                  </Col>
                }
                {item.type == "TEXT" &&
                  needOneMoreInput(purePrefix(item.prefix)) &&
                  <Col className='content-input'>
                    <CXFPConfigTextInput disabled={true} hasLabel={false} value={displayPrefixValue(purePrefix(item.prefix), item.value, true)} />
                  </Col>
                }
                {item.type == "TEXT" && item.suffix &&
                  <Col xs="auto" className='content-suffix CathaySansIt'>
                    <span>{item.suffix}</span>
                  </Col>
                }
              </CRow>
              {item.type == "MD" && item.value && item.value.length && item.value.map((value, valueIndex) => {
                return (
                  <>
                    <CRow>
                      <CCallout color="secondary" style={{ width: '1100px' }}>
                        <style>{tableStyles}</style>
                        <ReactMarkdown children={value} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}></ReactMarkdown>
                      </CCallout>
                      <CCol sm={6} style={{ marginTop: '5px', padding: "0px" }}>
                        <EditIcon style={{ backgroundColor: "#EFEFEF", height: "3rem", width: '3rem', marginLeft: "10px" }}/>
                        <DeleteIcon style={{ backgroundColor: "#EFEFEF", height: "3rem", width: '3rem', marginLeft: "10px" }} />
                      </CCol>
                    </CRow>
                  </>
                )
              })
              }
              {item.type == "MD" && item.value && item.value.length &&
                <CRow> 
                  <CCol style={{ marginTop: '10px' }}>
                    <CXButton text={ "+ Add new notam" } disabled={ true } fontSize={ "18px" } width={ "20%" } height='38px' />
                  </CCol>
                </CRow>
              }
            </React.Fragment>
          )
        })
        }
      </CCol>
      <CCol xs={1}>
      </CCol>
    </CRow>
  );
};

export default PortPageContent;