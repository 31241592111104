import React from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from '@coreui/react';
import { DownArrowIcon } from '../../assets/images';
import { IAppendixRule, setAppendixRules } from '../../slice/simSlice';
import { useAppDispatch } from '../../app/hooks';
import AppendixRulesTableItem from './AppendixRulesTableItem';
import NoDataTableItem from '../AdminDashboard/NoDataTableItem';
import { sortAutoItems } from '../../utils/sortHelper';
import Access from '../Access';
import { AccessType } from '../../constants/roleAccessConstant';

interface IAppendixRulesTableProps {
  appendixRules: IAppendixRule[];
  dispatchReducer: React.Dispatch<any>;
  routePath: string;
}


const AppendixRulesTable = ({ appendixRules, dispatchReducer, routePath }: IAppendixRulesTableProps) => {
  let sortedAppendixRules = [...appendixRules];
  const dispatch = useAppDispatch();

  const dispatchSortAction = () => {
    dispatch(setAppendixRules(sortedAppendixRules));
  };

  const sortByLocale = (key: string) => {
    const { sortedList } = sortAutoItems(sortedAppendixRules, key);
    sortedAppendixRules = sortedList.map(item => ({ ...item }));
    dispatchSortAction();
  }

  return <CTable className="appendixTable" borderless hover>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"} onClick={() => {
                    }}>
                        <div>
                    Airline
                    </div>
                    <div>
                    Flt no
                    </div>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortByLocale("aircraftType")
                    }}>
                    A/C type
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortByLocale("registration")
                    }}>
                    Reg
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortByLocale("dep")
                    }}>
                    Ori
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortByLocale("arr")
                    }}>
                        Dest
                        <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"} onClick={() => {
                    }}>
                        <div>
                    ALTN
                    </div>
                    <div>
                    ERA
                    </div>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortByLocale("expiry")
                    }}>
                        Expiry
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortByLocale("review")
                    }}>
                        Reviews
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortByLocale("files")
                    }}>
                    <div>
                        Files
                        <DownArrowIcon height={12} width={12} />
                    </div>
                    </CTableHeaderCell>
                    <Access route={routePath} access={[AccessType.UPDATE, AccessType.DELETE]}>
                        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>Action</CTableHeaderCell>
                    </Access>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {!!appendixRules && appendixRules.length !==0? 
                    appendixRules.map((appendixRule, index) => 
                        <AppendixRulesTableItem 
                            key={`AppendixRulesTableItem_${index}`} 
                            appendixRule={appendixRule}
                            dispatchReducer={dispatchReducer}
                            routePath={routePath}/>) 
                    : 
                    (
                        <NoDataTableItem colSpan={10} message={"There is no Appendix Rules"} />
                    ) 
                }
            </CTableBody>
        </CTable>
};

export default AppendixRulesTable;