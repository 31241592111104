import React from "react";
import { CCol, CRow } from "@coreui/react";
import { Modal } from "react-bootstrap";
import { CXButton, CXDropDown, CXTextInput } from "../../../components/CXForm";
import { ACTIONS } from "../../../constants/CarReportConstants";

export interface IDropProps {
  value: string;
  placeholder: string;
  onChange: any;
  options: any;
  field: string;
  state: any;
  itemWidth: string;
}

export interface ITextInputProps {
  value: string;
  placeholder: string;
  onChange: any;
  options: any;
  field: string;
  state: any;
  errMsg: string;
  validation: any;
}

export interface ICarEditModalProps {
  isShow: boolean;
  isEdit: boolean;
  modalTitle?: string;
  dropProps?: any;
  textInputProps?: any;
  editingItem: any;
  dispatchReducer: React.Dispatch<any>;
  isEnable?: boolean;
  onClickAction: Function;
}

export const CarEditItemModal = ({
  isShow,
  isEdit,
  modalTitle,
  dropProps,
  textInputProps,
  editingItem,
  dispatchReducer,
  isEnable = true,
  onClickAction,
}: ICarEditModalProps) => {
  const onChangeField = (
    value: string,
    field: string,
    state: any,
    setState: Function
  ) => {
    let tempObj = {
      ...state,
    };
    tempObj[field] = value;
    setState(tempObj);
  };

  const closeModal = () => {
    dispatchReducer({ type: ACTIONS.CLOSE });
  };

  const handleClose = () => {
    dispatchReducer({ type: ACTIONS.CLOSE });
  };

  const handleAction = () => {
    if (onClickAction) {
      onClickAction(isEdit ? ACTIONS.EDIT : ACTIONS.ADD_NEW, editingItem);
    }
    dispatchReducer({
      type: isEdit ? ACTIONS.EDIT : ACTIONS.ADD_NEW,
      emailItem: editingItem,
    });
  };

  return (
    <Modal
      className="appendixModal"
      show={isShow}
      dialogClassName="modal-90w"
      onHide={closeModal}
      size="xl"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CRow>
          <CCol xs={6}>
            <span className="modal-section-title">CAR report email</span>
          </CCol>
          <CCol xs={6}>
            {!!dropProps &&
              dropProps.length !== 0 &&
              Array.isArray(dropProps) &&
              dropProps.map((dropProp, index) => (
                <CRow>
                  <CCol>
                    <CXDropDown
                      value={dropProp.value}
                      placeholder={dropProp.placeholder}
                      onChange={dropProp.onChange}
                      options={dropProp.options}
                      onChangeField={onChangeField}
                      field={dropProp.field}
                      state={dropProp.state}
                      itemWidth={dropProp.itemWidth}
                      zIndex={1431 - index}
                    />
                  </CCol>
                </CRow>
              ))}
            {!!textInputProps &&
              textInputProps.length !== 0 &&
              Array.isArray(textInputProps) &&
              textInputProps.map((textProp, index) => (
                <CRow>
                  <CXTextInput
                    placeholder={textProp.placeholder}
                    value={textProp.value}
                    onChange={textProp.onChange}
                    onChangeField={onChangeField}
                    state={textProp.state}
                    field={textProp.field}
                    errMsg={textProp.errMsg}
                    additionalValidation={textProp.validation}
                    zIndex={1029 - index}
                  />
                </CRow>
              ))}
          </CCol>
        </CRow>
        <hr className="solid"></hr>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="cancel-button">
          <CXButton
            outline={true}
            text={"Cancel"}
            onClick={() => handleClose()}
            fontSize={"16px"}
            width={"100%"}
          />
        </div>
        <div className="save-button">
          <CXButton
            text={isEdit ? "Edit" : "Add"}
            onClick={() => handleAction()}
            fontSize={"16px"}
            width={"100%"}
            disabled={!isEnable}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
