import React, { useState } from 'react';
import { CenteredRightArrowIcon, DownArrowIcon } from '../../assets/images';

export const CXAccordion = ({ children, title }) => {
  const [expand, setExpand] = useState(false);
  const onClickSection = () => setExpand(!expand);
  return (
    <div style={{ background: "#F5F5F5", borderRadius: "16px", padding: "22px 25px", marginTop: "24px" }}>
      <div className={"clickable"} style={{ background: "#F5F5F5", position: "relative" }} onClick={onClickSection}>
        <div className={"loadsheetArrow"}>
          {expand ? <DownArrowIcon height={"24px"} width={"24px"} /> : <CenteredRightArrowIcon height={"24px"} width={"24px"} />}
        </div>
        <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", paddingLeft: "25px" }}>{title}</div>
      </div>
      {expand ? children : null}
    </div>
  );
};