import { useRef, useState } from 'react'

interface ICXTagsInputProps {
    placeholder: string;
    tagList: string[];
    disabled?: boolean;
    onChangeField?: Function;
    field?: string;
    state?: any;
    setState?: Function;
    additionalValidation?: Function;
    errMsg?: string;
    upperCase?: boolean;
    lowerCase?: boolean;
    onDispatchValue?: (updatedValue: string[], objectKey: string) => void;
    className?: string;
}
const TagsInput = ({placeholder, tagList=[], disabled=false, onChangeField, field, state, setState, additionalValidation, errMsg, upperCase=false, lowerCase=false, onDispatchValue,className}:ICXTagsInputProps) => {
    const [focus, setFocus] = useState(false);
    const [tags, setTags] = useState<string[]>(tagList);
    const textInputRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = (e) => {
        // If user did not press enter key, return
        if(e.key !== 'Enter') return
        // Get the value of the input
        let value = e.target.value
        // If the value is empty, return
        if(!value.trim()) return
        // If need upper or lower case
        if(upperCase || lowerCase){
            const valueStr = value.toString();
            value = upperCase ? valueStr.toUpperCase() : valueStr.toLowerCase();
        }
        // Add the value to the tags array
        setTags([...tags, value]);
        // Clear the input
        e.target.value = '';
        // Update to parent state
        if(onChangeField){
            onChangeField([...tags, value], field, state, setState);
        }

        if(onDispatchValue && field){
            onDispatchValue([...tags, value], field);
        }
    }

    const toggleFocus = () => {
        setFocus(!focus);
        if (textInputRef?.current) {
        textInputRef.current.focus();
        }
        
      };

    const removeTag = (index) => {
        let tempList = tags.filter((el, i) => i !== index);
        setTags(tempList)
        // Update to parent state
        if(onChangeField){
            onChangeField(tempList, field, state, setState);
        }
    }

    const onFocus = () => {
        setFocus(true)
      }
    
    const onBlur = () => {
    setFocus(false)
    }

    

    return (
        <>
            <div className={`tags-input-container ${className?className:''} ${additionalValidation && !additionalValidation(tagList) ? "error-border": "" }` }>
                <div style={{marginTop:"20px"}}>
                    { Array.isArray(tags) && tags.map((tag, index) => (
                        <div className="tag-item" key={`Tag${index}`}>
                            <span className="text">{tag}</span>
                            <span className="close" onClick={() => removeTag(index)}>&times;</span>
                        </div>
                    )) }
                    <input 
                        onKeyDown={handleKeyDown} 
                        type="text" 
                        className="tags-input" 
                        placeholder={""}
                        onFocus={() => onFocus()}
                        onBlur={() => onBlur()} 
                        disabled={disabled}/>
                </div>
                {(focus || tagList.length>0) ?
                <div>
                    <div className={"focused-label"} onClick={toggleFocus}>{placeholder}</div>
                    {/* <div className={"unit"} onClick={toggleFocus}>{unit || ""}</div> */}
                </div>
                :
                <div className={`${disabled ? "not-focused-label-disabled" : "not-focused-label"}`} onClick={toggleFocus}>{placeholder}</div>}
            </div>
            {additionalValidation && !additionalValidation(tagList) && errMsg && (
                <div className={"cx-error-message"}>{errMsg}</div>
            )}
        </>
    )
}

export default TagsInput