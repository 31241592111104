import * as React from 'react';
import { CTableRow, CTableDataCell } from '@coreui/react';
import _ from 'lodash';
import { TickIcon, CrossIcon } from '../../../assets/images';

const EDGStatusItem = ({ edgKind, status }) => {
  return <div className='d-flex justify-content-between align-items-center'>
    <div style={{ paddingTop: '2px' }}>{edgKind}</div>
    <div>
      {status ? <TickIcon />
        : <CrossIcon />}
    </div>
  </div>
}

const FlightStatusTableItem = ({ ufi, serial, std, timeline, crews, padis, flightplan, edg, flightplanOFPs, zfw, events, refueling, isActivated, isClosed, sentCloseFlightReminder, createAt, onClickView }) => {

  /**
   * edg status stored in ff_flight(progress), lido_flight_plan(edgStatus), edg_status
   * mapping between diff table shows as below:
   *
   * "edg" return from backend is object with AWS and Azure, can ref to story FCDOP3-2129
   * "edgBcp" return from backend is an array
   *
   *    edg_bcp      edg      desc                      edgStatus        color        remark
   *   |------|----|-----|---|----|---------------------|--------|------|----|------|------|
   *    empty       false     N/A                        NONE           danger       Neither normal nor backup EDG received
   *  NOT empty     false    NO_EDGS                     NOEDG          danger       tried to back up, but ALL failed
   *  NOT empty     false    NO_EDGS/BACK_FROM_OTHERS    NOEDG/BACKUP   warning      tried to back up, part of them failed
   *    empty       true     N/A                         NORMAL         success      EDG generated by OE side
   *  NOT empty    false     BACK_FROM_OTHERS            BACKUP         success      Backup EDG ALL successfully
   */

  const checkEdgStatus = () => {
    const dangerStatus = _.isEmpty(edg) || (edg && !edg.AWS && !edg.Azure);
    const status = dangerStatus ? "danger" : "success";
    return status;
  }

  return <CTableRow>
    <CTableDataCell className={"RobotoMonoBd tableItem"} style={{fontWeight: 'bold'}}>{ufi || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoBd tableItem"} style={{fontWeight: 'bold'}}>{serial || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoBd tableItem"}  style={{fontWeight: 'bold'}}>{std || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem"} >{timeline || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem"} color={padis?"success":"danger"}>{" "}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem flight-status-column"} color={crews?"success":"danger"}>{" "}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem flight-status-column"} color={flightplan?"success":"danger"}>{" "}</CTableDataCell>
    <CTableDataCell className={"flight-status-column flight-status-edg-col"} color={checkEdgStatus()}>
      <div>
        <EDGStatusItem edgKind={'AWS:'} status={edg?.AWS} />
        <EDGStatusItem edgKind={'Azure:'} status={edg?.Azure} />
        <div className='mt-1'>
          <span className='flight-status-edg-col-view-link' onClick={() => onClickView(ufi, serial)}>View</span>
        </div>
      </div>
    </CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem flight-status-column"} color={zfw?"success":"danger"}>{" "}</CTableDataCell>
    {/* <CTableDataCell className={"RobotoMonoRg tableItem"} color={refueling?"success":"danger"}>{" "}</CTableDataCell> */}
    <CTableDataCell className={"RobotoMonoRg tableItem flight-status-column"} color={isActivated?"success":"danger"}>{" "}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem flight-status-column"} color={isClosed?"success":"danger"}>{" "}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem"} color={sentCloseFlightReminder?"success":"danger"}>{" "}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem"}>{events || "-"}</CTableDataCell>
    <CTableDataCell className={"RobotoMonoRg tableItem"}>{createAt || "-"}</CTableDataCell>
  </CTableRow>
};

export default FlightStatusTableItem;
