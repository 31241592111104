const GENERIC_TABLE_TYPES = {
  snnRule: "SNN Rules",
  defect: "Defects",
  nsuNotam: "NSU Notam",
  oeNotam: "OE Notam",
  appendixRule: "Appendix Rules",
  portList: "Port List",
  crewFeedbackTagging: "Crew Feedback Tagging",
};
const Airline2IATA: { [index: string]: string } = {
  CPA: "CX",
  HDA: "KA",
  AHK: "LD",
  HKE: "UO",
};

const AirLines = {
  HKE: "HKE",
};

export {
  GENERIC_TABLE_TYPES,
  Airline2IATA,
  AirLines
};
