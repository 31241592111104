import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from "moment";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CRow, CCol, CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import NoDataTableItem from '../../components/AdminDashboard/NoDataTableItem';
import { CLOSE_FLIGHT_PATHS } from '../../constants/path';
import AppSectionOutline from '../../components/AppSectionOutline';
import { CenteredRightArrowIcon, DownArrowIcon } from '../../assets/images';
import { getResource } from '../../services/api';
import { postAdjustFlightPlan } from '../../services/adminAPI';
import { ufiFormatter } from '../../utils/ufiHelper';
import { dateFormatter } from '../../utils/dateHelper';
import DetailTableRow from '../DetailTableRow';
import {Airline2IATA} from "../../constants/genericTableConstant";
import { CXAccordion } from './CXAccordion';
import { CXScrollBox } from './CXScrollBox';
import { REFUELING_CONTENT_TYPE, ID1_SUMMARIES_KEYS_MAPPING, EBITS_SUMMARIES_KEYS_MAPPING, MF_SUMMARIES_KEYS_MAPPING, ID1_MILESTONES_KEYS_MAPPING, EBITS_MILESTONES_KEYS_MAPPING, MF_MILESTONES_KEYS_MAPPING } from '../../constants/flightsConstant';

import { pushToast } from '../../slice/appSlice';
import { useLocation } from "react-router-dom";
import { selectAuth } from '../../slice/authSlice';
import { UserGroupIDType } from '../../constants/roleAccessConstant';
import { checkIfHasSpecialRoles } from '../../utils/roleAccessHelper';


/**
 * Close Flight Detail Content Component for CX and UO
 * @returns {JSX}
 */

interface IFlightDetailContentProps {
  user: string;
  ufi: string;
  flightDetail: any;
}

const FlightDetailContent = ({ user, ufi, flightDetail }: IFlightDetailContentProps) => {
  const { pathname: routePath } = useLocation();
  const { userGroupIDs } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const closeFlightList = Object.values({});
  const closeFlightCount = closeFlightList.length;
  const [currentOFP, setCurrentOFP] = useState<string>("");

  const isSupervisor = checkIfHasSpecialRoles(routePath, userGroupIDs, [UserGroupIDType.SUPER_ADMIN, UserGroupIDType.SUPER_VISOR]);

  const [currentOFPObj, setCurrentOFPObj] = useState<any>();
  const [currentCrewsObj, setCurrentCrewsObj] = useState<any>();
  const [expandDigitalRefuel, setExpandDigitalRefuel] = useState(false);
  const [expandDigitalSummary, setExpandDigitalSummary] = useState(false);
  const [isFlightPlanHidden, setIsFlightPlanHidden] = useState(false);

  const digitalRefuelRawMessage = flightDetail.digitalRefuelRawMessage;

  const reOrderCrewListByRank = (ufi: string, crewList: any) => {

    const CXCrewRankOrder = ['CN', 'FO', 'SO', 'IM', 'SP', 'FP', 'BC'];
    const UOCrewRankOrder = ['CN', 'FO', 'SP', 'FP', 'FA'];

    const airlineICAO = ufi.substring(0, 3);
    const airlineIATA = Airline2IATA[airlineICAO];

    let desiredSequence = airlineIATA === 'CX'? CXCrewRankOrder : UOCrewRankOrder;

    const crewListCopy = [...crewList];
    crewListCopy.sort((a, b) => {
      const aIndex = desiredSequence.indexOf(a.rankCode);
      const bIndex = desiredSequence.indexOf(b.rankCode);
      return aIndex - bIndex;
    });
    setCurrentCrewsObj(crewListCopy);
  }

  const displayLoadSheetStatus = (loadSheetObj: any) => {
    let displayLabel = '--';
    if(!_.isEmpty(loadSheetObj.acceptRaw)){
      displayLabel = loadSheetObj.accept === true ? `Acknowledged by: ${loadSheetObj.galacxyId} ${moment.utc(loadSheetObj.mqsenttime).format('HHmm[Z]')}`
          : `Rejected: ${loadSheetObj.rejectReason}`
    }
    return displayLabel;
  }

  const getOfpCreatTimeBySerial = (serial: string) => {
    const correspondingOfp = _.find(flightDetail?.flightMessageAndProcessHistory, (processHistory: any) => {
        return processHistory.serial === serial;
    });
    return !!correspondingOfp ? correspondingOfp.firstReceive : '';
  }

  const changeOFP = (ofp: string) => {
    setCurrentOFP(ofp);
    let tempCurrentObj = {
      edg: flightDetail.edgs.find(edg => edg.serial === ofp),
      snn: flightDetail.lidoPlans.find(plan => plan.serial === ofp)?.flightplan?.snn?.text.split("\n")
    }
    // console.log(tempCurrentObj);
    setCurrentOFPObj(tempCurrentObj);
  }

  useEffect(()=> {
    if(flightDetail?.lidoPlans && flightDetail.lidoPlans.length > 0){
      changeOFP(flightDetail.lidoPlans[0].serial)
      //TODO: to enhance isDraftMZFW display logic whether display according to serial or UFI, tbc by phase 2 requirement
      setIsFlightPlanHidden(flightDetail.lidoPlans[0].isDraftMZFW || false);
    }
    if(!!flightDetail?.crewlist?.crews && flightDetail?.crewlist?.crews.length !== 0){
      reOrderCrewListByRank(ufi, flightDetail?.crewlist?.crews);
    }
  },[flightDetail])

  const getDigitalRefuelObj = (platformName, summariesData) => {
    let digitalRefuelStatus = "";
    let sumState = "";
    if(summariesData){
      if(summariesData.length > 1) {
        const sortSummaries = Object.values(summariesData).sort((a: any, b: any) => {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf()
        });
        sumState = _.get(sortSummaries[0],'state', "--");
      } else {
        sumState = _.get(summariesData[0],'state', "--");
      }

      if(sumState === "TicketRequired")
        digitalRefuelStatus = "Refuel Rejected";
      else if(sumState === "Acknowledged"){
        digitalRefuelStatus = "Refuel Accept";
      }
      else{
        digitalRefuelStatus = "Refuel Completed";
      }
    }

    const  digitalRefuelObj = [
        ["Digital", "string"],
        [digitalRefuelStatus, "string"],
        [platformName, "string"]
      ];
    return digitalRefuelObj;
  }

  const digitalRefuel = (flightDetail: any) => {
    let digitalRefuelObj;
    if((flightDetail?.id1Milestones && flightDetail.id1Milestones.length > 0)  || (flightDetail?.id1Summaries && flightDetail.id1Summaries.length > 0)){
      digitalRefuelObj = getDigitalRefuelObj("ID1", flightDetail?.id1Summaries);
    } else if((flightDetail?.ebitsMilestones && flightDetail.ebitsMilestones.length > 0) || (flightDetail?.ebitsSummaries && flightDetail.ebitsSummaries.length > 0)){
      digitalRefuelObj = getDigitalRefuelObj("Ebits", flightDetail?.ebitsSummaries);
    } else if((flightDetail?.mfMilestones && flightDetail.mfMilestones.length > 0) || (flightDetail?.mfSummaries && flightDetail.mfSummaries.length > 0)){
      digitalRefuelObj = getDigitalRefuelObj("MF", flightDetail?.mfSummaries);
    } else {
      return <NoDataTableItem colSpan={14} message={`There is no Digital Refueling Status.`} />;
    }

    return <>
            <DetailTableRow displayData={digitalRefuelObj} count={0} index={0} noLineBreak={true} hasArrow={false} />
          </>
  }

  const downloadPDFHandler = useCallback(async (fileName) => {
    const resp = await getResource(user, "pdf", encodeURIComponent(fileName));
    const fileURL = URL.createObjectURL(resp);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = fileName;
    alink.click();
  }, []);

  const digitalRefuelLastUpdate = (flightDetail: any) => {
    if(flightDetail?.id1Milestones && flightDetail?.id1Milestones.length > 0){
      return flightDetail.id1Milestones[flightDetail.id1Milestones.length-1].created;
    }
    if(flightDetail?.ebitsMilestones && flightDetail?.ebitsMilestones.length > 0){
      return flightDetail.ebitsMilestones[flightDetail.ebitsMilestones.length-1].created;
    }
    if(flightDetail?.mfMilestones && flightDetail?.mfMilestones.length > 0){
      return flightDetail.mfMilestones[flightDetail.mfMilestones.length-1].created;
    }

    return "--";
  }

const mapDigitalRefuelingContent = (contentType:REFUELING_CONTENT_TYPE, refuelingData) =>{
  let objMapping = {};
  let platformName = '';
  switch(contentType){
    case REFUELING_CONTENT_TYPE.ID1_SUM:
      objMapping = ID1_SUMMARIES_KEYS_MAPPING;
      platformName = 'ID1';
      break;
    case REFUELING_CONTENT_TYPE.EBITS_SUM:
      objMapping = EBITS_SUMMARIES_KEYS_MAPPING;
      platformName = 'Ebits';
      break;
    case REFUELING_CONTENT_TYPE.MF_SUM:
      objMapping = MF_SUMMARIES_KEYS_MAPPING;
      platformName = 'MF';
      break;
    case REFUELING_CONTENT_TYPE.ID1_MILESTONE:
      objMapping = ID1_MILESTONES_KEYS_MAPPING;
      platformName = 'ID1';
      break;
    case REFUELING_CONTENT_TYPE.EBITS_MILESTONE:
      objMapping = EBITS_MILESTONES_KEYS_MAPPING;
      platformName = 'Ebits';
      break;
    case REFUELING_CONTENT_TYPE.MF_MILESTONE:
      objMapping = MF_MILESTONES_KEYS_MAPPING;
      platformName = 'MF';
      break;
    default:
      break;
  }

  const contentPrefix = `Platform: ${platformName} \n \n`;
  const contentArr = refuelingData && objMapping && Object.keys(objMapping).map(key =>
    `${objMapping[key]}: ${refuelingData[key] || (refuelingData[key] === 0 ? 0 : "--")}  \n \n`
  )

  return  _.concat(contentPrefix, contentArr);
}

  const changeIsFlightPlanHidden = (event) => {
    const isHiddenCheckboxValue = event.target.checked;
    if(!ufi){
      dispatch(pushToast({ type: "error", message: "UFI missing" }));
      return;
    }
    const req = {"ufi": ufi , "isHidden": isHiddenCheckboxValue};
    postAdjustFlightPlan(req).then((res) => {
      if(res?.success){
        dispatch(pushToast({ type: "success", message: `${ufi} Flight plan is successfully ${isHiddenCheckboxValue? 'hidden': 'unhidden'}` }));
        setIsFlightPlanHidden(isHiddenCheckboxValue)
      }
    }).catch(err => {
      console.warn(err);
    });
  };

  return (
    <>
      <div className={"breadcrumbs"}>
        <Link to={`${CLOSE_FLIGHT_PATHS.closeFlight}`} style={{ margin: "4px" }}> Dispatcher </Link> / <span style={{ margin: "4px" }}> Flight plan details </span>
      </div>
      <div className='m-0 d-flex align-items-center' style={{ padding: "19px 4px" }}>
        <div className={"pageHeading CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px", whiteSpace: "pre-wrap" }}> {ufiFormatter(ufi)}</div>
      </div>

      {/* --------------------------------- Start of Flight detail --------------------------------- */}
      <div className={"simOFPDetailBody"} style={{ margin: "20px 0px", padding: "16px 24px" }}>
        <div style={{ marginBottom: "35px" }}>
          <CRow>
            <CCol xs={1}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Reg</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{flightDetail?.reg || "--"}</div>
              </div>
            </CCol>
            <CCol xs={1}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Flight no</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{flightDetail?.flightNo || "--"}</div>
              </div>
            </CCol>
            <CCol xs={3}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Flight date</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{flightDetail?.flightDate ? dateFormatter(flightDetail.flightDate).toUpperCase() : "--"}</div>
              </div>
            </CCol>
            <CCol xs={1}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Dep</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{flightDetail?.dep || "--"}</div>
              </div>
            </CCol>
            <CCol xs={1}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>Arr</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{flightDetail?.arr || "--"}</div>
              </div>
            </CCol>
            <CCol xs={2}>
              <div className={"d-flex flex-column"}>
                <div className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }}>STD</div>
                <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>{flightDetail?.std ? dateFormatter(flightDetail.std).toUpperCase() : "--"}</div>
              </div>
            </CCol>
            {isSupervisor && (
              <CCol xs={2}>
                <div className={"d-flex flex-column"} style={{width: '100%', height: '100%', justifyContent: 'center',}}>
                  <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", letterSpacing: "0.3px", color: "#2D2D2D" }}>
                  <input
                    style={{marginRight: "5px"}}
                    type="checkbox"
                    checked={isFlightPlanHidden}
                    onChange={changeIsFlightPlanHidden}
                  />
                    Hide this flight plan
                  </div>
                </div>
              </CCol>
            )}
          </CRow>
        </div>
        <CRow>
          <CCol>
            <CTable borderless className={"m-0"}>
              <CTableHead>
                <CTableRow>
                  {[
                    "Serial",
                    "Create time",
                    "Activated By",
                    "Activated time"
                  ].map((heading, index) => {
                    return <CTableHeaderCell className={"CathaySansRg px-0"}
                                             style={{
                                               fontWeight: 400,
                                               fontSize: "14px",
                                               lineHeight: "18px",
                                               letterSpacing: "0.3px",
                                               background: "#E7F1F5",
                                               color: "#4C4C4C"
                                             }} key={`${heading}${index}`} scope="col">{heading}</CTableHeaderCell>;

                  })}
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {
                  (flightDetail && flightDetail.lidoPlans && flightDetail.lidoPlans.length !== 0) ?
                    <>
                      {/* <CTableDataCell align="top" key={`flightDetailSerial`} className={"RobotoMonoRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "#000000" }}><div className={"RobotoMonoAlign"}>{data[0].toUpperCase()}</div></CTableDataCell> */}
                      {flightDetail.lidoPlans.map((flightPlan: any, index) => {
                        let lidoFlightRecord = {
                          serial: [flightPlan?.serial || "--", "clickable"],
                          flightPlanCreatedTime: [dateFormatter(getOfpCreatTimeBySerial(flightPlan?.serial))?.toUpperCase() || "--", "string"],
                          activatedBy: [flightPlan?.serial === flightDetail?.activatePlan?.serial ? flightDetail?.activatePlan?.galacxyId: "--", "string"],
                          activatedTime: [flightPlan?.serial === flightDetail?.activatePlan?.serial ? flightDetail?.activatePlan?.createAt: "--", "string"]
                        };
                        return <DetailTableRow displayData={lidoFlightRecord} count={closeFlightCount} index={index} hasArrow={false} clickableFunc={changeOFP} isFlightStatusPanel={true}/>
                      })}
                    </>
                    :
                    <NoDataTableItem colSpan={14} message={`There is no lido flight plan retrieved.`} />
                }
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>
      </div>
      {/* --------------------------------- End of Flight detail --------------------------------- */}

      {/* --------------------------------- Start of Flight OFP heading --------------------------------- */}
      <div style={{ margin: "40px 0px 32px 0px" }}>
        <CRow>
          <CCol xs={4}>
            <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "24px", lineHeight: "28px", whiteSpace: "pre-wrap", textTransform: "uppercase" }}>
              {currentOFP || "--"}
            </div>
          </CCol>
        </CRow>
      </div>
      {/* --------------------------------- End of Flight OFP heading --------------------------------- */}

      {/* --------------------------------- Start of Flight Message and Process History --------------------------------- */}
      <div style={{ margin: "32px 0px 24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px", letterSpacing: "0.27px" }}>
            Flight Message and Process History
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Serial",
                  "Ver",
                  "Receive count",
                  "Flight date",
                  "New plan receive time",
                  "Processed time"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                (!!flightDetail?.flightMessageAndProcessHistory && flightDetail?.flightMessageAndProcessHistory.length !== 0) ?
                flightDetail?.flightMessageAndProcessHistory.map((data: any, index) => {
                    let recordCount = flightDetail.flightMessageAndProcessHistory.length;
                    let record = {
                      serial: [data?.serial || "--", "string"],
                      ver: [data?.ver || "--", "string"],
                      receiveCount: [data?.receiveCount || "--", "string"],
                      flightDate: [dateFormatter(data?.flightDate) || "  ", "date"],
                      firstReceive: [dateFormatter(data?.firstReceive) || "  ", "date"],
                      processedTime: [dateFormatter(data?.processedTime) || "  ", "date"],
                      key: [data?.key || "", "download"],
                      ofpPackage: [data?.lidoOfpKey || "", "downloadOfp"]
                    };
                    return <DetailTableRow displayData={record} count={recordCount} index={index} hasArrow={false} user={user} ufi={ufi}/>
                  })
                  :
                  <NoDataTableItem colSpan={14} message={`There is no flight plan history.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Flight Message and Process History --------------------------------- */}


      {/* --------------------------------- Start of Snn --------------------------------- */}
        <div style={{ margin: "40px 0px 32px 0px" }}>
          <AppSectionOutline>
            <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
              SNN
            </div>
            {currentOFPObj?.snn ?
              currentOFPObj.snn.map((snn, index) => <div key={`SNN_${index}`}>{snn}</div>)
              :
              <NoDataTableItem colSpan={14} message={`There is no SNN.`} />
            }
          </AppSectionOutline>
        </div>
      {/* --------------------------------- End of Flight OFP heading --------------------------------- */}

      {/* --------------------------------- Start of Crew Sign On --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Crew Sign On
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Rank",
                  "Badge name",
                  "Last name",
                  "Crew Name",
                  "ERN",
                  "Galacxy ID",
                  "SD code",
                  "Sign on time",
                  "Sign on location"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                (!!flightDetail?.crewSignOns && flightDetail?.crewSignOns.length !== 0) ?
                flightDetail?.crewSignOns.map((crewData: any, index) => {
                    let recordCount = flightDetail.crewSignOns.length;
                    let flightMessageAndProcessHistory = {
                      category: [crewData?.category || "--", "string"],
                      badgeName: [crewData?.badgeName || "--", "bold string"],
                      lstNm: [crewData?.lstNm || "--", "string"],
                      crewName: [crewData?.crewName || "--", "string"],
                      currentErn: [crewData?.currentErn || "--", "string"],
                      galacxyId: [crewData?.galacxyId || "--", "string"],
                      specialDutyCode: [crewData?.specialDutyCode || "--", "string"],
                      signOnTime: [crewData?.created || "--", "date"],
                      signOnLocation: [[crewData?.lat || "22.297744033358153", crewData?.lng || "113.93436961849224", crewData?.location || "--"], "location"],
                    };
                    return <DetailTableRow displayData={flightMessageAndProcessHistory} count={recordCount} index={index} hasArrow={false} />
                  })
                  :
                  <NoDataTableItem colSpan={14} message={`There is no Crew Sign on Data.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Crew Sign On --------------------------------- */}


    {/* --------------------------------- Start of Crew List --------------------------------- */}
    <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "14px" }}>
            Crew List
          </div>
          <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            <p style={ {margin: 0, padding: 0}}>Last updated: {flightDetail?.crewlist?.updatedAt || "--"}</p>
            <p style={ {margin: 0, padding: 0}}>Created at: {flightDetail?.crewlist?.retrievedAt || "--"}</p>
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Rank",
                  "Badge name",
                  "Last name",
                  "ERN",
                  "Galacxy ID",
                  "SD code"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                currentCrewsObj ?
                    currentCrewsObj.map((crewData: any, index) => {
                    let recordCount = flightDetail.crewlist?.crews?.length;
                    let flightMessageAndProcessHistory = {
                      rankCode: [crewData?.rankCode || "--", "string"],
                      badgeName: [crewData?.badgeName || "--", "bold string"],
                      lstNm: [crewData?.lstNm || "--", "string"],
                      currentErn: [crewData?.currentErn || "--", "string"],
                      galacxyId: [crewData?.galacxyId || "--", "string"],
                      specialDutyCode: [crewData?.specialDutyCode.length > 0 ? crewData?.specialDutyCode : "--", "string"],
                    };
                    return <DetailTableRow displayData={flightMessageAndProcessHistory} count={recordCount} index={index} hasArrow={false} />
                  })
                  :
                  <NoDataTableItem colSpan={14} message={`There is no Crew List Data.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Crew List --------------------------------- */}


      {/* --------------------------------- Start of Loadsheet --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "36px" }}>
            {"Loadsheet (ZFW)"}
          </div>
          {(!!flightDetail?.allLoadsheet && flightDetail?.allLoadsheet.length !== 0) ?
                <div className={"d-flex"} style={{ overflow: "scroll" }}>
                  {flightDetail.allLoadsheet.slice().reverse().map((loadsheet, index) => {
                    return <div style={{ padding: "30px 0px 16px 24px" }} key={`loadsheet${index}`}>
                      <div>
                        {loadsheet.type === "LOADSHEET" ? <span className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "16px", lineHeight: "20px" }}>{loadsheet.type} - {loadsheet.version}</span>
                          : <span className={"CathaySansRg"} style={{ fontWeight: 400, fontSize: "16px", lineHeight: "20px" }}>{loadsheet.type}</span>}
                      </div>
                      <div style={{ marginBottom: "16px" }}>
                        {
                            loadsheet.accept != null &&
                            <div><span className={"CathaySansRg"}
                                       style={{fontWeight: 400, fontSize: "16px", lineHeight: "20px"}}>
                             {displayLoadSheetStatus(loadsheet)}
                           </span>
                            </div>
                        }
                      </div>
                      <div className={"RobotoMonoRg"} style={{ overflow: "scroll", border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D", background: "#ffffff" }}>
                        {loadsheet.raw || ""}
                      </div>
                    </div>
                  })}
                </div>
                : <NoData text={"No Loadhsheet retrieved"} />}

        </AppSectionOutline>
      </div >
      {/* --------------------------------- End of Loadsheet --------------------------------- */}

      {/* --------------------------------- Start of Standby Fuel History --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Stand By Fuel History
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Serial",
                  "Total Fuel",
                  "Ebits Time",
                  "Id1 Time",
                  "Email Time",
                  "MF Receive Time",
                  "Created At",
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                (!!flightDetail?.standByFuels && flightDetail.standByFuels.length !== 0) ?
                flightDetail.standByFuels.map((action, index) => {
                    let standByFuel = [
                      [action?.serial || "--", "string"],
                      [action?.standbyfuel || "--", "string"],
                      [action?.ebitsTime ? dateFormatter(action.ebitsTime) : "--", "date"],
                      [action?.id1Time ? dateFormatter(action.id1Time) : "--", "date"],
                      [action?.emailTime ? dateFormatter(action.emailTime) : "--", "date"],
                      [action?.messageTime ? dateFormatter(action.messageTime) : "--", "date"],
                      [action?.created ? dateFormatter(action.created) : "--", "date"]
                    ];
                    return <DetailTableRow key={`finalFuelAction${index}`} displayData={standByFuel} count={0} index={0} noLineBreak={true} hasArrow={false} />
                  })
                  :
                  <NoDataTableItem colSpan={14} message={`There is no Accept Fuel History Data.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Standby Fuel History --------------------------------- */}


      {/* --------------------------------- Start of Final Fuel History --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            Final Fuel History
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Serial",
                  "Galacxy ID",
                  "Value",
                  "Total Fuel",
                  "Accept fuel time"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                flightDetail?.acceptFuel ?
                    <DetailTableRow key={`acceptFuel`} displayData={[
                      [flightDetail?.acceptFuel?.serial || "--", "string"],
                      [flightDetail?.acceptFuel?.galacxyId || "--", "string"],
                      [flightDetail?.acceptFuel?.value || "--", "string"],
                      [flightDetail?.acceptFuel?.totalFuel || "--", "string"],
                      [flightDetail?.acceptFuel?.createAt ? dateFormatter(flightDetail?.acceptFuel?.createAt) : "--", "date"]
                    ]} count={0} index={0} noLineBreak={true} hasArrow={false} />

                  :
                  <NoDataTableItem colSpan={14} message={`There is no Accept Fuel History Data.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Final Fuel History --------------------------------- */}

      {/* --------------------------------- Start of Digital Refueling Status--------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "14px" }}>
            Digital Refueling Status
          </div>
          <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            <span>Last updated: {digitalRefuelLastUpdate(flightDetail)}</span>
          </div>
          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Type",
                  "Status",
                  "Platform"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
            {digitalRefuel(flightDetail)}
            </CTableBody>
          </CTable>
          <div style={{ background: "#F5F5F5", borderRadius: "16px", padding: "22px 25px", marginTop: "24px" }}>
            <div className={"clickable"} style={{ background: "#F5F5F5", position: "relative" }} onClick={() => { setExpandDigitalRefuel(!expandDigitalRefuel) }}>
              <div className={"loadsheetArrow"}>
                {expandDigitalRefuel ? <DownArrowIcon height={"24px"} width={"24px"} /> : <CenteredRightArrowIcon height={"24px"} width={"24px"} />}
              </div>
              <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", paddingLeft: "25px" }}>Digital Milestones</div>
            </div>
            {expandDigitalRefuel &&
              <>
                {((flightDetail?.id1Milestones && Array.isArray(flightDetail.id1Milestones)) ||
                  (flightDetail?.ebitsMilestones && Array.isArray(flightDetail.ebitsMilestones)) ||
                  (flightDetail?.mfMilestones && Array.isArray(flightDetail.mfMilestones))) &&
                  <div className={"d-flex"} style={{ overflow: "scroll" }}>
                    {flightDetail?.id1Milestones?.map((milestone, index) => {
                      return <div style={{ padding: "30px 0px 16px 24px" }} key={`loadsheet${index}`}>
                        <div className={"RobotoMonoRg"} style={{ overflow: "scroll", border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D", background: "#ffffff" }}>
                          { mapDigitalRefuelingContent(REFUELING_CONTENT_TYPE.ID1_MILESTONE, milestone) }
                        </div>
                      </div>
                    })}
                    {flightDetail?.ebitsMilestones?.map((milestone, index) => {
                      return <div style={{ padding: "30px 0px 16px 24px" }} key={`loadsheet${index}`}>
                        <div className={"RobotoMonoRg"} style={{ overflow: "scroll", border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D", background: "#ffffff" }}>
                          { mapDigitalRefuelingContent(REFUELING_CONTENT_TYPE.EBITS_MILESTONE, milestone) }
                        </div>
                      </div>
                    })}
                    {flightDetail?.mfMilestones?.map((milestone, index) => {
                      return <div style={{ padding: "30px 0px 16px 24px" }} key={`loadsheet${index}`}>
                        <div className={"RobotoMonoRg"} style={{ overflow: "scroll", border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D", background: "#ffffff" }}>
                          { mapDigitalRefuelingContent(REFUELING_CONTENT_TYPE.MF_MILESTONE, milestone) }
                        </div>
                      </div>
                    })}
                  </div>
                }
                { (!flightDetail.id1Milestones || (flightDetail.id1Milestones && flightDetail.id1Milestones.length == 0)) &&
                    (!flightDetail.ebitsMilestones || (flightDetail.ebitsMilestones && flightDetail.ebitsMilestones.length == 0)) &&
                    (!flightDetail.mfMilestones || (flightDetail.mfMilestones && flightDetail.mfMilestones.length == 0)) &&
                  <NoData text={"No Digital Refueling Status retrieved"} />
                }
              </>
            }
          </div>
          <div style={{ background: "#F5F5F5", borderRadius: "16px", padding: "22px 25px", marginTop: "24px" }}>
            <div className={"clickable"} style={{ background: "#F5F5F5", position: "relative" }} onClick={() => { setExpandDigitalSummary(!expandDigitalSummary) }}>
              <div className={"loadsheetArrow"}>
                {expandDigitalSummary ? <DownArrowIcon height={"24px"} width={"24px"} /> : <CenteredRightArrowIcon height={"24px"} width={"24px"} />}
              </div>
              <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", paddingLeft: "25px" }}>Digital Summaries</div>
            </div>
            {expandDigitalSummary &&
              <>
                {((flightDetail?.id1Summaries && Array.isArray(flightDetail.id1Summaries)) ||
                  (flightDetail?.ebitsSummaries && Array.isArray(flightDetail.ebitsSummaries)) ||
                  (flightDetail?.mfSummaries && Array.isArray(flightDetail.mfSummaries))) &&
                  <div className={"d-flex"} style={{ overflow: "scroll" }}>
                    {flightDetail?.id1Summaries?.map((summary, index) => {
                      return <div style={{ padding: "30px 0px 16px 24px" }} key={`loadsheet${index}`}>
                        <div className={"RobotoMonoRg"} style={{ overflow: "scroll", border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D", background: "#ffffff" }}>
                          { mapDigitalRefuelingContent(REFUELING_CONTENT_TYPE.ID1_SUM, summary) }
                        </div>
                      </div>
                    })}
                    {flightDetail?.ebitsSummaries?.map((summary, index) => {
                      return <div style={{ padding: "30px 0px 16px 24px" }} key={`loadsheet${index}`}>
                        <div className={"RobotoMonoRg"} style={{ overflow: "scroll", border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D", background: "#ffffff" }}>
                          { mapDigitalRefuelingContent(REFUELING_CONTENT_TYPE.EBITS_SUM, summary) }
                        </div>
                      </div>
                    })}
                    {flightDetail?.mfSummaries?.map((summary, index) => {
                      return <div style={{ padding: "30px 0px 16px 24px" }} key={`loadsheet${index}`}>
                        <div className={"RobotoMonoRg"} style={{ overflow: "scroll", border: "1px solid #4C4C4C", borderRadius: "4px", width: "356px", whiteSpace: "pre-line", padding: "15px 8px", color: "#2D2D2D", background: "#ffffff" }}>
                          { mapDigitalRefuelingContent(REFUELING_CONTENT_TYPE.MF_SUM, summary) }
                        </div>
                      </div>
                    })}
                  </div>
                }
                { (!flightDetail.id1Summaries || (flightDetail.id1Summaries && flightDetail.id1Summaries.length == 0)) &&
                  (!flightDetail.ebitsSummaries || (flightDetail.ebitsSummaries && flightDetail.ebitsSummaries.length == 0)) &&
                  (!flightDetail.mfSummaries || (flightDetail.mfSummaries && flightDetail.mfSummaries.length == 0)) &&
                  <NoData text={"No Digital Refueling Summaries retrieved"} />
                }
              </>
            }
          </div>
          {/* --------------------------------- Start of Digital Refuel RAW Message --------------------------------- */}
          <CXAccordion
            title={"Digital Refuel RAW Message (Ebits Raws excluded)"}
          >

              {
                (digitalRefuelRawMessage &&
                  (
                  !(_.isEmpty(digitalRefuelRawMessage.id1Raws.data)) ||
                  !(_.isEmpty(digitalRefuelRawMessage.mfRaws.data)))
                ) ?
                  <>
                      {
                        !(_.isEmpty(digitalRefuelRawMessage.id1Raws.data)) ?
                        <div className={"d-flex"} style={{ flexWrap: "nowrap", overflowX: "scroll" }}>
                          {
                            digitalRefuelRawMessage.id1Raws.data.map(id1RawsRow => {
                              // const raw = id1RawsRow.raw
                              const { raw, ...json } = id1RawsRow;
                              return <CXScrollBox>
                                <p>{`Source: ${digitalRefuelRawMessage.id1Raws.dataSourceName}`}</p>
                                <hr />
                                <p><b>Raw:</b></p>
                                <p>{raw && typeof (raw) === "string" ? raw + `\n` : null}</p>
                                <hr />
                                <p>{json && _.isPlainObject(json) ?
                                  Object.keys(json).map(key => <div>{`${key}: ${getNestedObjectText(json, key)}`}</div>) : null}
                                </p>
                                </CXScrollBox>
                            })
                          }
                        </div> : null}
                      {
                        !(_.isEmpty(digitalRefuelRawMessage.mfRaws.data)) ?
                        <div className={"d-flex"} style={{ flexWrap: "nowrap", overflowX: "scroll" }}>
                          {
                            digitalRefuelRawMessage.mfRaws.data.map(mfRawsRow => {
                              const { raw, ...json } = mfRawsRow;
                              return <CXScrollBox>
                                <p>{`Source: ${digitalRefuelRawMessage.mfRaws.dataSourceName}`}</p>
                                <hr />
                                <p><b>Raw:</b></p>
                                <p>{raw && _.isPlainObject(raw) ?
                                  Object.keys(raw).map(key => <div>{`${key}: ${getNestedObjectText(raw, key)}`}</div>) : null}
                                </p>
                                <hr />
                                <p>{json && _.isPlainObject(json) ?
                                  Object.keys(json).map(key => <div>{`${key}: ${getNestedObjectText(json, key)}`}</div>) : null}
                                </p>
                                </CXScrollBox>
                            })
                          }
                        </div> : null}
                  </> : <NoData text={"No Digital Refuel RAW Message retrieved"} />
                }
          </CXAccordion>
          {/* --------------------------------- End of Digital Refuel RAW Message --------------------------------- */}
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Digital Refueling Status --------------------------------- */}

      {/* --------------------------------- Start of Enroute Diversion Guide --------------------------------- */}
      <div style={{ margin: "24px 0px" }}>
        <AppSectionOutline>
          <div className={"CathaySansRg"} style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "14px" }}>
            Enroute Diversion Guide
          </div>
          <div className={"CathaySansRg"} style={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px", color: "#4C4C4C", marginBottom: "24px" }}>
            <span>Last updated: {( currentOFPObj?.edg ? currentOFPObj?.edg.updateAt : "--")}</span>
          </div>

          <CTable borderless className={"m-0"}>
            <CTableHead>
              <CTableRow>
                {[
                  "Type",
                  "Received"
                ].map((heading, index) => {
                  return <TableHeading text={heading} index={index} />
                })}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {
                currentOFPObj?.edg ?
                <>
                  <DetailTableRow key={`Enroute_diversion_DP`} displayData={[
                    ["DP", "string"],
                    [currentOFPObj.edg?.depress ? "True" : "False" || "--", "string"],
                    [currentOFPObj.edg?.value || "--", "string"],
                  ]} user={user} count={0} index={0} noLineBreak={true} hasArrow={false} />
                  <DetailTableRow key={`Enroute_diversion_1EO`} displayData={[
                    ["1EO", "string"],
                    [currentOFPObj.edg?.enginop1e ? "True" : "False" || "--", "string"],
                    [currentOFPObj.edg?.value || "--", "string"],
                  ]} count={0} index={0} noLineBreak={true} hasArrow={false} />
                  <DetailTableRow key={`Enroute_diversion_2EO`} displayData={[
                    ["2EO", "string"],
                    [currentOFPObj.edg?.enginop2e ? "True" : "False" || "--", "string"],
                    [currentOFPObj.edg?.value || "--", "string"],
                  ]} count={0} index={0} noLineBreak={true} hasArrow={false} />
                  <DetailTableRow key={`Enroute_diversion_EO`} displayData={[
                    ["EO", "string"],
                    [currentOFPObj.edg?.enginop ? "True" : "False" || "--", "string"],
                    [currentOFPObj.edg?.value || "--", "string"],
                  ]} count={0} index={0} noLineBreak={true} hasArrow={false} />
                </>
                :
                <NoDataTableItem colSpan={14} message={`There is no Enroute Diversion Guide.`} />
              }
            </CTableBody>
          </CTable>
        </AppSectionOutline>
      </div>
      {/* --------------------------------- End of Enroute Diversion Guide --------------------------------- */}


    </>
  );
};

const TableHeading = ({ text, index }) => {
  return <CTableHeaderCell className={"CathaySansRg px-0"} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "18px", letterSpacing: "0.3px", color: "#4C4C4C" }} key={`${text}${index}`} scope="col">{text}</CTableHeaderCell>;
};

const NoData = ({ text }) => {
  return <CRow>
    <CCol>
      <CTable borderless className={"m-0"}>
        <CTableHead>
          <CTableRow>
            {[].map((heading, index) => {
              return <TableHeading text={heading} index={index} />
            })}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {
            <NoDataTableItem colSpan={1} message={text} />
          }
        </CTableBody>
      </CTable>
    </CCol>
  </CRow>
};

const getNestedObjectText = (obj: any, key: string) => {
  const value = _.isPlainObject(obj[key]) ? JSON.stringify(obj[key]) : obj[key];
  return value;
}

export default FlightDetailContent;
