import React from "react";
import "./style/style.scss";

interface ICXButtonProps {
  text?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  fontSize?: string;
  outline?: boolean;
  textColor?: string;
  backgroundColor?: string;
  borderRadius?: string;
  disabled?: boolean;
  height?: string;
  width?: string;
  padding?: string;
  prefixIcon?: JSX.Element;
}

const CXButton = ({
  text,
  onClick,
  fontSize = "0.875rem",
  textColor = "#FFFFFF",
  backgroundColor = "#116F9A",
  borderRadius = "8px",
  outline = false,
  height = "48px",
  width = "172px",
  padding = "auto",
  disabled = false,
}: ICXButtonProps) => {
  /*
    ----------------------------- Sample component ------------------------------
    <CXButton text={"Add"} onClick={setText2} fontSize={"14px"} outline={false} textColor={"#FFFFFF"} backgroundColor={"#116F9A"} borderRadius={"8px"} height={"48px"}/>
    ----------------------------- Sample component ------------------------------
  */

  const buttonStyle = {
    background: backgroundColor,
    padding: padding,
    height: height,
    width: width,
    border: "0px",
    borderRadius: borderRadius,
  };

  const buttonTextStyle = {
    fontFamily: "CathaySans_Bd",
    fontSize: fontSize,
    color: textColor,
  };

  const outlineButtonStyle = {
    background: textColor,
    padding: padding,
    height: height,
    width: width,
    border: `2px solid ${backgroundColor}`,
    borderRadius: borderRadius,
  };

  const outlineButtonTextStyle = {
    fontFamily: "CathaySans_Bd",
    fontSize: fontSize,
    color: backgroundColor,
  };

  return (
    <button
      className={outline ? "cx-outline-button" : "cx-button"}
      style={outline ? outlineButtonStyle : buttonStyle}
      disabled={disabled}
      onClick={!!onClick ? onClick : () => {}}
    >
      <div className="text-ellipsis" style={outline ? outlineButtonTextStyle : buttonTextStyle}>
        {text || ""}
      </div>
    </button>
  );
};

export default CXButton;
