import React from 'react';
import { CCol, CRow } from '@coreui/react';
import { CXButton } from '../../CXForm';
import { DIGITAL_REFUELING_TYPES } from '../../../constants/simConstant';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { postSimDigitalRefueling } from '../../../services/simAPI';
import { selectSim, updateSimFlightPlans } from '../../../slice/simSlice';
import { pushToast } from '../../../slice/appSlice';

const RefuelingInProgress = () => {
  const dispatch = useAppDispatch();
  const { simOFPFormInEdit } = useAppSelector(selectSim);
  const sentRefuelingInProgress: boolean = !!simOFPFormInEdit?.additionalInfo?.digitalRefueling?.find(milestone => milestone.type === DIGITAL_REFUELING_TYPES.ebitsMilestone)?.count;

  const sendRefuelingInProgress = () => {
    let ufi = simOFPFormInEdit?.ufi;
    if (!!ufi) {
      postSimDigitalRefueling({ 
        ufi, 
        isSummary: false
      }).then(res => {
        // console.log(res);
        dispatch(updateSimFlightPlans(res));
        dispatch(pushToast({type: "success", message: `Start refueling.`}));
      }).catch(err => {
        console.log(err);
      })
    }
  };

  return <div style={{
    width: "642px",
    marginTop: "16px",
  }}>
    <CRow>
      <CCol xs={6}>
      
        <CXButton disabled={sentRefuelingInProgress} text={sentRefuelingInProgress ? "Refuel In Progress" : "Start"} onClick={() => sendRefuelingInProgress()} fontSize={"14px"} outline={false} textColor={"#FFFFFF"} backgroundColor={"#116F9A"} borderRadius={"8px"} height={"48px"} width={"100%"} />
      </CCol>
    </CRow>
  </div>
};

export default RefuelingInProgress;