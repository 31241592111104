import React, { useCallback, useEffect, useState } from 'react';
import { IPortSec } from '../../slice/dispatcherSlice';
import update from 'immutability-helper'
import PortPageContent from './PortPageContent';
import CXButton from '../CXForm/CXButton';
import PortPageContentView from './PortPageContentView';


interface IPortPageContentContainerProps {
  portTabSecs: IPortSec[],
  isEditMode: boolean;
  tabId: string;
  tabIndex: number;
  dispatchReducer: React.Dispatch<any>;
  blurPrefix: Function;
  updateNotam: Function;
  portInfo: any;
  setShowList: Function;
}

const PortPageContentContainer = ({ portTabSecs, isEditMode, tabId, tabIndex, dispatchReducer, portInfo, blurPrefix, updateNotam, setShowList }: IPortPageContentContainerProps) => {

  const [tabContents, setTabContents] = useState<IPortSec[]>(portTabSecs);
  const [tabIdx, setTabIdx] = useState<number>(tabIndex);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    let testFunction = (prevCards: IPortSec[]) => 
        update(prevCards, {
          $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevCards[dragIndex] as IPortSec],
          ],
        })
      
    setTabContents(testFunction);
}, [])

  const addContent = () => {
    dispatchReducer({type:"ADD_SEC", tabIndex: tabIndex, len: tabContents.length});
  }

  const removeTab = (secIndex) => {
    dispatchReducer({type:"REMOVE_SEC", tabIndex: tabIndex, secIndex: secIndex});
  }

  useEffect(()=> {
    setTabContents(portTabSecs);
    setTabIdx(tabIndex);
  },[portTabSecs, tabIndex])
  
  useEffect(()=> {
    setShowList(false);
  }, [])



  const uid = function(index:number){
    return Date.now().toString(36) + Math.random().toString(36) + index.toString();
  }

  return (
    <>
      {tabContents && tabContents.map((tabSec, index) =>
        isEditMode ? 
          <PortPageContentView
            key={tabSec.id ? tabSec.id : uid(index)}
            name={tabSec.name}
            secItems={tabSec.items}
            tabIndex={tabIdx}
            secIndex={index}
            blurPrefix={blurPrefix}
            updateNotam={ updateNotam }
            editTabSecs={tabContents}
            dispatchReducer={ dispatchReducer }
            moveCard={moveCard}
            removeTab={removeTab}
            isDeletable={!tabSec?.required}/>
        :
          <PortPageContent
            key={tabSec.id ? tabSec.id : uid(index)}
            name={tabSec.name}
            secItems={tabSec.items}
            isDeletable={!tabSec?.required}
            index={index}
            moveCard={moveCard}
            removeTab={removeTab}
            editTabSecs={tabContents}
            dispatchReducer={dispatchReducer}
            tabIndex={tabIdx}/>
        
      )}
      {isEditMode && 
        <CXButton outline={true} text={"+ Add new section"} onClick={()=>addContent()} disabled={!isEditMode} fontSize={"16px"} width={"18%"} />
      }
      </>
  );
};

export default PortPageContentContainer;