import React from 'react';
/**
 * Application home page component
 * @returns {JSX}
 */
const LandingPage = (props) => {
    return (
        <>
            <div className={"CathaySansBd"} style={{fontSize: "32px", lineHeight: "36px"}}>Welcome!</div>
        </>
    );
};

export default LandingPage;