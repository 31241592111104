import React, { useState } from 'react';
import { CDatePicker } from '@coreui/react-pro';
import './style/style.scss';
import { CalenderIcon } from './assets/images';
import moment from 'moment';

interface ICXDatePickerProps {
  timepicker?: boolean;
  disabled?: boolean;
  zIndex?: number;
  onChange: Function;
  placeholder: string;
  value: string;
  onChangeField?: Function;
  field?: string;
  state?: any;
  hasDefault?: boolean;
  additionalValidation?: Function;
  errMsg?: string;
}

const CXDatePicker = ({ disabled = false, timepicker = false, zIndex, onChange, placeholder, value, onChangeField, field, state, hasDefault = true, additionalValidation, errMsg }: ICXDatePickerProps) => {
  const [focus, setFocus] = useState(false);
  const [dateTime, setDateTime] = useState<Date | null>(new Date());
  /*
    ----------------------------- Sample component ------------------------------
    const [displayDate, setDisplayDate] = useState([pass in UTC date]);
    <CXDatePicker placeholder={"STD"} value={displayDate} onChange={setDisplayDate} timepicker={true} />
    ----------------------------- Sample component ------------------------------
  */

  /* 
    ----------------------------- Save for future debugging -----------------------------
    console.log(` 1. first enter ${value} `);
    console.log(` 2. CDatePicker date ${!!value ? `${moment.utc(new Date(value)).format("YYYY-MM-DDTHH:mm:ss")}Z` : `${moment.utc().format("YYYY-MM-DDTHH:mm:ss")}Z`} `);
    ----------------------------- Save for future debugging -----------------------------
  */

  const toggleFocus = () => {
    if (!disabled) {
      setFocus(!focus);
    }
  };

  // selectedDate would be set to the time you selected 
  // but be changed into the timezone of your device
  const select = (selectedDate) => {
    // return utc time to onChange function
    // frankie version
    // ${moment.utc(new Date(selectedDate)).add(8, 'hours').format("YYYY-MM-DDTHH:mm:ss")}
    const dateStr = moment(selectedDate).format('YYYY-MM-DDTHH:mm:SS');
    if(onChangeField){
      onChangeField(`${dateStr}Z`, field, state, onChange);
    }else{
      onChange(`${dateStr}Z`);
    }
    setFocus(false);
  };

  return (
    <>
      <div className={"cx-datepicker"} style={{ zIndex: !!zIndex ? zIndex : 1038 }}>
        <div className={"cx-form-control"}>
          {hasDefault ? 
            <CDatePicker className={`cx-datepicker-body ${additionalValidation && !additionalValidation(value) ? "error-border": "" }`} date={!!value ? `${moment(new Date(value)).format("YYYY-MM-DDTHH:mm:ss")}Z` : `${moment().format("YYYY-MM-DDTHH:mm:ss")}Z`} indicator={false} placeholder={""}
              onDateChange={(selectedDate) => select(selectedDate)}
              timepicker={timepicker}
            />
            :
            <CDatePicker className={`cx-datepicker-body ${additionalValidation && !additionalValidation(value) ? "error-border": "" }`} indicator={false}
              onDateChange={(selectedDate) => { setDateTime(selectedDate)}}
              onConfirm={()=>{ select(dateTime)}}
              timepicker={timepicker}
              footer
            />
          }
          <CalenderIcon className={`cx-calender-icon dropdown-toggle ${disabled ? "cx-disabled" : ""}`} height={16} width={16} />
          {(focus || !!value) && <div className={"focused-label cx-datepicker-labels"} onClick={toggleFocus}>{placeholder}</div>}
          {(focus || !!value) && <div className={"selected-date cx-datepicker-labels"}>{!!value ? moment.utc(new Date(value)).format(timepicker ? "DDMMMYYYY,HH:mm" : "DDMMMYYYY") : ""}</div>}
          {(!focus && !value) && <div className={`not-focused-label cx-datepicker-labels ${disabled ? "cx-disabled" : ""}`} onClick={toggleFocus}>{placeholder}</div>}
        </div>
      </div>
      {additionalValidation && !additionalValidation(value) && !!errMsg && (
        <div className={"cx-error-message"}>{errMsg}</div>
      )}
    </>
  );
};

export default CXDatePicker;